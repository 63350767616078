import * as React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'react-bootstrap';

export const AlertExpiredTeacher = ({ isActive }: { isActive: boolean }) => {
  if (isActive === true) {
    return <h1>Lesson Levels</h1>;
  } else {
    return (
      <h1 className="text-center">
        <span>
          Welcome back to your GrammarFlip account! You must have an active
          subscription in order to view lessons.
          <LinkContainer to={`/payment`}>
            <Button bsStyle="link" bsSize="sm" className="update-profile-link">
              Activate Subscription
            </Button>
          </LinkContainer>
        </span>
      </h1>
    );
  }
};
