import * as types from './actionTypes';

import Axios, { AxiosRequestConfig } from 'axios';
import { GFWritingAssignmentResult, ThunkResult } from '../models/models';

import { API } from '../apiEndpoints';
import { beginAjaxCall } from './ajaxStatusActions';
import constants from '../constants';
import { sortBy } from 'lodash';
import { toastr } from 'react-redux-toastr';

export function getAllWAByLessonID(lessonID: string): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.writingAssignment.getByLesson;

    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      params: { lessonID },
      url
    };
    return Axios(axiosOptions)
      .then(resp => {
        dispatch({
          type: types.LOAD_WA_SUCCESS,
          writingAssignments: resp.data
        });
      })
      .catch((error) => {
        dispatch({ type: types.LOAD_WA_FAILED, error });
        console.error('Error loading all writing assignments', error);
        constants.handleError(error, 'load all writing assignments');
        throw error;
      });
  };
}

export function getWAResultsForClass(classID: string, waID: string): ThunkResult<any> {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    
    const url = API.class.getWaResults + classID + '&writingAssignmentID=' + waID

    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url: url
    }
    
    if (!waID) {
      dispatch({ type: types.LOAD_WAR_SUCCESS, waResults: [] });
      return Promise.resolve(true);
    }
    return Axios(axiosOptions)
      .then((resp) => {
        return sortBy(
          resp.data,
          [
            function(o: any) {
              return o.student.last ? o.student.last.toLowerCase() : '';
            }
          ],
          [
            function(o: any) {
              return o.student.first.toLowerCase();
            }
          ]
        )
      })
      .then(waResults => {
        dispatch({ type: types.LOAD_WAR_SUCCESS, waResults });
        return waResults;
      })
      .catch(error => {
        dispatch({ type: types.LOAD_WAR_FAILED, error });
        constants.handleError(error, 'load writing assignment result');
        throw error;
      });
  };
}

export function getWritingAssignmentResult(
  writingAssignmentID: string
): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.writingAssignment.getAssignment;
    const studentID = getState().user.id;
    const params = { studentID, writingAssignmentID };
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url,
      params
    };
    return Axios(axiosOptions)
      .then(resp => {
        dispatch({
          type: types.GET_SINGLE_WRITING_ASSIGNMENT_RESULT_SUCCESS,
          result: resp.data
        });
        return resp.data;
      })
      .catch(error => {
        dispatch({
          type: types.GET_SINGLE_WRITING_ASSIGNMENT_RESULT_FAILED,
          params
        });
        console.error('Error getting writing assignment result', error);
        constants.handleError(error, 'get writing assignment result');
        throw error;
      });
  };
}

export function saveWritingAssignmentResult(
  writingAssignmentID: string,
  studentText: string,
  id: string
): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.writingAssignment.save;
    const studentID = getState().user.id;
    let data: any = {
      isCorrect: null,
      studentID,
      writingAssignmentID,
      studentText
    };
    if (id) {
      data = { ...data, id };
    }
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url,
      data
    };
    return Axios(axiosOptions)
      .then(resp => {
        dispatch({
          type: types.SAVE_WRITING_ASSIGNMENT_RESULT_SUCCESS,
          result: resp.data
        });
        toastr.success(
          'Success',
          'Writing Assignment Saved',
          constants.toastrSuccess
        );

        return resp.data;
      })
      .catch(error => {
        dispatch({ type: types.SAVE_WRITING_ASSIGNMENT_RESULT_FAILED, data });
        console.error('Error getting writing assignment result', error);
        constants.handleError(error, 'get writing assignment result');
        throw error;
      });
  };
}


export function saveWAUpdate(wa: GFWritingAssignmentResult) {

  let data: any = {
    StudentID: wa.studentID,
    WritingAssignmentID: wa.writingAssignmentID,
    TeacherText: wa.teacherText,
    IsCorrect: wa.isCorrect
  };

  // add an ID if it exists, so we dont create a new result
  if (!!wa.id){
    data = {...data, id: wa.id};
  }

  const axiosOptions: AxiosRequestConfig = {
    method: 'post',
    url: API.class.saveWaResult,
    data
  }

  return Axios(axiosOptions)
    .then((resp) => {
      return resp.data
    })
    .catch(error => {
      // error handled in component
      throw error
    })
}
