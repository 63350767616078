/*
* Teacher viewing a student's progress report
the URL for the view contains the
*/

import * as React from "react";
import { connect } from "react-redux";
import {
  GFLessonsWithProgress,
  GFStudentWithProgress,
  GFUser,
  GFStudent,
  Option,
  GFStudentUser,
  GFProgressFilters,
  GFInitialState
} from "../../models/models";
import {
  getVisibleProgressClassStudent,
  setStartDateClassStudent,
  setEndDateClassStudent,
  getStudentQuizResult
} from "../../actions/progressActions";
import ReactTable, { RowInfo } from "react-table";
import {
  Grid,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button
} from "react-bootstrap";
import AttemptsExpander from "./AttemptsExpander";
import {
  handleTdProps,
  columns,
  printElement
} from "./StudentReportUtil";
import { loadStudents } from "../../actions/studentActions";
import { loadClasses } from "../../actions/classActions";
import MainMenu from "../menu/MainMenu";
import { browserHistory, RouteComponentProps } from "react-router";
import { find, findIndex, filter } from "lodash";
import DateRangePicker from "../common/DateRangePicker";
import { initialOption, initialStudent } from "../../reducers/initialState";
import { getResultsCSVForStudent } from "../../actions/progressActions";
import {
  manualAjaxEnd,
  manualAjaxStart
} from "../../actions/ajaxStatusActions";
import LessonToggleButtons from "./LessonToggleButtons";
import { QuizAnswersModalContainer } from "./QuizAnswersModalContainer";
import { resetQuizStarts } from "../../actions/quizActions";
import { selectIsTeacher, selectIsSuperTeacher, selectHasGoogleClasses } from "../../reducers/userReducer";
import { selectClassOptions } from "../../reducers/classReducer";
import constants from "../../constants";
const FontAwesome = require("react-fontawesome");
const fileDownload = require("react-file-download");
const mixpanel = require("mixpanel-browser");

const graham = require("../../images/graham.gif");

const filterStudentsForClass = (students: GFStudent[], classID: string) => {
  return filter(students, { classID });
};

interface MatchParams {
  classID: string;
  studentID: string;
}
interface Props extends RouteComponentProps<MatchParams, {}> {
  user: GFUser;
  loading: boolean;
  loadStudents: (user: GFUser) => any;
  loadClasses: (user: GFUser) => any;
  students: GFStudent[];
  classOptions: Option[];
  classProgressData: GFStudentWithProgress[];
  getVisibleProgressClassStudent: typeof getVisibleProgressClassStudent;
  data: GFLessonsWithProgress[];
  startDate: Date;
  endDate: Date;
  setStartDateClassStudent: typeof setStartDateClassStudent;
  setEndDateClassStudent: typeof setEndDateClassStudent;
  getStudentQuizResult: typeof getStudentQuizResult;
  manualAjaxEnd: any;
  manualAjaxStart: any;
  progressFilters: GFProgressFilters;
  resetQuizStarts: typeof resetQuizStarts;
  hasGoogleClasses: boolean;
  isTeacher: boolean;
  isSuperTeacher: boolean;
}

interface State {
  selectedRow: any;
  student: GFStudentUser;
  selectedClass: Option;
}

class TeacherStudentReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRow: {},
      student: initialStudent.student,
      selectedClass: initialOption
    };
  }
  componentWillMount() {
    mixpanel.track("Viewing Teacher Student Progress Report");
    this.updateStudent();
    this.updateReport();
    if (this.props.students.length === 0) {
      this.props.loadStudents(this.props.user).then(() => {
        console.log("students loaded");
        this.updateStudent();
        this.updateReport();
      });
    }
    if (this.props.classOptions.length === 0) {
      this.props.loadClasses(this.props.user).then(() => {
        console.log("classes loaded");
        this.updateReport();
      });
    }

    // get the name of the class so we can display it
    const selectedClass =
      find(this.props.classOptions, {
        value: this.props.params.classID
      }) || initialOption;
    if (selectedClass && typeof selectedClass === "object") {
      this.setState({ selectedClass });
    }
  }
  componentDidMount() {
    // when a quiz is reset, refresh the report
    document.addEventListener("quizStartsReset", this.handleQuizStartReset);
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.startDate.getTime() != this.props.startDate.getTime() || prevProps.endDate.getTime() != this.props.endDate.getTime()) {
      this.updateReport(true);
    }
    if (
      JSON.stringify(prevProps.progressFilters.lessonIDs) !==
      JSON.stringify(this.props.progressFilters.lessonIDs)
    ) {
      this.updateReport();
    }
  }
  componentWillUnmount() {
    document.removeEventListener("quizStartsReset", this.handleQuizStartReset);
  }
  handleQuizStartReset = () => {
    this.updateReport(true);
  };

  updateStudent = () => {
    const foundStudent = find(this.props.students, {
      userID: this.props.params.studentID
    });
    if (!!foundStudent && typeof foundStudent === "object") {
      this.setState({ student: foundStudent.student });
    }
  };
  changeStudent = (num: number) => {
    const indexOfCurrentStudent = findIndex(this.props.students, {
      userID: this.props.params.studentID
    });
    let newIndex = indexOfCurrentStudent + num;
    if (newIndex >= this.props.students.length) {
      newIndex = 0;
    }
    if (newIndex < 0) {
      newIndex = this.props.students.length - 1;
    }

    const newStudent = this.props.students[newIndex];
    this.props.getVisibleProgressClassStudent(
      this.props.params.classID,
      newStudent.userID,
      false
    );
    browserHistory.replace(
      `/studentprogress/${this.props.params.classID}/${
        this.props.students[newIndex].userID
      }`
    );
    this.setState({ student: newStudent.student });
  };
  updateReport = (forceRefresh: boolean = false) => {
    const { classID, studentID } = this.props.params;
    if (
      this.props.classProgressData.length === 0 &&
      this.props.classOptions.length &&
      this.props.students.length
    ) {
      if (!!classID && !!studentID) {
        this.props.getVisibleProgressClassStudent(
          classID,
          studentID,
          forceRefresh
        );
      } else {
        console.error("missing classID or studentID", classID, studentID);
      }
    } else if (this.props.classProgressData.length) {
      this.props.getVisibleProgressClassStudent(
        classID,
        studentID,
        forceRefresh
      );
    } else {
      console.error("not updating report because something is missing");
    }
  };
  getTrProps = (state: any, rowInfo: any) => {
    if (rowInfo) {
      return {
        onClick: (e: React.MouseEvent<HTMLFormElement>) => {
          this.setState({
            selectedRow: {
              [rowInfo.viewIndex]: !this.state.selectedRow[rowInfo.viewIndex]
            }
          });
        },
        style: {
          background: this.state.selectedRow[rowInfo.viewIndex] ? `#fdf5c4` : ""
        }
      };
    } else {
      return {};
    }
  };
  goBack() {
    browserHistory.goBack();
  }

  getCSV = () => {
    const firstName = this.state.student.first;
    const lastName = this.state.student.last;
    const fullName = `${firstName} ${lastName}`;
    mixpanel.track("Getting Students CSV", { studentName: fullName });
    this.props.manualAjaxStart();
    getResultsCSVForStudent(this.state.student.id || "", this.props.user).then(
      csvData => {
        this.props.manualAjaxEnd();
        fileDownload(
          csvData,
          `${this.state.student.first}_${this.state.student.last}.csv`
        );
      }
    ).catch((error)=>{
      this.props.manualAjaxEnd();
      const message = 'download CSV';
      console.error(message, error)
      constants.handleError(error, message)
    });
  };

  doPrint = () => {
    // {this.state.student.student.first}
    const firstName = this.state.student.first;
    const lastName = this.state.student.last;
    const fullName = `${firstName} ${lastName}`;
    mixpanel.track("Printing Students Progress Report", {
      studentName: fullName
    });
    this.setState({ selectedRow: {} }, () => {
      printElement(document.getElementsByClassName("students")[0], false, null);
      window.print();
    });
  };

  render() {
    return (
      <Grid className="content modal-container">
        <div className="sidemenu">
          <MainMenu isTeacher={this.props.isTeacher} isSuperTeacher={this.props.isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
          <div className="logo text-center">
            <img alt="graham" src={graham} />
          </div>
        </div>
        <div className="students main-content content-with-sidebar">
          <Row className="sub-header">
            <Col xs={7} className="gf-breadcrumb">
              <Breadcrumb>
                <BreadcrumbItem>
                  <span onClick={this.goBack}>
                    {this.state.selectedClass.label} Progress Reports
                  </span>
                </BreadcrumbItem>
                <BreadcrumbItem active={true}>
                  {(`${this.state.student.first} ${this.state.student.last}`).trim()}
                  's Progress
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col xs={5} className="previous-next-buttons">
              <Button
                onClick={() => this.updateReport(true)}
                bsStyle="default"
                className="previous-button"
                bsSize="sm"
              >
                Refresh
              </Button>
              <Button
                onClick={this.doPrint}
                bsStyle="default"
                className="previous-button"
                bsSize="sm"
              >
                Print
              </Button>
              <Button
                onClick={this.getCSV}
                bsStyle="default"
                className="previous-button"
                bsSize="sm"
              >
                CSV
              </Button>

              <Button
                bsStyle="primary"
                className="previous-button"
                onClick={() => this.changeStudent(-1)}
                bsSize="sm"
              >
                <FontAwesome name="arrow-left" /> &nbsp;Prev
              </Button>
              <Button
                bsStyle="primary"
                onClick={() => this.changeStudent(1)}
                bsSize="sm"
              >
                Next &nbsp;
                <FontAwesome name="arrow-right" />
              </Button>
            </Col>
          </Row>
          <Row className="students-view-filters">
            <Col xs={6}>
              <DateRangePicker
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                handleChangeStart={this.props.setStartDateClassStudent}
                handleChangeEnd={this.props.setEndDateClassStudent}
              />
            </Col>
            <Col xs={6}>
              <LessonToggleButtons doStuff={true} />
            </Col>
          </Row>
          <Row />
          <ReactTable
            className="teacher-student-report -highlight"
            data={this.props.data}
            columns={columns}
            showPagination={false}
            showPageSizeOptions={false}
            pageSize={this.props.data.length}
            key={this.props.data.length}
            loading={this.props.loading}
            SubComponent={(rowInfo: RowInfo) => (
              <AttemptsExpander
                {...rowInfo}
                getAndShowStudentAnswers={this.props.getStudentQuizResult}
                resetQuizStarts={this.props.resetQuizStarts}
                studentID={this.props.params.studentID}
              />
            )}
            getTdProps={handleTdProps}
            getTrProps={this.getTrProps}
            expanded={this.state.selectedRow}
            noDataText="No progress found."
            resizable={false}
            sortable={false}
          />
        </div>
        <QuizAnswersModalContainer
          className="progress-reports-modal"
          bsSize="large"
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  const startDate = new Date(state.studentProgress.dateRange.startDate);
  const endDate = new Date(state.studentProgress.dateRange.endDate);
  const classOptions = selectClassOptions(state);

  return {
    user: state.user,
    loading: state.ajaxCallsInProgress > 0,
    students: filterStudentsForClass(state.students, ownProps.params.classID),
    classOptions,
    classProgressData: state.classProgress.data,
    data: state.studentProgress.data,
    startDate,
    endDate,
    progressFilters: state.progressFilters,
    hasGoogleClasses: selectHasGoogleClasses(state),
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state)

  };
};

export default connect(
  mapStateToProps,
  {
    loadStudents,
    loadClasses,
    getVisibleProgressClassStudent,
    setStartDateClassStudent,
    setEndDateClassStudent,
    getStudentQuizResult,
    manualAjaxStart,
    manualAjaxEnd,
    resetQuizStarts
  }
)(TeacherStudentReport);
