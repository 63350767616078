import * as React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { GFPlan } from '../../models/models';

interface Iprops {
  hasSubscription: boolean;
  openUpdateCreditCard: () => void;
  showConfirmationModal: () => void;
  isPurchaseOrder: boolean;
  openPaymentModal: (plan: GFPlan) => void;
}

export const ManageAccountButtons = (props: Iprops) => {
  if (props.hasSubscription) {
    return (
      <div>
        <Row>
          <div className="card-buttons">
            <div>
              <Col lg={5}>
                <Button
                  onClick={props.openUpdateCreditCard}
                  style={{ display: props.isPurchaseOrder ? 'none' : 'block' }}
                >
                  Change Card
                </Button>
              </Col>
              <Col lg={6}>
                <Button
                  onClick={props.showConfirmationModal}
                  className="cancel-button"
                >
                  Cancel Account
                </Button>
              </Col>
            </div>
          </div>
        </Row>
      </div>
    );
  } else {
    return (
      <div>
        <Row>
          <div className="card-buttons">
            <Col md={11}>
              <br />
              Need more students? Need more than one account for your school?
              Prefer to pay via school purchase order? &nbsp;
              <a
                href="https://www.grammarflip.com/request-a-quote-from-grammarflip/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Request a quote here!
              </a>
            </Col>
          </div>
        </Row>
      </div>
    );
  }
};
