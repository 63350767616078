import * as React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';

export const CardSection = () => {
  return (
    <div>
      <div className="checkoutForm form-group form-group-lg has-feedback">
        <div className="stripe">
          <label>
            Card number
            <div className="form-control">
              <CardNumberElement />
            </div>
          </label>
          <label>
            Expiration date
            <div className="form-control">
              <CardExpiryElement />
            </div>
          </label>
          <label>
            CVC
            <div className="form-control">
              <CardCvcElement />
            </div>
          </label>
          {/* <label>
              Postal code
              <div className="form-control">
                <PostalCodeElement />
              </div>
            </label> */}
        </div>
      </div>
    </div>
  );
};
