import * as React from 'react'; // tslint:disable-line
import {
  Modal,
  Button,
  FormControl,
  FormGroup,
  InputGroup
} from 'react-bootstrap';

const ForgotPassword = ({
  loading,
  container,
  show,
  close,
  email,
  change,
  submit,
  token,
  openToken,
  submitToken
}: {
  loading: boolean;
  container: any;
  show: boolean;
  close: () => void;
  email: string;
  change: any;
  submit: (event: React.FormEvent<HTMLFormElement>) => void;
  token: string;
  openToken: boolean;
  submitToken: (event: React.FormEvent<HTMLFormElement>)=> void;
}) => {
  const stepOne = 'Step 1 of 3: Enter your email to retrieve your password.';
  const stepTwo =
    'Step 2 of 3: Enter the token you received via email (check your spam folder).';

  const modalTitle = openToken ? stepTwo : stepOne;
  return (
    <Modal show={show} onHide={close} container={container}>
      <Modal.Header closeButton={true}>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      {openToken === false && (
        <form onSubmit={submit}>
          <Modal.Body>
            <FormGroup controlId="userEmail" bsSize="lg">
              <FormControl
                type="text"
                name="email"
                placeholder="Email"
                value={email}
                onChange={change}
              />
              <FormControl.Feedback />
            </FormGroup>
            <Modal.Footer>
              <Button onClick={close} type="button" bsStyle="default">
                Cancel
              </Button>
              <Button
                disabled={loading}
                type="submit"
                bsStyle="primary"
              >
                Reset Password
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </form>
      )}
      {openToken && (
        <form onSubmit={submitToken}>
          <Modal.Body>
            <FormGroup controlId="formInlineToken" bsSize="lg">
              <InputGroup>
                <FormControl
                  type="text"
                  name="token"
                  placeholder="Token"
                  value={token}
                  onChange={change}
                />
                <InputGroup.Button>
                  <Button
                    disabled={loading}
                    type="submit"
                    bsStyle="primary"
                  >
                    Submit Token
                  </Button>
                </InputGroup.Button>
              </InputGroup>
            </FormGroup>
          </Modal.Body>
        </form>
      )}
    </Modal>
  );
};

export default ForgotPassword;
