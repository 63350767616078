import React from 'react'
import { GFTeacherUser } from './../../models/models';
import { find } from 'lodash';
import {
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  Row
} from "react-bootstrap";
import constants from '../../constants';

const FontAwesome = require("react-fontawesome");

interface Props extends React.Props<TeachersList> {
  filteredTeachers: GFTeacherUser[],
  showEditTeacher: (teacher: GFTeacherUser) => void,
  showDeleteTeacher: (teacher: GFTeacherUser) => void,
  loading: boolean;
  showAddTeacher: () => void;
  openSeats: number;
}

interface State {}

class TeachersList extends React.Component<Props, State> {

  /**
   * The constructor
   * @param props
   */
  constructor(props: Props) {
    super(props);
    this.state = {}
  }

  /**
   * Renders the component
   * 
   * @returns Modal
   */
  render () {
    const { loading, openSeats, filteredTeachers, showAddTeacher, showEditTeacher, showDeleteTeacher } = this.props;
    return (
      <ListGroup className="teachers-list">
      {filteredTeachers.map((teacher) => {
        const plan = find(constants.paymentPlanObjectsByID, plan => {
          return plan.id === teacher.paymentPlan;
        });
        return <ListGroupItem className="list-item" key={teacher.id}>
          <Row>
            <Col
              xs={3}
              md={3}
              className="teacher-name"
              onClick={() => {
                showEditTeacher(teacher);
              }}
            >
              {teacher.first} {teacher.last}
            </Col>
            <Col xs={4} md={4}>
              <small style={{whiteSpace: 'nowrap'}}>{plan ? plan.name : 'n/a'}</small>
            </Col>
            <Col xs={3} md={3}>
              {!!teacher.socialType && (
                <small>Registered via Google</small>
              )}
            </Col>
            <Col xs={2} md={2}>
              <div className="fa-icons">

                <Button
                  title="Remove Teacher"
                  bsStyle="link"
                  className="pull-right"
                  onClick={() => {
                    showDeleteTeacher(teacher);
                  }}
                >
                  <FontAwesome name="times" />
                </Button>

                <Button
                  title="Edit Teacher"
                  bsStyle="link"
                  className="pull-right"
                  onClick={() => {
                    showEditTeacher(teacher);
                  }}
                >
                  <FontAwesome name="pencil-square-o" />
                </Button>
              </div>
            </Col>
          </Row>
        </ListGroupItem>
      })}
      {filteredTeachers.length === 0 && (
      <div className='padded'>
        <h2>Hi, there!</h2>
        <p>It looks like you have not connected any teachers under your administrator account. Click "Add Teacher" when you are ready to get started!</p>

        <Button bsStyle={'default'} disabled={loading} className="btn-sm" onClick={showAddTeacher} style={{ marginTop: 8 }}>
          <FontAwesome name="plus" />
          Add Teacher ({openSeats})
        </Button>

      </div>
      )}
    </ListGroup>
    );
  }
}

export default TeachersList;
