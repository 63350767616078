/*
 * General Purpose Section including Signing up and updating credit card with Stripe
 */

import * as React from "react";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { CardSection } from "../payment/CardSection";
import { GFUser, GFPlan } from "../../models/models";
import { Button, Modal } from "react-bootstrap";
import CouponSection from "../payment/CouponSection";
import constants from "../../constants";
import { toastr } from "react-redux-toastr";
import { throttle } from "lodash";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { browserHistory } from "react-router";

interface PostAffTrackerInterface {
  setAccountId: (accountId: string) => void;
  track: () => void;
  createSale: () => any;
  register: () => void;
}

interface Props {
  user: GFUser;
  loading: boolean;
  displayAmount: string;
  hide: () => void;
  stripeCoupon: string;
  stripeCouponDetails: any;
  couponChange: (e: any) => void;
  checkStripeCoupon: (e: any) => void;
  directPaymentSignup: (
    paymentPlan: string,
    paymentToken: any,
    stripeCoupon: string,
    user: any,
  ) => Promise<void>;
  manualAjaxEnd: any;
  manualAjaxStart: any;
  updateSubscription: (paymentPlan: string) => Promise<void>;
  selectedPlan: GFPlan;
  stripe: Stripe;
  elements: StripeElements;
  togglePlanRecurringType: () => void;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
  utm_term: string;
  gclid: string;
}
class DirectPaymentSection extends React.Component<Props, {}> {
  // created a global variable to stop stripe payment from double submitting
  private handlePaymentThrottled: (event: React.MouseEvent<Button, MouseEvent>) => void;
  constructor(props: Props) {
    super(props);
    this.state = {};
    this.handlePaymentThrottled = throttle(
      this.handlePayment,
      constants.paymentDoubleClickTimeout
    );
  }

  handleHomeschoolTracking(user: any) {
    const couponCode = this.props.stripeCoupon || "";
    return new Promise((resolve) => {
      if (window && window.PostAffTracker) {
        // Only homeschool users on the annual plan get tracked
        if (user && user.id && user.paymentPlan === "GF-Tier2-Annual-5" && couponCode.toLowerCase() === "homeschool") {
          window.PostAffTracker.setAccountId('c04ca1f1');
          const sale = window.PostAffTracker.createSale();
          sale.setCoupon(couponCode.toLowerCase());
          sale.setTotalCost('39.99');
          sale.setOrderID(user.id);
          sale.setProductID('GF-Tier2-Annual-5'); 
          window.PostAffTracker.register();
          console.log("Homeschool Tracking success", sale);
          return resolve(true);
        }
        return resolve(false);
      }
      console.error("missing window.PostAffTracker");
      return resolve(false);
    });
  }

  handlePayment = (e: React.MouseEvent<Button, MouseEvent>) => {
    e.preventDefault();
    this.handleSignup();
  };

  handleSignup() {
    if (!this.props.stripe && this.props.elements) {
      console.error("missing stripe", this.props.stripe);
      toastr.error('Error', 'Missing stripe while trying to signup.  Please try again or contact support.', constants.toastrError)

      return;
    }
    const cardElement = this.props.elements.getElement(CardNumberElement);
    if (!cardElement) {
      console.error("missing cardElement");
      toastr.error('Error', 'Missing credit card while trying to signup.  Please try again or contact support.', constants.toastrError)
      return;
    }
    this.props.manualAjaxStart(); // have to call ajaxstart manually because we are caling stripe's API here
    this.props.stripe
      .createToken(cardElement)
      .then(({ token }) => {
        this.props.manualAjaxEnd();
        if (token === undefined) {
          throw {
            message:
              "Invalid credit card info.  Please double check and try again.",
            status: 433,
          };
        }
        return this.props
          .directPaymentSignup(
            this.props.selectedPlan.id,
            token,
            this.props.stripeCoupon,
            {
              email: this.props.user.email,
              first: this.props.user.first,
              last: this.props.user.last,
              password: this.props.user.password,
              roleID: this.props.user.roleID,
              username: this.props.user.email,
              gender: this.props.user.gender,
              howDidYouHearAboutUs: this.props.user.howDidYouHearAboutUs,
              hearOther: this.props.user.hearOther,
              UTMSource: this.props.utm_source,
              UTMMedium: this.props.utm_medium,
              UTMCampaign: this.props.utm_campaign,
              UTMContent: this.props.utm_content,
              UTMTerm: this.props.utm_term,
              GCLID: this.props.gclid,
            }
          )
          .then((value)=> {
            this.props.hide();
            this.handleHomeschoolTracking(value)
              .then(() => {
                // Success we should be logged in now. Go to Dashboard.
                browserHistory.push("/classes");
              });
          });
      })
      .catch((error) => {
        // catch the error here because we are catching the stripe createToken error as well
        this.props.manualAjaxEnd();
        console.error("error signing up for a plan", error);
        let message = "";
        if (error && error.response) {
          if (error.response.status === 412) {
            message = constants.friendlyErrors.verifyRequired;
          }
          if (error.response.status === 433) {
            // this is an error we thew in the client app
            message = error.message;
          }
        }
        if (message.length) {
          toastr.error("Error", message, constants.toastrError);
        } else {
          constants.handleError(error, "signup");
        }
      });
  }


  render() {
    return (
      <div>
        <form>
          <Modal.Body>
            <div>
              <CardSection />
              <CouponSection
                stripeCoupon={this.props.stripeCoupon}
                stripeCouponDetails={this.props.stripeCouponDetails}
                checkStripeCoupon={this.props.checkStripeCoupon}
                handleChange={this.props.couponChange}
                loading={this.props.loading}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={this.props.hide}>
              Close
            </Button>
            <Button
              bsStyle="primary"
              type="button" // for some reason the submit button here does a form Post rather than calling the onSubmit function, so we will use a regular button type
              disabled={this.props.loading}
              onClick={this.handlePaymentThrottled}
            >
              {this.props.displayAmount}
            </Button>
          </Modal.Footer>
        </form>
      </div>
    );
  }
}

export default DirectPaymentSection;

declare global {
  interface Window {
    PostAffTracker: PostAffTrackerInterface;
  }
}
