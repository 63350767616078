import * as React from "react";
import { isNull } from "lodash";
import constants from "../../constants";
import { getCode, validateCode, resetClasses } from "../../actions/classActions";
import MainMenu from "../menu/MainMenu";
import VerificationAlert from "../common/VerificationAlert";
import { connect } from "react-redux";
import { Link } from "react-router";
import { GFClass, GFUser, GFInitialState, Option } from "../../models/models";
import {
  loadClasses,
  saveClass,
  deleteClass,
} from "../../actions/classActions";
import {
  Panel,
  Button,
  ButtonGroup,
  Grid,
  Row,
  Col,
  Modal,
  FormGroup,
  FormControl,
  ControlLabel,
  Tooltip,
} from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { StudentCountBanner } from "./StudentCountBanner";
import { AlertExpiredTeacher } from "../common/AlertExpiredTeacher";
import { selectHasGoogleClasses, selectIsTeacher, selectIsSuperTeacher } from "../../reducers/userReducer";
import { loadGoogleClasses } from "../../actions/googleActions";
import { importStudents } from "../../actions/googleActions";
import { confirmResetClassStarts } from "../../actions/progressActions";
import { quizTypeEnum, socialServiceEnum } from "../../enums";
import { selectClasses, selectGoogleClassOptions } from "../../reducers/classReducer";
import { loadStudents } from "../../actions/studentActions";
import { GoogleSyncConfirmationModal } from "./GoogleSyncConfirmationModal";
const FontAwesome = require("react-fontawesome");
const mixpanel = require("mixpanel-browser");

const graham = require("../../images/graham.gif");

interface StudentInviteModalProps {
  classCode: string;
  show: boolean;
  closeStudentInviteModal: () => void;
  container?: any;
}

const StudentInviteModal = (props: StudentInviteModalProps) => {
  const subject = encodeURIComponent(`GrammarFlip Student Invitation`);
  const addClassLink = `${process.env.REACT_APP_HOST}/profile?classcode=${props.classCode}`;
  const classLink = `${process.env.REACT_APP_HOST}/register?classcode=${props.classCode}`;
  const body01 = `Hey, student!
      This year we will be using GrammarFlip!  If you already have an account, do not create another one. Just click the link below to log into your account with your email address and password:`;
  const body02 = `If you do not have a GrammarFlip account, simply click the link below to register:`;
  const encodedBody = encodeURIComponent(`Hey, student!
      This year we will be using GrammarFlip!  If you already have an account, do not create another one. Just click the link below to log into your account with your email address and password:

      ${addClassLink}

      If you do not have a GrammarFlip account, simply click the link below to register:

      ${classLink}`);
  const mailtoLink = `
      mailto:student@example.com?subject=${subject}&body=${encodedBody}
    `;
  return (
    <Modal
      show={props.show}
      onHide={props.closeStudentInviteModal}
      container={props.container}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>Invite Students</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Please copy and paste this text into your email client, or click the
          email button below.
        </p>
        <p className="student-invite-body">
          {body01}
          <br />
          <br />
          {addClassLink}
          <br />
          <br />
          {body02}
          <br />
          <br />
          {classLink}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <a href={mailtoLink}>
          <Button bsStyle="primary">Email</Button>
        </a>

        <Button
          className="pull-left"
          bsStyle="default"
          onClick={props.closeStudentInviteModal}
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

interface Props extends React.Props<Classes> {
  user: GFUser;
  googleClasses: Option[];
  classes: GFClass[];
  loadClasses: typeof loadClasses;
  saveClass: ( name: string, code: string, id?: string) => Promise<void>;
  deleteClass: (classID: string)=>Promise<void>;
  loading: boolean;
  isTeacher: boolean;
  isSuperTeacher: boolean;
  hasGoogleClasses: boolean;
  confirmResetClassStarts: typeof confirmResetClassStarts;
  loadGoogleClasses: typeof loadGoogleClasses;
  importStudents: typeof importStudents;
  loadStudents: typeof loadStudents;
  resetClasses: () => Promise<void>;
}

interface State {
  showAddClass: boolean;
  showDelClass: boolean;
  name: string;
  code: string;
  showStudentInviteModal: boolean;
  showGoogleSyncConfirmationModal: boolean;
  classCode: string;
}

class Classes extends React.Component<Props, State> {
  classToDelete: any;
  classToEdit: any;
  refClassname: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      showAddClass: false,
      showDelClass: false,
      name: "",
      code: "",
      showStudentInviteModal: false,
      showGoogleSyncConfirmationModal: false,
      classCode: "",
    };

    this.showAddClass = this.showAddClass.bind(this);
    this.hideAddClass = this.hideAddClass.bind(this);
    this.showDelClass = this.showDelClass.bind(this);
    this.hideDelClass = this.hideDelClass.bind(this);
    this.generateCode = this.generateCode.bind(this);
    this.saveClass = this.saveClass.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteClass = this.deleteClass.bind(this);
    this.printClasses = this.printClasses.bind(this);
    this.handleWritingAssignment = this.handleWritingAssignment.bind(this);
    this.openStudentInviteModal = this.openStudentInviteModal.bind(this);
    this.closeStudentInviteModal = this.closeStudentInviteModal.bind(this);
    this.openGoogleSyncConfirmationModal = this.openGoogleSyncConfirmationModal.bind(this);
    this.closeGoogleSyncConfirmationModal = this.closeGoogleSyncConfirmationModal.bind(this);
  }

  componentDidMount() {
    if(this.props.isTeacher){
      // Ensure we have clean objects for students.
      this.props.loadStudents();
      // Ensure we have current data for classes.
      this.props.loadClasses();
    }
  }

  handleChange(e: any) {
    return this.setState({ [e.target.name]: e.target.value } as unknown as State);
  }

  showAddClass(gfClass?: GFClass) {
    if (!!gfClass) {
      this.classToEdit = gfClass;
      this.setState({
        showAddClass: true,
        name: gfClass.name,
        code: gfClass.code,
      });
    } else {
      this.generateCode();
      this.classToEdit = false;
      this.setState({
        showAddClass: true,
        name: "",
        code: "",
      });
    }
  }

  hideAddClass() {
    this.setState({ showAddClass: false });
  }

  saveClass() {
    if (this.state.name === "") {
      toastr.error("Error", `Class Name is required.`, constants.toastrError);
      // this.passwordInput.focus();
      return;
    }

    if (this.state.code === "") {
      toastr.error("Error", `Class Code is required.`, constants.toastrError);
      // this.passwordInput.focus();
      return;
    }

    const id = !!this.classToEdit ? this.classToEdit.id : "";
    validateCode(this.state.code).then((valid) => {
      if (valid || !!id) {
        this.props
          .saveClass(this.state.name, this.state.code, id)
          .then(() => {
            this.hideAddClass();
            this.classToEdit = false;
            this.props.loadClasses();
          })
          .catch((error: any) => {
            console.error("Error with saving class", error);
            let message = '';
            if (error.response && error.response.status === 409) {
              message =
                "That Class Code is in use! Please pick a different one.";
              console.error(message, error);
            }
            if (error.response && error.response.status === 410) {
              message =
                "Sorry, we cannot save this class because you have gone over the max number of students allowed for your account. Please contact us at support@grammarflip.com to increase your limit.";
              console.error(message, error);
            }
            if (message.length){
              toastr.error("Error", message, constants.toastrError);

            } else {
              constants.handleError(error, 'validate code')
            }
          });
      } else {
        // the code is invalid
        console.error("Error: Class Code is invalid");
        toastr.error(
          "Error",
          `That Class Code is in use! Please pick a different one.`,
          constants.toastrError
        );
      }
    });
  }

  showDelClass(gfClass: GFClass) {
    this.classToDelete = gfClass;
    this.setState({ showDelClass: true });
  }

  hideDelClass() {
    this.setState({ showDelClass: false });
    this.classToDelete = null;
  }

  deleteClass() {
    const gfClass = this.classToDelete;
    this.props
      .deleteClass(gfClass.id)
      .then(() => {
        toastr.success("Success", "class deleted", constants.toastrSuccess);
        this.hideDelClass();
      })
      .catch((error: any) => {
        this.hideDelClass();
      });
  }

  generateCode() {
    getCode(this.props.user).then((newCode) => {
      this.setState({ code: newCode });
    });
  }

  printClasses() {
    if (this.props.classes.length === 0) {
      type messageInfo = {
        [key: string]: string,
      }
      const messageOptions: messageInfo = {
        default: `Hi, there! It looks like you have not created any classes yet. Go
        ahead and click "Add Class" when you are ready to get started!`,
        google: `Hi, there! It looks like you have not created any classes yet. Go
        ahead and click "Add Class" or click "Sync With Classroom" when you are ready to get started!`
      };
      return (
        <h4 className="col-lg-12 col-xs-12">
          {messageOptions[this.props.user.socialType ? this.props.user.socialType : 'default']}
        </h4>
      );
    } else {
      return this.props.classes.map((gfClass: any) =>
        this.getClassHtml(gfClass, this.props.user)
      );
    }
  }

  getTooltip(title: string) {
    return <Tooltip id={title}>{title}</Tooltip>;
  }

  handleWritingAssignment(gfClass: any) {
    mixpanel.track("Writing Assignment was clicked for a class", {
      className: gfClass.name,
    });
  }

  shortenTitle(text: string) {
    const maxLength = 45;
    if (text.length > maxLength) {
      text = text.substr(0, maxLength - 3) + "...";
    }
    return text;
  }

  openStudentInviteModal(classCode: string) {
    this.setState({ showStudentInviteModal: true, classCode });
  }
  closeStudentInviteModal() {
    this.setState({ showStudentInviteModal: false });
  }
  openGoogleSyncConfirmationModal() {
    this.props.loadGoogleClasses(() => {
      this.setState({ showGoogleSyncConfirmationModal: true });
    });
  }
  closeGoogleSyncConfirmationModal() {
    this.setState({ showGoogleSyncConfirmationModal: false });
  }
  getClassHtml(gfClass: GFClass, gfUser: GFUser) {
    return (
      <div
        key={gfClass.id}
        className="class col-md-4 col-sm-4 col-xs-4 animated fadeInUp"
      >
        <Panel className="text-center" >
          <div style={{paddingLeft: '40px', paddingRight: '40px'}}>

          <h3 style={{height: '45px'}}>{this.shortenTitle(gfClass.name)}</h3>
          <h4>{gfClass.classSize} Students</h4>
          <p>Class Code: {gfClass.code}</p>

          <Button
            className="pull-right delete-button"
            onClick={() => {
              this.showDelClass(gfClass);
            }}
            title="Delete Class"
            >
            <FontAwesome name="trash" />
          </Button>
          {(!gfClass.googleCourseID && gfUser.isActive) && <Button
            bsStyle="primary"
            type="button"
            bsSize="sm"
            className="invite-students"
            onClick={() => {
              this.openStudentInviteModal(gfClass.code);
            }}
            >
            Invite Students
          </Button>}
            </div>
          <Row className="class-footer">
          {gfUser.isActive && <Col xs={12}>
              {!gfClass.googleCourseID && <Link to={`/students/${gfClass.id}`} title="Students">
                <Button className="pull-left">
                  <FontAwesome name="users" />
                </Button>
              </Link>}
              <Link
                to={`/classprogress/${gfClass.id}`}
                title="Progress Reports"
              >
                <Button className="pull-left">
                  <FontAwesome name="trophy" />{" "}
                </Button>
              </Link>
              <Link
                to={`/gradingwritingassignment/${gfClass.id}`}
                title="Writing Portals"
              >
                <Button
                  className="pull-left"
                  onClick={() => {
                    this.handleWritingAssignment(gfClass);
                  }}
                >
                  <FontAwesome name="file-text" />
                </Button>
              </Link>

              <Button
                className="pull-right"
                onClick={() => {
                  this.showAddClass(gfClass);
                }}
                title="Edit Class"
              >
                <FontAwesome name="pencil" />
              </Button>
              <Button
                className="pull-right"
                onClick={() => {
                  this.props.confirmResetClassStarts(gfClass.id, quizTypeEnum.postEval);
                }}
                title="Reset All Post-Evals"
              >
                <FontAwesome name="arrow-circle-o-left" />
              </Button>
              <Button
                className="pull-right"
                onClick={() => {
                  this.props.confirmResetClassStarts(gfClass.id, quizTypeEnum.diagnostic);
                }}
                title="Reset All Pre-Tests"
              >
                <FontAwesome name="arrow-circle-left" />
              </Button>
            </Col>}
          </Row>
        </Panel>
      </div>
    );
  }

  render() {
    let classesModalTitle;
    if (this.classToEdit === false) {
      classesModalTitle = "Add New Class";
    } else {
      classesModalTitle = "Edit Class";
    }
    const fontAwesomeI = <i className="fa fa-plus" aria-hidden="true" />;
    let salutation = "Welcome to GrammarFlip";
    if (this.props.user.first) {
      salutation += `, ${this.props.user.first}!`;
    } else {
      salutation += "!";
    }
    const { isTeacher, isSuperTeacher } = this.props;
    const isGoogleUser = this.props.user.hasOwnProperty('socialType') &&
      this.props.user.socialType === socialServiceEnum.google;
    return (
      <Grid className="content modal-container">
        <div className="sidemenu">
          <MainMenu isTeacher={isTeacher} isSuperTeacher={isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
          <div className="logo text-center">
            <img alt="graham" src={graham} />
          </div>
        </div>
        <div className="classes content-with-sidebar main-content">
          <VerificationAlert
            user={this.props.user}
            isTeacher={this.props.isTeacher}
          />
          <Row className="sub-header">
            <Col md={7} sm={6} xs={12}>
              {this.props.user.isActive && <h1>{salutation}</h1>}
              {this.props.user.isActive === false && (
                <AlertExpiredTeacher isActive={this.props.user.isActive} />
              )}
            </Col>
            <Col md={5} sm={6} xs={12} className="text-right sub-header-button">
              <ButtonGroup>
                {!this.props.hasGoogleClasses && <Button
                  bsStyle="primary"
                  id="addClass"
                  style={{marginRight: '10px'}}
                  onClick={() => {
                    this.showAddClass();
                  }}
                >
                  {fontAwesomeI}
                  Add Class
                </Button>}
                {isGoogleUser && <Button onClick={this.openGoogleSyncConfirmationModal} bsStyle="primary" disabled={this.props.loading}>
                  <FontAwesome name="google" style={{marginRight: 15}} />
                  Sync With Classroom
                </Button>}
              </ButtonGroup>
            </Col>
          </Row>
          <StudentCountBanner
            user={this.props.user}
            classes={this.props.classes}
            onReset={() => {
              const toastrConfirmOptions = {
                onOk: () => {
                  this.props.resetClasses().then(() => {
                    this.props.loadClasses();
                  });
                },
                onCancel: () => console.log('CANCEL: clicked'),
                okText: 'Yes',
                cancelText: 'cancel'
              };
              toastr.confirm("Are you sure you want to remove all students and all class sections?", toastrConfirmOptions);
            }}
          />
          <Modal
            show={this.state.showAddClass}
            onHide={this.hideAddClass}
            // onShow={() => {  // TODO fix this
            //   ReactDOM.findDOMNode(this.refClassname).focus();
            // }}
            container={this}
          >
            <Modal.Header closeButton={true}>
              <Modal.Title>{classesModalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <FormGroup controlId="className" bsSize="lg">
                  <ControlLabel>Class Name</ControlLabel>
                  <FormControl
                    type="text"
                    name="name"
                    value={this.state.name}
                    placeholder="Ex. First Period"
                    onChange={this.handleChange}
                    ref={(input) => {
                      this.refClassname = input;
                    }}
                    disabled={this.props.hasGoogleClasses}
                  />
                  <FormControl.Feedback />
                </FormGroup>
                <FormGroup controlId="classCode" bsSize="lg">
                  <ControlLabel>
                    Class Code (Provide this code to the students in this class
                    section.)
                  </ControlLabel>
                  <FormControl
                    type="text"
                    name="code"
                    value={this.state.code}
                    placeholder="SECRETCODE27"
                    onChange={this.handleChange}
                    disabled={true}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.hideAddClass} bsStyle="default">
                Close
              </Button>
              <Button
                disabled={this.props.loading}
                id="saveClass"
                bsStyle="primary"
                onClick={this.saveClass}
              >
                Save Class
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="row classes-list">{this.printClasses()}</div>
          <Modal
            show={this.state.showDelClass}
            onHide={this.hideDelClass}
            container={this}
          >
            <Modal.Header closeButton={true}>
              <Modal.Title>Are You Sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this class? This cannot be undone.
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.hideDelClass} bsStyle="default">
                Cancel
              </Button>
              <Button
                disabled={this.props.loading}
                bsStyle="primary"
                onClick={this.deleteClass}
              >
                Delete Class
              </Button>
            </Modal.Footer>
          </Modal>
          <StudentInviteModal
            closeStudentInviteModal={this.closeStudentInviteModal}
            classCode={this.state.classCode}
            show={this.state.showStudentInviteModal}
          />
          <GoogleSyncConfirmationModal
            show={this.state.showGoogleSyncConfirmationModal}
            container={this}
            onHide={this.closeGoogleSyncConfirmationModal}
            onClick={(selectedClasses: any) => {
              this.props.importStudents(selectedClasses, () => {
                this.closeGoogleSyncConfirmationModal();
                this.props.loadClasses();
              })
            }}
            loading={this.props.loading}
            googleClasses={this.props.googleClasses}
            importedClasses={this.props.classes}
          />
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  const classes = selectClasses(state);
  const googleClasses = selectGoogleClassOptions(state);
  return {
    user: state.user,
    googleClasses,
    classes,
    loading: state.ajaxCallsInProgress > 0,
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state),
    hasGoogleClasses: selectHasGoogleClasses(state),
  };
};

export default connect(mapStateToProps, {
  loadClasses,
  saveClass,
  deleteClass,
  confirmResetClassStarts,
  loadGoogleClasses,
  importStudents,
  resetClasses,
  loadStudents,
})(Classes);
