import React from 'react'
import { find, forEach } from "lodash";
import {
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal,
} from "react-bootstrap";
const FontAwesome = require("react-fontawesome");

interface Props extends React.Props<AddStudentModal> {
  showAddStudent: boolean;
  hideAddStudent: () => void;
  loading: boolean;
  classFilter: string;
  addStudentByEmail: (
    classID: string,
    email: string,
  ) => Promise<any>;
  loadStudents: () => void;
  loadClasses: () => void;
}

interface State {
  email: string;
  formValidations: any;
  formValid: boolean;
}

class AddStudentModal extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      formValidations: {
        email: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [
            { required: true, message: "Email or Username is required" }
          ],
        }
      },
      formValid: false,
    }
  }

  componentWillUnmount() {
    this.setState({
      email: '',
      formValid: false
    });
  }

  validateForm() {
    const formValid = !find(this.state.formValidations, ["valid", false]);
    this.setState({ formValid });
  }

  validate(value: string, validator: any) {
    let valid = true;
    if (validator.pattern && value.length > 0) {
      const newValue = value.match(validator.pattern);
      valid = newValue ? true : false;
    }
    if (validator.required) {
      valid = !!value.length || false;
    }
    if (validator.mustMatch) {
      valid =
        this.state[validator.mustMatch as keyof State] === value ? true : false;
    }
    return valid;
  }

  validateField(name: string, value: string, showErrors: boolean) {
    // loop over each field we need to validate
    forEach(this.state.formValidations, (field, key) => {
      if (key === name) {
        const newFormValidations = Object.assign(
          {},
          this.state.formValidations
        );
        let errorMessage = "";

        // loop over each validation for this field, and set error to the error message if something is invalid
        field.validators.forEach((validator: any) => {
          if (!this.validate(value, validator)) {
            errorMessage = validator.message;
          }
        });
        // after we have checked all the validators, is there an error message for this field?
        if (!errorMessage) {
          // no error message
          newFormValidations[name].validationState = "success";
          newFormValidations[name].validationMessage = "";
          newFormValidations[name].valid = true;
          if (!showErrors) {
            newFormValidations[name].validationState = null;
            newFormValidations[name].validationMessage = "";
          }
        } else {
          // error
          // only show the errors if showErrors
          if (showErrors) {
            newFormValidations[name].validationState = "error";
            newFormValidations[name].validationMessage = errorMessage;
          }

          newFormValidations[name].valid = false;
        }
        this.setState(
          { formValidations: newFormValidations },
          this.validateForm
        );
      }
    });
  }
  
  handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({
      email: value
    }, () => {
      this.validateField(name, value, true)
    });
  }

  render () {
    const { showAddStudent, hideAddStudent, loading, classFilter } = this.props;
    const { email, formValid } = this.state;
    return (
      <Modal
        show={showAddStudent}
        onHide={hideAddStudent}
        container={this}
      >
        <Modal.Header>
          <Modal.Title>
            <h2 className="body-text">Add Existing Student</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="studentForm" onSubmit={() => {}}>
            <FormGroup
              controlId="studentUsername"
              bsSize="lg"
              validationState={
                this.state.formValidations.email.validationState
              }
            >
              <ControlLabel>Email or Username</ControlLabel>
              <FormControl
                type="text"
                name="email"
                value={email}
                onChange={this.handleChange}
              />
              <FormControl.Feedback />
              <HelpBlock>
                {this.state.formValidations.email.validationMessage}
              </HelpBlock>
            </FormGroup>
            <Button
              disabled={loading || !formValid}
              bsStyle="primary"
              type="button"
              block
              onClick={() => {
                // Send email and class id to API
                this.props.addStudentByEmail(email, classFilter)
                .then((resp:any) => {
                  // Close modal.
                  this.setState({email: ''});
                  this.props.loadStudents();
                  this.props.loadClasses();
                  hideAddStudent();
                })
                .catch(e => {
                  // Close modal.
                  this.setState({email: ''});
                  hideAddStudent();
                });                
              }}
            >
              <FontAwesome name="plus" />
              <span className="backLink">Add Student To Class</span>
            </Button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={hideAddStudent}
            bsStyle="default"
            type="button"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddStudentModal;
