import React from 'react'
import { GFUser } from './../../models/models';
import {
  Modal,
} from "react-bootstrap";
import ChangePW from "./../common/ChangePW";
import constants from "./../../constants";
import { toastr } from "react-redux-toastr";

interface Props extends React.Props<NewTeacherChangePWModal> {
  show: boolean;
  user: GFUser|null;
  changePW: (password: string) => any;
  checkSession: () => any;
}

interface State {
  loading: boolean;
}

class NewTeacherChangePWModal extends React.Component<Props, State> {

  /**
   * The constructor
   * @param props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  save = (password: string) => {
    const { changePW, checkSession } = this.props;
    changePW(password)
    .then((resp: any) => {
      toastr.success(
        "Success",
        `Your password has been updated!`,
        constants.toastrSuccess
      );
      checkSession();
    })
    .catch((error: any) => {
      console.error("Error updating user password", error);
      const message = "update password";
      constants.handleError(error, message);
    });
  }

  /**
   * Renders the component
   * 
   * @returns Modal
   */
  render () {
    const { show, user } = this.props;
    const { loading } = this.state;
    return !!user && (
      <Modal
        show={show}
        onHide={() => {}}
        container={this}
      >
        <Modal.Body>
          <h2>Create a new password</h2>
          <ChangePW
            changePW={this.save}
            loading={loading}
            isSocialType={!!user.socialType}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default NewTeacherChangePWModal;
