/*
* Student viewing their own progress report
*/

import * as React from 'react';
import { connect } from 'react-redux';
import {
  GFLessonsWithProgress,
  GFUser,
  GFInitialState,
  GFCustomLesson
} from '../../models/models';
import {
  getMyProgress,
  getStudentQuizResult
} from '../../actions/progressActions';
import ReactTable, { RowInfo } from 'react-table';
import { Grid, Row, Col } from 'react-bootstrap';
import AttemptsExpander from './AttemptsExpander';
import { handleTdProps, columns } from './StudentReportUtil';
import { QuizAnswersModalContainer } from './QuizAnswersModalContainer';
import { toastr } from 'react-redux-toastr';
import { find } from 'lodash';
import { getCustomLessons } from '../../actions/customLessonActions';

interface Props extends React.Props<StudentReport> {
  user: GFUser;
  data: GFLessonsWithProgress[];
  getMyProgress: () => void;
  loading: boolean;
  getStudentQuizResult: typeof getStudentQuizResult;
  customLessons: { [key: string]: GFCustomLesson };
  getCustomLessons: typeof getCustomLessons;
}

interface State {
  selectedRow: any;
}

class StudentReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRow: {}
    };
  }
  componentWillMount() {
    this.props.getMyProgress();
    this.props.getCustomLessons();

    document.addEventListener(
      'finishedGettingUpdatedData',
      this.handleUpdatedData
    );
  }
  componentWillUnmount() {
    document.removeEventListener(
      'finishedGettingUpdatedData',
      this.handleUpdatedData,
      false
    );
  }

  handleUpdatedData = () => {
    this.props.getMyProgress();
  };

  getTrProps = (state: any, rowInfo: any) => {
    if (rowInfo) {
      return {
        onClick: (e: React.MouseEvent<HTMLFormElement>) => {
          this.setState({
            selectedRow: {
              [rowInfo.viewIndex]: !this.state.selectedRow[rowInfo.viewIndex]
            }
          });
        },
        style: {
          background: this.state.selectedRow[rowInfo.viewIndex] ? `#fdf5c4` : ''
        }
      };
    } else {
      return {};
    }
  };
  getAndShowStudentAnswers = (
    quizResultID: string,
    columnID?: string,
    lessonID?: string
  ) => {
    const customLesson = find(
      this.props.customLessons,
      cL => cL.lessonID === lessonID
    );
    // if (columnID !== 'quiz05'){
    //   toastr.warning('Warning', 'Only graded post-evaluations can be viewed.')
    //   return;
    // }

    if (
      columnID === 'quiz05' &&
      customLesson &&
      !customLesson.postEvalAnswersEnabled
    ) {
      toastr.warning('', 'Your teacher must enable the answers to be displayed before you can review this Post-Evaluation.');
      return;
    }
    this.props.getStudentQuizResult(quizResultID);
  };
  render() {
    return (
      <Grid className="content modal-container">
        <div className="students main-content content-without-sidebar">
          <Row className="students-header">
            <Col md={10} xs={10} className="text-xl-left">
              <h1 className="pageDescription">My Progress Report</h1>
            </Col>
          </Row>
          <ReactTable
            data={this.props.data}
            columns={columns}
            showPagination={false}
            showPageSizeOptions={false}
            pageSize={this.props.data.length}
            key={this.props.data.length}
            loading={this.props.loading}
            SubComponent={(rowInfo: RowInfo) => (
              <AttemptsExpander
                {...rowInfo}
                getAndShowStudentAnswers={this.getAndShowStudentAnswers}
                studentID={this.props.user.id}
              />
            )}
            getTdProps={handleTdProps}
            getTrProps={this.getTrProps}
            expanded={this.state.selectedRow}
            noDataText="No progress found."
            resizable={false}
            sortable={false}
          />
        </div>
        <QuizAnswersModalContainer
          className="progress-reports-modal"
          bsSize="large"
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  return {
    user: state.user,
    data: state.myProgress.data,
    customLessons: state.customLessons,
    loading: state.ajaxCallsInProgress > 0
  };
};

export default connect(
  mapStateToProps,
  {
    getMyProgress,
    getStudentQuizResult,
    getCustomLessons
  }
)(StudentReport);
