import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { GFClass } from '../models/models';

export default function googleClasses(
  state: GFClass[] = initialState.googleClasses,
  action: any
) {
  switch (action.type) {
    case types.LOAD_GOOGLE_CLASSES_SUCCESS:
      return action.googleClasses || [];

    default:
      return state;
  }
}
