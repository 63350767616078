export const LOAD_CLASSES_SUCCESS = 'LOAD_CLASSES_SUCCESS';
export const LOAD_CLASSES_FAILED = 'LOAD_CLASSES_FAILED';
export const CREATE_CLASS_SUCCESS = 'CREATE_CLASS_SUCCESS';
export const CREATE_CLASS_FAILED = 'CREATE_CLASS_FAILED';
export const UPDATE_CLASS_SUCCESS = 'UPDATE_CLASS_SUCCESS';
export const UPDATE_CLASS_FAILED = 'UPDATE_CLASS_FAILED';
export const DELETE_CLASS_SUCCESS = 'DELETE_CLASS_SUCCESS';
export const DELETE_CLASS_FAILED = 'DELETE_CLASS_FAILED';
export const TOGGLE_SUPER_DIAGNOSTIC_SUCCESS = 'TOGGLE_SUPER_DIAGNOSTIC_SUCCESS'
export const TOGGLE_SUPER_DIAGNOSTIC_FAILED = 'TOGGLE_SUPER_DIAGNOSTIC_FAILED'
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAILED = 'RESET_FAILED';

export const LOAD_STUDENTS_SUCCESS = 'LOAD_STUDENTS_SUCCESS';
export const LOAD_STUDENTS_FAILED = 'LOAD_STUDENTS_FAILED';
export const UPDATE_STUDENT_SUCCESS = 'UPDATE_STUDENT_SUCCESS';
export const UPDATE_STUDENT_FAILED = 'UPDATE_STUDENT_FAILED';
export const DELETE_STUDENT_CLASS_SUCCESS = 'DELETE_STUDENT_CLASS_SUCCESS';
export const DELETE_STUDENT_CLASS_FAILED = 'DELETE_STUDENT_CLASS_FAILED';
export const ADD_STUDENT_CLASS_SUCCESS = 'ADD_STUDENT_CLASS_SUCCESS';
export const ADD_STUDENT_CLASS_FAILED = 'ADD_STUDENT_CLASS_FAILED';

export const UPDATE_TEACHER_SUCCESS = 'UPDATE_TEACHER_SUCCESS';
export const UPDATE_TEACHER_FAILED = 'UPDATE_TEACHER_FAILED';
export const REMOVE_TEACHER_SUCCESS = 'REMOVE_TEACHER_SUCCESS';
export const REMOVE_TEACHER_FAILED = 'REMOVE_TEACHER_FAILED';
export const ADD_TEACHER_SUCCESS = 'ADD_TEACHER_SUCCESS';
export const ADD_TEACHER_FAILED = 'ADD_TEACHER_FAILED';
export const SEARCH_TEACHERS_SUCCESS = 'SEARCH_TEACHERS_SUCCESS';
export const SEARCH_TEACHERS_FAILED = 'SEARCH_TEACHERS_FAILED';

export const ADD_LESSON_TO_GOOGLE_SUCCESS = 'ADD_LESSON_TO_GOOGLE_SUCCESS';
export const ADD_LESSON_TO_GOOGLE_FAILED = 'ADD_LESSON_TO_GOOGLE_FAILED';
export const ADD_QUIZ_TO_GOOGLE_SUCCESS = 'ADD_QUIZ_TO_GOOGLE_SUCCESS';
export const ADD_QUIZ_TO_GOOGLE_FAILED = 'ADD_QUIZ_TO_GOOGLE_FAILED';
export const ADD_WA_TO_GOOGLE_SUCCESS = 'ADD_WA_TO_GOOGLE_SUCCESS';
export const ADD_WA_TO_GOOGLE_FAILED = 'ADD_WA_TO_GOOGLE_FAILED';
export const ADD_STUDENT_BY_EMAil_SUCCESS = 'ADD_STUDENT_BY_EMAil_SUCCESS';
export const ADD_STUDENT_BY_EMAil_FAILED = 'ADD_STUDENT_BY_EMAIL_FAILED';
export const IMPORT_CLASSES_SUCCESS = 'IMPORT_CLASSES_SUCCESS';
export const IMPORT_CLASSES_FAILED = 'IMPORT_CLASSES_FAILED';
export const IMPORT_STUDENTS_SUCCESS = 'IMPORT_STUDENTS_SUCCESS';
export const IMPORT_STUDENTS_FAILED = 'IMPORT_STUDENTS_FAILED';
export const LOAD_GOOGLE_CLASSES_SUCCESS = 'LOAD_GOOGLE_CLASSES_SUCCESS';
export const LOAD_GOOGLE_CLASSES_FAILED = 'LOAD_GOOGLE_CLASSES_FAILED';

export const REMOVE_STUDENT_CLASS_SUCCESS = 'REMOVE_STUDENT_CLASS_SUCCESS';
export const REMOVE_STUDENT_CLASS_FAILED = 'REMOVE_STUDENT_CLASS_FAILED';

export const LOAD_COURSES_SUCCESS = 'LOAD_COURSES_SUCCESS';
export const LOAD_COURSES_FAILED = 'LOAD_COURSES_FAILED';
export const LOAD_LESSONS_SUCCESS = 'LOAD_LESSONS_SUCCESS';
export const LOAD_LESSONS_FAILED = 'LOAD_LESSONS_FAILED';
export const LOAD_LESSON = 'LOAD_LESSON';
export const LOAD_QUIZZES_SUCCESS = 'LOAD_QUIZZES_SUCCESS';
export const LOAD_QUIZZES_FAILED = 'LOAD_QUIZZES_FAILED';
export const LOAD_QUIZ = 'LOAD_QUIZ';
export const LOAD_QUIZZES_BY_LESSON_SUCCESS = 'LOAD_QUIZZES_BY_LESSON_SUCCESS';
export const LOAD_QUIZZES_BY_LESSON_FAILED = 'LOAD_QUIZZES_BY_LESSON_FAILED';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const TEACHER_REGISTER_SUCCESS = 'TEACHER_REGISTER_SUCCESS';
export const TEACHER_REGISTER_FAILED = 'TEACHER_REGISTER_FAILED';
export const STUDENT_REGISTER_SUCCESS = 'STUDENT_REGISTER_SUCCESS';
export const STUDENT_REGISTER_FAILED = 'STUDENT_REGISTER_FAILED';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATEPW_SUCCESS = 'USER_UPDATEPW_SUCCESS';
export const USER_UPDATE_FAILED = 'USER_UPDATE_FAILED';
export const USER_UPDATEPW_FAILED = 'USER_UPDATEPW_FAILED';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_SIGNUP_BADGE = 'USER_SIGNUP_BADGE';
export const USER_CHECK_SESSION_SUCCESS = 'USER_CHECK_SESSION_SUCCESS';
export const USER_CHECK_SESSION_FAILED = 'USER_CHECK_SESSION_FAILED';
export const USER_UPDATESHWTUTORIAL_SUCCESS = 'USER_UPDATESHWTUTORIAL_SUCCESS';
export const USER_UPDATESHWTUTORIAL_FAILED = 'USER_UPDATESHWTUTORIAL_FAILED';

export const PAY_SUCCESS = 'PAY_SUCCESS';
export const PAY_FAILED = 'PAY_FAILED';
export const CHECK_COUPON_SUCCESS = 'CHECK_COUPON_SUCCESS';
export const CHECK_COUPON_FAILED = 'CHECK_COUPON_FAILED';

export const SET_SOCIAL = 'SET_SOCIAL';

export const LOAD_PROGRESS_SUCCESS = 'LOAD_PROGRESS_SUCCESS';
export const LOAD_PROGRESS_FAILED = 'LOAD_PROGRESS_FAILED';
export const SET_LESSON_FILTER = 'SET_LESSON_FILTER';

export const LOAD_CLASS_PROGRESS_SUCCESS = 'LOAD_CLASS_PROGRESS_SUCCESS';
export const LOAD_CLASS_PROGRESS_FAILED = 'LOAD_CLASS_PROGRESS_FAILED';

export const LOAD_CLASS_LESSON_PROGRESS_SUCCESS =
  'LOAD_CLASS_LESSON_PROGRESS_SUCCESS';
export const LOAD_CLASS_LESSON_PROGRESS_FAILED =
  'LOAD_CLASS_LESSON_PROGRESS_FAILED';
export const CLASS_LESSON_PROGRESS_RESET = 'CLASS_LESSON_PROGRESS_RESET';

export const SET_WA = 'SET_WA';
export const LOAD_WA_SUCCESS = 'LOAD_WA_SUCCESS';
export const LOAD_WA_FAILED = 'LOAD_WA_FAILED';
export const LOAD_WAR_SUCCESS = 'LOAD_WAR_SUCCESS';
export const LOAD_WAR_FAILED = 'LOAD_WAR_FAILED';
export const GET_SINGLE_WRITING_ASSIGNMENT_RESULT_SUCCESS =
  'GET_SINGLE_WRITING_ASSIGNMENT_RESULT_SUCCESS';
export const GET_SINGLE_WRITING_ASSIGNMENT_RESULT_FAILED =
  'GET_SINGLE_WRITING_ASSIGNMENT_RESULT_FAILED';
export const SAVE_WRITING_ASSIGNMENT_RESULT_SUCCESS =
  'SAVE_WRITING_ASSIGNMENT_RESULT_SUCCESS';
export const SAVE_WRITING_ASSIGNMENT_RESULT_FAILED =
  'SAVE_WRITING_ASSIGNMENT_RESULT_FAILED';

export const LOAD_STUDENT_QUIZ_ANSWERS_SUCCESS =
  'LOAD_STUDENT_QUIZ_ANSWERS_SUCCESS';
export const LOAD_STUDENTS_QUIZ_RESULTS_FAILED = 'LOAD_STUDENTS_RESULTS_FAILED';

export const LOAD_BADGES_SUCCESS = 'LOAD_BADGES_SUCCESS';
export const LOAD_BADGES_FAILED = 'LOAD_BADGES_FAILED';

export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const END_AJAX_CALL = 'END_AJAX_CALL';

export const SET_START_DATE_CLASS_STUDENT = 'SET_START_DATE_CLASS_STUDENT';
export const SET_END_DATE_CLASS_STUDENT = 'SET_END_DATE_CLASS_STUDENT';
export const SET_START_DATE_CLASS_LESSON = 'SET_START_DATE_CLASS_LESSON';
export const SET_END_DATE_CLASS_LESSON = 'SET_END_DATE_CLASS_LESSON';
export const SET_START_DATE_USER = 'SET_START_DATE_USER';
export const SET_END_DATE_USER = 'SET_END_DATE_USER';
export const SET_START_DATE_SUBTEACHER = 'SET_START_DATE_SUBTEACHER';
export const SET_END_DATE_SUBTEACHER = 'SET_END_DATE_SUBTEACHER';

export const GET_PROGRESS_USER_SUCCESS = 'GET_PROGRESS_USER_SUCCESS';
export const GET_PROGRESS_USER_FAILED = 'GET_PROGRESS_USER_FAILED';

export const GET_PROGRESS_CLASS_SUCCESS = 'GET_PROGRESS_CLASS_SUCCESS';
export const GET_PROGRESS_CLASS_FAILED = 'GET_PROGRESS_CLASS_FAILED';

export const GET_PROGRESS_SUBTEACHER_SUCCESS = 'GET_PROGRESS_SUBTEACHER_SUCCESS';
export const GET_PROGRESS_SUBTEACHER_FAILED = 'GET_PROGRESS_SUBTEACHER_FAILED';

export const GET_VISIBLE_PROGRESS_CLASS_LESSON =
  'GET_VISIBLE_PROGRESS_CLASS_LESSON';
export const GET_VISIBLE_PROGRESS_CLASS_STUDENT =
  'GET_VISIBLE_PROGRESS_CLASS_STUDENT';
export const GET_VISIBLE_PROGRESS_SUBTEACHER =
  'GET_VISIBLE_PROGRESS_SUBTEACHER';
export const GET_VISIBLE_PROGRESS_USER = 'GET_VISIBLE_PROGRESS_USER';
export const CLEAR_VISIBLE_PROGRESS = 'CLEAR_VISIBLE_PROGRESS';

export const MODAL_SHOW_QUIZ_ANSWERS = 'MODAL_SHOW_QUIZ_ANSWERS';
export const MODAL_HIDE_QUIZ_ANSWERS = 'MODAL_HIDE_QUIZ_ANSWERS';

export const ADD_ANSWER = 'ADD_ANSWER';
export const RESET_ANSWERS = 'RESET_ANSWERS';

export const SAVE_QUIZ_ANSWERS_SUCCESS = 'SAVE_QUIZ_ANSWERS_SUCCESS';

export const SAVE_QUIZ_TEACHER = 'SAVE_QUIZ_TEACHER';

export const GET_CUSTOM_LESSONS_SUCCESS = 'GET_CUSTOM_LESSONS_SUCCESS';
export const GET_CUSTOM_LESSONS_FAILED = 'GET_CUSTOM_LESSONS_FAILED';
export const UPDATE_CUSTOM_LESSONS_SUCCESS = 'UPDATE_CUSTOM_LESSONS_SUCCESS';
export const UPDATE_CUSTOM_LESSONS_FAILED = 'UPDATE_CUSTOM_LESSONS_FAILED';
export const FILTER_VISIBLE_CUSTOM_LESSONS = 'FILTER_VISIBLE_CUSTOM_LESSONS';
export const SET_SELECTED_IN_PROGRESS_QUIZ = 'SET_SELECTED_IN_PROGRESS_QUIZ';

export const SET_SELECTED_CLASS_ID = 'SET_SELECTED_CLASS_ID';
export const CLEAR_SELECTED_CLASS_ID = 'CLEAR_SELECTED_CLASS_ID';

export const START_QUIZ_SUCCESS = 'START_QUIZ_SUCCESS';
export const START_QUIZ_FAILED = 'START_QUIZ_FAILED';

export const GET_SUPER_DIAGNOSTIC_CUSTOM_DIAGNOSTICS_SUCCESS = 'GET_SUPER_DIAGNOSTIC_CUSTOM_DIAGNOSTICS_SUCCESS';
export const GET_SUPER_DIAGNOSTIC_CUSTOM_DIAGNOSTICS_FAILED = 'GET_SUPER_DIAGNOSTIC_CUSTOM_DIAGNOSTICS_FAILED';
export const UPDATE_CUSTOM_DIAGNOSTICS = 'UPDATE_CUSTOM_DIAGNOSTICS'
export const UPDATE_CUSTOM_DIAGNOSTICS_FAILED = 'UPDATE_CUSTOM_DIAGNOSTICS_FAILED';

export const GET_SUPER_DIAGNOSTIC_TOPICS_SUCCESS = 'GET_SUPER_DIAGNOSTIC_TOPICS_SUCCESS';
export const GET_SUPER_DIAGNOSTIC_TOPICS_FAILED = 'GET_SUPER_DIAGNOSTIC_TOPICS_FAILED';

export const SHOW_MODAL_ENABLE_SUPER_DIAGNOSTIC = 'SHOW_MODAL_ENABLE_SUPER_DIAGNOSTIC';
export const HIDE_MODAL_ENABLE_SUPER_DIAGNOSTIC = 'HIDE_MODAL_ENABLE_SUPER_DIAGNOSTIC';

export const LOAD_CAN_STUDENT_TAKE_SUPER_DIAGNOSTIC_SUCCESS = 'LOAD_CAN_STUDENT_TAKE_SUPER_DIAGNOSTIC_SUCCESS'
export const LOAD_CAN_STUDENT_TAKE_SUPER_DIAGNOSTIC_FAILED = 'LOAD_CAN_STUDENT_TAKE_SUPER_DIAGNOSTIC_FAILED'

export const GET_SUPER_DIAGNOSTIC_QUIZ_SUCCESS = 'GET_SUPER_DIAGNOSTIC_QUIZ_DIAGNOSTICS_SUCCESS';
export const GET_SUPER_DIAGNOSTIC_QUIZ_FAILED = 'GET_SUPER_DIAGNOSTIC_QUIZ_FAILED'

export const SAVE_SUPER_DIAGNOSTIC_ANSWERS_SUCCESS = 'SAVE_SUPER_DIAGNOSTIC_ANSWERS_SUCCESS'
export const SAVE_SUPER_DIAGNOSTIC_ANSWERS_FAILED = 'SAVE_SUPER_DIAGNOSTIC_ANSWERS_FAILED'
export const RESET_SUPER_DIAGNOSTIC_ANSWERS = 'RESET_SUPER_DIAGNOSTIC_ANSWERS'

export const SAVE_SUPER_DIAGNOSTIC_TEACHER = 'SAVE_SUPER_DIAGNOSTIC_TEACHER';

export const SUPER_DIAGNOSTIC_ADD_ANSWER = 'SUPER_DIAGNOSTIC_ADD_ANSWER'
export const SUPER_DIAGNOSTIC_RESET_ANSWERS = 'SUPER_DIAGNOSTIC_RESET_ANSWERS'

export const GET_STUDENT_SUPER_DIAGNOSTIC_RESULT_SUCCESS = 'GET_STUDENT_SUPER_DIAGNOSTIC_RESULT_SUCCESS'
export const GET_STUDENT_SUPER_DIAGNOSTIC_RESULT_FAILED = 'GET_STUDENT_SUPER_DIAGNOSTIC_RESULT_FAILED'

export const AUTO_SAVE_SUPER_DIAGNOSTIC_ANSWERS_SUCCESS = 'AUTO_SAVE_SUPER_DIAGNOSTIC_ANSWERS_SUCCESS'

export const GET_SUPER_DIAGNOSTIC_REPORT_CLASS_SUCCESS = 'GET_SUPER_DIAGNOSTIC_REPORT_CLASS_SUCCESS'
export const GET_SUPER_DIAGNOSTIC_REPORT_CLASS_FAILED = 'GET_SUPER_DIAGNOSTIC_REPORT_CLASS_FAILED'

export const GET_SUPER_DIAGNOSTIC_REPORT_STUDENT_SUCCESS = 'GET_SUPER_DIAGNOSTIC_REPORT_STUDENT_SUCCESS'
export const GET_SUPER_DIAGNOSTIC_REPORT_STUDENT_FAILED = 'GET_SUPER_DIAGNOSTIC_REPORT_STUDENT_FAILED'

export const GET_SUPER_DIAGNOSTIC_TOPIC = 'GET_SUPER_DIAGNOSTIC_TOPIC'

export const GET_SUPER_DIAGNOSTIC_REPORT_TOPIC_SUCCESS = 'GET_SUPER_DIAGNOSTIC_REPORT_TOPIC_SUCCESS'
export const GET_SUPER_DIAGNOSTIC_REPORT_TOPIC_FAILED = 'GET_SUPER_DIAGNOSTIC_REPORT_TOPIC_FAILED'

export const GET_SUPER_DIAGNOSTIC_STUDENT_RESULTS_SUCCESS = 'GET_SUPER_DIAGNOSTIC_STUDENT_RESULTS_SUCCESS'
export const GET_SUPER_DIAGNOSTIC_STUDENT_RESULTS_FAILED = 'GET_SUPER_DIAGNOSTIC_STUDENT_RESULTS_FAILED'

export const UPDATE_APP_SETTINGS = 'UPDATE_APP_SETTINGS';