import * as React from "react";
import {
  Button,
  Col,
  Grid,
  Modal,
  Row,
} from "react-bootstrap";
import Select from 'react-select';
import { ThunkAction } from 'redux-thunk';
import {
  GFInitialState,
  GFLessonWithCustomLesson,
  GFLesson,
  Option
} from "../../models/models";
import { toastr } from 'react-redux-toastr';
import { isNull } from "lodash";
const mixpanel = require("mixpanel-browser");

export interface GoogleShareablesTypeMap {
  quiz: string;
  lesson: string;
  writingassignment: string;
}

interface Props extends React.Props<GoogleShareToClassRoomButton> {
  classOptions: Option[];
  lesson: GFLessonWithCustomLesson | GFLesson;
  item?: any;
  link: string;
  shareToClassRoom: (parentID: string, link: string, classID: string) => ThunkAction<any, GFInitialState, undefined, any>;
  subtitle?: string;
  type: keyof GoogleShareablesTypeMap;
  hide?: boolean;
}

interface State {
  showClassSelectModal: boolean;
  selectedClass: Option|null;
}

class GoogleShareToClassRoomButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showClassSelectModal: false,
      selectedClass: null
    }
  }

  toggleClassSelectModalVisibility = (e?: any|undefined) => {
    if(e){
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({
      showClassSelectModal: !this.state.showClassSelectModal
    });
  }

  handleClassChange = (selectedClass: any) => {
    this.setState({ selectedClass });
  };

  share = () => {
    const {lesson, link, item, type, subtitle} = this.props;
    const {selectedClass} = this.state;
    const idMap: GoogleShareablesTypeMap = {
      quiz: type === 'quiz' && item ? item.id : '',
      lesson: lesson.id,
      writingassignment: type === 'writingassignment' && item ? item.id : '',
    };
    if(selectedClass){
      this.props.shareToClassRoom(
        idMap[type],
        link,
        selectedClass.value
      );
      this.toggleClassSelectModalVisibility();
      switch(type){
        case 'lesson':
          if(lesson){
            mixpanel.track("Lesson material added to Google Classroom", {
              lesson: lesson.id,
              name: lesson.name
            });
          }
          break;
        case 'writingassignment':
          if(item){
            mixpanel.track("Writing assignment added to Google Classroom", {
              wa: item.id,
              name: subtitle
            });
          }
          break;
        case 'quiz':
        default:
          if(item){
            mixpanel.track("Quiz assignment added to Google Classroom", {
              quiz: item.id,
              name: subtitle
            });
          }
          break;
      }
    } else {
      toastr.error("Please select a class.");
    }
  }

  render() {
    const { lesson, subtitle, type, hide } = this.props;
    const { showClassSelectModal, selectedClass } = this.state;
    const defaultOption = { value: 0, label: 'Select a class' };
    const classOptions = [defaultOption, ...this.props.classOptions];
    const containerClassName = ["google-add-to-classroom"];
    if(type !== 'lesson'){
      containerClassName.push('pointer');
    }
    if(hide){
      containerClassName.length = 0;
      containerClassName.push('google-add-to-classroom-restricted');
      return <div className={containerClassName.join(" ")}><div /></div>
    }
    return <div className={containerClassName.join(" ")}>
      <div onClick={this.toggleClassSelectModalVisibility}></div>
      <Modal className='centered-modal' show={showClassSelectModal} onHide={this.toggleClassSelectModalVisibility}>
        <Modal.Body>
          <h4>Share to Classroom</h4>
          <p style={{textAlign: 'center', fontSize: 16}}><strong>{lesson.name}</strong></p>
          {subtitle && <p style={{textAlign: 'center'}}><strong>{subtitle}</strong></p>}
          <Select
            className="react-select-container"
            options={classOptions}
            onChange={this.handleClassChange}
            value={selectedClass}
            placeholder="Select a Google Classroom"
            classNamePrefix="react-select"
            isSearchable={false}
          />
          <Grid fluid style={{marginTop: 20}}>
            <Row>
              <Col sm={12} style={{marginBottom: 20}}>
                <em>Remember to enable this lesson under the “Customize Lessons” tab for your students to have continued access to this lesson and activity.</em>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Button block onClick={this.toggleClassSelectModalVisibility}>Cancel</Button>
              </Col>
              <Col sm={6}>
                <Button bsStyle="primary" block onClick={this.share} disabled={isNull(selectedClass)}>Continue</Button>
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
      </Modal>
    </div>
  }
}

export default GoogleShareToClassRoomButton;
