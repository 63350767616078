import * as React from 'react';
import { Modal } from 'react-bootstrap';

interface Props extends React.Props<Loading> {
  show: boolean;
  message: string;
}
class Loading extends React.Component<Props, {}> {
  render() {
    return (
      <Modal
        show={this.props.show}
        className="loading-modal"
        onHide={() => {
          console.log('loading hidden');
        }}
      >
        <Modal.Body className="text-center">
          <h1>{this.props.message || 'LOADING...'}</h1>
          <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
        </Modal.Body>
      </Modal>
    );
  }
}

export default Loading;
