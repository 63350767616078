import * as React from "react";

import {
  Modal,
  Sizes,
} from "react-bootstrap";
import { GFInitialState } from "../../models/models";

import {ConfirmDisableModal} from "./ConfirmDisableModal";
import { connect } from "react-redux";
import { hideEnableSuperDiagnosticModal, toggleSuperDiagnostic } from "../../actions/superDiagnosticActions";

type Iprops = {
  className: string;
  bsSize?: Sizes;
  info: InfoBody;
};

type IdispatchProps = {
  show: boolean;
  cancel: typeof hideEnableSuperDiagnosticModal;
  toggleSuperDiagnostic: typeof toggleSuperDiagnostic;
};

interface InfoBody { 
    id: string;
    name: string;
    superDiagnosticEnabled: boolean | undefined;
}

// export the combined types for the component we are rendering in the body of the modal
export type ISuperDiagnosticModalProps = Iprops & IdispatchProps;

class ConfirmDisableModalContainerClass extends React.PureComponent<
ISuperDiagnosticModalProps,
  {}
> {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.cancel}
        bsSize={this.props.bsSize}
        className={this.props.className}
      >
        <Modal.Header>
          <Modal.Title>
            Are you sure?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ConfirmDisableModal {...this.props} />
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: Iprops) => {
  return {
    show: state.showConfirmDisableModal,
  };
};

export const ConfirmDisableModalContainer = connect(mapStateToProps, {
  cancel: hideEnableSuperDiagnosticModal,
  toggleSuperDiagnostic
})(ConfirmDisableModalContainerClass);
