import { combineReducers } from 'redux';
import { userReducer as user, socialReducer as socialObj } from './userReducer';
import googleClasses from './googleReducer';
import classes from './classReducer';
import students from './studentReducer';
import courses from './courseReducer';
import lessons from './lessonsReducer';
import quizzes from './quizzesReducer';
import { customDiagnosticReducer, topicsReducer, superDiagnosticReducer, superDiagnosticViewReducer } from './superDiagnosticReducer';

import {
  classProgress,
  classProgressRaw,
  myProgressRaw,
  studentProgress,
  myProgress,
  progressFilters,
  quizResultReducer,
  teacherProgress
} from './progressReducer';
import badges from './badgesReducer';
import ajaxCallsInProgress from './ajaxStatusReducer';
import { reducer as toastr } from 'react-redux-toastr';
import {
  waArrayReducer as writingAssignments,
  waResultsReducer as waResultsByID
} from './waReducer';
import { quizViewReducer } from './quizViewReducer';
import customLessonsReducer from './customLessonsReducer';
import visibleCustomLessonsWithLessonReducer from './visibleCustomLessonsReducer';
import { selectedClassIDReducer, appSettingsReducer, createShowModalWithNamedType } from './commonReducers';
import { GFInitialState } from '../models/models';
import { superDiagnosticReportReducer, selectSuperDiagnosticTopicReducer } from './superDiagnosticReportReducer';

const rootReducer = combineReducers<GFInitialState>({
  user,
  socialObj,
  googleClasses,
  classes,
  courses,
  lessons,
  quizzes,
  superDiagnosticTopics: topicsReducer,
  customDiagnosticsByID: customDiagnosticReducer,
  superDiagnosticQuiz: superDiagnosticReducer,
  superDiagnosticView: superDiagnosticViewReducer,
  // TODO:  Topics, customDiagnostics
  students,
  writingAssignments,
  waResultsByID,
  badges,
  ajaxCallsInProgress,
  toastr,
  classProgress,
  myProgressRaw,
  classProgressRaw,
  studentProgress,
  teacherProgress,
  myProgress,
  progressFilters,
  quizView: quizViewReducer,
  customLessons: customLessonsReducer,
  visibleLessonsWithCustomLesson: visibleCustomLessonsWithLessonReducer,
  selectedClassID: selectedClassIDReducer,
  quizResult: quizResultReducer,
  appSettings: appSettingsReducer,
  showConfirmDisableModal: createShowModalWithNamedType('ENABLE_SUPER_DIAGNOSTIC'),
  superDiagnosticReport: superDiagnosticReportReducer,
  selectedSuperDiagnosticTopic: selectSuperDiagnosticTopicReducer
});

export default rootReducer;
