// const root = process.env.REACT_APP_SERVER_DOMAIN;
// console.log(
//   `ENV: ${process.env.NODE_ENV} SERVER_DOMAIN: ${root}/ Version: ${
//     process.env.REACT_APP_VERSION
//   }`
// );
const rootStudent = process.env.REACT_APP_SERVER_STUDENT;
const rootTeacher = process.env.REACT_APP_SERVER_TEACHER;
const rootUser = process.env.REACT_APP_SERVER_USER;
const rootCurriculum = process.env.REACT_APP_SERVER_CURRICULUM;

export const API = {
  POST: {
    addCustomLesson: `${rootTeacher}/customlesson/add`,
    updateCustomLesson: `${rootTeacher}/customlesson/update`,
    payment: {
      signup: `${rootUser}/payment/signup`,
      directSignup: `${rootUser}/user/direct`,
      updateSubscription: `${rootUser}/payment/updatesubscription`,
      updateCard: `${rootUser}/payment/updatecard`,
      cancelSubscription: `${rootUser}/payment/cancelsubscription`,
      checkCoupon: `${rootUser}/payment/couponcheck?code=`
    },
    user: {
      startResetPassword: `${rootUser}/user/startresetpassword`,
      save: `${rootUser}/user/save`, // teacher register
      checkSession: `${rootUser}/user/checksession`,
      updateUserPassword: `${rootUser}/user/updateuserpassword`,
      login: `${rootUser}/user/login`,
      loginsocial: `${rootUser}/user/loginsocial`,
      loginSocialWithCode: `${rootUser}/user/googlelogin`,
      decodeSocialCode: `${rootUser}/user/googleregistration`,
      resendVerification: `${rootUser}/user/resendverification`,
      verifyResetPasswordCode: `${rootUser}/user/verifyresetpasswordcode`,
      updateStudentPassword: `${rootUser}/user/updatestudentpassword`,
      updateTeacherPassword: `${rootUser}/user/updatesubteacherpassword`,
      updateShowTutorial: `${rootUser}/user/updateshowtutorial`,
    }
  },
  GET: {
    getCustomLessons: `${rootTeacher}/customlesson/getCustomLessons`,
    progress: {
      getClassReport: `${rootTeacher}/progress/getclassreport`,
      getStudentReport: `${rootTeacher}/progress/getstudentreport?studentID=`,
      getSuperTeacherReport: `${rootTeacher}/progress/getsuperteacherreport`,
      getResultsCSVForStudent: `${rootTeacher}/progress/studentprogressreport.csv?studentID=`,
      getStudentQuizResult: `${rootTeacher}/progress/getstudentquizresult?quizresultid=`
    }
  },
  writingAssignment: {
    getAssignment: `${rootStudent}/writingassignment/getassignment`,
    save: `${rootStudent}/writingassignment/save`,
    getByLesson: `${rootStudent}/writingassignment/getall`
  },
  student: {
    saveQuiz: `${rootStudent}/student/savequiz`,
    startQuiz: `${rootStudent}/student/startquiz`,
    resetQuizStarts: `${rootStudent}/student/resetquizstarts`,
    create: `${rootStudent}/student/create`,
    getEarnedBadges: `${rootStudent}/student/getearnedbadges`,
    getAll: `${rootStudent}/student/search?search=`,
    deleteClass: `${rootStudent}/student/deleteclass`,
    addClass: `${rootStudent}/student/addclass`
  },
  superTeacher: {
    searchTeacher: `${rootUser}/user/getteacher?email=`,
    removeSubTeacher: `${rootUser}/user/removesubteacher`,
    addSubTeacher: `${rootUser}/user/savesubteacher`,
  },
  course: {
    getAll: `${rootCurriculum}/course/getall`
  },
  lesson: {
    getByCourse: `${rootCurriculum}/lesson/getbycourse`,
    getAll: `${rootCurriculum}/lesson/getall`
  },
  quiz: {
    getAll: `${rootCurriculum}/quiz/getentirelist`,
    getByLesson: `${rootCurriculum}/quiz/getall`
  },
  class: {
    getAll: `${rootTeacher}/class/getall`,
    create: `${rootTeacher}/class/save`,
    delete: `${rootTeacher}/class/delete/`,
    getCode: `${rootTeacher}/class/getcode`,
    validateCode: `${rootTeacher}/class/validatecode?code=`,
    getWaResults: `${rootTeacher}/writingassignment/getassignments?classID=`,
    saveWaResult: `${rootTeacher}/writingassignment/save`,
    getSuperDiagnosticQuiz: `${rootTeacher}/class/getsuperdiagnostic`,
    updateClassSuperDiagnostic: `${rootTeacher}/class/updateClassSuperDiagnostic`,
    addStudentByEmail: `${rootTeacher}/class/addstudenttoclass`,
    resetAll: `${rootTeacher}/class/clear`,
  },
  customDiagnostic: {
    get: `${rootTeacher}/customdiagnostic/get`,
    update: `${rootTeacher}/customdiagnostic/update`
  },
  topic: {
    getAll: `${rootCurriculum}/topic/getall`
  },
  superDiagnostic: {
    canStudentTakeSuperDiagnostic: `${rootStudent}/student/CanStudentTakeSuperDiagnostic`,
    saveSuperDiagnostic: `${rootStudent}/student/savesuperdiagnostic`,
    getStudentSuperDiagnosticResult: `${rootStudent}/student/GetStudentSuperDiagnosticResult`,
    superDiagnosticReportClass: `${rootTeacher}/progress/SuperDiagnosticReportClass`,
    superDiagnosticReportStudent: `${rootTeacher}/progress/SuperDiagnosticReportStudent`,
    superDiagnosticReportTopic: `${rootTeacher}/progress/SuperDiagnosticReportTopic`,
    superDiagnosticReportStudentAnswers: `${rootTeacher}/progress/GetSuperDiagnosticResult`
  },
  progress: {
    resetClassProgress: `${rootTeacher}/progress/resetclassprogress`,
    resetStudentProgress: `${rootTeacher}/progress/resetStudentprogress`
  },
  google: {
    createLesson: `${rootTeacher}/google/createlesson`,
    createQuiz: `${rootTeacher}/google/createquiz`,
    createWritingAssignment: `${rootTeacher}/google/createwritingassignment`,
    importStudents: `${rootTeacher}/google/studentimport`,
    importClasses: `${rootTeacher}/google/classimport`,
    getClasses: `${rootTeacher}/google/getclassesfromgoogle/`,
  }
};
