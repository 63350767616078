import * as types from './actionTypes';
import { beginAjaxCall } from './ajaxStatusActions';
import { GFUser, GFClass, ThunkResult } from '../models/models';
import { sortBy, find } from 'lodash';
import * as moment from 'moment';
import constants from '../constants';
import { toastr } from 'react-redux-toastr';
import { getCustomLessonsHelper } from './customLessonActions';
import { API } from '../apiEndpoints';
import Axios, { AxiosRequestConfig } from 'axios';
import { initialClass } from '../reducers/initialState';

/*
* TEMPLATE AXIOS ACTION API REQUEST

export function templateAction(): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());

    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url: API.something
    };
    return Axios(axiosOptions)
      .then(resp => {
        dispatch({ type: types.something_SUCCESS, something: resp.data });
        return something;
      })
      .catch((error: any) => {
        dispatch({ type: types.something_FAILED, error });
        constants.handleError(error, 'what failed');
        console.error('what failed', error)
        throw error;
      });
  };
}

*/


/*
  Get all classes for current teacher
*/
export function loadClasses(): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());

    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url: API.class.getAll
    };

    return Axios(axiosOptions)
      .then(resp => {
        const dataArray: any[] = [];
        resp.data.map((gfClass: GFClass) => {
          getCustomLessonsHelper(gfClass.id, dispatch, getState);
          dataArray.push(gfClass);
        });
        const classes = sortBy(dataArray, (gfClass: GFClass) => {
          return moment.utc(gfClass.createDate).toDate();
        });
        dispatch({ type: types.LOAD_CLASSES_SUCCESS, classes });
        return classes;
      })
      .catch((error: any) => {
        dispatch({ type: types.LOAD_CLASSES_FAILED, error });
        constants.handleError(error, 'load classes');
        console.error('error loading classes', error)
        throw error;
      });
  };
}

/*
  Create or Edit a class for current teacher
*/
export function saveClass(
  name: string,
  code: string,
  id = '',
  ): ThunkResult<any> {
    return (dispatch, getState) => {
      const classes = getState().classes;
      const foundClass = find(classes, {id});
      let newClass: GFClass = {
        ...initialClass,
        id,
        name,
        code
      }
      
      let currentCode = foundClass ? foundClass.code : code

      if (foundClass){
        newClass = {...foundClass, ...newClass}
      }
      dispatch(beginAjaxCall());
      
      let data

      if (id) {
        data = {
          id,
          name,
          code: currentCode
        }
      } else {
        data = {
          name,
          code: currentCode
        }
      }

      const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        url: API.class.create,
        data
      };
      
      return Axios(axiosOptions)
      .then((resp) => {
        if (!resp) {
          throw undefined;
        } else {
          const gfClass = {...newClass, ...resp.data.value}
          if (!!id) {
            dispatch({ type: types.UPDATE_CLASS_SUCCESS, gfClass });
          } else {
            dispatch({ type: types.CREATE_CLASS_SUCCESS, gfClass });
          }
          toastr.success('Success', 'Class Saved', constants.toastrSuccess);
        }
      })
      .catch(error => {
        dispatch({ type: types.CREATE_CLASS_FAILED, error });
        // error handled in component
        // const message = 'save class'
        // console.error(message, error);
        // constants.handleError(error, message);
        throw error;
      });
      
    }
}


/*
  Delete a class for current teacher
*/
export function deleteClass(classID: string): ThunkResult<any> {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    const url = API.class.delete + classID
    const axiosOptions: AxiosRequestConfig = {
      method: 'delete',
      url: url
      }
    return Axios(axiosOptions)
      .then((data: any) => {
        if (!data) {
          throw data;
        } else {
          dispatch({ type: types.DELETE_CLASS_SUCCESS, classID });
        }
      })
      .catch(error => {
        dispatch({ type: types.DELETE_CLASS_FAILED, error });
        const message = 'delete class'
        console.error(message, error);
        constants.handleError(error, message);
        throw error;
      });
  };
}

/*
  Generate a validated code for use with a new class
*/
export function getCode(user: GFUser) {

  const axiosOptions: AxiosRequestConfig = {
    method: 'get',
    url: API.class.getCode
  }

  return Axios(axiosOptions)
    .then((resp) => {
      const data = resp.data.replace(/['"]+/g, '')
      return data
    })
    .catch(error => {
      const message = 'get class code'
      console.error(message, error);
      constants.handleError(error, message);
      throw error
  })
}
/*
  Validate a given code for use with a new  class
*/
export function validateCode(code: string) {
  const url = API.class.validateCode + code

  const axiosOptions: AxiosRequestConfig = {
    method: 'get',
    url: url
  }

  return Axios(axiosOptions)
    .then((data: any) =>{
      return data
    })
    .catch(error => {
      // error handled in component
      throw error
    })
}

/**
 * Add a valid student to a teacher class by email.
 * @param email 
 * @param classID 
 */
export function addStudentByEmail(
  email: string, 
  classID: string
  ): ThunkResult<Promise<void>> {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    const data = {
      email: email,
      classID: classID
    };
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url: API.class.addStudentByEmail,
      data
    }
    return Axios(axiosOptions)
      .then((resp) => {
        dispatch({ type: types.ADD_STUDENT_BY_EMAil_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: types.ADD_STUDENT_BY_EMAil_FAILED, error });
        const message = 'add student by email'
        console.error(message, error);
        constants.handleError(error, message);
        throw error;
      });
  };
}

/**
 * Reset everything.
 */
 export function resetClasses(): ThunkResult<Promise<void>> {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url: API.class.resetAll
    }
    return Axios(axiosOptions)
      .then((resp) => {
        dispatch({ type: types.RESET_SUCCESS });
        toastr.success('Success', 'Reset complete.', constants.toastrSuccess);
      })
      .catch(error => {
        dispatch({ type: types.RESET_FAILED, error });
        constants.handleError(error, 'reset classes');
        console.error('reset classes', error)
        throw error;
      });
  };
}
