/*
 * Super Teacher viewing the teacher progress
 */

import * as React from "react";

import {
  Button,
  Col,
  ControlLabel,
  FormGroup,
  Grid,
  Row
} from "react-bootstrap";
import {
  convertToOptions,
} from "./StudentReportUtil";
import {
  FooterCell,
  printElement,
  scoreCell
} from "./SuperTeacherReportUtil";
import {
  GFInitialState,
  GFQuizItem,
  GFTeacherUser,
  GFUser,
  Option
} from "../../models/models";
import ReactTable from "react-table";
import { RouteComponentProps, browserHistory } from "react-router";
import { find, isEmpty } from "lodash";
import { getAllQuizzes, resetQuizStarts } from "../../actions/quizActions";
import {
  getVisibleProgressSubTeacher,
  setStartDateSubTeacher,
  setEndDateSubTeacher,
} from "../../actions/progressActions";

import DateRangePicker from "../common/DateRangePicker";
import MainMenu from "../menu/MainMenu";
import { QuizAnswersModalContainer } from "./QuizAnswersModalContainer";
import Select from "react-select";
import { connect } from "react-redux";
import { loadClasses } from "../../actions/classActions";
import { selectIsTeacher, selectIsSuperTeacher, selectHasGoogleClasses } from "../../reducers/userReducer";
import { selectClassOptions } from "../../reducers/classReducer";

// const fileDownload = require("react-file-download");
const mixpanel = require("mixpanel-browser");
const graham = require("../../images/graham.gif");

/*
* assign the correct color to the table cell
*/
const handleTdProps = (
  state: any,
  rowInfo: any,
  column: any,
  instance: any
) => {
  const quizResult = rowInfo.row[column.id];
  if (
    quizResult &&
    quizResult.length &&
    !state.expanded[rowInfo.row._index] &&
    quizResult[0].className
  ) {
    return {
      className: `${quizResult[0].className}`
    };
  } else {
    return {
      className: "no-score"
    };
  }
};

const sortQuizzes = (a: any[], b: any[], desc: boolean) => {
  a = a[0].score;
  b = b[0].score;

  // Return either 1 or -1 to indicate a sort priority
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }
  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0;
};

const columns = [
  {
    Header: "Teacher Name",
    id: "name",
    accessor: (teacher: any) => `${teacher.first} ${teacher.last}`,
    minWidth: 200,
    Footer: <strong>Averages</strong>
  },
  {
    Header: "Pre-Test",
    id: "quiz01",
    accessor: (teacher: any) => teacher.quiz01Results,
    Cell: scoreCell,
    sortMethod: sortQuizzes,
    minWidth: 100,
    Footer: FooterCell
  },
  {
    Header: "PE 1",
    id: "quiz02",
    accessor: (teacher: any) => teacher.quiz02Results,
    Cell: scoreCell,
    sortMethod: sortQuizzes,
    minWidth: 100,
    Footer: FooterCell
  },
  {
    Header: "PE 2",
    id: "quiz03",
    accessor: (teacher: any) => teacher.quiz03Results,
    Cell: scoreCell,
    sortMethod: sortQuizzes,
    minWidth: 100,
    Footer: FooterCell
  },
  {
    Header: "PE 3",
    id: "quiz04",
    accessor: (teacher: any) => teacher.quiz04Results,
    Cell: scoreCell,
    sortMethod: sortQuizzes,
    minWidth: 100,
    Footer: FooterCell
  },
  {
    Header: "Post-Evaluation",
    id: "quiz05",
    accessor: (teacher: any) => teacher.quiz05Results,
    Cell: scoreCell,
    sortMethod: sortQuizzes,
    minWidth: 140,
    Footer: FooterCell
  }
];

interface MatchParams {
  classID: string;
  lessonID: string;
}
interface Props extends RouteComponentProps<MatchParams, {}> {
  user: GFUser;
  data: any;
  teachers: GFTeacherUser[];
  classOptions: Option[];
  lessonOptions: Option[];
  loading: boolean;
  loadClasses: () => Promise<any>;
  getVisibleProgressSubTeacher: typeof getVisibleProgressSubTeacher;
  startDate: Date;
  endDate: Date;
  setStartDateSubTeacher: typeof setStartDateSubTeacher;
  setEndDateSubTeacher: typeof setEndDateSubTeacher;
  getTeacherQuizResult: any;
  getAllQuizzes: () => Promise<void>;
  quizzes: { [key: string]: GFQuizItem };
  resetQuizStarts: typeof resetQuizStarts;
  hasGoogleClasses: boolean;
  isTeacher: boolean;
  isSuperTeacher: boolean;
  lessons: any;
}

interface State {
  selectedRow: any;
  selectedClass: Option | null;
  selectedLesson: Option | null;
}

class SuperTeacherSubTeacherReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedClass: null,
      selectedLesson: null,
      selectedRow: {}
    };
  }
  componentDidMount() {
    mixpanel.track("Viewing Super Teacher Progress Report");
    this.checkDependencies();
    this.updateReport();
  }
  componentDidUpdate(prevProps: Props) {
    if(this.state.selectedLesson === null && this.props.lessonOptions.length > 0){
      this.setState({selectedLesson: this.props.lessonOptions[0]}, () => {
        this.updateReport();
      });
    }
    if (prevProps.startDate.getTime() !== this.props.startDate.getTime() || prevProps.endDate.getTime() !== this.props.endDate.getTime()) {
      this.updateReport();
    }
  }

  /*
  * check for dependencies. once you get one, try again by recursively calling self
  */
  checkDependencies = () => {
    let dependencyPromises: Array<Promise<any>> = [];

    if (isEmpty(this.props.quizzes)) {
      dependencyPromises = [...dependencyPromises, this.props.getAllQuizzes()];
    }
    if (this.props.teachers.length === 0) {
      dependencyPromises = [
        ...dependencyPromises
      ];
    }
    if (this.props.classOptions.length === 0) {
      dependencyPromises = [
        ...dependencyPromises,
        this.props.loadClasses()
      ];
    }
    if (dependencyPromises.length) {
      Promise.all(dependencyPromises).then(() => {
        this.updateSelects();
      }).catch(error => {
        console.error('failed to get dependencies', error)
      });
    } else {
      this.updateSelects();
    }
  };
  updateSelects = () => {
    const { lessonID } = this.props.params;
    if (
      this.props.lessonOptions &&
      this.props.lessonOptions.length
    ) {
      const selectedLesson =
        find(this.props.lessonOptions, (obj: any) => {
          return obj.value === lessonID;
        }) || this.props.lessonOptions[0];
      this.setState({ selectedLesson }, () => {
        this.updateReport();
      });
    } else {
      console.log("skipping update selects");
    }
  };
  updateReport = () => {
    this.setState({ selectedRow: {} });
    // replace current path so we know which course is selected
    const { selectedLesson } = this.state;
    const { lessons } = this.props;
    if (selectedLesson) {
      const selectedLessonObject = find(lessons, {id: selectedLesson.value});
      if(selectedLessonObject){
        const path = `/teacherprogress/${selectedLesson.value}`;
        browserHistory.replace(path);
        this.props.getVisibleProgressSubTeacher(
          selectedLessonObject
        );
      }
    }
  };
  // need to adjust Option type so changing to any for now
  handleClassChange = (selectedClass: any) => {
    this.setState({ selectedClass }, () => {
      this.updateReport();
    });
  };
  // need to adjust Option type so changing to any for now
  handleLessonChange = (selectedLesson: any) => {
    this.setState({ selectedLesson }, () => {
      this.updateReport();
    });
  };

  doPrint = () => {
    const lessonForPrint =
      this.state.selectedLesson === null ? "" : this.state.selectedLesson.label;
    mixpanel.track("Printing SuperTeacher Progress Report", {
      lessonName: lessonForPrint
    });
    this.setState({ selectedRow: {} }, () => {
      printElement(document.getElementsByClassName("teachers")[0], false, null);
      window.print();
    });
  };

  render() {
    const { data } = this.props;
    const lessonForPrint =
      this.state.selectedLesson === null ? "" : this.state.selectedLesson.label;
    return (
      <Grid className="content modal-container">
        <div className="sidemenu">
          <MainMenu isTeacher={this.props.isTeacher} isSuperTeacher={this.props.isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
          <div className="logo text-center">
            <img alt="graham" src={graham} />
          </div>
        </div>
        <div className="teachers main-content content-with-sidebar">
          <Row className="teachers-header">
            <Col md={10} xs={10} className="text-xl-left">
              <h1 className="pageDescription">
                Teacher Progress Report
                <Button
                  onClick={this.doPrint}
                  bsStyle="default"
                  type="button"
                  bsSize="sm"
                >
                  Print
                </Button>
                <Button
                  onClick={() => this.updateReport()}
                  bsStyle="default"
                  type="button"
                  bsSize="sm"
                >
                  Refresh
                </Button>
              </h1>
            </Col>
          </Row>
          <Row className="class-form">
            <Col xs={4} style={{ paddingTop: "15px" }}>
              <DateRangePicker
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                handleChangeStart={this.props.setStartDateSubTeacher}
                handleChangeEnd={this.props.setEndDateSubTeacher}
              />
            </Col>

            <Col xs={8}>
              <FormGroup bsSize="lg" className="class-lesson-selects">
                <Row>
                  <Col xs={2}>
                    <ControlLabel>Lesson</ControlLabel>
                  </Col>
                  <Col xs={10}>
                    <Select
                      className="react-select-container"
                      options={this.props.lessonOptions}
                      onChange={this.handleLessonChange}
                      value={this.state.selectedLesson}
                      placeholder="search..."
                      classNamePrefix="react-select"
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row className="for-print">
            <Col xs={12}>Lesson: {lessonForPrint}</Col>
          </Row>
          <Row className="class-table">
            <Col xs={12}>
              {!!data && <ReactTable
                className="teacher-class-report -highlight"
                data={data}
                columns={columns}
                showPagination={false}
                showPageSizeOptions={false}
                pageSize={data.length}
                key={data.length}
                loading={this.props.loading}
                getTdProps={handleTdProps}
                expanded={this.state.selectedRow}
                noDataText="No progress found."
                resizable={false}
                sortable={false}
              />}
            </Col>
          </Row>
        </div>
        <QuizAnswersModalContainer
          className="progress-reports-modal"
          bsSize="large"
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  const startDate = new Date(state.teacherProgress.dateRange.startDate);
  const endDate = new Date(state.teacherProgress.dateRange.endDate);
  const classOptions = selectClassOptions(state);
  return {
    user: state.user,
    data: state.teacherProgress.data,
    teachers: state.user.subTeachers,
    classOptions,
    lessonOptions: convertToOptions(Object.values(state.lessons)),
    lessons: state.lessons,
    loading: state.ajaxCallsInProgress > 0,
    startDate,
    endDate,
    quizzes: state.quizzes,
    hasGoogleClasses: selectHasGoogleClasses(state),
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state)
  };
};

export default connect(
  mapStateToProps,
  {
    getVisibleProgressSubTeacher,
    loadClasses,
    setStartDateSubTeacher,
    setEndDateSubTeacher,
    getAllQuizzes,
    resetQuizStarts
  }
)(SuperTeacherSubTeacherReport);
