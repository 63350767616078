import * as React from "react";
import { calculateScore } from "../../actions/quizActions";
import { Row, Col, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { RouteComponentProps, browserHistory } from "react-router";
import { GFQuizAnswer } from "../../models/models";
import { QuizBreadcrumbContainer } from "./QuizBreadcrumbContainer";

const getScoreString = (score: number) => {
  let grade;
  let message;
  if (score >= 80 && score <= 100) {
    message =
      "Nice job!  It looks like you have a solid understanding of the material.";
    grade = "a";
  } else if (score >= 60 && score <= 79) {
    message = "Hmmm.  It looks like you missed a few.";
    grade = "b";
  } else if (score >= 0 && score <= 59) {
    message =
      "It looks like you're still learning the material - don't give up!";
    grade = "c";
  }
  return { grade, message };
};

const QuizScore = ({ quizAnswers }: { quizAnswers: GFQuizAnswer[] }) => {
  const { score, numCorrect, tot } = calculateScore(quizAnswers);
  const { grade, message } = getScoreString(score);

  return (
    <div>
      <Row>
        <Col
          md={10}
          mdOffset={1}
          sm={10}
          smOffset={1}
          xs={10}
          xsOffset={1}
          className="text-center"
        >
          <h2 style={{ fontSize: "26px" }}>{message}</h2>
        </Col>
      </Row>
      <div className="quiz-score">
        <h1 className={"quiz-score-" + grade}>{score.toFixed(0) + "%"}</h1>
        <p>
          {numCorrect} / {tot} correct.
        </p>
      </div>
    </div>
  );
};

interface RouterParams {
  courseID: string;
  lessonID: string;
  quizID: string;
}
interface Iprops extends RouteComponentProps<RouterParams, {}> {
  retakeQuiz: () => void;
  quizAnswers: GFQuizAnswer[];
}

export const QuizComplete = (props: Iprops) => {
  if (
    !props.quizAnswers ||
    (props.quizAnswers && props.quizAnswers.length === 0)
  ) {
    browserHistory.push(
      `/quiz/${props.params.courseID}/${props.params.lessonID}/${props.params.quizID}`
    );
  }
  return (
    <div className="content container quiz-complete">
      <div className="main-content content-without-sidebar ">
        <Row className="sub-header">
          <QuizBreadcrumbContainer params={props.params} />
        </Row>
        <Row className="question">
          <Col md={12} sm={12} xs={12} className="text-center">
            <QuizScore quizAnswers={props.quizAnswers} />
          </Col>
        </Row>
        <Row className="question">
          <Col md={12} sm={12} xs={12} className="text-center">
            <LinkContainer
              to={`/lesson/${props.params.courseID}/${props.params.lessonID}`}
            >
              <Button bsStyle="primary" style={{ marginLeft: 10 }}>
                Return to Lesson
              </Button>
            </LinkContainer>
            <LinkContainer to={`/courses`}>
              <Button bsStyle="primary" style={{ marginLeft: 10 }}>
                Return to dashboard
              </Button>
            </LinkContainer>
          </Col>
        </Row>
      </div>
    </div>
  );
};
