import * as React from "react";
import * as viewerMode from "../common/viewerMode";

import {
  GFQuizAnswer,
} from "../../models/models";
import { IquizAnswersProps } from "../progressReports/QuizAnswersModalContainer";
import Question from "../common/Question";
import { sortBy } from "lodash";

/*
 * Quiz Answers displays the student's answers for a Practice Exercise (quiz)
 */


class PracticeExercise extends React.Component<IquizAnswersProps, {}> {
  constructor(props: IquizAnswersProps) {
    super(props);
    this.state = {};
  }

  getCurrentStudentProgress() {
    let orderingQuizzes: any;
    const { quizResult, title } = this.props;
    if (quizResult.id) {
      // quiz not complete and locked (user attemted 3 times and was locked out)
      if (quizResult.isComplete === false && quizResult.isLocked) {
        return this.errorIncompleteLocked();
      }
      if (!quizResult.answers || quizResult.answers && quizResult.answers.length === 0) {
        return this.errorNoAnswers();
      }

      if (quizResult && quizResult.quiz) {
        orderingQuizzes = sortBy(quizResult.quiz.questions, ["quiz", "order"]);
      }
      return (
        <div className="practice-exercise-modal">
          <div className="quiz">
            {quizResult.isLocked && this.errorLocked()}
            <div className="question">
              {title && <p className="d-none do-print"><strong>{title}</strong></p>}
              <p className={this.props.showingInModal === false ? "d-none do-print" : ""}>{quizResult.quiz.name}</p>
              <div className="teacher-viewing-text-question">
                <p
                  dangerouslySetInnerHTML={{
                    __html: quizResult.quiz.instructions.replace(
                      "**blank**",
                      "__________"
                    ),
                  }}
                />
              </div>
              {orderingQuizzes.map((curQ: any, index: number) => {
                const finalAnswer = {
                  isCorrect: false,
                  answer: "",
                  questionID: curQ.id,
                };
                const selectedAnswer = quizResult.answers.filter((answer) => {
                  if (answer.questionID === curQ.id) {
                    return true;
                  }
                });
                if (selectedAnswer[0] && selectedAnswer[0].id) {
                  finalAnswer.isCorrect = selectedAnswer[0].isCorrect;
                  finalAnswer.answer = selectedAnswer[0].answer;
                }
                // what is the student's answer for this question.  pass that as the selectedAnswer or textAnswer
                return this.buildQuestion(curQ, finalAnswer, index);
              })}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  errorNoAnswers() {
    return (
      <div>
        <h3>Sorry! Student answers not available.  The student either took this quiz over one year ago or they did not complete the quiz.</h3>
      </div>
    );
  }
  errorIncompleteLocked() {
    return (
      <div>
        <h3>
          Warning: This assessment has been opened three times without having
          been submitted and has been locked to discourage potential cheating.
          The teacher can reset this assessment.
        </h3>
      </div>
    );
  }
  errorLocked() {
    return (
      <div style={{ margin: "0 20px 25px 20px", fontSize: "1.1em" }}>
        <i>
          This assessment has been completed and locked. It cannot be taken
          again unless the teacher resets it for the student.
        </i>
      </div>
    );
  }

  buildQuestion(curQ: any, selectedAnswer: GFQuizAnswer, index: number) {
    let optionsClass = '';
    switch(true){
      case curQ.type === 'choice' && curQ.options && curQ.options.length > 2:
        optionsClass = 'four-choices';
        break;
      case curQ.type === 'choice' && curQ.options && curQ.options.length <= 2:
        optionsClass = 'two-choices';
        break;
    }
    return (
      <div key={curQ.id} className={`d-flex qtype-${curQ.type} ${selectedAnswer.isCorrect ? 'correct' : ''} ${optionsClass}`}>
        <div className="number">
          <span>{++index}.</span>
        </div>
        <div className="w-100">
          <Question
            curQ={curQ}
            showCorrectAnswer={this.props.show}
            forceDisabled={this.props.forceDisabled}
            selectedAnswer={selectedAnswer}
            viewerMode={viewerMode.TEACHER_VIEW_STUDENT_QUIZ_RESULT}
            handleChange={() => {
              console.log("placeholder function");
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.getCurrentStudentProgress()}
      </div>
    );
  }
}

export default PracticeExercise;
