import * as React from "react";
import { Row, Col, Button, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const thumbsUp = require("../../images/graham-thumbs-up.png");


export const SuperDiagnosticComplete = () => {

  return (
    <div className="content container quiz-complete">
      <div className="main-content content-without-sidebar ">
        <Row className="question">
        <Row className="sub-header text-center">
            <h1>Diagnostic Complete!</h1>
        </Row>
          <Col md={12} sm={12} xs={12} className="text-center">
            <div style={{maxWidth:"300px", margin: "5px auto 20px auto"}}>
            <Image src={thumbsUp} responsive/>
            </div>
            <LinkContainer to={`/courses`}>
              <Button bsStyle="primary" style={{ marginLeft: 10 }}>
                Return to dashboard
              </Button>
            </LinkContainer>
          </Col>
        </Row>
      </div>
    </div>
  );
};
