import * as React from "react";

import {
  GFDateRange,
  GFInitialState,
  GFStudent,
  GFUser,
  Option,
  GFSuperDiagnosticTopicResult,
  GFSuperDiagnosticTopic
} from "../../models/models";
import ReactTable, { RowInfo, Column } from "react-table";

import { connect } from "react-redux";
import { selectIsTeacher } from "../../reducers/userReducer";
import { ProgressBar } from "react-bootstrap";

import { browserHistory, RouteComponentProps } from "react-router";
import { orderBy } from "lodash";
import { selectClassOptions } from "../../reducers/classReducer";
interface rowInfoEdited extends RowInfo{
  original: GFSuperDiagnosticTopicResult;
}

/*
* assign the correct color to the table cell
*/
const handleTdProps = (
  state: any,
  rowInfo: rowInfoEdited | undefined,
  column: any,
  instance: any
) => {
return {};
};

interface MatchParams {
  classID: string;
}


interface Props extends RouteComponentProps<MatchParams, {}> {
  user: GFUser;
  data: GFSuperDiagnosticTopicResult[];
  students: GFStudent[];
  classOptions: Option[];
  loading: boolean;
  dateRange: GFDateRange;
  getStudentQuizResult: any;
  isTeacher: boolean;
  selectedTopic: GFSuperDiagnosticTopic;
}

interface State {
  selectedRow: any;
  columns: Column<GFSuperDiagnosticTopicResult>[] | undefined;
}

class SuperDiagnosticReportClass extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRow: {},
      columns: this.columns()
    };
  }
  componentDidUpdate(prevProps: Props){
    if (prevProps.selectedTopic !== this.props.selectedTopic){
      this.setState({columns: this.columns()})
    }
  }

  getTrProps = (state: any, rowInfo: rowInfoEdited | undefined) => {
    if (rowInfo) {
      return {
        onClick: (e: React.MouseEvent<HTMLFormElement>) => {
          this.setState({
            selectedRow: {
              [rowInfo.viewIndex]: !this.state.selectedRow[rowInfo.viewIndex]
            },
          });
          const path = `/super-diagnostic-report-student/${this.props.params.classID}/${rowInfo.original.studentID}`
          browserHistory.push(path) 
        },
        style: {
          background: this.state.selectedRow[rowInfo.viewIndex] ? `#fdf5c4` : ""
        },
        title: "view student report"
      };

    } else {
      return {};
    }
  };

  createTopicName = () => {
    if (this.props.selectedTopic){
      return this.props.selectedTopic.name
    } else {
      return 'All Topics'
    }
  }


  columns = () => (
    [
      {
        Header: "Student",
        id: "student",
        accessor: 'studentName',
        minWidth: 50,
        
      },
      {
        Header: `Performance On ${this.props.selectedTopic ? this.props.selectedTopic.name : "All Topics"}`,
        id: "performance",
        accessor: ({percentPassed, score, hasTakenQuiz}: GFSuperDiagnosticTopicResult)=> { 
          let percent = score !== undefined ? score : undefined;
          if (percentPassed !== undefined){
            percent = percentPassed;
          }
          if (percent !== undefined && hasTakenQuiz) {
            return (
              <ProgressBar>
              <ProgressBar bsStyle="success" now={percent} label={`${percent}%`} />
              <ProgressBar bsStyle="danger" now={100 - percent}  />
            </ProgressBar>
          )
        } else {
          return (
            'N/A'
            )
          }
        }
      }
    ]
  ) 
    
    render() {

    return (
      <ReactTable
        className="teacher-class-report -highlight"
        data={this.props.data}
        columns={this.state.columns}
        showPagination={false}
        showPageSizeOptions={false}
        pageSize={this.props.data.length}
        key={this.props.data.length}  
        loading={this.props.loading}
        getTdProps={handleTdProps}
        getTrProps={this.getTrProps}
        noDataText="No progress found."
        resizable={false}
        sortable={false}
      />
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  const classOptions = selectClassOptions(state);


  return {
    data: state.superDiagnosticReport.classTopicResults,
    students: state.students,
    classOptions,
    loading: state.ajaxCallsInProgress > 0,
    dateRange: state.classProgress.dateRange,
    isTeacher: selectIsTeacher(state)
  };
};

export default connect(
  mapStateToProps, null
)(SuperDiagnosticReportClass);
