import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { GFLessonWithCustomLesson } from '../models/models';

export default function visibleCustomLessonsWithLessonReducer(
  state: GFLessonWithCustomLesson[] = initialState.visibleLessonsWithCustomLesson,
  action: any
): GFLessonWithCustomLesson[] {
  switch (action.type) {
    case types.FILTER_VISIBLE_CUSTOM_LESSONS:
      return action.lessonsWithCustomLesson;
    case types.USER_LOGOUT_SUCCESS:
      return initialState.visibleLessonsWithCustomLesson;
    default:
      return state;
  }
}
