import * as types from './actionTypes';

import Axios, { AxiosRequestConfig } from 'axios';

import { API } from '../apiEndpoints';
import { beginAjaxCall } from './ajaxStatusActions';
import constants from '../constants';
import { ThunkResult } from '../models/models';

/*
* Load All Lessons
*/
export function getAllLessons(): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.lesson.getAll;

    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url
    };
    return Axios(axiosOptions)
      .then(response => {
        dispatch({ type: types.LOAD_LESSONS_SUCCESS, lessons: response.data });
      })
      .catch((error: any) => {
        dispatch({ type: types.LOAD_LESSONS_FAILED, error });
        console.error('Error loading all lessons', error);
        constants.handleError(error, 'load all lessons');
        throw error;
      });
  };
}

/*
* Load Lessons by Course
*/
export function getLessonsByCourseID(courseID: string): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.lesson.getByCourse;

    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      params: { courseID },
      url
    };
    return Axios(axiosOptions)
      .then(response => {
        dispatch({ type: types.LOAD_LESSONS_SUCCESS, lessons: response.data });
      })
      .catch((error: any) => {
        dispatch({ type: types.LOAD_LESSONS_FAILED, error });
        console.error('Error loading lessons', error);
        constants.handleError(error, 'load lessons');
        throw error;
      });
  };
}
