import { GFUser } from "../models/models";

const mixpanel = require("mixpanel-browser");

class MixPanelUtil {
  static identify(user: GFUser, isTeacher: boolean) {
    mixpanel.people.set({
      $first_name: user.first,
      $last_name: user.last,
      $email: user.email,
      Role: isTeacher ? "Teacher" : "Student"
    });
    // we don't now if the user signed up or logged in so we will
    // call mixpanel.alias just in case. If they signed up alias() will work.
    // If they logged in alias() will fail
    // either way we call identify() after, which should be ok since alias()
    // has created a link between
    // the mixpanel automatically generated id and the user._id
    mixpanel.alias(user.id);
    // wait 400 ms and then identify the user
    setTimeout(() => {
      mixpanel.identify(user.id);
    }, 400);
  }
  static mixpanelInit(id: string) {
    mixpanel.init(id);
  }
}

export default MixPanelUtil;
