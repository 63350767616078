import { transitionInType, transitionOutType } from './models/models';
import { toastr } from 'react-redux-toastr';
import { paymentPlanObjectsByID } from './constantsGfPlans';
import { v4 as uuidv4 } from 'uuid';
import { API } from './apiEndpoints';

type queryInfo = {
  [key: string]: string,
}

const checkForGoogleClassroomError = (response:any): boolean => {
if (response?.status === 424){
  // this is a google classroom error
  if (typeof response.data === 'string'){
    const hasRefreshTokenError = response.data.indexOf('refresh error') !== -1;
    const hasInvalidGrantError = response.data.indexOf('invalid_grant') !== -1;
    if (hasRefreshTokenError || hasInvalidGrantError){
      // issue refreshing the token, lets get a new refresh token
      window.location.replace(buildGoogleLoginLink('', window.location.pathname, true))
      return true;
    }
  }
}
return false;
}

/**
 * 
 * @param redirectPath what Google will redirect to
 * @param return_to save the return_to so that our app will return to this after the user finishes 
 * authenticating (we might not need this if we configued all the possible URLS as Google redirects in Google)
 * @param shouldPrompt if we should force the Google permissions prompt
 * @returns url to redirect to for authenticating with Google
 */
const buildGoogleLoginLink = (redirectPath = '', return_to = '', shouldPrompt = false): string => {
  return_to && sessionStorage.setItem('return_to', return_to);

  const scopes = [
    "email",
    "profile",
    "openid",
    "https://www.googleapis.com/auth/classroom.courses.readonly",
    "https://www.googleapis.com/auth/classroom.rosters.readonly",
    "https://www.googleapis.com/auth/classroom.coursework.me",
    "https://www.googleapis.com/auth/classroom.coursework.students",
    "https://www.googleapis.com/auth/classroom.profile.emails",
    "https://www.googleapis.com/auth/classroom.courseworkmaterials",
    "https://www.googleapis.com/auth/userinfo.email",
  ];
  const redirectEncoded = encodeURI(`${process.env.REACT_APP_HOST}${redirectPath}`)
  const nonce = uuidv4();
  // const prompt = path.indexOf('register') >= 0 ? 'consent' : '';
  const queryObject: queryInfo = {
    scope: scopes.join(" "),
    access_type: 'offline',
    nonce: nonce,
    response_type: 'code',
    redirect_uri: redirectEncoded,
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
  };
  if(shouldPrompt){
    // more info: https://developers.google.com/identity/protocols/oauth2/web-server#httprest_6
    queryObject.prompt = 'consent';
    queryObject.include_granted_scopes='true';
  }
  const query = Object.keys(queryObject).reduce((previousValue: string, currentValue: string) => {
    return `${previousValue}${currentValue}=${encodeURI(queryObject[currentValue])}&`;
  }, '').slice(0, -1);
  return `https://accounts.google.com/o/oauth2/v2/auth?${query}`;
};

const constants = {
  // roleIDs
  // public static readonly Guid SuperAdmin = Guid.Parse("1122e78b-c754-467d-b4a3-e85f429e3237");
  // public static readonly Guid Teacher = Guid.Parse("7c24ead7-b045-40bd-bd5b-511df99d37f7");
  // public static readonly Guid Student = Guid.Parse("44af7e04-8b53-4db9-b648-5fe86987226a");
  buildGoogleLoginLink,
  UserRoleIDs: {
    SuperAdmin: '1122e78b-c754-467d-b4a3-e85f429e3237',
    Teacher: '7c24ead7-b045-40bd-bd5b-511df99d37f7',
    Student: '44af7e04-8b53-4db9-b648-5fe86987226a'
  },
  toastrError: {
    transitionIn: 'bounceInDown' as transitionInType,
    transitionOut: 'bounceOutUp' as transitionOutType,
    timeOut: 6000
  },
  toastrSuccess: {
    transitionIn: 'bounceInDown' as transitionInType,
    transitionOut: 'bounceOutUp' as transitionOutType,
    timeOut: 3000
  },
  toastrWarning: {
    transitionIn: 'bounceInDown' as transitionInType,
    transitionOut: 'bounceOutUp' as transitionOutType,
    timeOut: 4000
  },
  toastrSuccessBadge: {
    transitionIn: 'bounceInDown' as transitionInType,
    transitionOut: 'bounceOutUp' as transitionOutType,
    timeOut: 0
  },

  google: {
    clientID:
      '650963933950-lh0u5bnenfo4ikgfrthedgok6i2c8aei.apps.googleusercontent.com'
  },

  links: {
    features: 'http://grammarflip.com/features',
    pricing: 'http://grammarflip.com/pricing',
    blog: 'http://grammarflip.com/blog',
    about: 'http://grammarflip.com/about',
    support: 'http://grammarflip.freshdesk.com/'
  },
  paymentPlanObjectsByID,
  paymentPlanRecurringType: {
    annual: 'Annual',
    monthly: 'Monthly'
  },
  friendlyErrors: {
    verifyRequired: 'Please verify your email before signing up.',
    subscriptionRequired:
      'You do not have an active subscription. Click the billing icon ($) in the upper-right corner to activate your subscription.'
  },
  handleError(error: any, message: string) {
    let msg = '';
    if (error && error.response && error.response.data && checkForGoogleClassroomError(error.response) === false) {
      let messageDetail = '';
      if (
        error.response.data instanceof Object &&
        error.response.data.hasOwnProperty('value')
      ) {
        messageDetail = error.response.data.value;
      } else if (
        error.response.data instanceof Object &&
        error.response.data.hasOwnProperty('message')
      ) {
        messageDetail = error.response.data.message;
      } else if (error.hasOwnProperty('statusText')){
        messageDetail = error.statusText;
      } else if (error.response.data instanceof Object && error.response.data.Message){
        messageDetail = error.response.data.Message

      } else if (typeof error.response.data === 'string'){
        messageDetail = error.response.data;
      }
      msg = `Failed to ${message}. ${messageDetail}`;
    } else if (error && error.message) {
      msg = `Failed to ${message}.  Please try again or contact support. ${
        error.message
      }`;
    } else {
      msg = `Failed to ${message}.  Please try again or contact support.`;
    }
    if (error.response && error.response.status === 412) {
      msg = constants.friendlyErrors.subscriptionRequired;
    }
    if (error.response && error.response.status === 401){
      msg='Please Login';
      document.dispatchEvent(new Event('startLogout'));
    }
    if (error.response && error.response.status === 409) {
      // email already exists
      msg =
        'You already have an account under this email address!  Do not create another account; simply log into your existing account using this email address and your password.';
      if(error.response.config.url === API.POST.user.decodeSocialCode && error.response.request.responseURL.indexOf('registrationType=student') >= 0){
        msg = 'You already have an account under this email address!  Do not create another account.  Ask your teacher to add you to the classroom using the “Move/Add Existing Student” button from the Manage Students tab.';
      }
    }
    if (!navigator.onLine) {
      msg = 'Please connect to the internet.';
    }
    // show friendly timeout errors
    if (error.code === 'ECONNABORTED') {
      msg =
        'GrammarFlip is having trouble connecting to the internet. Please refresh and try again.';
    }
    toastr.error('Error', msg, constants.toastrError);
  },
  cacheCourseDataHours: 1, // how many hours to cache the course data,
  httpTimeout: 60000,
  timedQuizHours: 1,
  paymentDoubleClickTimeout: 5000,
  passwordRegex: /^((?=.*\d)(?=.*[a-zA-Z@$!%*?&\-_]).{4,30})$/gm,
  passwordValidationMessage: 'Password must be a minimum of 4 characters and contain at least 1 letter and 1 number.',
  emailRegex: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gm,
  emailValidationMessage: 'Email must be a valid email format.'
};

export default constants;
