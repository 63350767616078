import * as types from './actionTypes';
import { beginAjaxCall } from './ajaxStatusActions';
import {
  GFInitialState,
  GFCustomLesson,
  GFLesson,
  GFLessonWithCustomLesson,
  ThunkResult
} from '../models/models';
import constants from '../constants';
import { API } from '../apiEndpoints';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { initialCustomLesson } from '../reducers/initialState';
import { find, filter, orderBy, sortBy } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { v4 as uuidv4 } from 'uuid';
// import { toastr } from 'react-redux-toastr';

/*
* Get the custom lessons for this class
*/

export function getCustomLessons(classID?: string): ThunkResult<any> {
  return function(dispatch, getState) {
    if (!classID) {
      const { user } = getState();
      classID = user.classes.length ? user.classes[0].id : '';
    }
    return getCustomLessonsHelper(classID, dispatch, getState);
  };
}

export const getCustomLessonsHelper = (
  classID: string,
  dispatch: Dispatch,
  getState: () => GFInitialState
) => {
  dispatch(beginAjaxCall());
  const url = API.GET.getCustomLessons;

  const axiosConfig: AxiosRequestConfig = {
    method: 'get',
    url,
    params: { classID }
  };
  return axios(axiosConfig)
    .then(response => {
      dispatch({
        type: types.GET_CUSTOM_LESSONS_SUCCESS,
        customLessons: response.data
      });
    })
    .catch((error: AxiosError) => {
      console.error('error getting custom lessons', error);
      constants.handleError(error, 'get custom lessons');
      dispatch({
        type: types.GET_CUSTOM_LESSONS_FAILED
      });
    });
};

export function toggleCustomLesson(
  lessonID: string,
  classID: string,
  customLessonID: string,
  currentValue: boolean,
  toggleType: 'lessonEnabled' | 'postEvalEnabled' | 'postEvalAnswersEnabled'
): ThunkResult<any> {
  return function(dispatch, getState) {
    const { customLessons } = getState();
    // show confirm dialog with custom message
    let confirmMessage = '';
    switch (toggleType) {
      case 'postEvalEnabled':
        if (currentValue === true) {
          toastr.success(
            'Success',
            'Post-Evaluation access disabled.',
            constants.toastrSuccess
          );
        } else {
          confirmMessage =
            'Are you sure?  This will give students access to the Post-Evaluation for this lesson.';
        }
        break;
      case 'postEvalAnswersEnabled':
        if (currentValue === true) {
          toastr.success(
            'Success',
            'Display of Post-Evaluation answers disabled.',
            constants.toastrSuccess
          );
        } else {
          confirmMessage =
            'Are you sure? This will give students access to the answers on the Post-Evaluation for this lesson.';
        }
      /* falls through */
      default:
        break;
    }
    if (confirmMessage.length) {
      const toastrConfirmOptions = {
        onOk: () => {
          toggleCustomLessonHelper(
            customLessons,
            classID,
            lessonID,
            customLessonID,
            toggleType,
            dispatch,
            getState,
            currentValue
          );
        },
        onCancel: () => console.log('CANCEL: clicked'),
        okText: 'Yes',
        cancelText: 'cancel'
      };
      toastr.confirm(confirmMessage, toastrConfirmOptions);
    } else {
      toggleCustomLessonHelper(
        customLessons,
        classID,
        lessonID,
        customLessonID,
        toggleType,
        dispatch,
        getState,
        currentValue
      );
    }
  };
}

const toggleCustomLessonHelper = (
  customLessons: { [key: string]: GFCustomLesson },
  classID: string,
  lessonID: string,
  customLessonID: string,
  toggleType: 'lessonEnabled' | 'postEvalEnabled' | 'postEvalAnswersEnabled',
  dispatch: Dispatch,
  getState: () => GFInitialState,
  currentValue?: boolean
) => {
  const originalCustomLesson = customLessons[customLessonID];

  if (originalCustomLesson) {
    const updatedCustomLesson = {
      ...originalCustomLesson,
      [toggleType]: !originalCustomLesson[toggleType]
    };
    updateCustomLessonsHelper(
      [updatedCustomLesson],
      customLessons,
      dispatch,
      getState
    );
  } else {
    const newCustomLesson = {
      ...initialCustomLesson,
      id: uuidv4(),
      lessonID,
      classID,
      [toggleType]: !currentValue
    };
    saveCustomLessonsHelper(
      [newCustomLesson],
      customLessons,
      dispatch,
      getState
    );
  }
};

/*
 enable access to all the lessons for this class and course
 loop over the lessonsWithcustomLesson and build an array of customLessons that have lessonEnabled set to true
 */
export function toggleAllCustomLessons(
  classID: string,
  courseID: string,
  lessonEnabled?: boolean,
  postEvalEnabled?: boolean,
  postEvalAnswersEnabled?: boolean
): ThunkResult<any> {
  return function(dispatch, getState) {
    const { customLessons, visibleLessonsWithCustomLesson } = getState();
    const lessonsWithTempCustomLesson = visibleLessonsWithCustomLesson.filter(
      lesson => lesson.customLesson.id.length === 0
    );
    const lessonsWithCustomLesson = visibleLessonsWithCustomLesson.filter(
      lesson => lesson.customLesson.id.length > 0
    );
    let customLesson = {};
    if (lessonEnabled !== undefined) {
      customLesson = { ...customLesson, lessonEnabled };
    }
    if (postEvalEnabled !== undefined) {
      customLesson = { ...customLesson, postEvalEnabled };
    }
    if (postEvalAnswersEnabled !== undefined) {
      customLesson = { ...customLesson, postEvalAnswersEnabled };
    }
    const updatedCustomLessons = lessonsWithCustomLesson.map(
      (lessonWithCustomLesson: GFLessonWithCustomLesson) => {
        return { ...lessonWithCustomLesson.customLesson, ...customLesson };
      }
    );
    const newCustomLessons = lessonsWithTempCustomLesson.map(
      (lessonWithCustomLesson: GFLessonWithCustomLesson) => {
        return {
          ...lessonWithCustomLesson.customLesson,
          id: uuidv4(),
          classID,
          courseID,
          lessonID: lessonWithCustomLesson.id,
          ...customLesson
        };
      }
    );

    if (updatedCustomLessons.length) {
      updateCustomLessonsHelper(
        updatedCustomLessons,
        customLessons,
        dispatch,
        getState
      );
    }
    if (newCustomLessons.length) {
      saveCustomLessonsHelper(
        newCustomLessons,
        customLessons,
        dispatch,
        getState
      );
    }
  };
}

const saveCustomLessonsHelper = (
  updatedCustomLessons: GFCustomLesson[],
  customLessons: { [key: string]: GFCustomLesson },
  dispatch: Dispatch,
  getState: () => GFInitialState
) => {
  dispatch(beginAjaxCall());
  const url = API.POST.addCustomLesson;
  dispatch({
    type: types.UPDATE_CUSTOM_LESSONS_SUCCESS,
    customLessons: updatedCustomLessons
  });
  const axiosConfig: AxiosRequestConfig = {
    method: 'post',
    url,
    data: updatedCustomLessons
  };
  return axios(axiosConfig)
    .then()
    .catch((error: AxiosError) => {
      console.error('error saving custom lessons', error);
      constants.handleError(error, 'save custom lessons');
      dispatch({
        type: types.UPDATE_CUSTOM_LESSONS_FAILED,
        customLessons
      });
    });
};

const updateCustomLessonsHelper = (
  updatedCustomLessons: GFCustomLesson[],
  customLessons: { [key: string]: GFCustomLesson },
  dispatch: Dispatch,
  getState: () => GFInitialState
) => {
  dispatch(beginAjaxCall());
  const url = API.POST.updateCustomLesson;
  dispatch({
    type: types.UPDATE_CUSTOM_LESSONS_SUCCESS,
    customLessons: updatedCustomLessons
  });
  const axiosConfig: AxiosRequestConfig = {
    method: 'post',
    url,
    data: updatedCustomLessons
  };
  return axios(axiosConfig)
    .then()
    .catch((error: AxiosError) => {
      console.log('error updating custom lessons', error);
      constants.handleError(error, 'update custom lessons');
      dispatch({
        type: types.UPDATE_CUSTOM_LESSONS_FAILED,
        customLessons
      });
    });
};

export const filterVisibleLessonsWithCustomLesson = (
  classID: string,
  courseID: string
): ThunkResult<any> => {
  return (dispatch, getState) => {
    const { lessons, customLessons } = getState();

    const filteredLessons = filter(lessons, lesson => {
      const courseLesson = filter(lesson.courseLessons, {
        courseID
      });
      return courseLesson.length ? true : false;
    });

    const lessonsWithCustomLesson = filteredLessons.map((lesson: GFLesson) => {
      const foundCustomLesson = find(customLessons, {
        lessonID: lesson.id,
        classID
      });
      // console.log('found custom lesson', foundCustomLesson);
      if (foundCustomLesson) {
        return { ...lesson, customLesson: foundCustomLesson };
      } else {
        return { ...lesson, customLesson: initialCustomLesson };
      }
    });

    dispatch({
      type: types.FILTER_VISIBLE_CUSTOM_LESSONS,
      lessonsWithCustomLesson: orderBy(lessonsWithCustomLesson, 'order')
    });
  };
};

export const filterLessonsWithCustomLessonHelper = (
  lessons: { [key: string]: GFLesson },
  customLessons: { [key: string]: GFCustomLesson },
  classID: string,
  courseID: string
) => {
  let filteredLessons = filter(lessons, lesson => {
    const courseLesson = filter(lesson.courseLessons, {
      courseID
    });
    return courseLesson.length ? true : false;
  });

  const lessonsWithCustomLessons = filteredLessons.map((lesson: GFLesson) => {
    const foundCustomLesson = find(customLessons, {
      lessonID: lesson.id,
      classID
    });
    if (foundCustomLesson) {
      return { ...lesson, customLesson: foundCustomLesson };
    } else {
      return { ...lesson, customLesson: initialCustomLesson };
    }
  });

  filteredLessons = filter(
    lessonsWithCustomLessons,
    lesson => lesson.customLesson.lessonEnabled
  );
  return sortBy(filteredLessons, (el: GFLesson) => {
    return el.courseLessons[0].order;
  });
};
