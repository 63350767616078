import * as React from "react";

import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  HelpBlock,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  Row,
  Tooltip,
} from "react-bootstrap";
import {
  GFClass,
  GFInitialState,
  GFStudent,
  GFUser,
} from "../../models/models";
import { find, forEach, sortBy, uniqBy } from "lodash";
import {
  loadStudents,
  teacherDeleteStudentClass,
  updateStudent,
  updateStudentPW,
} from "../../actions/studentActions";

import ChangePW from "../common/ChangePW";
import MainMenu from "../menu/MainMenu";
import AddStudentModal from "./AddStudentModal";
import VerificationAlert from "../common/VerificationAlert";
import { confirmResetStudentStarts } from "../../actions/progressActions";
import { connect } from "react-redux";
import constants from "../../constants";
import { initialStudent } from "../../reducers/initialState";
import { loadClasses, addStudentByEmail } from "../../actions/classActions";
import { quizTypeEnum } from "../../enums";
import { selectIsTeacher, selectIsSuperTeacher, selectHasGoogleClasses } from "../../reducers/userReducer";
import { toastr } from "react-redux-toastr";

const FontAwesome = require("react-fontawesome");

const graham = require("../../images/graham.gif");

interface Props extends React.Props<Students> {
  user: GFUser;
  classes: GFClass[];
  students: GFStudent[];
  loadClasses: () => Promise<void>;
  loadStudents: () => Promise<void>;
  teacherDeleteStudentClass: any;
  updateStudent: typeof updateStudent;
  updateStudentPW: typeof updateStudentPW;
  loading: boolean;
  params: any;
  hasGoogleClasses: boolean;
  isTeacher: boolean;
  isSuperTeacher: boolean;
  confirmResetStudentStarts: typeof confirmResetStudentStarts;
  addStudentByEmail:  (
    classID: string,
    email: string,
  ) => Promise<any>;
}

interface State {
  filteredStudents: GFStudent[];
  showAddStudent: boolean;
  showEditStudent: boolean;
  student: GFStudent;
  class: GFClass;
  searchInput: string;
  classFilter: string;
  showDeleteStudentClassModal: boolean;
  formValidations: any;
  formValid: boolean;
}

class Students extends React.Component<Props, State> {
  studentToEdit: any;
  studentToDelete: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      filteredStudents: [],
      showAddStudent: false,
      showEditStudent: false,
      searchInput: "",
      classFilter: "",
      student: initialStudent,
      class: {
        id: "",
        name: "",
        code: "",
        classSize: 0,
        createDate: "",
      },
      showDeleteStudentClassModal: false,
      formValidations: {
        first: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [{ required: true, message: "First Name is required" }],
        },
        last: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [{ required: true, message: "Last Name is required" }],
        },
        username: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [
            { required: true, message: "Email or Username is required" },
          ],
        },
      },
      formValid: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.printClasses = this.printClasses.bind(this);
    this.printStudents = this.printStudents.bind(this);
    this.changeClassFilter = this.changeClassFilter.bind(this);
    this.searchStudents = this.searchStudents.bind(this);
    this.showAddStudent = this.showAddStudent.bind(this);
    this.hideAddStudent = this.hideAddStudent.bind(this);
    this.showEditStudent = this.showEditStudent.bind(this);
    this.hideEditStudent = this.hideEditStudent.bind(this);
    this.saveStudent = this.saveStudent.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.showDeleteStudentClass = this.showDeleteStudentClass.bind(this);
    this.hideDeleteStudentClass = this.hideDeleteStudentClass.bind(this);
    this.deleteStudentClass = this.deleteStudentClass.bind(this);
    this.changeStudentPW = this.changeStudentPW.bind(this);
    this.printEditStudentModal = this.printEditStudentModal.bind(this);
    this.addExistingStudent = this.addExistingStudent.bind(this);
  }

  componentDidMount() {
    if (this.props.classes.length === 0) {
      // no classes so let's retrieve them

      // JF Question: if classes are added on another device,
      // then will they update on this device without refreshig the entire app?
      this.props.loadClasses().then((classes: any) => {
        if (!!this.props.params.classID) {
          this.changeClassFilter(this.props.params.classID);
        }
      });
    } else {
      if (!!this.props.params.classID) {
        this.changeClassFilter(this.props.params.classID);
      }
    }
    if (this.props.students.length !== 0) {
      this.searchStudents();
    }
    this.props
      .loadStudents();
  }
  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(prevProps.students) !== JSON.stringify(this.props.students)
    ) {
      this.searchStudents();
    }
  }

  /*
   * FORM VALIDATION
   */
  validateField(name: string, value: string, showErrors: boolean) {
    // loop over each field we need to validate
    forEach(this.state.formValidations, (field, key) => {
      if (key === name) {
        const newFormValidations = Object.assign(
          {},
          this.state.formValidations
        );
        let errorMessage = "";

        // loop over each validation for this field, and set error to the error message if something is invalid
        field.validators.forEach((validator: any) => {
          if (!this.validate(value, validator)) {
            errorMessage = validator.message;
          }
        });
        // after we have checked all the validators, is there an error message for this field?
        if (!errorMessage) {
          // no error message
          newFormValidations[name].validationState = "success";
          newFormValidations[name].validationMessage = "";
          newFormValidations[name].valid = true;
          if (!showErrors) {
            newFormValidations[name].validationState = null;
            newFormValidations[name].validationMessage = "";
          }
        } else {
          // error
          // only show the errors if showErrors
          if (showErrors) {
            newFormValidations[name].validationState = "error";
            newFormValidations[name].validationMessage = errorMessage;
          }

          newFormValidations[name].valid = false;
        }
        this.setState(
          { formValidations: newFormValidations },
          this.validateForm
        );
      }
    });
  }
  validate(value: string, validator: any) {
    let valid = true;
    if (validator.pattern && value.length > 0) {
      const newValue = value.match(validator.pattern);
      valid = newValue ? true : false;
    }
    if (validator.required) {
      valid = !!value.length || false;
    }
    if (validator.mustMatch) {
      valid =
        this.state[validator.mustMatch as keyof State] === value ? true : false;
    }
    return valid;
  }
  validateForm() {
    const formValid = !find(this.state.formValidations, ["valid", false]);
    this.setState({ formValid });
  }

  // validate all fields and conditionally show errors
  validateAllFields(showErrors: boolean) {
    this.validateField(
      "username",
      this.state.student.student.username,
      showErrors
    );
    this.validateField("first", this.state.student.student.first, showErrors);
    this.validateField("last", this.state.student.student.last, showErrors);
  }

  /* End of form validation */

  handleChange(e: any) {
    const value = e.target.value;
    const name = e.target.name;
    const oldStudent = this.state.student.student;
    const newStudent = Object.assign({}, oldStudent, {
      [e.target.name]: e.target.value,
    });
    const newerStudent = Object.assign({}, this.state.student, {
      student: newStudent,
    });
    this.setState({ student: newerStudent }, () =>
      this.validateField(name, value, true)
    );
  }

  handleInputChange(e: any) {
    this.setState({ [e.target.name]: e.target.value } as unknown as State, () => {
      this.searchStudents();
    });
  }

  changeClassFilter(classID: string) {
    this.setState(
      {
        classFilter: classID,
      },
      () => {
        this.searchStudents();
      }
    );
  }

  searchStudents() {
    const val = this.state.searchInput.toLowerCase();
    const cf = this.state.classFilter;
    let stud = this.props.students.filter((student) => {
      return (
        student.student.first.toLowerCase().indexOf(val) !== -1 ||
        student.student.last.toLowerCase().indexOf(val) !== -1
      );
    });
    if (cf !== "") {
      stud = stud.filter((student) => {
        return student.classID === cf;
      });
    }
    // dedupe
    const uniqStudents = uniqBy(stud, "userID");
    this.setState({
      filteredStudents: sortBy(uniqStudents, student => student.student.last.toLocaleLowerCase()),
    });
  }

  showEditStudent(student: GFStudent) {
    const su = Object.assign({}, student.student, {
      password: "",
      password2: "",
    });
    const st = Object.assign({}, student, { student: su });

    this.setState(
      {
        showEditStudent: true,
        student: st,
      },
      () => {
        // validate all the form fields but do not show errors
        this.validateAllFields(false);
      }
    );
  }

  hideEditStudent() {
    // const fv = Object.assign({}, this.state.formValidations, { validationState: null, validationMessage: '' });
    const fv = {
      first: {
        validationState: null,
        validationMessage: "",
        valid: true,
        validators: [{ required: true, message: "First Name is required" }],
      },
      last: {
        validationState: null,
        validationMessage: "",
        valid: true,
        validators: [{ required: true, message: "Last Name is required" }],
      },
      username: {
        validationState: null,
        validationMessage: "",
        valid: true,
        validators: [
          { required: true, message: "Email or Username is required" },
        ],
      },
    };

    this.setState({ showEditStudent: false, formValidations: fv });
  }

  showAddStudent() {
    this.setState({ showAddStudent: true });
  }

  hideAddStudent() {
    this.setState({ showAddStudent: false });
  }

  saveStudent(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    // check if the form is valid
    if (!this.state.formValid) {
      toastr.error(
        `Form Error`,
        `Please check your form entries and try again.`,
        constants.toastrError
      );
      // validate and show errors
      this.validateAllFields(true);
      return;
    }

    this.hideEditStudent();
    this.props
      .updateStudent(this.state.student.student)
  }

  showDeleteStudentClass(student: GFStudent) {
    this.studentToDelete = student;
    this.setState({ showDeleteStudentClassModal: true });
  }

  hideDeleteStudentClass() {
    this.setState({ showDeleteStudentClassModal: false });
    this.studentToDelete = null;
  }

  deleteStudentClass() {
    const student = this.studentToDelete;
    this.props
      .teacherDeleteStudentClass(
        student.student.id,
        student.classID,
      )
      .then((deleted: any) => {
        if (deleted) {
          toastr.success(
            "Success",
            "Student deleted.",
            constants.toastrSuccess
          );
          this.props.loadClasses();
        }
        this.hideDeleteStudentClass();
      })
      .catch((error: any) => {
        this.hideDeleteStudentClass();
        console.error("Error with deleting student", error);
        const message = "delete student";
        constants.handleError(error, message);
      });
  }

  changeStudentPW(password: string) {
    this.hideEditStudent();
    this.props
      .updateStudentPW(this.state.student.student, password)
  }

  printEditStudentModal() {
    return (
      <Modal
        show={this.state.showEditStudent}
        onHide={this.hideEditStudent}
        container={this}
      >
        {/*<Modal.Header closeButton>
          <Modal.Title>Edit Student</Modal.Title>
        </Modal.Header>*/}
        <Modal.Body>
          <form id="studentForm" onSubmit={this.saveStudent}>
            <h2>Update Student Profile</h2>
            <FormGroup
              controlId="studentFirst"
              bsSize="lg"
              validationState={this.state.formValidations.first.validationState}
            >
              <ControlLabel>First Name</ControlLabel>
              <FormControl
                type="text"
                name="first"
                value={this.state.student.student.first}
                placeholder="first name"
                onChange={this.handleChange}
              />
              <FormControl.Feedback />
              <HelpBlock>
                {this.state.formValidations.first.validationMessage}
              </HelpBlock>
            </FormGroup>
            <FormGroup
              controlId="studentLast"
              bsSize="lg"
              validationState={this.state.formValidations.last.validationState}
            >
              <ControlLabel>Last Name</ControlLabel>
              <FormControl
                type="text"
                name="last"
                value={this.state.student.student.last}
                placeholder="last name"
                onChange={this.handleChange}
              />
              <FormControl.Feedback />
              <HelpBlock>
                {this.state.formValidations.first.validationMessage}
              </HelpBlock>
            </FormGroup>

            <FormGroup
              controlId="studentUsername"
              bsSize="lg"
              validationState={
                this.state.formValidations.username.validationState
              }
            >
              <ControlLabel>Email or Username</ControlLabel>
              <FormControl
                type="text"
                name="username"
                value={this.state.student.student.username}
                placeholder="username"
                disabled={!!this.state.student.student.socialType}
                onChange={this.handleChange}
              />
              <FormControl.Feedback />
              <HelpBlock>
                {this.state.formValidations.first.validationMessage}
              </HelpBlock>
            </FormGroup>
            <Button
              disabled={this.props.loading}
              bsStyle="primary"
              type="submit"
            >
              Update Student Profile
            </Button>
          </form>
          <ChangePW
            changePW={this.changeStudentPW}
            loading={this.props.loading}
            isSocialType={!!this.state.student.student.socialType}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={this.hideEditStudent}
            bsStyle="default"
            type="button"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  printClasses() {
    if (this.props.loading) {
      return "";
    } else if (this.props.classes.length === 0) {
      return (
        <h4>
          Hi, there! It looks like you do not have any students registered yet.
        </h4>
      );
    } else {
      return (
        <FormGroup bsSize="large" controlId="formControlsSelect">
          <FormControl
            componentClass="select"
            placeholder="select"
            value={this.state.classFilter}
            onChange={(e: any) => {
              this.changeClassFilter(e.target.value);
            }}
          >
            <option value="">Filter by class section</option>
            {this.props.classes.map((gfClass) => this.getClassHtml(gfClass))}
          </FormControl>
        </FormGroup>
      );
    }
  }

  getClassHtml(gfClass: GFClass) {
    return (
      <option key={gfClass.id} value={gfClass.id}>
        {gfClass.name}
      </option>
    );
  }

  getTooltip(title: string) {
    return <Tooltip id={title}>{title}</Tooltip>;
  }

  printStudents() {
    return (
      <ListGroup>
        {this.state.filteredStudents.map((student) => (
          <ListGroupItem className="list-item" key={student.student.id}>
            <Row>
              <Col
                xs={4}
                md={4}
                className="student-name"
                onClick={() => {
                  this.showEditStudent(student);
                }}
              >
                {student.student.first} {student.student.last}
              </Col>
              <Col xs={4} md={4}>
                {!!student.student.socialType && (
                  <small>Registered via Google</small>
                )}
              </Col>
              <Col xs={4} md={4}>
                <div className="fa-icons">

                  <Button
                    title="Remove Student"
                    bsStyle="link"
                    className="pull-right"
                    onClick={() => {
                      this.showDeleteStudentClass(student);
                    }}
                  >
                    <FontAwesome name="times" />
                  </Button>

                  <Button
                    title="Edit Student"
                    bsStyle="link"
                    className="pull-right"
                    onClick={() => {
                      this.showEditStudent(student);
                    }}
                  >
                    <FontAwesome name="pencil-square-o" />
                  </Button>
                  <Button
                    title="Reset All Post-Evals"
                    bsStyle="link"
                    className="pull-right"
                    onClick={() => {
                      this.props.confirmResetStudentStarts(student.student.id, quizTypeEnum.postEval);
                    }}
                  >
                    <FontAwesome name="arrow-circle-o-left" />
                  </Button>
                  <Button
                    title="Reset All Pre-Tests"
                    bsStyle="link"
                    className="pull-right"
                    onClick={() => {
                      this.props.confirmResetStudentStarts(student.student.id, quizTypeEnum.diagnostic);
                    }}
                  >
                    <FontAwesome name="arrow-circle-left" />
                  </Button>
                </div>
              </Col>
            </Row>
          </ListGroupItem>
        ))}
        {this.state.filteredStudents.length === 0 && <h4>No Students Found</h4>}
      </ListGroup>
    );
  }

  addExistingStudent() {
    if(this.state.classFilter === ''){
      return toastr.error(
        `Error`,
        `From the dropdown menu, please select the class section into which you would like this student placed.`,
        {...constants.toastrError, timeOut: 12000}
      );
    }
    this.showAddStudent();
  }

  render() {
    const { loading } = this.props;
    return (
      <Grid className="content modal-container">
        <div>
          <AddStudentModal 
            classFilter={this.state.classFilter} 
            showAddStudent={this.state.showAddStudent} 
            hideAddStudent={this.hideAddStudent} 
            loading={this.props.loading}
            addStudentByEmail={this.props.addStudentByEmail}
            loadStudents={this.props.loadStudents}
            loadClasses={this.props.loadClasses}
          />
        </div>
        <div>{this.printEditStudentModal()}</div>
        <div className="sidemenu">
          <MainMenu isTeacher={this.props.isTeacher} isSuperTeacher={this.props.isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
          <div className="logo text-center">
            <img alt="graham" src={graham} />
          </div>
        </div>
        <div className="students content-with-sidebar main-content">
          <VerificationAlert
            user={this.props.user}
            isTeacher={this.props.isTeacher}
          />
          <Row className="sub-header">
            <Col md={12} xs={12}>
              <h1 className="pull-left">Manage Students</h1>
              {this.props.classes.length > 0 && <Button disabled={loading} className="pull-right btn-sm" onClick={this.addExistingStudent} style={{marginTop: 8}}>
                <FontAwesome name="envelope" />
                Move/Add Existing Student to Class
              </Button>}
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={6}>
              {this.printClasses()}
            </Col>
            <Col xs={6} md={6}>
              <form>
                <FormGroup bsSize="large">
                  <InputGroup>
                    <InputGroup.Addon>
                      <FontAwesome name="search" />
                    </InputGroup.Addon>
                    <FormControl
                      onChange={this.handleInputChange}
                      placeholder="Filter by name"
                      name="searchInput"
                      type="text"
                      value={this.state.searchInput}
                    />
                  </InputGroup>
                </FormGroup>
              </form>
            </Col>
          </Row>
          {this.printStudents()}
        </div>
        <Modal
          show={this.state.showDeleteStudentClassModal}
          onHide={this.hideDeleteStudentClass}
          container={this}
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>Are You Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to remove this student from your class? Doing 
            so will delete this student's diagnostic results, but all other scores 
            will be kept.
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideDeleteStudentClass} bsStyle="default">
              Cancel
            </Button>
            <Button
              disabled={this.props.loading}
              bsStyle="primary"
              onClick={this.deleteStudentClass}
            >
              Remove Student
            </Button>
          </Modal.Footer>
        </Modal>
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  return {
    user: state.user,
    classes: state.classes,
    students: state.students,
    loading: state.ajaxCallsInProgress > 0,
    hasGoogleClasses: selectHasGoogleClasses(state),
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state),
  };
};

export default connect(mapStateToProps, {
  addStudentByEmail,
  loadClasses,
  loadStudents,
  updateStudent,
  updateStudentPW,
  teacherDeleteStudentClass,
  confirmResetStudentStarts,
})(Students);
