import React from 'react'
import { find, forEach } from "lodash";
import { GFTeacherUser } from './../../models/models';
import {
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal,
} from "react-bootstrap";
import ChangePW from "./../common/ChangePW";
import constants from "../../constants";
import { toastr } from 'react-redux-toastr';

interface Props extends React.Props<EditTeacherModal> {
  showEditTeacher: boolean;
  hideEditTeacher: () => void;
  setTeacherToEdit: (teacher: any) => void;
  loading: boolean;
  teacher: GFTeacherUser|null;
  saveTeacher: (
    teacher: any,
  ) => Promise<any>;
  changeTeacherPW: (password: string) => void;
}

interface State {
  email: string;
  first: string;
  last: string;
  formValidations: any;
  formValid: boolean;
}

class EditTeacherModal extends React.Component<Props, State> {

  /**
   * The constructor
   * @param props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      first: '',
      last: '',
      formValidations: {
        email: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [
            { required: true, message: "Email or Username is required" }
          ],
        },
        first: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [{ required: true, message: "First Name is required" }],
        },
        last: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [{ required: true, message: "Last Name is required" }],
        },
        username: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [
            { required: true, message: "Email or Username is required" },
          ],
        },
      },
      formValid: true,
    }
  }

  componentDidMount() {
    const { teacher } = this.props;
    if(teacher){
      this.setState({
        first: teacher.first,
        last: teacher.last,
        email: teacher.email || ''
      });
    }    
  }

  /**
   * Get called when component will unmount
   */
  componentWillUnmount() {
    this.setState({
      email: '',
      formValid: false
    });
  }

  /**
   * Validate a Form
   */
  validateForm() {
    const formValid = !find(this.state.formValidations, ["valid", false]);
    this.setState({ formValid });
  }

  /**
   * Validate a Form input
   * @param value 
   * @param validator 
   * @returns boolean
   */
  validate(value: string, validator: any) {
    let valid = true;
    if (validator.pattern && value.length > 0) {
      const newValue = value.match(validator.pattern);
      valid = newValue ? true : false;
    }
    if (validator.required) {
      valid = !!value.length || false;
    }
    if (validator.mustMatch) {
      valid =
        this.state[validator.mustMatch as keyof State] === value ? true : false;
    }
    return valid;
  }

  /**
   * Perform Form validations
   * @param name 
   * @param value 
   * @param showErrors 
   */
  validateField(name: string, value: string, showErrors: boolean) {
    // loop over each field we need to validate
    forEach(this.state.formValidations, (field, key) => {
      if (key === name) {
        const newFormValidations = Object.assign(
          {},
          this.state.formValidations
        );
        let errorMessage = "";

        // loop over each validation for this field, and set error to the error message if something is invalid
        field.validators.forEach((validator: any) => {
          if (!this.validate(value, validator)) {
            errorMessage = validator.message;
          }
        });
        // after we have checked all the validators, is there an error message for this field?
        if (!errorMessage) {
          // no error message
          newFormValidations[name].validationState = "success";
          newFormValidations[name].validationMessage = "";
          newFormValidations[name].valid = true;
          if (!showErrors) {
            newFormValidations[name].validationState = null;
            newFormValidations[name].validationMessage = "";
          }
        } else {
          // error
          // only show the errors if showErrors
          if (showErrors) {
            newFormValidations[name].validationState = "error";
            newFormValidations[name].validationMessage = errorMessage;
          }

          newFormValidations[name].valid = false;
        }
        this.setState(
          { formValidations: newFormValidations },
          this.validateForm
        );
      }
    });
  }
  
  /**
   * Gets called when a Form input changes
   * @param e 
   */
  handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({
      [name]: value
    } as unknown as State, () => {
      this.validateField(name, value, true)
    });
  }

  handleSubmit = (e: any) => {
    e.preventDefault();
    const { hideEditTeacher, setTeacherToEdit } = this.props;
    const { teacher, saveTeacher } = this.props;
    const { first, last, email } = this.state;
    saveTeacher({ ...teacher, first, last, email })
    .then((resp:any) => {
      toastr.success(
        "Success",
        "Teacher updated.",
        constants.toastrSuccess
      );
      this.setState({email: '', first: '', last: ''});
      setTeacherToEdit({});
      this.validateForm();
      hideEditTeacher();
    })
    .catch(e => {
      // Close modal.
      this.setState({email: '', first: '', last: ''});
      setTeacherToEdit({});
      this.validateForm();
      hideEditTeacher();
    }); 

  }

  /**
   * Renders the component
   * 
   * @returns Modal
   */
  render () {
    const { loading, showEditTeacher, hideEditTeacher, teacher, changeTeacherPW } = this.props;
    const { email, formValid, first, last } = this.state;
    return !!teacher && (
      <Modal
        show={showEditTeacher}
        onHide={hideEditTeacher}
        container={this}
      >
        <Modal.Body>
          <form id="studentForm" onSubmit={this.handleSubmit}>
            <h2>Update Teacher Profile</h2>
            <FormGroup
              controlId="teacherFirst"
              bsSize="lg"
              validationState={this.state.formValidations.first.validationState}
            >
              <ControlLabel>First Name</ControlLabel>
              <FormControl
                type="text"
                name="first"
                value={first}
                placeholder="first name"
                onChange={this.handleChange}
              />
              <FormControl.Feedback />
              <HelpBlock>
                {this.state.formValidations.first.validationMessage}
              </HelpBlock>
            </FormGroup>
            <FormGroup
              controlId="teacherLast"
              bsSize="lg"
              validationState={this.state.formValidations.last.validationState}
            >
              <ControlLabel>Last Name</ControlLabel>
              <FormControl
                type="text"
                name="last"
                value={last}
                placeholder="last name"
                onChange={this.handleChange}
              />
              <FormControl.Feedback />
              <HelpBlock>
                {this.state.formValidations.first.validationMessage}
              </HelpBlock>
            </FormGroup>

            <FormGroup
              controlId="teacherUsername"
              bsSize="lg"
              validationState={
                this.state.formValidations.username.validationState
              }
            >
              <ControlLabel>Email or Username</ControlLabel>
              <FormControl
                type="text"
                name="email"
                value={email}
                placeholder="email"
                disabled={true}
                onChange={this.handleChange}
              />
              <FormControl.Feedback />
              <HelpBlock>
                {this.state.formValidations.first.validationMessage}
              </HelpBlock>
            </FormGroup>
            <Button
              disabled={loading || (!formValid && !teacher.socialType) || (!first || !last)}
              bsStyle="primary"
              type="submit"
            >
              Update Teacher Profile
            </Button>
          </form>
          <ChangePW
            changePW={changeTeacherPW}
            loading={this.props.loading}
            isSocialType={!!teacher.socialType}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={hideEditTeacher}
            bsStyle="default"
            type="button"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditTeacherModal;
