import * as React from "react";
import moment from "moment";

import Axios from "axios";
import { GFInitialState, GFUser } from "./models/models";
import Header from "./components/header/Header";
import HelpWidget from "./components/help/HelpWidget";
import Loading from "./components/common/Loading";
import NewTeacherChangePWModal from "./components/auth/NewTeacherChangePWModal";
import MixPanelUtil from "./api/mixpanel";
import { TrackJS } from "trackjs";
import { browserHistory } from "react-router";
import { checkSession } from "./actions/userActions";
import { connect } from "react-redux";
import constants from "./constants";
import { getAllCourses } from "./actions/courseActions";
import { getAllLessons } from "./actions/lessonActions";
import { getAllQuizzes } from "./actions/quizActions";
import { getCustomLessons } from "./actions/customLessonActions";
import { userLogout, userUpdateShowTutorial, userUpdatePassword } from "./actions/userActions";
import { selectIsStudent, selectIsTeacher, selectIsSuperTeacher } from "./reducers/userReducer";
import { canStudentTakeSuperDiagnostic } from "./actions/superDiagnosticActions";
import { loadClasses } from "./actions/classActions";
import { forEach } from "lodash";
import { migrations } from "./constantsMigrations";
import { updateAppSettings } from "./actions/commonActions";
import { toastr } from 'react-redux-toastr';


interface Props extends React.Props<App> {
  children: any;
  loading: boolean;
  user: GFUser;
  userLogout: ()=> Promise<any>;
  checkSession: () => Promise<any>;
  userUpdateShowTutorial: (user: GFUser) => Promise<any>;
  getAllQuizzes: typeof getAllQuizzes;
  getAllCourses: typeof getAllCourses;
  getAllLessons: typeof getAllLessons;
  getCustomLessons: typeof getCustomLessons;
  canStudentTakeSuperDiagnostic: typeof canStudentTakeSuperDiagnostic;
  version: string;
  isTeacher: boolean;
  isStudent: boolean;
  canTakeSuperDiagnostic: boolean;
  loadClasses: typeof loadClasses;
  state: GFInitialState;
  updateAppSettings: typeof updateAppSettings;
  userUpdatePassword: typeof userUpdatePassword;
}
interface State {
  fullScreenLoading: boolean;
}

class App extends React.Component<Props, State, void> {
  private timeout: any;
  constructor(props: Props) {
    super(props);
    Axios.defaults.headers.common["apikey"] = props.user.apiKey; // try to set the API key before we call any API endpoints
    this.state = {
      fullScreenLoading: false,
    };
  }

  componentDidMount() {
    console.log('cookies', navigator.cookieEnabled)
    const { user } = this.props;
    Axios.defaults.headers.common["apikey"] = user.apiKey;
    // init mixpanel identity if we already have a user
    if (user.id.length) {
      MixPanelUtil.identify(user, this.props.isTeacher);
      TrackJS.configure({
        userId: user.email,
        version: `${process.env.REACT_APP_VERSION}`,
      });
    }
    setTimeout(() => {
      this.checkSession();
    }, 2000);
    document.addEventListener('startLogout', this.logout)
    // document.addEventListener('loggedin', this.getUpdatedData)
    document.addEventListener("loggedin", this.getUpdatedData);

    document.addEventListener("paymentSucccess", this.getUpdatedData);

    document.addEventListener("registerSucccess", this.getUpdatedData);
  }
  
  componentWillUnmount() {
    clearTimeout(this.timeout);
    document.removeEventListener('startLogout', this.logout);
    document.removeEventListener('loggedin', this.getUpdatedData);
    document.removeEventListener('paymentSuccess', this.getUpdatedData);
    document.removeEventListener('registerSuccess', this.getUpdatedData);
  }

  logout = () => {
    this.props.userLogout().then(()=>{
      browserHistory.push("/");
    });
    this.setState({ fullScreenLoading: false });
  };

  /*
   * Check the user session and get the current user object
   */
  checkSession = () => {
    if (
      this.props.user &&
      this.props.user.apiKey &&
      this.props.user.apiKey !== ""
    ) {
      this.checkAppVersion()
        .then(() => {
          return this.props.checkSession().then(() => {
            this.getUpdatedData(); // do not return this because if it fails, it will log the user out
          });
        })
        .catch((err) => {
          if (err.response && err.response.status === "401") {
            console.log("bad session", err);
            setTimeout(() => {
              this.logout();
            }, 500);
          } else {
            console.error("Error checking session", err);
          }
        });
    }
  };

  /*
   * Get updated data from the server without blocking the user
   * for now we are getting the courses, lessons, and list of quizes (not the entire quiz objects)
   * we don't care too much if these fail, so we just call them.  they will fail again when the user tries to access
   * them in the relevant component - and the user will be informed why they can not be accessed - including informing them
   * about an expired plan.
   * TODO we can add a date for when the lessons, quizzes, and courses were updated so that we only
   *  go get these if and when they were updated.
   *  In the meantime we will only get updated data if it has been longer than a day.
   */
  getUpdatedData = () => {
    setTimeout(() => {
      
      const now = moment();
      const lastUpdated = moment(this.props.user.lastUpdated);
      const diff = now.diff(lastUpdated, "hours");
      if (this.state.fullScreenLoading === false) {
        this.setState({ fullScreenLoading: true });
      }
      if (this.props.user.isActive === false) {
        this.setState({ fullScreenLoading: false });
        return Promise.resolve(true);
      }
      if (this.props.user.lastUpdated && diff < constants.cacheCourseDataHours) {
        console.log("data is up to date", diff);
        this.setState({ fullScreenLoading: false });
        return Promise.resolve(true);
      } else {
        let intialLoadPromises = [
          this.props.getAllQuizzes(), // this gets a list of the quizzes without the questions
          this.props.getAllCourses(),
          this.props.getAllLessons(),
        ];
        if (this.props.isStudent && this.props.user.classes.length) {
          // verify that the student only has 1 class.  If not then the customLessons will not work properly
          if (this.props.user.classes.length > 1) {
            console.error(
              "student has too many classes",
              this.props.user.classes
              );
            }
            if (this.props.user.classes.length === 0) {
              console.log(
                "student does not have any classes, skipping initial promises"
                );
                return Promise.resolve(true);
              }
              intialLoadPromises = [
                ...intialLoadPromises,
                this.props.getCustomLessons(this.props.user.classes[0].id),
                this.props.canStudentTakeSuperDiagnostic(),
              ];
            }
            return Promise.all(intialLoadPromises)
            .then(() => {
              this.setState({ fullScreenLoading: false });
              document.dispatchEvent(new Event("finishedGettingUpdatedData"));
            })
            .catch((error) => {
              console.error("error getting updated data at app launch", error);
              this.setState({ fullScreenLoading: false });
              throw error;
            });
          }
        }, 300);
        };
        
        /*
        * Check the app version.  If it has changed, log the user out.
        * This helps avoid breaking changes in the Redux store
        * if we wanted to improve this we could choose to not to log the user out on incremental . updates
        */
       checkAppVersion = () => {
         if (
           this.props.version &&
           this.props.version === `${process.env.REACT_APP_VERSION}`
    ) {
      // if (false) { // helps test upgrade path
      return Promise.resolve(true);
    } else {
      return this.runMigrations();
    }
  };

  /*
  * loop over the migrations and run them
  * for now we simply check to see if shouldLogout is set
  */
  runMigrations = ():Promise<any> => {
    let shouldLogout = false;
    const oldVersion = parseInt(
      (this.props.version || '0.0.0').replace(/\./g, ''),
      10
    )
    let state = this.props.state;
    forEach(migrations, (mig, key) => {
      let versionKey = key as any;
      if (versionKey > oldVersion){
        // run the migration
        console.log('running migtation from: ' + oldVersion + ' to: ' + key)
        state = mig(this.props.state);
        if (state.shouldLogout){
          shouldLogout = true;
        }
      }
    })
    // TODO set the updated state to actual redux state.  Also need to prevent the app from loading until this is completed.
    if (shouldLogout){
      console.log('migration requires logging out')
      
      toastr.warning('', 'App upgraded, please login again.', {
        ...constants.toastrWarning,
        timeOut: 5000
      });
      setTimeout(() => {
        this.logout();
      }, 5000);
      this.props.updateAppSettings({version:`${process.env.REACT_APP_VERSION}` })
      return Promise.reject(
        `App has been updated from: ${this.props.version} to: ${process.env.REACT_APP_VERSION}`
      );
    } else {
      this.props.updateAppSettings({version:`${process.env.REACT_APP_VERSION}` })
      console.log("migration does not require logging out")
      return Promise.resolve()
    }
  }

  render() {
    const { user, userUpdatePassword } = this.props;
    const mustUpdatePassword = !!user.mustUpdatePassword;
    return (
      <div className="app">
        {(!mustUpdatePassword) && (<>
          <Header
            user={this.props.user}
            loading={this.props.loading}
            logout={this.logout}
            isTeacher={this.props.isTeacher}
            isStudent={this.props.isStudent}
            superDiagnosticEnabled={this.props.canTakeSuperDiagnostic}
          />
          <Loading show={this.state.fullScreenLoading} message={"Loading..."} />
          {this.props.children}
        </>)}
        <HelpWidget isTeacher={this.props.isTeacher} user={this.props.user} userUpdateShowTutorial={this.props.userUpdateShowTutorial} />
        <NewTeacherChangePWModal user={user} show={mustUpdatePassword} changePW={userUpdatePassword} checkSession={this.checkSession} />
      </div>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  return {
    user: state.user,
    loading: state.ajaxCallsInProgress > 0,
    version: state.appSettings.version,
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state),
    isStudent: selectIsStudent(state),
    canTakeSuperDiagnostic: state.superDiagnosticView.canTakeSuperDiagnostic,
    state
  };
};

export default connect(mapStateToProps, {
  userLogout,
  checkSession,
  getAllQuizzes,
  getAllCourses,
  getAllLessons,
  getCustomLessons,
  canStudentTakeSuperDiagnostic,
  loadClasses,
  updateAppSettings,
  userUpdateShowTutorial,
  userUpdatePassword,
})(App);
