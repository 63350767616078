// this compenent simply wraps the payment form in the StripeProvider
import * as React from "react";

import { ElementsConsumer, Elements } from '@stripe/react-stripe-js';
import DirectCheckout from './DirectCheckout';
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE || '')

export default () => (
  <Elements stripe={stripePromise}>

    <ElementsConsumer>
      {({ stripe, elements }) => (
        <DirectCheckout stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  </Elements>
);