import * as types from './actionTypes';

import Axios, { AxiosRequestConfig } from 'axios';
import { GFTeacherUser, ThunkResult } from '../models/models';

import { API } from '../apiEndpoints';
import { beginAjaxCall } from './ajaxStatusActions';
import constants from '../constants';
import { toastr } from "react-redux-toastr";

/**
 * 
 * Search for a teacher by email at API. Should only search
 * full valid email, we want 1 exact match to be returned, or none.
 * 
 * @param query 
 * @returns ThunkResult<any>
 */
export function searchTeachers(query: string): ThunkResult<any> {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url: API.superTeacher.searchTeacher + encodeURIComponent(query)
    }
    return Axios(axiosOptions)
      .then((resp) => {
        dispatch({ type: types.SEARCH_TEACHERS_SUCCESS });
        return resp.data;
      })
      .catch(error => {
        dispatch({ type: types.SEARCH_TEACHERS_FAILED });
        const message = 'download queried list of teachers'
        console.error(message, error);
        constants.handleError(error, message);
        throw error;
      });
  };
}


/**
 * 
 * SuperTeacher updating the teacher's password
 * 
 * @param teacher 
 * @param password 
 * @returns ThunkResult<any>
 */
export function updateTeacherPW(
  teacher: GFTeacherUser|null,
  password: string
): ThunkResult<any>|null {
  if(teacher){
    return function(dispatch, getState) {
      dispatch(beginAjaxCall());
      const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        url: API.POST.user.updateTeacherPassword,
        data: { id: teacher.id, password }
      }
      return Axios(axiosOptions)
        .then((resp) => {
          if (!resp) {
            throw new Error('Update error');
          } else {
            dispatch({ type: types.USER_UPDATEPW_SUCCESS });
            toastr.success("Success", "Password updated.", constants.toastrSuccess);
          }
        })
        .catch(error => {
          dispatch({ type: types.USER_UPDATEPW_FAILED, error, axiosOptions });
          const message = "update teacher password";
          console.error(message, error);
          constants.handleError(error, message);
        });
    };
  } else {
    return null;
  }
}
