/*
 * QuizAnswersModal Container
 */
import * as React from "react";

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Modal,
  Row,
  Sizes,
} from "react-bootstrap";
import {
  GFCourse,
  GFInitialState,
  GFLesson,
  GFStudentQuizResult,
} from "../../models/models";

import QuizAnswers from "../quiz/QuizAnswers";
import { connect } from "react-redux";
import { hideAnswersModal } from "../../actions/progressActions";

const BreadcrumbTitle = ({
  quizResult,
  lessons,
}: {
  quizResult: GFStudentQuizResult;
  lessons: { [key: string]: GFLesson };
}) => {
  if (!quizResult.id.length) {
    return null;
  }
  const currentLesson = lessons[quizResult.quiz.lessonID];
  if (!currentLesson) {
    return <div>This lesson no longer exists.</div>;
  }
  if (quizResult.id) {
    return (
      <Row className="sub-header">
        <Col md={12} xs={12} className="gf-breadcrumb">
          <Breadcrumb>
            <BreadcrumbItem active={true}>
              <span>{currentLesson.name}</span>
            </BreadcrumbItem>
            <BreadcrumbItem active={true}>
              <span>{quizResult.quiz.name}</span>
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
};

type Iprops = {
  className: string;
  bsSize?: Sizes;
  title?: string;
};
type IdispatchProps = {
  show: boolean;
  showingInModal?: boolean;
  forceDisabled?: boolean;
  cancel: typeof hideAnswersModal;
  quizResult: GFStudentQuizResult;
  lessons: { [key: string]: GFLesson };
  courses: { [key: string]: GFCourse };
};

// export the combined types for the component we are rendering in the body of the modal
export type IquizAnswersProps = Iprops & IdispatchProps;

class QuizAnswersModalContainerClass extends React.PureComponent<
  IquizAnswersProps,
  {}
> {
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.cancel}
        bsSize={this.props.bsSize}
        className={this.props.className}
      >
        <Modal.Header>
          <Modal.Title>
            {!this.props.title && 
            <BreadcrumbTitle
            lessons={this.props.lessons}
            quizResult={this.props.quizResult}
            />
          }
          {this.props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuizAnswers {...this.props} />
          <Button onClick={this.props.cancel} bsStyle="default">
            Done
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: Iprops) => {
  return {
    quizResult: state.quizResult.quizResult,
    show: state.quizResult.show,
    lessons: state.lessons,
    courses: state.courses,
  };
};

export const QuizAnswersModalContainer = connect(mapStateToProps, {
  cancel: hideAnswersModal,
})(QuizAnswersModalContainerClass);
