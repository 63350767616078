/*
* QuizCompleteContainer
*/

import { connect } from 'react-redux';
import { GFInitialState } from '../../models/models';
import { QuizComplete } from './QuizComplete';
import { resetAnswers } from '../../actions/quizActions';

const mapStateToProps = (state: GFInitialState, ownProps: {}) => {

  return {
    loading: state.ajaxCallsInProgress > 0,
    quizAnswers: state.quizView.quizAnswers
  };
};

export const QuizCompleteContainer = connect(
  mapStateToProps,
  {
    resetAnswers
  }
)(QuizComplete);
