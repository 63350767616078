import * as React from "react";

import {
  Col,
  ControlLabel,
  FormGroup,
  Grid,
  Row,
  Button,
} from "react-bootstrap";
import {
  GFClass,
  GFInitialState,
  GFSuperDiagnosticTopic,
  GFSuperDiagnosticTopicsById,
  Option,
  GFSuperDiagnosticCustomDiagnostic,
} from "../../models/models";
import ReactTable, { Column, RowInfo } from "react-table";
import {
  getCustomDiagnostics,
  getTopics,
  updateCustomDiagnostics,
  toggleSuperDiagnostic,
  showConfirmDisableModal,
} from "../../actions/superDiagnosticActions";

import MainMenu from "../menu/MainMenu";
import Select from "react-select";
import Toggle from "react-toggle";
import { connect } from "react-redux";
import { values, orderBy, keyBy, isEmpty, find } from "lodash";
import { initialClass } from "../../reducers/initialState";
import { ConfirmDisableModalContainer } from "./ConfirmDisableModalContainer";
import { browserHistory, RouteComponentProps } from "react-router";
import { topicHeaderEnum } from "../../enums";
import { toastr } from "react-redux-toastr";
import constants from "../../constants";
import { selectClassOptions } from "../../reducers/classReducer";
import { selectIsTrial, selectHasGoogleClasses, selectIsSuperTeacher } from "../../reducers/userReducer";

const graham = require("../../images/graham.gif");
interface MatchParams {
  classID: string;
}
interface RowInfoEdited extends RowInfo {
  original: GFSuperDiagnosticTopic;
}

interface Props extends RouteComponentProps<MatchParams, {}> {}

interface DispatchProps {
  getTopics: typeof getTopics;
  getCustomDiagnostics: typeof getCustomDiagnostics;
  updateCustomDiagnostics: typeof updateCustomDiagnostics;
  toggleSuperDiagnostic: typeof toggleSuperDiagnostic;
  showConfirmDisableModal: typeof showConfirmDisableModal;
  customDiagnostics: GFSuperDiagnosticCustomDiagnostic[];
  topics: GFSuperDiagnosticTopicsById;
  classes: GFClass[];
  showModal: boolean;
  selectedClass: GFClass;
  classOptions: Option[];
  loading: boolean;
  hasGoogleClasses: boolean;
  isTrial: boolean;
  isSuperTeacher: boolean;
}

class BuildSuperDiagnostic extends React.Component<Props & DispatchProps, {}> {
  columns: Column[];
  constructor(props: Props & DispatchProps) {
    super(props);
    this.columns = [
      {
        Header: () => <span style={{ lineHeight: 2.1 }}>Activate Topic</span>,
        id: "topicEnabled",
        width: 150,
        Cell: ({ original: topic }) => {
          const diag = this.getDiagnosticByTopic(topic);
          return (
            <Toggle
              icons={false}
              defaultChecked={diag && diag.diagnosticEnabled}
              onChange={(e) => {
                this.onEnableTopic(topic, e.target.checked);
              }}
              disabled={this.props.selectedClass.superDiagnosticEnabled}
              className="gf-toggle"
            />
          );
        },
      },
      {
        Header: () => <span style={{ lineHeight: 2.1 }}>Topic Name</span>,
        id: "name",
        accessor: "name",
        minWidth: 200,
        Cell: ({ original: topic }) => {
          return <span>{topic.name}</span>;
        },
      },
    ];
  }

  componentDidMount() {
    if (this.props.classes.length){
      this.props.getTopics();
      this.initializeClassSelectAndDiagnostic();
    }
  }

  componentDidUpdate(prevProps: Props & DispatchProps) {
    if (
      JSON.stringify(this.props.classes) !== JSON.stringify(prevProps.classes) && this.props.classes && this.props.classes.length
    ) {
      this.initializeClassSelectAndDiagnostic();
    }
  }

  initializeClassSelectAndDiagnostic = () => {
    if (
      this.props.selectedClass.id.length === 0 &&
      this.props.classes.length > 0
    ) {
      browserHistory.replace(
        `buildSuperDiagnostic/${this.props.classes[0].id}`
      );
      this.props.getCustomDiagnostics(this.props.classes[0].id);
    } else {
      this.props.getCustomDiagnostics(this.props.selectedClass.id);
    }
  };

  getClassInfo = () => {
    const { id, name } = (this.props.selectedClass ||
      this.props.classes[0]) as GFClass;
    const superDiagnosticEnabled = this.props.selectedClass
      .superDiagnosticEnabled;
    return {
      id,
      name,
      superDiagnosticEnabled,
    };
  };

  getClassById(id: string) {
    return this.props.classes.find((c) => c.id === id);
  }

  getDiagnosticByTopic(topic: GFSuperDiagnosticTopic) {
    return this.props.customDiagnostics.find((c) => c.topicID === topic.id);
  }

  onEnabledToggle(superDiagnosticEnabled: boolean) {
    if (this.verifyHasClass() === false) return;

    const { id, name } = (this.props.selectedClass ||
      this.props.classes[0]) as GFClass;
    if (this.props.selectedClass.superDiagnosticEnabled) {
      this.props.showConfirmDisableModal();
    } else {
      const countEnabledTopics = this.props.customDiagnostics.filter(d => d.diagnosticEnabled === true).length;
      if (countEnabledTopics === 0){
        toastr.warning('Warning', "Please enable at least one topic", constants.toastrWarning);
        return;
      }
      this.props.toggleSuperDiagnostic(id, superDiagnosticEnabled);
    }
  }

  onEnableTopic(topic: GFSuperDiagnosticTopic, enabled: boolean) {
    if (this.verifyHasClass() === false) return;
    // Trigger redux action
    const diag = this.getDiagnosticByTopic(topic);
    let updated: GFSuperDiagnosticCustomDiagnostic = {
      diagnosticEnabled: enabled,
      classID: this.props.selectedClass.id,
      topicID: topic.id,
    };
    if (diag) {
      updated = { ...updated, id: diag.id };
      const updatedList = [
        ...this.props.customDiagnostics.filter((c) => c.id !== diag.id),
        updated,
      ];
      this.props.updateCustomDiagnostics(updatedList);
    } else {
      const newList = [...this.props.customDiagnostics, updated];
      this.props.updateCustomDiagnostics(newList);
    }
  }

  verifyHasClass = (): boolean => {
    if (this.props.classes.length) {
      return true;
    } else {
      toastr.warning(
        "Warning",
        "Please add a class first.",
        constants.toastrError
      );
      return false;
    }
  };

  onClassSelect(selectedOption: Option) {
    browserHistory.replace(`/buildSuperDiagnostic/${selectedOption.value}`);
    this.props.getCustomDiagnostics(selectedOption.value);
  }

  handleTdProps = (
    state: any,
    rowInfo: RowInfoEdited | undefined,
    column: any,
    instance: any
  ) => {
    if (column.id && column.id === "topicEnabled") {
      return {
        style: { textAlign: "center" },
      };
    } else {
      return {};
    }
  };

  demoSuperDiagnostic = () => {
    if (this.verifyHasClass() === false) return;
    const countEnabledTopics = this.props.customDiagnostics.filter(d => d.diagnosticEnabled === true).length;
      if (countEnabledTopics === 0){
        toastr.warning('Warning', "Please enable at least one topic", constants.toastrWarning);
        return;
      }
    browserHistory.push(`/super-diagnostic/${this.props.selectedClass.id}`);
  };

  render() {
    if (this.props.classes.length === 0){
      return (
<Grid className="content modal-container">
        <div className="sidemenu">
          <MainMenu isTeacher={true} isSuperTeacher={this.props.isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
          <div className="logo text-center">
            <img src={graham} alt="graham" />
          </div>
        </div>
        <div className="custom-lessons main-content content-with-sidebar">
          <h2>Please add a class before building a diagnostic assessment.  Click on your teacher dashboard to add a class.</h2>
        </div>
        </Grid>

      )
    }
    if (
      !this.props.classes ||
      (this.props.classes && !this.props.classes.length) ||
      !this.props.customDiagnostics ||
      (this.props.customDiagnostics && !this.props.customDiagnostics.length) ||
      isEmpty(this.props.topics) === true
    ) {
      return <h3 style={{ margin: "20px" }}>Loading...</h3>;
    }


    const topicsByHeader: { [header: string]: GFSuperDiagnosticTopic[] } = {};

    for (const [id, topic] of Object.entries(this.props.topics)) {
      if (!(topic.header in topicsByHeader)) {
        topicsByHeader[topic.header] = [];
      }
      topicsByHeader[topic.header].push(topic);
    }

    return (
      <Grid className="content modal-container">
        <div className="sidemenu">
          <MainMenu isTeacher={true} isSuperTeacher={this.props.isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
          <div className="logo text-center">
            <img src={graham} alt="graham" />
          </div>
        </div>
        <div className="custom-lessons main-content content-with-sidebar">
          <Row className="students-header">
            <Col xs={12} className="text-xl-left">
              <h1 className="pageDescription">Build Diagnostic</h1>
              <p>
                GrammarFlip's suggested diagnostic assesses students on ten key
                foundational topics stemming from the Parts of Speech, Parts of
                the Sentence, and Mechanics and Usage. You may elect to use the
                ten suggested diagnostic topics (currently toggled to the "on"
                position), or you may create your own diagnostic based on any of
                the topics provided.
              </p>
              <p>
                In order to balance a range of topics, a student's attention
                span, and the time constraints of a single sitting to complete
                the diagnostic, most topics (but not all) contain
                "fill-in-the-blank" questions, a strong determiner of student
                knowledge.
              </p>
            </Col>
          </Row>
          <Row className="class-form">
            <Col xs={12} lg={5}>
              <FormGroup bsSize="lg" className="class-lesson-selects">
                <Row>
                  <Col xs={4}>
                    <ControlLabel style={{ marginLeft: "-2px" }}>
                      Select Class
                    </ControlLabel>
                  </Col>
                  <Col xs={7} className="class-row">
                    <Select
                      className="react-select-container"
                      options={this.props.classOptions}
                      onChange={(opt) => this.onClassSelect(opt as Option)}
                      isSearchable={false}
                      value={{
                        value: this.props.selectedClass.id,
                        label: this.props.selectedClass.name,
                      }}
                      placeholder="select a class"
                      classNamePrefix="react-select"
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col xs={8} lg={5}>
              <FormGroup bsSize="lg" className="class-lesson-selects">
                <Row>
                  <Col xs={6}>
                    <ControlLabel style={{ marginLeft: "-2px" }}>
                      Enable Diagnostic
                    </ControlLabel>
                  </Col>
                  <Col xs={6}>
                    <FormGroup style={{ marginTop: "5px" }}>
                      <Toggle
                        icons={false}
                        checked={
                          this.props.selectedClass.superDiagnosticEnabled
                        }
                        onChange={(e) => this.onEnabledToggle(e.target.checked)}
                        className="gf-toggle"
                        disabled={this.props.loading}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            <Col xs={2}>
              <Button
                bsStyle="primary"
                bsSize="small"
                className="pull-right"
                style={{ marginTop: "15px", marginRight: '15px'}}
                onClick={this.demoSuperDiagnostic}
              >
                View Diagnostic
              </Button>
            </Col>
          </Row>
          {this.props.selectedClass.superDiagnosticEnabled && (
            <Row>
              <Col xs={12}>
                <p>
                  <i>
                    Students can now log into their accounts to access the
                    diagnostic assessment. In order to edit topics, you must
                    first disable the diagnostic.
                  </i>
                  <p>
                    <i>
                      Diagnostic will not be accessible to students once
                      completed.
                    </i>
                  </p>
                </p>
              </Col>
            </Row>
          )}

          <Row className="class-table">
            <Col xs={12} lg={6} style={{ marginLeft: "-5px" }}>
              {/* Parts of Speech */}
              <h1
                key={topicHeaderEnum.partsOfSpeech + "header"}
                style={{ marginTop: "15px" }}
              >
                {topicHeaderEnum.partsOfSpeech}
              </h1>
              <ReactTable
                key={topicHeaderEnum.partsOfSpeech + "table"}
                className="super-diagnostic-topics"
                data={topicsByHeader[topicHeaderEnum.partsOfSpeech]}
                columns={this.columns}
                showPagination={false}
                showPageSizeOptions={false}
                noDataText="No topics found."
                resizable={false}
                sortable={false}
                pageSize={topicsByHeader[topicHeaderEnum.partsOfSpeech].length}
                getTdProps={this.handleTdProps}
              />
              {/* Parts of the sentence  */}
              <h1
                key={topicHeaderEnum.partsOfSentence + "header"}
                style={{ marginTop: "15px" }}
              >
                {topicHeaderEnum.partsOfSentence}
              </h1>
              <ReactTable
                key={topicHeaderEnum.partsOfSentence + "table"}
                className="super-diagnostic-topics"
                data={topicsByHeader[topicHeaderEnum.partsOfSentence]}
                columns={this.columns}
                showPagination={false}
                showPageSizeOptions={false}
                noDataText="No topics found."
                resizable={false}
                sortable={false}
                pageSize={
                  topicsByHeader[topicHeaderEnum.partsOfSentence].length
                }
                getTdProps={this.handleTdProps}
              />
            </Col>
            {/* Mechanics and Usage  */}
            <Col xs={12} lg={6} style={{ marginLeft: "-5px" }}>
              <h1
                key={topicHeaderEnum.mechanicsAndUsage + "header"}
                style={{ marginTop: "15px" }}
              >
                {topicHeaderEnum.mechanicsAndUsage}
              </h1>
              <ReactTable
                key={topicHeaderEnum.mechanicsAndUsage + "table"}
                className="super-diagnostic-topics"
                data={topicsByHeader[topicHeaderEnum.mechanicsAndUsage]}
                columns={this.columns}
                showPagination={false}
                showPageSizeOptions={false}
                noDataText="No topics found."
                resizable={false}
                sortable={false}
                pageSize={
                  topicsByHeader[topicHeaderEnum.mechanicsAndUsage].length
                }
                getTdProps={this.handleTdProps}
              />
                              <Row>
            <Col xs={12} style={{marginTop: '20px'}}>
            <small>* suggested diagnostic topic</small>
            </Col>
          </Row>
            </Col>
          </Row>
        </div>
        {this.props.showModal ? (
          <ConfirmDisableModalContainer
            className="progress-reports-modal"
            bsSize="large"
            info={this.getClassInfo()}
          />
        ) : null}

      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: Props) => {
  const selectedClass =
    find(state.classes, { id: ownProps.params.classID }) || initialClass;
  const classOptions = selectClassOptions(state);
  const isTrial = selectIsTrial(state);

  return {
    loading: state.ajaxCallsInProgress > 0,
    topics: state.superDiagnosticTopics,
    customDiagnostics: values(state.customDiagnosticsByID),
    classes: state.classes,
    showModal: state.showConfirmDisableModal,
    selectedClass,
    classOptions,
    isTrial,
    hasGoogleClasses: selectHasGoogleClasses(state),
    isSuperTeacher: selectIsSuperTeacher(state),
  };
};

export default connect(mapStateToProps, {
  // Map actions thru as props
  getCustomDiagnostics,
  updateCustomDiagnostics,
  getTopics,
  toggleSuperDiagnostic,
  showConfirmDisableModal,
})(BuildSuperDiagnostic);
