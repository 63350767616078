import { GFPlan } from "./models/models";

export const paymentPlanObjectsByID: { [key: string]: GFPlan } = {
    masterAnnual150: {
      time: 'Annual',
      price: 14999,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Annual', // master plan 150
      size: 150,
      rank: 155,
      name: '150 Student Seats'
    },
    masterMonthly150: {
      time: 'Monthly',
      price: 1499,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Monthly',
      size: 150,
      rank: 150,
      name: ''
    },
    masterAnnual125: {
      time: 'Annual',
      price: 12499,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Annual-125',
      size: 125,
      rank: 145,
      name: '125 Student Seats'
    },
    masterMonthly125: {
      time: 'Monthly',
      price: 1249,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Monthly-125',
      size: 125,
      rank: 140,
      name: ''
    },
    masterAnnual100: {
      time: 'Annual',
      price: 9899,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Annual-100',
      size: 100,
      rank: 135,
      name: '100 Student Seats'
    },
    masterMonthly100: {
      time: 'Monthly',
      price: 989,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Monthly-100',
      size: 100,
      rank: 130,
      name: ''
    },
    masterAnnual75: {
      time: 'Annual',
      price: 8999,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Annual-75',
      size: 75,
      rank: 125,
      name: '75 Student Seats'
    },
    masterMonthly75: {
      time: 'Monthly',
      price: 899,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Monthly-75',
      size: 75,
      rank: 120,
      name: ''
    },
    masterAnnual50: {
      time: 'Annual',
      price: 6999,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Annual-50',
      size: 50,
      rank: 115,
      name: '50 Student Seats'
    },
    masterMonthly50: {
      time: 'Monthly',
      price: 690,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Monthly-50',
      size: 50,
      rank: 110,
      name: ''
    },
    masterAnnual25: {
      time: 'Annual',
      price: 4999,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Annual-25',
      size: 25,
      rank: 105,
      name: '25 Student Seats'
    },
    masterMonthly25: {
      time: 'Monthly',
      price: 499,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Monthly-25',
      size: 25,
      rank: 100,
      name: ''
    },
    masterAnnual150_2: {
      time: 'Annual',
      price: 14999,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-2020-Annual-150', // master plan 150
      size: 150,
      rank: 155,
      name: '150 Student Seats'
    },
    masterMonthly150_2: {
      time: 'Monthly',
      price: 1599,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-2020-Monthly-150',
      size: 150,
      rank: 150,
      name: ''
    },
    masterAnnual125_2: {
      time: 'Annual',
      price: 12499,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-2020-Annual-125',
      size: 125,
      rank: 145,
      name: '125 Student Seats'
    },
    masterMonthly125_2: {
      time: 'Monthly',
      price: 1399,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-2020-Monthly-125',
      size: 125,
      rank: 140,
      name: ''
    },
    masterAnnual100_2: {
      time: 'Annual',
      price: 9999,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-2020-Annual-100',
      size: 100,
      rank: 135,
      name: '100 Student Seats'
    },
    masterMonthly100_2: {
      time: 'Monthly',
      price: 1099,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-2020-Monthly-100',
      size: 100,
      rank: 130,
      name: ''
    },
    masterAnnual75_2: {
      time: 'Annual',
      price: 8999,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-2020-Annual-75',
      size: 75,
      rank: 125,
      name: '75 Student Seats'
    },
    masterMonthly75_2: {
      time: 'Monthly',
      price: 999,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-2020-Monthly-75',
      size: 75,
      rank: 120,
      name: ''
    },
    masterAnnual50_2: {
      time: 'Annual',
      price: 6999,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-2020-Annual-50',
      size: 50,
      rank: 115,
      name: '50 Student Seats'
    },
    masterMonthly50_2: {
      time: 'Monthly',
      price: 799,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-2020-Monthly-50',
      size: 50,
      rank: 110,
      name: ''
    },
    masterMonthly25_2: {
      time: 'Monthly',
      price: 599,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-2020-Monthly-25',
      size: 25,
      rank: 101,
      name: ''
    },
    masterAnnual25_2:{
        time: "Annual",
        price: 4999,
        tier: 'GF-Tier2',
        id: 'GF-Tier2-2020-Annual-25',
        size: 25,
        rank: 102,
        name: '25 Student Seats'
    },
    masterAnnual5_2: {
      // homeschool plan
      time: 'Annual',
      price: 3999,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Annual-5',
      size: 5,
      rank: 95,
      name: 'Homeschool - 5 Student Seats'
    },
    masterMonthly5_2: {
      // homeschool plan (does not actually exist)
      time: 'Monthly',
      price: 3999,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Monthly-5',
      size: 5,
      rank: 94,
      name: 'Homeschool - 5 Student Seats'
    },
    masterAnnual5: {
      // homeschool plan
      time: 'Annual',
      price: 3999,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Annual-5',
      size: 5,
      rank: 95,
      name: 'Homeschool - 5 Student Seats'
    },
    masterMonthly5: {
      // homeschool plan (does not actually exist)
      time: 'Monthly',
      price: 3999,
      tier: 'GF-Tier2',
      id: 'GF-Tier2-Monthly-5',
      size: 5,
      rank: 94,
      name: ''
    },
    trial: {
      time: 'Annual',
      price: 0,
      tier: 'GF-Tier2',
      id: 'trial',
      size: 5,
      rank: 0,
      name: '5 Student Seats'
    },
    expired: {
      time: 'Monthly',
      price: 0,
      tier: 'GF-Tier1',
      id: 'expired',
      size: 0,
      rank: 1,
      name: ''
    },
    cancelled: {
      time: 'Monthly',
      price: 0,
      tier: 'GF-Tier1',
      id: 'cancelled',
      size: 0,
      rank: 2,
      name: ''
    }
  };