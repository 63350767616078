/*
* Student Report constants to share between the StudentReport and the TeacherStudentReport
*/

import * as React from 'react';
import moment from 'moment';

import { GFStudentQuizResult } from '../../models/models';
import { RowInfo, RowRenderProps } from 'react-table';
import { filter, map, mean, round } from 'lodash';

import { expanderToggle } from './AttemptsExpander';

const FontAwesome = require('react-fontawesome');

interface ResultRow extends RowRenderProps {
  value: GFStudentQuizResult[];
}

export const scoreCell = (row: ResultRow) => {
  const isExpanded = row.isExpanded ? 'expanded' : '';
  const quizResult = row.value[0];
  const cellClassName = `${quizResult.className} ${isExpanded}`;
  const score = quizResult.scoreString;
  return (
    <div className={cellClassName}>
      {score}
    </div>
  );
};

export const expanderScoreCell = (row: { value: GFStudentQuizResult }) => {
  const createDate = row.value.createDate
    ? moment
        .utc(row.value.createDate)
        .local(true)
        .format('MM/DD/YY')
    : '';

  if (row.value.isLocked && !row.value.isComplete) {
    return (
      <div>
        <FontAwesome name="exclamation" title="incomplete" /> &nbsp;
        <b>{createDate} &nbsp;</b>
        <FontAwesome name="lock" title="locked" />{' '}
      </div>
    );
  }
  const score = row.value.isComplete ? (
    row.value.scoreString
  ) : (
    <FontAwesome name="info" />
  );
  return (
    <div>
      {score} &nbsp;
      {row.value.overrideScore !== true && <b>{createDate} &nbsp;</b>}
      {row.value.isLocked && <FontAwesome name="lock" title="locked" />}
    </div>
  );
};

/*
* assign the correct color to the table cell
*/
export const handleTdProps = (
  state: any,
  rowInfo: RowInfo | undefined,
  column: any,
  instance: any
) => {
  if (
    rowInfo &&
    rowInfo.row[column.id] &&
    rowInfo.row[column.id].length &&
    !state.expanded[rowInfo.row._index]
  ) {
    return {
      className: `${rowInfo.row[column.id][0].className}`
    };
  } else {
    return {};
  }
};

const sortQuizzes = (a: any[], b: any[], desc: boolean) => {
  a = a[0].score;
  b = b[0].score;

  // Return either 1 or -1 to indicate a sort priority
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }
  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0;
};
export const FooterCell = (props: any) => {
  // filter out students who do not have a score or the score is incomplete, or the scoreString has been removed
  // (this happens when it is locked and the date filter is selecting a date outside of the locked progress object)
  const arrayOfScores = filter(
    props.data,
    d =>
      d[props.column.id][0].id &&
      d[props.column.id][0].scoreString !== '--'
        ? true
        : false
  );
  if (!arrayOfScores.length) {
    return <span />;
  }
  return (
    <span>
      {round(mean(map(arrayOfScores, d => d[props.column.id][0].score)))}%
    </span>
  );
};

export const columns = [
  {
    Header: 'Lesson Name',
    accessor: 'name',
    minWidth: 200,
    Footer: <strong>Averages</strong>
  },
  {
    Header: 'Pre-Test',
    id: 'quiz01',
    accessor: (lesson: any) => lesson.quiz01Results,
    Cell: scoreCell,
    sortMethod: sortQuizzes,
    minWidth: 100,
    Footer: FooterCell
  },
  {
    Header: 'PE 1',
    id: 'quiz02',
    accessor: (lesson: any) => lesson.quiz02Results,
    Cell: scoreCell,
    sortMethod: sortQuizzes,
    minWidth: 100,
    Footer: FooterCell
  },
  {
    Header: 'PE 2',
    id: 'quiz03',
    accessor: (lesson: any) => lesson.quiz03Results,
    Cell: scoreCell,
    sortMethod: sortQuizzes,
    minWidth: 100,
    Footer: FooterCell
  },
  {
    Header: 'PE 3',
    id: 'quiz04',
    accessor: (lesson: any) => lesson.quiz04Results,
    Cell: scoreCell,
    sortMethod: sortQuizzes,
    minWidth: 100,
    Footer: FooterCell
  },
  {
    Header: 'Post-Evaluation',
    id: 'quiz05',
    accessor: (lesson: any) => lesson.quiz05Results,
    Cell: scoreCell,
    sortMethod: sortQuizzes,
    minWidth: 140,
    Footer: FooterCell
  },
  {
    expander: true,
    Expander: expanderToggle,
    style: {
      cursor: 'pointer',
      textAlign: 'center',
      userSelect: 'none'
    }
  }
];

export const printElement = (elem: any, append: boolean, delimiter: any) => {
  const domClone = elem.cloneNode(true);

  let $printSection = document.getElementById('printSection');

  if (!$printSection) {
    $printSection = document.createElement('div');
    $printSection.id = 'printSection';
    document.body.appendChild($printSection);
  }

  if (append !== true) {
    $printSection.innerHTML = '';
  } else if (append === true) {
    if (typeof delimiter === 'string') {
      $printSection.innerHTML += delimiter;
    } else if (typeof delimiter === 'object') {
      $printSection.appendChild(delimiter);
    }
  }

  $printSection.appendChild(domClone);
};
