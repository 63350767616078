import * as React from "react";
import { ThunkAction } from 'redux-thunk';
import { Col, Grid, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import {
  GFCourse,
  GFInitialState,
  GFLessonWithCustomLesson,
  GFUser,
  GFLesson,
  Option
} from "../../models/models";
import { Link, RouteComponentProps, browserHistory } from "react-router";
import {
  filterLessonsWithCustomLessonHelper,
  getCustomLessons
} from "../../actions/customLessonActions";
import { postLessonToGoogleClassroom } from "../../actions/googleActions";
import { LinkContainer } from "react-router-bootstrap";
import MainMenu from "../menu/MainMenu";
import VerificationAlert from "../common/VerificationAlert";
import { connect } from "react-redux";
import { loadClasses } from "../../actions/classActions";
import { selectClassOptions } from '../../reducers/classReducer';
import { getLessonsByCourseID } from "../../actions/lessonActions";
import { initialCourse } from "../../reducers/initialState";
import { selectIsStudent, selectIsTeacher, selectIsSuperTeacher, selectHasGoogleClasses } from "../../reducers/userReducer";
import { socialServiceEnum } from "../../enums";
import GoogleShareToClassRoomButton from "../common/GoogleShareToClassRoomButton";

const graham = require("../../images/graham.gif");

const LessonListItem = ({
  classOptions,
  gfLesson,
  courseID,
  isTeacher,
  isGoogleUser,
  postLessonToGoogleClassroom,
}: {
  classOptions: Option[];
  gfLesson?: GFLessonWithCustomLesson | GFLesson;
  courseID: string;
  isTeacher: boolean;
  isGoogleUser: boolean;
  postLessonToGoogleClassroom: (parentID: string, link: string, classID: string) => ThunkAction<any, GFInitialState, undefined, any>;
}) => {
  if (!gfLesson){
    return null;
  }
  let imagePath = gfLesson.imagePath;
  if (imagePath === null || imagePath === "") {
    imagePath = require("../../images/graham-thumb.jpg");
  }
  const showKahoot = gfLesson.kahootPath && isTeacher;
  const showGoogleClassroom = isTeacher && isGoogleUser;
  const lessonLink = `${process.env.REACT_APP_HOST}/lesson/${courseID}/${gfLesson.id}`;
  return (
    <ListGroupItem className="lesson list-item" key={gfLesson.id}>
      <Row className="vertical-align">
        <LinkContainer to={`/lesson/${courseID}/${gfLesson.id}`}>
          <Col xs={2} lg={1}>
            <img width={64} height={64} src={imagePath} alt="lesson" />
          </Col>
        </LinkContainer>
        <LinkContainer to={`/lesson/${courseID}/${gfLesson.id}`}>
          <Col xs={showKahoot ? 8 : 10} lg={showKahoot ? 9 : 11}>
            <span>
              <span className="lesson-name">{gfLesson.name}</span>
              {isTeacher && (
                <p style={{ lineHeight: 2, fontSize: "12px" }}>
                  {gfLesson.commonCoreNumber}
                </p>
              )}
            </span>
          </Col>
        </LinkContainer>
        {showKahoot && (
          <Link
            to={gfLesson.kahootPath}
            target="_blank"
            style={{ padding: "20px 5px 20px 5px" }}
            className="kahoot-path-link"
          >
            <Col xs={2} lg={1} className="kahoot-path" />
          </Link>
        )}
        {showGoogleClassroom && (
          <GoogleShareToClassRoomButton
            type='lesson'
            link={lessonLink}
            shareToClassRoom={postLessonToGoogleClassroom}
            lesson={gfLesson}
            classOptions={classOptions}
          />
        )}
      </Row>
    </ListGroupItem>
  );
};

interface RouterParams {
  courseID: string;
}

interface Props extends RouteComponentProps<RouterParams, {}> {
  user: GFUser;
  classOptions: Option[];
  loadClasses: typeof loadClasses;
  filteredLessons: Array<GFLessonWithCustomLesson | GFLesson>;
  getLessonsByCourseID: typeof getLessonsByCourseID;
  loading: boolean;
  selectedCourse: GFCourse;
  getCustomLessons: typeof getCustomLessons;
  hasGoogleClasses: boolean;
  isTeacher: boolean;
  isStudent: boolean;
  isSuperTeacher: boolean;
  postLessonToGoogleClassroom: typeof postLessonToGoogleClassroom;
}

class CourseLessonsClass extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.openKahootLesson = this.openKahootLesson.bind(this);
  }

  componentWillMount() {
    // if we have a courseID then display the lessons in that course
    const { courseID } = this.props.params;

    if (!courseID) {
      console.warn("missing courseID when trying to view courseLessons");
      browserHistory.push("/courses");
      return;
    }

    if (!this.props.filteredLessons.length) {
      this.props.getLessonsByCourseID(courseID);
    }
    if (this.props.isStudent && this.props.user.classes.length) {
      this.props.getCustomLessons(this.props.user.classes[0].id);
    }
    if(this.props.isTeacher && this.props.classOptions.length === 0){
      this.props.loadClasses();
    }
  }
  openKahootLesson(kahootPath: string) {
    window.open(kahootPath);
  }

  render() {
    const {isStudent} = this.props;
    const contClasses = isStudent
      ? "courses main-content content-without-sidebar student animated fadeIn"
      : "courses main-content content-with-sidebar animated fadeIn";
    return (
      <Grid className="content modal-container">
        {this.props.isTeacher && (
          <div className="sidemenu">
            <MainMenu isTeacher={this.props.isTeacher} isSuperTeacher={this.props.isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
            <div className="logo text-center">
              <img alt="graham" src={graham} />
            </div>
          </div>
        )}
        <div className={contClasses}>
          <VerificationAlert user={this.props.user} isTeacher={this.props.isTeacher} />
          <div className="row sub-header">
            <Col md={12}>
              <h1 className="text-center">{this.props.selectedCourse.name}</h1>
              <h3 className="text-center">
                {this.props.selectedCourse.description}
              </h3>
            </Col>
          </div>
          <div className="row courses-list">
            <ListGroup className="col-md-12">
              {this.props.filteredLessons.map((gfLesson, index) => {
                return (
                  <LessonListItem
                    gfLesson={gfLesson}
                    courseID={this.props.params.courseID}
                    isTeacher={this.props.isTeacher}
                    isGoogleUser={this.props.user.hasOwnProperty('socialType') && this.props.user.socialType === socialServiceEnum.google && this.props.hasGoogleClasses}
                    key={gfLesson.id}
                    postLessonToGoogleClassroom={this.props.postLessonToGoogleClassroom}
                    classOptions={this.props.classOptions}
                  />
                );
              })}
            </ListGroup>
          </div>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: Props) => {
  const classOptions = selectClassOptions(state);
  const selectedCourse =
    state.courses[ownProps.params.courseID] || initialCourse;
  // when student, use the student's classID, when teacher use the selected classID
  let selectedClassID = state.selectedClassID;
  const isStudent = selectIsStudent(state);
  if (isStudent && state.user.classes.length) {
    selectedClassID = state.user.classes[0].id;
  }
  const filteredLessons = filterLessonsWithCustomLessonHelper(
    state.lessons,
    state.customLessons,
    selectedClassID,
    ownProps.params.courseID
  );
  return {
    user: state.user,
    filteredLessons,
    selectedCourse,
    loading: state.ajaxCallsInProgress > 0,
    hasGoogleClasses: selectHasGoogleClasses(state),
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state),
    isStudent,
    classOptions
  };
};

export default connect(
  mapStateToProps,
  {
    getLessonsByCourseID,
    getCustomLessons,
    loadClasses,
    postLessonToGoogleClassroom,
  }
)(CourseLessonsClass);
