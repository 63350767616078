import * as React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Grid,
  ListGroup,
  Row
} from "react-bootstrap";
import {
  GFInitialState,
  GFLesson,
  GFQuizItem,
  GFUser,
  GFWritingAssignment,
  Option
} from "../../models/models";
import { RouteComponentProps, browserHistory } from "react-router";
import { filter, find, isEmpty, map } from "lodash";
import {
  initialCustomLesson,
  initialLesson
} from "../../reducers/initialState";
import { selectClassOptions } from '../../reducers/classReducer';
import { LinkContainer } from "react-router-bootstrap";
import { QuizWAListItem } from "./QuizWAListItem";
import VerificationAlert from "../common/VerificationAlert";
import { connect } from "react-redux";
import constants from "../../constants";
import { postQuizToGoogleClassroom, postWAToGoogleClassroom } from "../../actions/googleActions";
import { getAllWAByLessonID } from "../../actions/waActions";
import { getCustomLessons } from "../../actions/customLessonActions";
import { getLessonsByCourseID } from "../../actions/lessonActions";
import { getQuizzesByLessonID } from "../../actions/quizActions";
import { sortBy } from "lodash";
import { toastr } from "react-redux-toastr";
import { selectIsStudent, selectIsTeacher, selectHasGoogleClasses } from "../../reducers/userReducer";
import { quizTypeEnum, socialServiceEnum } from "../../enums";

const mixpanel = require("mixpanel-browser");

interface RouterParams {
  courseID: string;
  lessonID: string;
}

interface Props extends RouteComponentProps<RouterParams, {}> {
  user: GFUser;
  lesson: GFLesson;
  quizzes: { [key: string]: GFQuizItem };
  getLessonsByCourseID: typeof getLessonsByCourseID;
  getQuizzesByLessonID: typeof getQuizzesByLessonID;
  getAllWAByLessonID: typeof getAllWAByLessonID;
  writingAssignments: { [key: string]: GFWritingAssignment };
  loading: boolean;
  lessonQuizzes: GFQuizItem[]; // the quizzes for this lesson
  getCustomLessons: typeof getCustomLessons;
  postEvalEnabled: boolean;
  isStudent: boolean;
  isTeacher: boolean;
  postQuizToGoogleClassroom: typeof postQuizToGoogleClassroom;
  postWAToGoogleClassroom: typeof postWAToGoogleClassroom;
  classOptions: Option[];
  hasGoogleClasses: boolean;
}

interface State {
  showSecondary: boolean;
  primaryVideo: string;
  secondaryVideo: string;
  srcSlideShare: string;
}

class Lesson extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showSecondary: false,
      primaryVideo: `https://fast.wistia.net/embed/iframe/${
        this.props.lesson.primaryVideoPath
      }`,
      secondaryVideo: !isEmpty(this.props.lesson.secondaryVideoPath) ? `https://fast.wistia.net/embed/iframe/${
        this.props.lesson.secondaryVideoPath
      }` : '',
      srcSlideShare: `https://www.slideshare.net/slideshow/embed_code/key/${
        this.props.lesson.slideshowPath
      }`
    };
    /* video embed URLS
    * YouTube: `https://www.youtube.com/embed/${this.props.lesson.secondaryVideoPath}?ecver=1`
    * Wistia: `https://fast.wistia.net/embed/iframe/${this.props.lesson.secondaryVideoPath}?videoFoam=true`
    * Vimeo: `https://player.vimeo.com/video/${this.props.lesson.primaryVideoPath}`
    */
  }

  componentWillMount() {
    const { lessonID, courseID } = this.props.params;
    if (!lessonID || !courseID) {
      // no lesson id and no coursID is not allowed
      browserHistory.push(`/courses`);
      return;
    }
    if (!this.props.lesson.id.length) {
      this.props.getLessonsByCourseID(courseID);
    }
    if (this.props.isStudent && this.props.user.classes.length) {
      // verify that the student only has 1 class.  If not then the customLessons will not work properly
      if (this.props.user.classes.length > 1) {
        console.error("student has too many classes", this.props.user.classes);
      }
      this.props.getCustomLessons(this.props.user.classes[0].id);
    }
    // after a bit get the most up to date quiz questions and the status
    this.props.getQuizzesByLessonID(lessonID);

    this.props.getAllWAByLessonID(lessonID);

    mixpanel.track("Lesson clicked", {
      lesson: lessonID,
      name: this.props.lesson.name
    });
    window.scrollTo(0, 0);
  }

  /*
  * Go to quiz.
  * Quiz will check if this is a post-eval and if it is enabled
  */
  startQuiz = (gfQuiz: GFQuizItem) => {
    // set the current active quiz item
    this.goToQuiz(gfQuiz);
  };
  goToQuiz = (gfQuiz: GFQuizItem) => {
    mixpanel.track("Practice Exercise started", {
      quiz: gfQuiz.id,
      name: gfQuiz.name
    });
    const path = `/quiz/${this.props.params.courseID}/${
      this.props.params.lessonID
    }/${gfQuiz.id}`;
    browserHistory.push(path);
  };
  goToQuizAnswers = (gfQuiz: GFQuizItem) => {
    mixpanel.track("Print View selected", {
      quiz: gfQuiz.id,
      name: gfQuiz.name
    });
    const path = `/quizAnswers/${this.props.params.courseID}/${
      this.props.params.lessonID
    }/${gfQuiz.id}`;
    browserHistory.push(path);
  };

  /*
  * Start a Writing assignment
  */
  startWA = (wa: GFWritingAssignment) => {
    mixpanel.track("Writing Assignment started", {
      writingAssignmentID: wa.id,
      writingAssignmentName: wa.name
    });
    const path = `/writingassignment/${this.props.params.courseID}/${
      this.props.params.lessonID
    }/${wa.id}`;
    window.scrollTo(0, 0);
    browserHistory.push(path);
  };

  swapVideo = () => {
    this.setState({
      showSecondary: !this.state.showSecondary
    });
  }

  render() {
    let courseName;
    if (
      this.props.user.id &&
      this.props.lesson.id &&
      this.props.lesson.courseLessons &&
      this.props.lesson.courseLessons[0].course
    ) {
      courseName = this.props.lesson.courseLessons[0].course.name;
    }

    return (
      <Grid className="content modal-container">
        <div className="main-content content-without-sidebar lesson animated fadeIn">
          <VerificationAlert user={this.props.user} isTeacher={this.props.isTeacher} />
          <Row className="sub-header">
            <Col md={10} xs={10} className="gf-breadcrumb lesson">
              <Breadcrumb>
                <LinkContainer
                  to={`/courseLessons/${this.props.params.courseID}`}
                >
                  <BreadcrumbItem>
                    <span>{courseName}</span>
                  </BreadcrumbItem>
                </LinkContainer>
                <BreadcrumbItem active={true}>
                  {this.props.lesson.name}
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
          {/* <Row className="lesson-description">
          <Col
            md={12}
            sm={12}
            xs={12}
            dangerouslySetInnerHTML={{ __html: this.props.lesson.description }}
          />
        </Row> */}
          <Row className="lesson-list">
            <Col md={6} sm={6} xs={12} className="firstVideo text-center">
              <iframe
                style={{
                  border: "1px solid #CCC",
                  borderWidth: 1,
                  marginBottom: 5,
                  maxWidth: "100%"
                }}
                width="500"
                height="360"
                src={
                  this.state.showSecondary
                    ? this.state.secondaryVideo
                    : this.state.primaryVideo
                }
                frameBorder="0"
                allowFullScreen={true}
                title="Video"
              />
              {!this.state.showSecondary && !isEmpty(this.state.secondaryVideo) && (
              <Button
                bsStyle="link"
                onClick={this.swapVideo}
                className="video-hyperlink"
              >
                Click here to see this Grammar Lesson in context.
              </Button>
            )}
            {this.state.showSecondary && (
              <Button
                bsStyle="link"
                onClick={this.swapVideo}
                className="video-hyperlink"
              >
                Click here to see the main Grammar lesson.
              </Button>
            )}
            </Col>
            <Col md={6} sm={6} xs={12}>
              <iframe
                style={{
                  border: "1px solid #CCC",
                  borderWidth: 1,
                  marginBottom: 5,
                  maxWidth: "100%"
                }}
                src={this.state.srcSlideShare}
                width="500"
                height="360"
                frameBorder="0"
                marginWidth={0}
                marginHeight={0}
                scrolling="no"
                allowFullScreen={true}
                title="SlideShare"
              />
            </Col>
          </Row>
          {this.props.lessonQuizzes.length !== 0 && (
            <h1>Pre-Test, Practice Exercises, and Post-Evaluation</h1>
          )}
          <ListGroup className="lesson-list">
            {this.props.lessonQuizzes.map((gfQuiz, index) => {
              const isPostEval =
                gfQuiz.type === quizTypeEnum.postEval;
              const enabled =
                !isPostEval || (isPostEval && this.props.postEvalEnabled);
              const itemUrl = `${process.env.REACT_APP_HOST}/quiz/${this.props.params.courseID}/${
                this.props.params.lessonID
              }/${gfQuiz.id}`;
              return (
                <QuizWAListItem
                  key={gfQuiz.id}
                  onClick={() => {
                    if(!enabled && this.props.isStudent){
                      toastr.warning(
                        "",
                        "Your teacher must enable the Post-Evaluation before you can proceed.",
                        constants.toastrSuccess
                      );
                    } else if (this.props.user.paymentPlan === "trial" && isPostEval) {
                      toastr.warning("", "Post-Evaluations are not enabled on trial accounts.");
                    } else {
                      this.startQuiz(gfQuiz);
                    }
                  }}
                  enabled={enabled}
                  item={gfQuiz}
                  type="quiz"
                  itemUrl={itemUrl}
                  onPrintView={this.goToQuizAnswers}
                  isTeacher={this.props.isTeacher}
                  isGoogleUser={this.props.user.hasOwnProperty('socialType') && this.props.user.socialType === socialServiceEnum.google && this.props.hasGoogleClasses}
                  lessonID={this.props.params.lessonID}
                  lesson={this.props.lesson}
                  postQuizToGoogleClassroom={this.props.postQuizToGoogleClassroom}
                  postWAToGoogleClassroom={this.props.postWAToGoogleClassroom}
                  classOptions={this.props.classOptions}
                  isTrial={this.props.user.paymentPlan === "trial"}
                />
              );
            })}
          </ListGroup>
          {!isEmpty(this.props.writingAssignments) && (
            <h1>Writing Activities</h1>
          )}
          <ListGroup>
            {map(this.props.writingAssignments, (wa, index) => {
              const itemUrl = `${process.env.REACT_APP_HOST}/writingassignment/${this.props.params.courseID}/${
                this.props.params.lessonID
              }/${wa.id}`;
              return (
                <QuizWAListItem
                  key={wa.id}
                  onClick={this.startWA}
                  enabled={true}
                  item={wa}
                  type="writingassignment"
                  itemUrl={itemUrl}
                  onPrintView={this.goToQuizAnswers}
                  isTeacher={this.props.isTeacher}
                  isGoogleUser={this.props.user.hasOwnProperty('socialType') && this.props.user.socialType === socialServiceEnum.google && this.props.hasGoogleClasses}
                  lesson={this.props.lesson}
                  postQuizToGoogleClassroom={this.props.postQuizToGoogleClassroom}
                  postWAToGoogleClassroom={this.props.postWAToGoogleClassroom}
                  classOptions={this.props.classOptions}
                  isTrial={this.props.user.paymentPlan === "trial"}
                />
              );
            })}
          </ListGroup>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  // if this is a student, user their classID, if a teacher then user the selected class
  const { user, selectedClassID } = state;
  const classOptions = selectClassOptions(state);
  const isStudent = selectIsStudent(state);
  const classID =
    isStudent && user.classes.length
      ? user.classes[0].id
      : selectedClassID;
  const lesson = state.lessons[ownProps.params.lessonID] || initialLesson;
  const lessonQuizzes =
    sortBy(
      filter(state.quizzes, {
        lessonID: ownProps.params.lessonID
      }),
      "type"
    ) || [];

  // find the customLesson for this lesson and class then check if the post-eval is enabled
  const customLesson =
    find(
      state.customLessons,
      customL =>
        customL.lessonID === ownProps.params.lessonID &&
        customL.classID === classID
    ) || initialCustomLesson;

  // post-evals are disabled for students unless a teacher has enabled them for the class
  // post-evals are enabled for Teachers who select "view all lessons" or if they have enabled them for the class they have selected
  let postEvalEnabled = false;
  if (selectedClassID.length === 0 && isStudent === false) {
    postEvalEnabled = true;
  } else {
    postEvalEnabled =
      customLesson && !customLesson.postEvalEnabled ? false : true;
  }

  return {
    user: state.user,
    lesson,
    lessonQuizzes,
    writingAssignments: state.writingAssignments,
    postEvalEnabled,
    loading: state.ajaxCallsInProgress > 0,
    isStudent,
    isTeacher: selectIsTeacher(state),
    classOptions,
    hasGoogleClasses: selectHasGoogleClasses(state),
  };
};

export default connect(
  mapStateToProps,
  {
    getLessonsByCourseID,
    getQuizzesByLessonID,
    getAllWAByLessonID,
    getCustomLessons,
    postQuizToGoogleClassroom,
    postWAToGoogleClassroom,
  }
)(Lesson);
