import * as React from 'react';
import { Button, FormControl, InputGroup, FormGroup } from 'react-bootstrap';

interface Props extends React.Props<CouponSection> {
  stripeCoupon: string;
  stripeCouponDetails: any;
  checkStripeCoupon: any;
  handleChange: any;
  loading: any;
}

class CouponSection extends React.Component<Props, {}> {
  render() {
    return (
      <div>
        <FormGroup controlId="stripeCoupon" bsSize="lg">
          <InputGroup>
            <FormControl
              type="text"
              name="stripeCoupon"
              value={this.props.stripeCoupon}
              placeholder="Coupon Code"
              onChange={this.props.handleChange}
            />
            <InputGroup.Button>
              <Button
                disabled={this.props.loading}
                onClick={this.props.checkStripeCoupon}
                bsStyle="primary"
              >
                Apply
              </Button>
            </InputGroup.Button>
          </InputGroup>
          <FormControl.Feedback />
        </FormGroup>
        <div className="coupon-text">
          {this.props.stripeCouponDetails &&
            `${this.props.stripeCouponDetails.percent_off}% discount applied!`}
        </div>
      </div>
    );
  }
}

export default CouponSection;
