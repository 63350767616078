/*
* stateless component to help select a date range
*/
import * as React from 'react';
import DatePicker from 'react-datepicker';
import { FormGroup, ControlLabel, Col, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';

// need to create interface for props but using any for now
const DateRangePicker = (props: any) => {
  return (
    <div className="gf-date-range-picker">
      {/*<h4>Custom Date Range </h4>*/}
      <FormGroup>
        <Row className="start-picker-row">
          <Col xs={3}>
            <ControlLabel> Start </ControlLabel>
          </Col>
          <Col xs={9}>
            <DatePicker
              selected={props.startDate}
              selectsStart={true}
              startDate={props.startDate}
              endDate={props.endDate}
              onChange={props.handleChangeStart}
              className="form-control"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <ControlLabel> End </ControlLabel>
          </Col>
          <Col xs={9}>
            <DatePicker
              selected={props.endDate}
              selectsEnd={true}
              startDate={props.startDate}
              endDate={props.endDate}
              onChange={props.handleChangeEnd}
              className="form-control"
            />
          </Col>
        </Row>
      </FormGroup>
    </div>
  );
};

export default DateRangePicker;
