import * as React from "react";

import {
  Button,
  Col,
  Grid,
  Row,
  Breadcrumb,
  BreadcrumbItem,
} from "react-bootstrap";
import { printElement } from "./StudentReportUtil";
import {
  GFInitialState,
  GFStudent,
  GFSuperDiagnosticReportReducer,
  GFUser,
  GFClass,
} from "../../models/models";
import { RouteComponentProps, browserHistory, Link } from "react-router";

import {
  getSuperDiagnosticReportClass,
  getSuperDiagnosticReportStudent,
} from "../../actions/superDiagnosticActions";

import MainMenu from "../menu/MainMenu";
import { connect } from "react-redux";
import { loadClasses } from "../../actions/classActions";
import { loadStudents } from "../../actions/studentActions";
import { selectIsTeacher, selectIsSuperTeacher, selectHasGoogleClasses } from "../../reducers/userReducer";
import SuperDiagnosticReportStudent from "./SuperDiagnosticReportStudent";
import { filter, find } from "lodash";
import { initialStudent, initialClass } from "../../reducers/initialState";

const graham = require("../../images/graham.gif");

interface MatchParams {
  studentID: string;
  classID: string;
}

interface Props extends RouteComponentProps<MatchParams, {}> {
  user: GFUser;
  data: GFSuperDiagnosticReportReducer;
  students: GFStudent[];
  loading: boolean;
  loadStudents: () => Promise<any>;
  getSuperDiagnosticReportClass: typeof getSuperDiagnosticReportClass;
  getStudentQuizResult: any;
  hasGoogleClasses: boolean;
  isTeacher: boolean;
  isSuperTeacher: boolean;
  getSuperDiagnosticReportStudent: typeof getSuperDiagnosticReportStudent;
  selectedStudent: GFStudent;
  selectedClass: GFClass;
}

interface State {
  selectedRow: any;
}

class SuperDiagnosticClassReportLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRow: {},
    };
  }
  componentDidMount() {
    this.checkDependencies();
    this.updateReports(this.props.params.studentID);
  }

  /*
   * check for dependencies. once you get one, try again by recursively calling self
   */
  checkDependencies = () => {
    let dependencyPromises: Array<Promise<any>> = [];

    if (this.props.students.length === 0) {
      dependencyPromises = [...dependencyPromises, this.props.loadStudents()];
      if (dependencyPromises.length) {
        Promise.all(dependencyPromises)
          .then(() => {
            this.updateReports(this.props.params.studentID);
          })
          .catch((error) => {
            console.error("failed to get dependencies", error);
          });
      } else {
        this.props.loadStudents();
      }
    }
  };

  /*
   * updateReports
   */
  updateReports = (id: string) => {
    let result = this.props.students.filter((student) => student.userID === id);

    const path = `/super-diagnostic-report-student/${this.props.params.classID}/${id}`;
    browserHistory.replace(path);
    this.props.getSuperDiagnosticReportStudent(id);
  };

  doPrint = () => {
    this.setState({ selectedRow: {} }, () => {
      printElement(document.getElementsByClassName("students")[0], false, null);
      window.print();
    });
  };

  goBack = () => {
    browserHistory.push(
      `super-diagnostic-report-class/${this.props.params.classID}`
    );
  };

  changeStudentNext = (e: any) => {
    const currentStudent = this.props.students.findIndex(
      (student) => student.userID === this.props.params.studentID
    );
    if (currentStudent === this.props.students.length - 1) {
      this.updateReports(this.props.students[0].userID);
    } else {
      this.updateReports(this.props.students[currentStudent + 1].userID);
    }
  };

  changeStudentPrevious = (e: any) => {
    const currentStudent = this.props.students.findIndex(
      (student) => student.userID === this.props.params.studentID
    );
    if (currentStudent === 0) {
      this.updateReports(
        this.props.students[this.props.students.length - 1].userID
      );
    } else {
      this.updateReports(this.props.students[currentStudent - 1].userID);
    }
  };

  render() {
    const studentName = this.props.selectedStudent
      ? `${this.props.selectedStudent.student.first} ${this.props.selectedStudent.student.last}`
      : null;

    return (
      <Grid className="content modal-container">
        <div className="sidemenu">
          <MainMenu isTeacher={this.props.isTeacher} isSuperTeacher={this.props.isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
          <div className="logo text-center">
            <img alt="graham" src={graham} />
          </div>
        </div>
        <div className="students main-content content-with-sidebar">
          <Row className="students-header">
            <Col
              xs={8}
              className="text-xl-left gf-breadcrumb"
              style={{ marginBottom: "30px"}}
            >
              <Breadcrumb>
                <BreadcrumbItem>
                  <span onClick={this.goBack}>
                    {this.props.selectedClass.name} Diagnostic Progress Report
                  </span>
                </BreadcrumbItem>
                <BreadcrumbItem active={true}>{studentName}</BreadcrumbItem>
              </Breadcrumb>
            </Col>
            <Col xs={4}>
              <Button
                onClick={this.changeStudentPrevious}
                bsStyle="default"
                type="button"
                bsSize="sm"
                style={{ margin: "5px" }}
              >
                Previous
              </Button>
              <Button
                onClick={this.changeStudentNext}
                bsStyle="default"
                type="button"
                bsSize="sm"
                style={{ margin: "5px" }}
              >
                Next
              </Button>
              <Button
                onClick={this.doPrint}
                bsStyle="default"
                type="button"
                bsSize="sm"
                style={{ margin: "5px" }}
              >
                Print
              </Button>
            </Col>
          </Row>
          <Row className="class-table">
            <Col xs={8}>
              <SuperDiagnosticReportStudent params={this.props.params} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} style={{ marginTop: "20px" }}>
              <small>* suggested diagnostic topic</small>
            </Col>
          </Row>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: Props) => {
  const classStudents = filter(state.students, {
    classID: ownProps.params.classID,
  });
  const selectedStudent =
    find(state.students, { userID: ownProps.params.studentID }) ||
    initialStudent;
  const selectedClass =
    find(state.classes, { id: ownProps.params.classID }) || initialClass;
  return {
    data: state.superDiagnosticReport,
    students: classStudents,
    loading: state.ajaxCallsInProgress > 0,
    hasGoogleClasses: selectHasGoogleClasses(state),
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state),
    selectedStudent,
    selectedClass,
  };
};

export default connect(mapStateToProps, {
  getSuperDiagnosticReportStudent,
  loadStudents,
  loadClasses,
})(SuperDiagnosticClassReportLayout);
