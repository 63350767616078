import * as React from "react";

import {
  Button,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";

import { ISuperDiagnosticModalProps } from "./ConfirmDisableModalContainer";
import { toastr } from "react-redux-toastr";
import constants from "../../constants";

interface State {
  value: string
}

export class ConfirmDisableModal extends React.Component<ISuperDiagnosticModalProps, State> {
  constructor(props: ISuperDiagnosticModalProps) {
    super(props);
    this.state = {
      value: ''
    };
  }

  handleChange = (e: any) => {
    this.setState({ value: e.target.value });
  }

  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (this.state.value.trim() === this.props.info.name.trim() && this.props.info.superDiagnosticEnabled) {
      this.props.toggleSuperDiagnostic(this.props.info.id, false);
      this.props.cancel();
    } else {
      toastr.error(
        'Error',
        `Incorrect class name.`,
        constants.toastrError
      );
    }
  }

  render() {
    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <FormGroup
            controlId="formBasicText"
          >
            <ControlLabel style={{color: 'red'}}>
    Disabling diagnostic will delete any existing student answers and reports of the diagnostic for the following class section: <br /> <br /> <p><i>{this.props.info.name}</i></p>
            </ControlLabel>
            <br/>
            <br/>
            <ControlLabel>
              Confirm the name of the class in order to continue.
            </ControlLabel>
            <FormControl
              type="text"
              value={this.state.value}
              placeholder="Type name of class."
              onChange={this.handleChange}
            />
          </FormGroup>
          <div className="modal-footer">
        <Button onClick={this.props.cancel} bsStyle="default" type="button">
          Cancel
        </Button>

          <Button
            type="submit"
            bsStyle="primary"
            >
            Disable
          </Button>

            </div>
        </form>
      </div>
    );
  }
}
