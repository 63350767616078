import * as React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  FormGroup,
  Grid,
  Row
} from "react-bootstrap";
import {
  GFCourse,
  GFInitialState,
  GFLesson,
  GFUser,
  GFWritingAssignment,
  GFWritingAssignmentResult
} from "../../models/models";
import {
  getAllWAByLessonID,
  getWritingAssignmentResult,
  saveWritingAssignmentResult
} from "../../actions/waActions";
import {
  initialLesson,
  initialStudent,
  initialWritingAssignment
} from "../../reducers/initialState";

import RichTextEditor from "react-rte";
import VerificationAlert from "../common/VerificationAlert";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import constants from "../../constants";
import { toastr } from "react-redux-toastr";
import { selectIsStudent, selectIsTeacher } from "../../reducers/userReducer";

const mixpanel = require("mixpanel-browser");

const toolbarConfig: any = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ["INLINE_STYLE_BUTTONS", "HISTORY_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" }
  ]
};

interface Props extends React.Props<WritingAssignment> {
  user: GFUser;
  writingAssignment: GFWritingAssignment;
  loading: boolean;
  params: any;
  getAllWAByLessonID: typeof getAllWAByLessonID;
  lesson: GFLesson;
  courses: { [key: string]: GFCourse };
  lessons: { [key: string]: GFLesson };
  getWritingAssignmentResult: (waID: string) => Promise<any>;
  saveWritingAssignmentResult: (
    waID: string,
    studentText: string,
    resultID: string
  ) => Promise<any>;
  isStudent: boolean;
  isTeacher: boolean;
}

interface State {
  value: any;
  waResult: GFWritingAssignmentResult;
}

class WritingAssignment extends React.Component<Props, State> {
  private timeout: any;
  private savingWA: boolean;
  private savingWAtimeout: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      value: RichTextEditor.createEmptyValue(),
      waResult: {
        id: "",
        studentID: this.props.user.id || "",
        writingAssignmentID: this.props.writingAssignment.id,
        studentText: "",
        student: initialStudent.student
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.backToLesson = this.backToLesson.bind(this);
    this.backToCourse = this.backToCourse.bind(this);
    this.saveResult = this.saveResult.bind(this);

    this.savingWA = false;
  }

  componentDidMount() {
    if (!this.props.params.lessonID) {
      // no lesson id is not allowed
      browserHistory.replace(`/courses`);
    }
    document.addEventListener(
      "finishedGettingUpdatedData",
      this.handleUpdatedData
    );
    if (!this.props.writingAssignment.id && !this.props.params.waID) {
      // no wa id is not allowed
      browserHistory.replace(`/courses`);
    } else {
      this.loadWritingAssignment();
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.savingWAtimeout);
    document.removeEventListener(
      "finishedGettingUpdatedData",
      this.handleUpdatedData,
      false
    );
  }

  handleUpdatedData = () => {
    this.loadWritingAssignment();
  };

  loadWritingAssignment = () => {
    this.props.getAllWAByLessonID(this.props.params.lessonID);

    // only get results for students
    if (this.props.isStudent){
      // make sure we have the data that we need
      this.props
      .getWritingAssignmentResult(this.props.params.waID)
      .then((waResult: GFWritingAssignmentResult) => {
        if (!!waResult && waResult.studentText !== null) {
          this.setState({
            waResult,
            value: RichTextEditor.createValueFromString(
              waResult.studentText,
              "html"
              )
            });
          }
        })
        .catch(error => {
          console.error("error loading writing assignment", error);
        });
      }
  };

  handleChange(value: any) {
    this.setState({ value });
  }

  backToLesson() {
    window.scrollTo(0, 0);
    browserHistory.replace(
      `/lesson/${this.props.params.courseID}/${this.props.params.lessonID}`
    );
  }

  backToCourse() {
    browserHistory.replace(`/courses/${this.props.params.courseID}`);
  }

  saveResult() {
    // prevent doubl submission
    if (this.savingWA) {
      console.info("already saving WA, returning");
      return;
    }
    this.savingWA = true;
    this.timeout = setTimeout(() => {
      this.savingWA = false;
    }, 1000);

    if (this.props.isStudent) {
      this.props
        .saveWritingAssignmentResult(
          this.props.params.waID,
          this.state.value.toString("html"),
          this.state.waResult.id
        )
        .then(() => {
          window.scrollTo(0, 0);
          browserHistory.replace(
            `/lesson/${this.props.params.courseID}/${
              this.props.params.lessonID
            }`
          );
        });
    } else {
      // teacher pretend save a writing assignment
      toastr.warning(
        "Thank you",
        "Only students can save Writing Assignments!",
        constants.toastrSuccess
      );
      this.timeout = setTimeout(() => {
        this.backToLesson();
      }, 1500); // go back to the lesson after a second and a half
    }
    mixpanel.track("Finished Writing Assignment", {
      writingAssignmentID: this.props.writingAssignment.id,
      writingAssignmentName: this.props.writingAssignment.name
    });
  }

  render() {
    let courseName;
    if (this.props.lesson && this.props.lesson.id) {
      courseName = this.props.lesson.courseLessons[0].course.name;
    }
    return (
      <Grid className="content modal-container">
        <div className="main-content content-without-sidebar writing-assignment animated fadeIn">
          <VerificationAlert user={this.props.user} isTeacher={this.props.isTeacher} />
          <Row className="sub-header">
            <Col md={12} xs={12} className="gf-breadcrumb">
              <Breadcrumb>
                <BreadcrumbItem>
                  <span onClick={this.backToCourse}>{courseName}</span>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <span onClick={this.backToLesson}>
                    {this.props.lesson.name}
                  </span>
                </BreadcrumbItem>
                <BreadcrumbItem active={true}>
                  <span>{this.props.writingAssignment.name}</span>
                </BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
          <Row className="">
            <Col md={12}>
              <div
                className="teachers-instructions"
                dangerouslySetInnerHTML={{
                  __html: this.props.writingAssignment.description
                }}
              />
            </Col>
          </Row>
          <Row className="">
            <Col md={12}>
              <FormGroup>
                <RichTextEditor
                  toolbarConfig={toolbarConfig}
                  value={this.state.value}
                  onChange={this.handleChange}
                  placeholder="Write your brilliant responses here!"
                  autoFocus={false}
                  className="rich-text-editor"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button
                bsStyle="primary"
                disabled={this.props.loading}
                className="pull-right save-button"
                onClick={this.saveResult}
              >
                Save
              </Button>
            </Col>
          </Row>
          <Row className="">
            <Col md={12}>
              {this.state.waResult.isCorrect === false && (
                <h1 className="wrong-text">Check some of your work.</h1>
              )}
              {this.state.waResult.isCorrect === true && (
                <h1 className="right-text">Looking good!</h1>
              )}
              {this.state.waResult.teacherText && (
                <p className="">{this.state.waResult.teacherText}</p>
              )}
            </Col>
          </Row>
          {/*<Row className="">
            <Col md={8} mdOffset={2} sm={8} smOffset={2} xs={8} xsOffset={2}>
              {(this.state.waResult.isCorrect === false) && <h1 className="wrong-text">You got this WRONG!</h1>}
              {(this.state.waResult.isCorrect === true) && <h1 className="right-text">You got this RIGHT!</h1>}
              {(this.state.waResult.teacherText) && <p className="">{this.state.waResult.teacherText}</p>}
            </Col>
          </Row>*/}
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  const lesson = state.lessons[ownProps.params.lessonID] || initialLesson;
  const writingAssignment =
    state.writingAssignments[ownProps.params.waID] || initialWritingAssignment;
  return {
    user: state.user,
    writingAssignment,
    loading: state.ajaxCallsInProgress > 0,
    lesson,
    courses: state.courses,
    lessons: state.lessons,
    isStudent: selectIsStudent(state),
    isTeacher: selectIsTeacher(state)
  };
};

export default connect(
  mapStateToProps,
  {
    getAllWAByLessonID,
    getWritingAssignmentResult,
    saveWritingAssignmentResult
  }
)(WritingAssignment);
