import * as types from './actionTypes';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { GFUser, GFStripeCoupon, ThunkResult } from '../models/models';
import constants from '../constants';
import MixPanelUtil from '../api/mixpanel';
import { TrackJS } from 'trackjs';
import { toastr } from 'react-redux-toastr';
import { API } from '../apiEndpoints';
import { selectIsTeacher } from '../reducers/userReducer';
import Axios, { AxiosRequestConfig } from 'axios';
const mixpanel = require('mixpanel-browser');

export function paySuccess(user: GFUser) {
  return { type: types.PAY_SUCCESS, user };
}
export function payFailed(error: any) {
  return { type: types.PAY_FAILED, error };
}

export function paymentSignup(
  paymentPlan: string,
  paymentToken: any,
  stripeCoupon: string
): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.POST.payment.signup;
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      data: {
        paymentPlan,
        paymentToken: paymentToken.id,
        stripeCoupon
      },
      url
    };
    return Axios(axiosOptions)
      .then(resp => {
        if (!resp.data) {
          throw undefined;
        } else {
          dispatch(paySuccess(resp.data));
          document.dispatchEvent(new CustomEvent('paymentSucccess'));
          toastr.success(
            'Success',
            `Thank you for choosing GrammarFlip!`,
            constants.toastrSuccess
          );
          return resp.data;
        }
      })
      .catch(error => {
        // error handled in component
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function directPaymentSignup(
  paymentPlan: string,
  paymentToken: any,
  stripeCoupon: string,
  user: any,
): ThunkResult<any> {
  return (dispatch, getState) => {
    const isTeacher = selectIsTeacher(getState());
    dispatch(beginAjaxCall());
    const url = API.POST.payment.directSignup;
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      data: {
        paymentPlan,
        paymentToken: paymentToken.id,
        stripeCoupon,
        email: user.email,
        first: user.first, 
        last: user.last,
        password: user.password,
        roleID: user.roleID,
        username: user.username,
        gender: user.gender,
        howDidYouHearAboutUs: user.howDidYouHearAboutUs,
        hearOther: user.hearOther,
        UTMSource: user.utm_source,
        UTMMedium: user.utm_medium,
        UTMCampaign: user.utm_campaign,
        UTMContent: user.utm_content,
        UTMTerm: user.utm_term,
        GCLID: user.gclid,
      },
      url
    };
    return Axios(axiosOptions)
      .then(resp => {
        if (!resp.data) {
          throw undefined;
        } else {
          dispatch(paySuccess(resp.data));
          document.dispatchEvent(new CustomEvent('paymentSucccess'));
          toastr.success(
            'Success',
            `Thank you for choosing GrammarFlip!`,
            constants.toastrSuccess
          );

          // log user in
          const user = resp.data;
          dispatch({ type: types.TEACHER_REGISTER_SUCCESS, user });
          document.dispatchEvent(new CustomEvent('registerSucccess'));
          MixPanelUtil.identify(user, isTeacher);
          TrackJS.configure({
            userId: user.email,
            version: `${process.env.REACT_APP_VERSION}`
          });
          Axios.defaults.headers.common['apikey'] = user.apiKey;
          mixpanel.track('Teacher Signed Up', { TeacherRole: undefined });

          return resp.data;
        }
      })
      .catch(error => {
        // error handled in component
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

/* 
* Update Subscription
*/
export function updateSubscription(paymentPlan: string): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.POST.payment.updateSubscription;
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      data: { paymentPlan },
      url
    };
    return Axios(axiosOptions)
      .then(resp => {
        if (!resp.data) {
          throw undefined;
        } else {
          dispatch(paySuccess(resp.data));
          document.dispatchEvent(new CustomEvent('paymentSucccess'));
          toastr.success(
            'Success',
            `Thank you for choosing GrammarFlip!`,
            constants.toastrSuccess
          );
          return resp.data;
        }
      })
      .catch(error => {
        console.error('Error updating subscription', error);
        dispatch(payFailed(error));
        let message = '';
        if (error && error.response) {
          if (error.response.status === 412) {
            message = constants.friendlyErrors.verifyRequired;
          }
          if (error.response.status === 433) {
            // this is an error we thew in the client app
            message = error.message;
          }
        }

        if (message.length) {
          toastr.error('Error', message, constants.toastrError);
        } else {
          constants.handleError(error, 'signup');
        }
        throw error;
      });
  };
}

/* 
* Cancel Subscription
*/
export function cancelSubscription(): ThunkResult<any> {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url: API.POST.payment.cancelSubscription
    }
    return Axios(axiosOptions)
      .then((resp) => {
        if (!resp) {
          throw undefined;
        } else {
          dispatch(paySuccess(resp.data));
          return resp.data;
        }
      })
      .catch(error => {
        // error handled in component
        dispatch(payFailed(error));
        throw error;
      });
  };
}

/* 
* Coupon Code Check
*/
export function couponCheck(couponCode: string): ThunkResult<any> {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    const url = API.POST.payment.checkCoupon + couponCode
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url: url
    }
    return Axios(axiosOptions)
      .then((resp) => {
        if (!resp) {
          throw undefined;
        } else {
          const data: GFStripeCoupon = resp.data
          dispatch({ type: types.CHECK_COUPON_SUCCESS, data });
          return data;
        }
      })
      .catch(error => {
        // error handled in component
        dispatch({ type: types.CHECK_COUPON_FAILED, error });
        throw error;
      });
  };
}

/* 
* Update Credit Card
*/
export function updateCard(paymentToken: string): ThunkResult<any> {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url: API.POST.payment.updateCard,
      data: {paymentToken}
    }
    return Axios(axiosOptions)
      .then((resp) => {
        if (!resp) {
          throw undefined;
        } else {
          dispatch(paySuccess(resp.data));
          return resp.data;
        }
      })
      .catch(error => {
        // error handled in component
        dispatch(payFailed(error));
        throw error;
      });
  };
}
