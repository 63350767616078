import * as moment from 'moment';
import * as types from './actionTypes';

import Axios, { AxiosRequestConfig } from 'axios';
import { GFStudentUser, GFUser, ThunkResult } from '../models/models';
import { omit, sortBy } from 'lodash';

import { API } from '../apiEndpoints';
import { beginAjaxCall } from './ajaxStatusActions';
import { canStudentTakeSuperDiagnostic } from './superDiagnosticActions';
import constants from '../constants';
import { toastr } from "react-redux-toastr";

// export function loadStudentsSuccess(students: GFUser[]) {
//   return { type: types.LOAD_STUDENTS_SUCCESS, students };
// }

// export function loadStudentsFailed(error: any) {
//   return { type: types.LOAD_STUDENTS_FAILED, error };
// }

// export function updateStudentSuccess(gfUser: GFUser) {
//   return { type: types.UPDATE_STUDENT_SUCCESS, gfUser };
// }
// export function updateStudentFailed(gfStudent: GFStudent) {
//   return { type: types.UPDATE_STUDENT_FAILED, gfStudent };
// }
// export function deleteClassSuccess(studentID: string) {
//   return { type: types.DELETE_STUDENT_CLASS_SUCCESS, studentID };
// }
// export function deleteClassFailed(error: any) {
//   return { type: types.DELETE_STUDENT_CLASS_FAILED, error };
// }

export function loadStudents(): ThunkResult<any> {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url: API.student.getAll
    }
    return Axios(axiosOptions)
      .then((resp) => {
        dispatch({ type: types.LOAD_STUDENTS_SUCCESS, students: resp.data });
        return resp.data;
      })
      .catch(error => {
        dispatch({ type: types.LOAD_STUDENTS_FAILED, error });
        const message = 'download list of students'
        console.error(message, error);
        constants.handleError(error, message);
        throw error;
      });
  };
}

/*
* Teacher updating the student's info
*/
export function updateStudent( student: GFStudentUser): ThunkResult<any> {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    const cleanedStudent = omit(student,['price'] )
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url: API.POST.user.save,
      data: {...cleanedStudent, email: cleanedStudent.username?.trim(), username: cleanedStudent.username?.trim()}
    }
    return Axios(axiosOptions)
      .then((resp) => {
        if (!resp) {
          throw undefined;
        } else {
          dispatch({ type: types.UPDATE_STUDENT_SUCCESS, payload: resp.data });
          toastr.success(
            "Success",
            "Student updated successfully.",
            constants.toastrSuccess
          );
        }
      })
      .catch(error => {
        dispatch({ type: types.UPDATE_STUDENT_FAILED });
        let message = "";
        if (error.response && error.response.status === 409){
          message = 'This email or username is already in use.';
        }
        if (message.length){
          console.error(error, message);
          toastr.error('Error', message, constants.toastrError)
        } else {
          message = "update student"
          console.error(message, error);
          constants.handleError(error, message);
        }
      });
  };
}

/*
*  Teacher updating the student's password
*/
export function updateStudentPW(
  student: GFStudentUser,
  password: string
): ThunkResult<any> {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url: API.POST.user.updateStudentPassword,
      data: { id: student.id, password }
    }
    return Axios(axiosOptions)
      .then((resp) => {
        if (!resp) {
          throw undefined;
        } else {
          dispatch({ type: types.USER_UPDATEPW_SUCCESS });
          toastr.success("Success", "Password updated.", constants.toastrSuccess);
        }
      })
      .catch(error => {
        dispatch({ type: types.USER_UPDATEPW_FAILED, error, axiosOptions });
        const message = "update student";
        console.error(message, error);
        constants.handleError(error, message);
      });
  };
}

/*
* Student adding a class
*/
export function addStudentClass(
  studentID: string,
  classID: string
): ThunkResult<any> {
  const body = {
    Class: { Code: classID },
    UserID: studentID
  }
  const axiosOptions: AxiosRequestConfig = {
    method: 'post',
    data: body,
    url: API.student.addClass
  }
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    return Axios(axiosOptions)
      .then((resp) => {
        if (!resp) {
          throw resp;
        } else {
          const data = resp.data
          dispatch({ type: types.ADD_STUDENT_CLASS_SUCCESS, data });
          setTimeout(() => {
            dispatch(canStudentTakeSuperDiagnostic())
          }, 1000);
          return data;
        }
      })
      .catch(error => {
        // error handled in component
        dispatch({ type: types.ADD_STUDENT_CLASS_FAILED, error, axiosOptions });
        throw error;
      });
  };
}
/*
* Teacher removing a student from their class
*/
export function teacherDeleteStudentClass(
  studentID: string,
  classID: string,
): ThunkResult<any>  {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url: API.student.deleteClass,
      data: { userID: studentID, classID }
    }
    return Axios(axiosOptions)
      .then((resp) => {
        if (!resp) {
          throw resp;
        } else {
          dispatch({ type: types.DELETE_STUDENT_CLASS_SUCCESS, studentID });
          return resp.data;
        }
      })
      .catch(error => {
        // error handled in component
        dispatch({ type: types.DELETE_STUDENT_CLASS_FAILED, error });
        throw error;
      });
  };
}

/*
* Student removing a class
*/
export function removeStudentClass(
  studentID: string,
  classID: string,
  user: GFUser
): ThunkResult<any>  {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url: API.student.deleteClass,
      data: { userID: studentID, classID }
    }
    return Axios(axiosOptions)
      .then((resp) => {
        if (!resp) {
          throw resp;
        } else {
          const data = resp.data
          dispatch({ type: types.REMOVE_STUDENT_CLASS_SUCCESS, data, classID });
          setTimeout(() => {
            dispatch(canStudentTakeSuperDiagnostic())
          }, 1000);
          return data;
        }
      })
      .catch(error => {
        const message = "removing a student from a class";
        console.error(message, error);
        constants.handleError(error, message);
        dispatch({ type: types.REMOVE_STUDENT_CLASS_FAILED, error });
        throw error;
      });
  };
}

export function getBadges(): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.student.getEarnedBadges;
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url
    };
    return Axios(axiosOptions)
      .then(resp => {
        // map to inner badge object since we don't need the extra scruff
        const sortedBadges = sortBy(resp.data, (badge: any) => {
          return moment.utc(badge.createDate).valueOf();
        });
        const badges = sortedBadges.map((badge: any) => badge.badge);
        dispatch({ type: types.LOAD_BADGES_SUCCESS, badges });
        return badges;
      })
      .catch(error => {
        dispatch({ type: types.LOAD_BADGES_FAILED });
        console.error('Error getting badges', error);
        constants.handleError(error, 'get badges');
      });
  };
}