import * as React from "react";

import { GFInitialState, GFPlan, GFUser } from "../../models/models";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import {
  couponCheck,
  paymentSignup,
  updateSubscription,
} from "../../actions/paymentActions";
import {
  manualAjaxEnd,
  manualAjaxStart,
} from "../../actions/ajaxStatusActions";

import { Modal } from "react-bootstrap";
import PaymentSection from "./PaymentSection";
import { connect } from "react-redux";
import constants from "../../constants";
import { toastr } from "react-redux-toastr";

interface Props {
  selectedPlan: GFPlan;
  stripe: Stripe;
  show: boolean;
  hide: () => void;
  elements: StripeElements;
  togglePlanRecurringType: () => void;
  manualAjaxStart: typeof manualAjaxStart;
  manualAjaxEnd: typeof manualAjaxEnd;
}
interface DispatchProps {
  user: GFUser;
  couponCheck: (couponCode: string) => Promise<void>;
  loading: boolean;
  paymentSignup: (
    paymentPlan: string,
    paymentToken: any,
    stripeCoupon: string
  ) => Promise<any>;
  updateSubscription: (paymentPlan: string) => Promise<void>;
}

interface State {
  stripeCouponDetails?: any;
  stripeCoupon: string;
}

class CheckoutModalClass extends React.Component<Props & DispatchProps, State> {
  constructor(props: Props & DispatchProps) {
    super(props);
    this.state = {
      stripeCoupon: "",
    };
  }

  calculateAmount = (): string => {
    let amount = 0;
    let discount = 1;
    let displayAmount = "";
    if (this.state.stripeCouponDetails) {
      discount = 100 - this.state.stripeCouponDetails.percent_off;
      amount = this.props.selectedPlan.price * (discount / 100);
      displayAmount = (amount / 100).toFixed(2);
    } else {
      displayAmount = (this.props.selectedPlan.price / 100).toFixed(2);
    }
    if (
      this.props.selectedPlan.id === this.props.user.paymentPlan &&
      !this.props.user.isCancelling
    ) {
      return `Paying $${displayAmount}/${this.props.selectedPlan.time}`;
    } else {
      return `Pay $${displayAmount}/${this.props.selectedPlan.time}`;
    }
  };

  checkStripeCoupon = (e: any) => {
    e.preventDefault();
    this.props
      .couponCheck(this.state.stripeCoupon)
      .then((coupon) => {
        this.setState({ stripeCouponDetails: coupon });
      })
      .catch((error) => {
        console.error("Unable to apply coupon", error);
        let message = '';
        if (error.response && error.response.status === 404) {
          message = "We were not able to find a coupon with that code.";
        }
        if (message.length){
          toastr.error("Error", message, constants.toastrError);
        } else {
          constants.handleError(error, 'apply coupon')
        }
      });
  };

  handleChange = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    const targetName = e.currentTarget.name;
    this.setState({ [targetName]: e.currentTarget.value } as State);
  };

  render() {
    const displayAmount = this.calculateAmount();

    return (
      <Modal show={this.props.show} onHide={this.props.hide}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Manage Payment</Modal.Title>
        </Modal.Header>
        <PaymentSection
          {...this.props}
          displayAmount={displayAmount}
          stripeCoupon={this.state.stripeCoupon}
          stripeCouponDetails={this.state.stripeCouponDetails}
          couponChange={this.handleChange}
          checkStripeCoupon={this.checkStripeCoupon}
          togglePlanRecurringType={this.props.togglePlanRecurringType}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  return {
    user: state.user,
    loading: state.ajaxCallsInProgress > 0,
  };
};

const CheckoutModal = connect(mapStateToProps, {
  couponCheck,
  updateSubscription,
  paymentSignup,
  manualAjaxEnd,
  manualAjaxStart,
})(CheckoutModalClass);
export default CheckoutModal;
