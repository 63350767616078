import * as types from "../actions/actionTypes";
import { keyBy } from "lodash";
import {
  GFInitialState,
  GFcanTakeSuperDiagnosticReducer,
  GFQuizItem,
  GFSuperDiagnosticViewReducer,
  GFQuizAnswer,
  GFSuperDiagnosticTopicsById,
} from "../models/models";
import initialState from "./initialState";
import { combineReducers } from "redux";
import { createSelectedIDWithName } from "./commonReducers";
import { cleanQuizObject } from "./quizzesReducer";

export function topicsReducer(
  state: GFSuperDiagnosticTopicsById = {},
  action: any
) {
  switch (action.type) {
    case types.GET_SUPER_DIAGNOSTIC_TOPICS_SUCCESS:
      return keyBy(action.topics, "id");
    case types.USER_LOGOUT_SUCCESS:
      return {};
    default:
      return state;
  }
}

export function customDiagnosticReducer(
  state: Partial<GFInitialState> = {},
  action: any
) {
  switch (action.type) {
    case types.GET_SUPER_DIAGNOSTIC_CUSTOM_DIAGNOSTICS_SUCCESS:
      if (action.customDiagnostics) {
        return keyBy(action.customDiagnostics, "id");
      } else {
        return state;
      }
    case types.UPDATE_CUSTOM_DIAGNOSTICS:{
      if (action.payload) {
        return keyBy(action.payload, "id");
      } else {
        return state;
      }
    }
    case types.UPDATE_CUSTOM_DIAGNOSTICS_FAILED:{
      if (action.payload){
        return action.payload;
      } else {
        return state;
      }
    }
    case types.USER_LOGOUT_SUCCESS:
      return {};

    default:
      return state;
  }
}

export function superDiagnosticReducer(
  state: GFQuizItem = initialState.superDiagnosticQuiz,
  action: any
): GFQuizItem {
  switch (action.type) {
    case types.GET_SUPER_DIAGNOSTIC_QUIZ_SUCCESS:
      return cleanQuizObject(action.superDiagnosticQuiz);

    case types.GET_SUPER_DIAGNOSTIC_QUIZ_FAILED:
      return state;

    case types.USER_LOGOUT_SUCCESS:
      return initialState.superDiagnosticQuiz;
    default:
      return state;
  }
}

export function canTakeSuperDiagnosticReducer(
  state: GFcanTakeSuperDiagnosticReducer = initialState.superDiagnosticView
    .canTakeSuperDiagnostic,
  action: any
): GFcanTakeSuperDiagnosticReducer {
  switch (action.type) {
    case types.LOAD_CAN_STUDENT_TAKE_SUPER_DIAGNOSTIC_SUCCESS:
      return action.data;
    case types.SAVE_SUPER_DIAGNOSTIC_ANSWERS_SUCCESS:
      return false;
    case types.USER_LOGOUT_SUCCESS:
      return initialState.superDiagnosticView.canTakeSuperDiagnostic;
    default:
      return state;
  }
}

/*
 * stores the index of the questions for the last successfully submitted answer
 */
const superDiagnosticAnswersSubmittedReducer = (
  state: number = initialState.superDiagnosticView.quizAnswersSubmitted,
  action: any
): number => {
  switch (action.type) {
    case types.SAVE_SUPER_DIAGNOSTIC_ANSWERS_SUCCESS:
      return action.questionIndex + 1;
    case types.SAVE_SUPER_DIAGNOSTIC_TEACHER:
      return action.questionIndex + 1;
    case types.AUTO_SAVE_SUPER_DIAGNOSTIC_ANSWERS_SUCCESS:
      return action.questionIndex + 1;
    case types.RESET_SUPER_DIAGNOSTIC_ANSWERS:
      return 0;
      case types.GET_STUDENT_SUPER_DIAGNOSTIC_RESULT_SUCCESS:
        if (action.data && action.data.answers){
          return action.data.answers.length;
        } else {return state;}
    case types.USER_LOGOUT_SUCCESS:
      return initialState.superDiagnosticView.quizAnswersSubmitted;
    default:
      return state;
  }
};

const superDiagnosticQuizAnswersReducer = (
  state: GFQuizAnswer[] = initialState.superDiagnosticView.quizAnswers,
  action: any
) => {
  switch (action.type) {
    case types.SUPER_DIAGNOSTIC_ADD_ANSWER:
      return [...state, action.answer];
    case types.SUPER_DIAGNOSTIC_RESET_ANSWERS:
      return initialState.superDiagnosticView.quizAnswers;
    case types.RESET_SUPER_DIAGNOSTIC_ANSWERS:
      return initialState.superDiagnosticView.quizAnswers;
    case types.SAVE_SUPER_DIAGNOSTIC_ANSWERS_SUCCESS:
      return initialState.superDiagnosticView.quizAnswers;
    case types.GET_STUDENT_SUPER_DIAGNOSTIC_RESULT_SUCCESS:
      if (action.data && action.data.answers){
        return action.data.answers;
      } else {
        return state;
      }
    case types.USER_LOGOUT_SUCCESS:
      return initialState.superDiagnosticView.quizAnswers;

    default:
      return state;
  }
};

export const superDiagnosticViewReducer = combineReducers<
  GFSuperDiagnosticViewReducer
>({
  quizAnswersSubmitted: superDiagnosticAnswersSubmittedReducer,
  inProgressQuizID: createSelectedIDWithName("IN_PROGRESS_QUIZ"),
  quizAnswers: superDiagnosticQuizAnswersReducer,
  canTakeSuperDiagnostic: canTakeSuperDiagnosticReducer,
});
