import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { GFQuizViewReducer, GFQuizAnswer } from '../models/models';
import { createSelectedIDWithName } from './commonReducers';
import { combineReducers } from 'redux';

const quizAnswersSubmittedReducer = (state: boolean = initialState.quizView.quizAnswersSubmitted, action: any): boolean => {
  switch (action.type) {
    case types.SAVE_QUIZ_ANSWERS_SUCCESS:
      return true;
    case types.SAVE_QUIZ_TEACHER:
      return true;
    case types.RESET_ANSWERS:
      return false;
    case types.USER_LOGOUT_SUCCESS:
      return initialState.quizView.quizAnswersSubmitted;
    default:
      return state;
  }
};

const quizAnswersReducer = (
  state: GFQuizAnswer[] = initialState.quizView.quizAnswers,
  action: any
) => {
  switch (action.type) {
    case types.ADD_ANSWER:
      return [...state, action.answer];
    case types.RESET_ANSWERS:
      return initialState.quizView.quizAnswers;
    case types.USER_LOGOUT_SUCCESS:
      return initialState.quizView.quizAnswers;

    default:
      return state;
  }
}

export const quizViewReducer = combineReducers<GFQuizViewReducer>(
 {
    quizAnswersSubmitted: quizAnswersSubmittedReducer,
    inProgressQuizID: createSelectedIDWithName(
      'IN_PROGRESS_QUIZ'
    ),
    quizAnswers: quizAnswersReducer
}
)
