import * as types from './actionTypes';
import { Option, GFAppSettings } from '../models/models';

export const setSelectedClassID = (classID: Option) => ({
  type: types.SET_SELECTED_CLASS_ID,
  classID: classID ? classID.value : ''
});

export const clearSelectedClassID = () => ({
  type: types.CLEAR_SELECTED_CLASS_ID
});
export const updateAppSettings = (settings: GFAppSettings) => ({
  type: types.UPDATE_APP_SETTINGS,
  payload: settings
});
