/*
 *  Student Register Form
 */
import * as React from "react";

import {
  Button,
  Checkbox,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Row,
} from "react-bootstrap";
import { validateField, validateForm } from "../common/UtilValidation";

import constants from "../../constants";
import { registerTypeEnum } from "./Register";
import { socialServiceEnum } from "../../enums";
import { toastr } from "react-redux-toastr";
import { userDecodeSocialCode, studentCreate } from "../../actions/userActions";
import { isEmpty } from "lodash";
const graham = require("../../images/registerGraham.png");
const FontAwesome = require("react-fontawesome");

interface Props extends React.Props<StudentRegisterForm> {
  handleSubmit: typeof studentCreate;
  manualAjaxEnd: any;
  manualAjaxStart: any;
  setSocialObj: any;
  goBack: any;
  loading: boolean;
  socialObj: any;
  classcode: string;
  userDecodeSocialCode: typeof userDecodeSocialCode;
  googleCode?: string;
}

interface State {
  gender: string;
  first: string;
  last: string;
  email: string;
  username: string;
  classCode: string;
  password: string;
  password2: string;
  role: string;
  roleID: string;
  under13: any;
  token: string;
  formValidations: any;
  formValid: boolean;
  social?: socialServiceEnum;
}

class StudentRegisterForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      gender: "O",
      first: "",
      last: "",
      email: "",
      username: "",
      classCode: "",
      password: "",
      password2: "",
      role: "",
      roleID: constants.UserRoleIDs.Teacher,
      under13: false,
      token: "",
      formValidations: {
        first: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [{ required: true, message: "First Name is required" }],
        },
        last: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [{ required: true, message: "Last Name is required" }],
        },
        email: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [
            { required: true, message: "Email or Username is required" },
          ],
        },
        password: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [
            { required: true, message: "Password is required" },
            {
              pattern: constants.passwordRegex,
              message: constants.passwordValidationMessage,
            },
          ],
        },
        password2: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [
            { required: true, message: "Confirm Password is required" },
            {
              pattern: constants.passwordRegex,
              message: constants.passwordValidationMessage,
            },
            { mustMatch: "password", message: "passwords have to match" },
          ],
        },
        classCode: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [
            { required: true, message: "Class Code is required" },
            {
              pattern: "^[A-Za-z0-9-]{8}$",
              message: <div style={{padding: '4px'}}>GrammarFlip class codes must be 8 characters (letters and numbers only). If you have a 7 digit Google Classroom code, <a href='/'>click here</a> to go back to the login page and then click Login with Google.</div>,
            },
          ],
        },
      },
      formValid: false,
    };
  }
  componentDidMount() {
    // validate all the form fields but do not show errors
    this.validateAllFields(false);
    if (this.props.classcode.length) {
      this.setState({ classCode: this.props.classcode }, () => {
        const fieldV = validateField(
          "classCode",
          this.state.classCode,
          true,
          this.state.formValidations
        );
        this.setState({ formValidations: fieldV });
      });
    }
    if (!!this.props.googleCode) {
      this.props.userDecodeSocialCode(
        this.props.googleCode,
        constants.buildGoogleLoginLink(`/register?type=${registerTypeEnum.student}&state=${this.props.classcode}`),
        registerTypeEnum.student
      );
    }
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.socialObj !== this.props.socialObj) {
      this.setStateFromSocialServiceParams();
    }
  }

  // validate all fields and conditionally show errors
  validateAllFields = (showErrors: boolean) => {
    validateField(
      "email",
      this.state.email,
      showErrors,
      this.state.formValidations
    );
    validateField(
      "password",
      this.state.password,
      showErrors,
      this.state.formValidations
    );
    validateField(
      "password2",
      this.state.password2,
      showErrors,
      this.state.formValidations,
      this.state.password
    );
    validateField(
      "first",
      this.state.first,
      showErrors,
      this.state.formValidations
    );
    validateField(
      "last",
      this.state.last,
      showErrors,
      this.state.formValidations
    );
    validateField(
      "classCode",
      this.state.classCode,
      showErrors,
      this.state.formValidations
    );
  };

  handleChange = (e: React.FormEvent<FormControl & HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value =
      e.currentTarget.type === "checkbox"
        ? e.currentTarget.checked
        : e.currentTarget.value;
    const fv = validateField(
      name,
      value,
      true,
      this.state.formValidations,
      this.state.password
    );
    const vf = validateForm(this.state.formValidations);

    this.setState({
      [name]: value,
      formValidations: fv,
      formValid: vf,
    } as State);
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // check if registering in with Google
    if (isEmpty(this.props.socialObj) === false) {
      // registering with google
      // validateField(
      //   "first",
      //   this.state.first,
      //   true,
      //   this.state.formValidations
      // );
      // validateField("last", this.state.last, true, this.state.formValidations);
      // validateField(
      //   "classCode",
      //   this.state.classCode,
      //   true,
      //   this.state.formValidations
      // );
      // this.forceUpdate();
      // if (
      //   this.state.formValidations.classCode.valid !== true ||
      //   this.state.formValidations.first.valid !== true ||
      //   this.state.formValidations.last.valid !== true
      // ) {
      //   toastr.error(
      //     "Error",
      //     `Please check your form entries and try again.`,
      //     constants.toastrError
      //   );
      //   this.forceUpdate(); // needed because if the user clicks submit whithout touching some fields, then the inlide validation does not show as expected
      //   return;
      // }
    } else {
      if (!this.state.formValid) {
        toastr.error(
          "Error",
          `Please check your form entries and try again.`,
          constants.toastrError
        );
        // validate and show errors
        this.validateAllFields(true);
        this.forceUpdate(); // needed because if the user clicks submit whithout touching some fields, then the inlide validation does not show as expected
        return;
      }
    }
    this.props.handleSubmit(this.state);
  };
  reqGoogleLogin = () => {
    this.props.manualAjaxStart();
    console.log("trying to register with google");
  };

  responseGoogle = (resp: any) => {
    this.props.manualAjaxEnd();
    if (!!resp.error) {
      console.error("Error with Google Authentication", resp);
      let message =
        "Unable to register with Google.  Please try again or contact support.";
      if (resp.details) {
        message = resp.details;
      }
      if (resp.error !== "idpiframe_initialization_failed") {
        toastr.error("Error", message, constants.toastrError);
      }
    } else {
      console.log("successfully registered with Google");
      // fill in the form info using google profile
      this.props.setSocialObj(resp);
      this.setStateFromSocialServiceParams(socialServiceEnum.google);
    }
  };

  setStateFromSocialServiceParams = (
    service: socialServiceEnum = socialServiceEnum.google
  ) => {
    this.setState({
      first: this.props.socialObj.first,
      last: this.props.socialObj.last,
      email: this.props.socialObj.email,
      social: service,
    });
  };
  render() {
    return (
      <div className="student-register">
        <h3 className="text-center"> Student Registration Form </h3>
        <form id="registerForm" onSubmit={this.handleSubmit}>
          <Row>
            <Col lg={12}>
              <FormGroup bsSize="lg">
                <Checkbox
                  onChange={this.handleChange}
                  checked={this.state.under13}
                  id="under13"
                  name="under13"
                >
                  Under 13 or don't have an email address?
                </Checkbox>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="google">
              {/* <FormGroup bsSize="lg"> */}
                {/* <GoogleLogin
                  clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                  buttonText="Register with Google"
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogle}
                  onRequest={this.reqGoogleLogin}
                  icon={true}
                  className="google-login"
                /> */}
                {(isEmpty(this.props.socialObj) || (this.props.socialObj.hasOwnProperty('id') && isEmpty(this.props.socialObj.id))) && <Button
                  bsStyle="default"
                  type="button"
                  href={constants.buildGoogleLoginLink(
                    `/register?type=${registerTypeEnum.student}&state=${this.props.classcode}`
                  )}
                  className="google-login"
                  disabled={this.props.loading}
                >
                  <FontAwesome name="google" /> &nbsp; Register With Google
                </Button>}
              {/* </FormGroup> */}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup
                controlId="first"
                bsSize="lg"
                validationState={
                  this.state.formValidations.first.validationState
                }
              >
                <ControlLabel>First Name</ControlLabel>
                <FormControl
                  type="text"
                  name="first"
                  placeholder="First Name"
                  value={this.state.first}
                  onChange={this.handleChange}
                />
                <FormControl.Feedback />
                <HelpBlock>
                  {this.state.formValidations.first.validationMessage}
                </HelpBlock>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup
                controlId="last"
                bsSize="lg"
                validationState={
                  this.state.formValidations.last.validationState
                }
              >
                <ControlLabel>Last Name</ControlLabel>
                <FormControl
                  type="text"
                  name="last"
                  placeholder="Last Name"
                  value={this.state.last}
                  onChange={this.handleChange}
                />
                {this.state.under13 && (
                  <HelpBlock>
                    Enter the first 2 letters of your last name.
                  </HelpBlock>
                )}
                <FormControl.Feedback />
                <HelpBlock>
                  {this.state.formValidations.last.validationMessage}
                </HelpBlock>
              </FormGroup>
            </Col>
          </Row>
          {!this.state.social && (
            <span>
              {!this.state.under13 && (
                <span>
                  <Row>
                    <Col lg={12}>
                      <FormGroup
                        controlId="userEmail"
                        bsSize="lg"
                        validationState={
                          this.state.formValidations.email.validationState
                        }
                      >
                        <ControlLabel>Email</ControlLabel>
                        <FormControl
                          type="text"
                          name="email"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={this.handleChange}
                        />
                        <FormControl.Feedback />
                        <HelpBlock>
                          {this.state.formValidations.email.validationMessage}
                        </HelpBlock>
                      </FormGroup>
                    </Col>
                  </Row>
                </span>
              )}
              {this.state.under13 && (
                <Row>
                  <Col md={12}>
                    <FormGroup
                      controlId="username"
                      bsSize="lg"
                      validationState={
                        this.state.formValidations.email.validationState
                      }
                    >
                      <ControlLabel>Username</ControlLabel>
                      <FormControl
                        type="text"
                        name="email"
                        placeholder="Username"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      <FormControl.Feedback />
                      <HelpBlock>
                        {this.state.formValidations.email.validationMessage}
                      </HelpBlock>
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={6}>
                  <FormGroup
                    controlId="password"
                    bsSize="lg"
                    validationState={
                      this.state.formValidations.password.validationState
                    }
                  >
                    <ControlLabel>Password</ControlLabel>
                    <FormControl
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                    <FormControl.Feedback />
                    <HelpBlock>
                      {this.state.formValidations.password.validationMessage}
                    </HelpBlock>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    controlId="passwordConfirmation"
                    bsSize="lg"
                    validationState={
                      this.state.formValidations.password2.validationState
                    }
                  >
                    <ControlLabel>Password Confirmation</ControlLabel>
                    <FormControl
                      type="password"
                      name="password2"
                      placeholder="Confirm Password"
                      value={this.state.password2}
                      onChange={this.handleChange}
                    />
                    <FormControl.Feedback />
                    <HelpBlock>
                      {this.state.formValidations.password2.validationMessage}
                    </HelpBlock>
                  </FormGroup>
                </Col>
              </Row>
            </span>
          )}
          <Row>
            <Col lg={12}>
              <FormGroup
                controlId="classCode"
                bsSize="lg"
                validationState={
                  this.state.formValidations.classCode.validationState
                }
              >
                <ControlLabel>Class Code</ControlLabel>
                <FormControl
                  type="text"
                  name="classCode"
                  placeholder="Class Code"
                  value={this.state.classCode}
                  onChange={this.handleChange}
                />
                <FormControl.Feedback />
                <HelpBlock>
                  {this.state.formValidations.classCode.validationMessage}
                </HelpBlock>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormGroup>
                <p className="register-terms">
                  <small>
                    By clicking <b>Be a Student</b> below you agree to the
                    GrammarFlip{" "}
                    <a
                      href="https://www.grammarflip.com/privacy-policy-terms-use/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy and Terms of Use
                    </a>
                    .
                  </small>
                </p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Button bsStyle="default" onClick={this.props.goBack}>
                  back to login
                </Button>
                <Button
                  disabled={this.props.loading}
                  id="btnRegister"
                  bsStyle="primary"
                  className="pull-right"
                  type="submit"
                >
                  be a student
                </Button>
              </FormGroup>
              <br />
              <br />
              <br />
            </Col>
          </Row>
        </form>
        <div className="login-graham student">
          <img alt="graham" src={graham} />
        </div>
      </div>
    );
  }
}

export default StudentRegisterForm;
