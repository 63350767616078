import * as React from "react";
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
} from "react-bootstrap";
import constants from "../../constants";
import { validateField, validateForm } from "../common/UtilValidation";
import { toastr } from "react-redux-toastr";

interface Props extends React.Props<ChangePW> {
  changePW: (password: string) => void;
  loading: boolean;
  isSocialType: boolean;
}

interface State {
  password: string;
  password2: string;
  formValid: boolean;
  formValidations: any;
}

class ChangePW extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      password: "",
      password2: "",
      formValid: true,
      formValidations: {
        password: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [
            { required: true, message: "Password is required" },
            {
              pattern: constants.passwordRegex,
              message: constants.passwordValidationMessage,
            },
          ],
        },
        password2: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [
            { required: true, message: "Confirm Password is required" },
            {
              pattern: constants.passwordRegex,
              message: constants.passwordValidationMessage,
            },
            { mustMatch: "password", message: "passwords have to match" },
          ],
        },
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    // validate all the form fields but do not show errors
    this.validateAllFields(false);
  }
  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    // check if the form is valid
    if (!this.state.formValid) {
      toastr.error(
        "Error",
        `Please check your form entries and try again.`,
        constants.toastrError
      );

      // validate and show errors
      this.validateAllFields(true);
      this.forceUpdate(); // needed because if the user clicks submit whithout touching some fields, then the inlide validation does not show as expected

      return;
    }
    this.props.changePW(this.state.password);
  }
  handleChange(e: any) {
    const name = e.target.name;
    const value = e.target.value;
    const fv = validateField(
      name,
      value,
      true,
      this.state.formValidations,
      this.state.password
    );
    const vf = validateForm(this.state.formValidations);

    this.setState({
      [name]: value,
      formValidations: fv,
      formValid: vf,
    } as unknown as State);
  }

  // validate all fields and conditionally show errors
  validateAllFields(showErrors: boolean) {
    validateField(
      "password",
      this.state.password,
      showErrors,
      this.state.formValidations
    );
    validateField(
      "password2",
      this.state.password2,
      showErrors,
      this.state.formValidations,
      this.state.password
    );
  }

  /* End of form validation */

  render() {
    if (this.props.isSocialType) {
      return (
        <div>
          <hr />
          <p>This account is using Login with Google.</p>
        </div>
      );
    }
    return (
      <form id="studentPasswordForm" onSubmit={this.handleSubmit}>
        {/* <h2>Change Student Password</h2> */}
        <br />
        <FormGroup
          controlId="studentPassword"
          bsSize="lg"
          validationState={this.state.formValidations.password.validationState}
        >
          <ControlLabel>New Password</ControlLabel>
          <FormControl
            type="password"
            name="password"
            value={this.state.password}
            placeholder="New Password"
            onChange={this.handleChange}
          />
          <FormControl.Feedback />
          <HelpBlock>
            {this.state.formValidations.password.validationMessage}
          </HelpBlock>
        </FormGroup>
        <FormGroup
          controlId="studentPasswordAgain"
          bsSize="lg"
          validationState={this.state.formValidations.password2.validationState}
        >
          <ControlLabel>Confirm New Password</ControlLabel>
          <FormControl
            type="password"
            name="password2"
            value={this.state.password2}
            placeholder="Confirm New Password"
            onChange={this.handleChange}
          />
          <FormControl.Feedback />
          <HelpBlock>
            {this.state.formValidations.password2.validationMessage}
          </HelpBlock>
        </FormGroup>
        <Button disabled={this.props.loading} bsStyle="primary" type="submit">
          Change Password
        </Button>
      </form>
    );
  }
}
export default ChangePW;
