/*
 * QuizAnswers Container
 */
import * as React from "react";

import {
  Button,
  Col,
  Grid,
  Row,
} from "react-bootstrap";
import {
  GFCourse,
  GFInitialState,
  GFLesson,
  GFQuizItem,
  GFStudentQuizResult,
} from "../../models/models";
import {
  initialQuiz,
} from "../../reducers/initialState";
import MainMenu from "../menu/MainMenu";
import QuizAnswers from "./QuizAnswers";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { hideAnswersModal, showAnswersModal } from "../../actions/progressActions";
import { selectIsTeacher, selectIsSuperTeacher, selectHasGoogleClasses } from "../../reducers/userReducer";
import { printElement } from "../progressReports/StudentReportUtil";
import { GFQuizAnswer } from './../../models/models';
import { v4 as uuidv4 } from 'uuid';
import { QuizBreadcrumbContainer } from "./QuizBreadcrumbContainer";

const graham = require("../../images/graham.gif");

const makeQuizResultWithAnswers = (props: Iprops, quiz: GFQuizItem, show: boolean) => {
  return {
    quiz: quiz,
    id: 'quiz-key',
    studentID: '',
    lessonID: props.params.lessonID,
    quizID: props.params.quizID,
    answers: quiz.questions.map(question => {
      const answer: GFQuizAnswer = {
        questionID: question.id,
        answer: '',
        isCorrect: true,
      };
      switch(question.type){
        case 'choice':
          const option = question.options ? question.options.filter( option => {
            return option.isAnswer;
          }) : [];
          answer.answer = option.length > 0 ? option[0].option : question.correctAnswer;
          break;
        case 'blank':
          answer.answer = question.correctAnswer;
          break;
      }
      if(show){
        answer.id = uuidv4();
      }      
      return answer;
    }),
    score: 100,
    createDate: '',
    className: '',
    scoreString: '100',
    isComplete: true,
    isLocked: false,
    show: true,
  }
}

interface RouterParams {
  courseID: string;
  lessonID: string;
  quizID: string;
}

interface Iprops extends RouteComponentProps<RouterParams, {}> {
  // Add your regular properties here
  className: string;
}

interface IdispatchProps {
  show: boolean;
  quiz: GFQuizItem;
  hideAnswers: typeof hideAnswersModal;
  showAnswers: typeof showAnswersModal;
  quizResult: GFStudentQuizResult;
  lessons: { [key: string]: GFLesson };
  courses: { [key: string]: GFCourse };
  hasGoogleClasses: boolean;
  isTeacher: boolean;
  isSuperTeacher: boolean;
};

// export the combined types for the component we are rendering in the body of the modal
export type IquizAnswersProps = Iprops & IdispatchProps;

class QuizAnswersContainerClass extends React.PureComponent<
  IquizAnswersProps,
  {}
> {

  doPrint = () => {
    printElement(document.getElementsByClassName("quiz-answers-table")[0], false, null);
    window.print();
  };
  
  render() {
    const { courses, lessons } = this.props;
    const { courseID, lessonID } = this.props.params;
    return (
      <Grid className="content modal-container">
        <div className="sidemenu">
          <MainMenu isTeacher={this.props.isTeacher} isSuperTeacher={this.props.isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
          <div className="logo text-center">
            <img alt="graham" src={graham} />
          </div>
        </div>
        <div className="students main-content content-with-sidebar">
          <Row className="students-header">
            <Col md={10} xs={10} className="text-xl-left">
              <h1 className="pageDescription">
                <div className="pull-right">
                  <Button
                    onClick={() => {
                      if(this.props.show){
                        this.props.hideAnswers();
                      } else {
                        this.props.showAnswers();
                      }                      
                    }}
                    bsStyle="default"
                    type="button"
                    bsSize="sm"
                  >
                    {this.props.show === true ? "Hide" : "Show"} answers
                  </Button>
                  <Button
                    onClick={this.doPrint}
                    bsStyle="default"
                    type="button"
                    bsSize="sm"
                  >
                    Print
                  </Button>
                </div>
              </h1>
            </Col>
          </Row>
          <Row className="quiz-answers-table">
            <Col xs={12}>
              <Row className="sub-header no-print">
                <QuizBreadcrumbContainer params={this.props.params} />
              </Row>
              <QuizAnswers 
                {...this.props} 
                cancel={this.props.hideAnswers} 
                show={this.props.show}
                showingInModal={false}
                title={`${courses[courseID].name} / ${lessons[lessonID].name}`}
                forceDisabled={true} />
            </Col>
          </Row>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: Iprops) => {
  const quiz = state.quizzes[ownProps.params.quizID] || initialQuiz;
  const quizResult: GFStudentQuizResult = makeQuizResultWithAnswers(ownProps, quiz, state.quizResult.show);
  return {
    quiz,
    quizResult: quizResult,
    show: state.quizResult.show,
    lessons: state.lessons,
    courses: state.courses,
    hasGoogleClasses: selectHasGoogleClasses(state),
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state)
  };
};

export const QuizAnswersContainer = connect(mapStateToProps, {
  hideAnswers: hideAnswersModal,
  showAnswers: showAnswersModal,
})(QuizAnswersContainerClass);
