import * as React from 'react'; // tslint:disable-line

import { Nav, NavItem } from 'react-bootstrap';

import { LinkContainer } from 'react-router-bootstrap';
const FontAwesome = require('react-fontawesome');
const mixpanel = require('mixpanel-browser');

// TODO upgreade the LinkContainer plugin add add this in order to keep the sidebar menu item
// selected while params are added to the route
// const testActive = (match, location) => {
//   if (!match) {
//     return false
//   }

//   console.log(match, location)
//   return true;
// }

const MainMenu = ({isTeacher, isSuperTeacher, hideManageStudents}: {isTeacher: boolean, isSuperTeacher: boolean, hideManageStudents: boolean}) => {
  function handleWritingAssignment(link: string) {
    mixpanel.track('Writing Activities clicked', { link });
  }
  return (
    <Nav bsStyle="pills" stacked={true} activeKey={1} className="gf-nav">
      {isTeacher && (
        <LinkContainer to="/classes">
          <NavItem eventKey={1}>
            <FontAwesome name="th-large" /> View Dashboard
          </NavItem>
        </LinkContainer>
      )}
      {isTeacher && (
        <LinkContainer to="/buildSuperDiagnostic">
          <NavItem eventKey={1}>
            <FontAwesome name="th-list" key="solid" /> Build Diagnostic
          </NavItem>
        </LinkContainer>
      )}
            {isTeacher && (
        <LinkContainer to="/super-diagnostic-report-class/">
          <NavItem eventKey={3}>
            <FontAwesome name="trophy" /> View Diagnostic Results
          </NavItem>
        </LinkContainer>
      )}
      <LinkContainer to="/courses">
        <NavItem eventKey={5}>
          <FontAwesome name="book" /> View Lessons
        </NavItem>
      </LinkContainer>
      {isTeacher && (
        <LinkContainer to="/customLessons">
          <NavItem eventKey={6}>
            <FontAwesome name="address-card" /> Customize Lessons
          </NavItem>
        </LinkContainer>
      )}
      {isTeacher && (
        <LinkContainer to="/classprogress">
          <NavItem eventKey={3}>
            <FontAwesome name="trophy" /> View Progress Reports
          </NavItem>
        </LinkContainer>
      )}
      {isTeacher && (
        <LinkContainer to="/gradingwritingassignment">
          <NavItem
            eventKey={4}
            onClick={() => {
              handleWritingAssignment('/gradingwritingassignment');
            }}
          >
            <FontAwesome name="file-text" /> View Writing Portals
          </NavItem>
        </LinkContainer>
      )}
      {isTeacher && !hideManageStudents && (
        <LinkContainer to="/students">
          <NavItem eventKey={2}>
            <FontAwesome name="users" /> Manage Students
          </NavItem>
        </LinkContainer>
      )}
      {isSuperTeacher && (
        <LinkContainer to="/teachers">
          <NavItem eventKey={2}>
            <FontAwesome name="graduation-cap" /> Manage Teachers
          </NavItem>
        </LinkContainer>
      )}
    </Nav>
  );
};

export default MainMenu;
