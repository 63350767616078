/*
* Custom Lesson Class SelectContainer
*/

import { connect } from 'react-redux';
import { GFInitialState } from '../../models/models';
import { CustomLessonClassSelect } from './CustomLessonClassSelect';
import { setSelectedClassID } from '../../actions/commonActions';
import { find } from 'lodash';
import { selectClassOptions } from '../../reducers/classReducer';

const mapStateToProps = (state: GFInitialState, ownProps: {}) => {
  const defaultOption = { value: '', label: 'Display All Lessons' };

  const classOptions = selectClassOptions(state);

  const selectedClass =
    find(classOptions, (obj) => {
      return obj.value === state.selectedClassID;
    }) || defaultOption;

  return {
    classOptions,
    loading: state.ajaxCallsInProgress > 0,
    selectedClass
  };
};

export const CustomLessonClassSelectContainer = connect(
  mapStateToProps,
  {
    setSelectedClassID
  }
)(CustomLessonClassSelect);
