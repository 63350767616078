import { Col, Breadcrumb, BreadcrumbItem } from "react-bootstrap"
import React from "react"
import { LinkContainer } from "react-router-bootstrap"


interface RouterParams {
    courseID: string;
    lessonID: string;
    quizID: string;
  }

  interface Iprops {
    courseName: string;
    lessonName: string;
    quizName: string;
    params: RouterParams
  }


export const QuizBreadcrumb = ({params, courseName, lessonName, quizName}:Iprops) =>{
    return (

        
        <Col md={12} xs={12} className="gf-breadcrumb">
<Breadcrumb>
  <LinkContainer to={`/courses/${params.courseID}`}>
    <BreadcrumbItem>
      <span>{courseName}</span>
    </BreadcrumbItem>
  </LinkContainer>
  <LinkContainer
    to={`/lesson/${params.courseID}/${
        params.lessonID
    }`}
    >
    <BreadcrumbItem>
      <span>{lessonName}</span>
    </BreadcrumbItem>
  </LinkContainer>
  <BreadcrumbItem active={true}>
    <span>{quizName}</span>
  </BreadcrumbItem>
</Breadcrumb>
</Col>
    )
}