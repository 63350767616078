import * as types from './actionTypes';
import { ThunkResult } from '../models/models';

export function beginAjaxCall() {
  return { type: types.BEGIN_AJAX_CALL };
}

export function endAjaxCall() {
  return { type: types.END_AJAX_CALL };
}

export function manualAjaxStart(): ThunkResult<any> {
  return function(dispatch) {
    dispatch(beginAjaxCall());
  };
}

export function manualAjaxEnd(): ThunkResult<any> {
  return function(dispatch) {
    dispatch(endAjaxCall());
  };
}
