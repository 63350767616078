import * as types from '../actions/actionTypes';
import { initialCustomLesson } from './initialState';
import { keyBy } from 'lodash';
import { GFCustomLesson } from '../models/models';
import { map, pickBy } from 'lodash';

export default function customLessonsReducer(
  state: { [key: string]: GFCustomLesson } = {},
  action: any
): { [key: string]: GFCustomLesson } {
  switch (action.type) {
    case types.GET_CUSTOM_LESSONS_SUCCESS:
      const newCustomLessons = keyBy(
        map(action.customLessons, customLesson => {
          return cleanCustomLessonObject(customLesson);
        }),
        'id'
      );
      return { ...state, ...newCustomLessons };
    case types.UPDATE_CUSTOM_LESSONS_FAILED: // revert back to before the API call failed
      return action.customLessons;
    case types.UPDATE_CUSTOM_LESSONS_SUCCESS:
      // console.error('why twice?');
      const updatedCustomLessons = keyBy(action.customLessons, 'id');
      return { ...state, ...updatedCustomLessons };
    case types.USER_LOGOUT_SUCCESS:
      return {};
    default:
      return state;
  }
}

const cleanCustomLessonObject = (customLesson: GFCustomLesson) => {
  return {
    ...initialCustomLesson,
    ...pickBy(customLesson, (property, key) => property !== null)
  };
};
