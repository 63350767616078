import React, { useState } from 'react';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
} from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import { convertToGoogleClassOptions } from '../progressReports/StudentReportUtil';

interface Iprops {
  container: any;
  show: boolean;
  onHide: () => void;
  onClick: (selectedClasses: any) => void;
  loading: boolean;
  googleClasses: any[];
  importedClasses: any[];
}

export const GoogleSyncConfirmationModal = (props: Iprops) => {
  const {container, loading, show, onClick, onHide, googleClasses, importedClasses} = props;
  const [selected, setSelected] = useState(convertToGoogleClassOptions(importedClasses));

  return (<Modal
  show={show}
  onHide={onHide}
  container={container}
>
  <Modal.Header closeButton={true} />
  <Modal.Body>
    Warning: Synchronizing your GrammarFlip account with Google Classroom will initiate the following:
    <ListGroup className="list-style-dash">
      <ListGroupItem>Any existing class sections created in your GrammarFlip account will be deleted.</ListGroupItem>
      <ListGroupItem>Any existing students who are not enrolled in your Google Classroom account will be removed.</ListGroupItem>
      <ListGroupItem>If a student enrolled in a prior or existing GrammarFlip class section matches the same email address as what is listed in your Google Classroom, we will keep that student’s data (scores, progress, etc.) upon sync.  Otherwise, the data of students with non-matching emails will not be kept once you sync your Google Classroom.</ListGroupItem>
    </ListGroup>
    Clicking “OK” will import your existing Google Classrooms (and the students within those classrooms) into GrammarFlip. <strong>Synching may take up to 10 minutes to complete. Please be patient and refresh your screen.</strong>
    <MultiSelect
      options={googleClasses}
      value={selected}
      onChange={setSelected}
      labelledBy="Select Google Classrooms"
      hasSelectAll={false}
      className="google-classes"
    />
  </Modal.Body>
  <Modal.Footer>
    <Button onClick={onHide} bsStyle="default">
      Cancel
    </Button>
    <Button
      disabled={loading || !googleClasses.length || !selected.length}
      bsStyle="primary"
      onClick={() => onClick(selected)}
    >
      OK
    </Button>
  </Modal.Footer>
</Modal>);
};
