import * as types from './actionTypes';

import Axios, { AxiosRequestConfig } from "axios";
import { GFClass, GFQuizAnswer, GFSuperDiagnosticCustomDiagnostic, ThunkResult } from "../models/models";

import { API } from "../apiEndpoints";
import { beginAjaxCall, endAjaxCall, manualAjaxEnd } from "./ajaxStatusActions";
import constants from '../constants';
import { find, map } from "lodash";
import { initialClass } from "../reducers/initialState";
import { toastr } from "react-redux-toastr";
import { selectIsTrial } from '../reducers/userReducer';
const mixpanel = require('mixpanel-browser');


export const showConfirmDisableModal = () => ({
  type: types.SHOW_MODAL_ENABLE_SUPER_DIAGNOSTIC
});
export const hideEnableSuperDiagnosticModal = () => ({
  type: types.HIDE_MODAL_ENABLE_SUPER_DIAGNOSTIC
});

export const addSuperDiagnosticQuizAnswer = (answer:GFQuizAnswer) => ({
  type: types.SUPER_DIAGNOSTIC_ADD_ANSWER,
  answer
});

export const resetSuperDiagnosticQuizAnswers = () => ({
  type: types.RESET_SUPER_DIAGNOSTIC_ANSWERS
});

export const getSuperDiagnosticTopic = (topic: string) => ({
  type: types.GET_SUPER_DIAGNOSTIC_TOPIC,
  topic
})

export function getTopics(): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall())
    const url = `${API.topic.getAll}`;
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url
    };
    return Axios(axiosOptions)
      .then(({data: topics}) => {
        dispatch({ type: types.GET_SUPER_DIAGNOSTIC_TOPICS_SUCCESS, topics });
      })
      .catch(error => {
        dispatch({ type: types.GET_SUPER_DIAGNOSTIC_TOPICS_FAILED });
        console.error('Error getting topics', error);
        constants.handleError(error, 'get all topics.');
        throw error;
      });
  }
}

export function getCustomDiagnostics(classID: string): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall())
    const url = `${API.customDiagnostic.get}/${classID}`;
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url
    };
    return Axios(axiosOptions)
      .then(({data: customDiagnostics}) => {
        dispatch({ type: types.GET_SUPER_DIAGNOSTIC_CUSTOM_DIAGNOSTICS_SUCCESS, customDiagnostics });
      })
      .catch(error => {
        dispatch({ type: types.GET_SUPER_DIAGNOSTIC_CUSTOM_DIAGNOSTICS_FAILED });
        console.error('Error getting custom diagnostics', error);
        constants.handleError(error, 'load custom diagnostics.');
      });
  }
}

export function updateCustomDiagnostics(data: GFSuperDiagnosticCustomDiagnostic[]): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall())
    const url = `${API.customDiagnostic.update}`;
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url,
      data
    };
    const originalCustomDiagnostics = getState().customDiagnosticsByID;
    dispatch({ type: types.UPDATE_CUSTOM_DIAGNOSTICS, payload: data });
    return Axios(axiosOptions)
      .then(() => {
        dispatch(manualAjaxEnd())
      })
      .catch(error => {
        dispatch({ type: types.UPDATE_CUSTOM_DIAGNOSTICS_FAILED, error, data, payload: originalCustomDiagnostics });
        console.error('Error updating custom diagnostics', error, data);
        constants.handleError(error, 'update custom diagnostics.');
      });
  }
}

/*
* submit a super diagnostic quiz
*/
export function saveSuperDiagnostic(
  quizID: string,
  quizName: string,
  questionIndex: number
): ThunkResult<Promise<void>> {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    const { quizAnswers } = getState().superDiagnosticView;
    const maxQuestions = getState().superDiagnosticQuiz.questions.length
    const url = API.superDiagnostic.saveSuperDiagnostic;
    // grind quiz results into the sausage that api is expecting
    const axiosConfig: AxiosRequestConfig = {
      method: 'post',
      url,
      data: {
        Answers: quizAnswers,
        quizID,
      }
    };
    return Axios(axiosConfig)
      .then(response => {
        if (quizAnswers.length === maxQuestions) {
          dispatch({ type: types.SAVE_SUPER_DIAGNOSTIC_ANSWERS_SUCCESS, questionIndex });
          mixpanel.track('Finished Super Diagnostic', {
            quizID,
            quizName
          });
        } else {
          dispatch({ type: types.AUTO_SAVE_SUPER_DIAGNOSTIC_ANSWERS_SUCCESS, questionIndex });
        }
      })
      .catch(error => {
        dispatch({ type: types.SAVE_SUPER_DIAGNOSTIC_ANSWERS_FAILED, error, axiosConfig });
        console.error(
          'Error with saving Diagnostic results',
          error,
          quizName
        );
        const message = 'save diagnostic.';
        constants.handleError(error, message);
        throw error;
      });
  };
}

export const saveSuperDiagnosticTeacher = (questionIndex: number) => ({
  type: types.SAVE_SUPER_DIAGNOSTIC_TEACHER,
  questionIndex
});


export function toggleSuperDiagnostic(
  classID: string,
  superDiagnosticEnabled: boolean
  ): ThunkResult<any> {
    return (dispatch, getState) => {
      const isTrial = selectIsTrial(getState());
      dispatch(beginAjaxCall());

      const data = {
        id: classID,
        superDiagnosticEnabled
      }
      
      const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        url: API.class.updateClassSuperDiagnostic,
        data
      };
      return Axios(axiosOptions)
      .then((resp) => {
        if (!resp) {
          throw undefined;
        } else {
          dispatch({ type: types.TOGGLE_SUPER_DIAGNOSTIC_SUCCESS, payload: data});
          if (isTrial){
            toastr.success('Success', `Diagnostic ${superDiagnosticEnabled ? 'enabled.  Topics locked.  Students should now log into their accounts to access the diagnostic assessment.' : 'disabled.'}`, {...constants.toastrSuccess, timeOut: 12000});
          }else {
            toastr.success('Success', `Diagnostic ${superDiagnosticEnabled ? 'enabled.  Topics locked.  Students should now log into their accounts to access the diagnostic assessment.' : 'disabled.'}`, {...constants.toastrSuccess, timeOut: 9000});
          }
        }
      })
      .catch(error => {
        dispatch({ type: types.TOGGLE_SUPER_DIAGNOSTIC_FAILED, error });
        constants.handleError(error, 'toggle diagnostic.');
        console.error('error toggling diagnostic on classes.')
      });
    }
}

export function getSuperDiagnosticQuiz(classId: string): ThunkResult<any> {
  return (dispatch, getState) => {
    const url = `${API.class.getSuperDiagnosticQuiz}/${classId}`

    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url
    }
    
    return Axios(axiosOptions)
    .then(resp => {
      dispatch({ type: types.GET_SUPER_DIAGNOSTIC_QUIZ_SUCCESS, superDiagnosticQuiz: resp.data })
    })
    .catch((error: any) => {
      dispatch({ type: types.GET_SUPER_DIAGNOSTIC_QUIZ_FAILED})
      console.error('Error loading diagnostic quiz', error)
      constants.handleError(error, 'load diagnostic quiz')
      throw error
    })
  }
}

export function canStudentTakeSuperDiagnostic(): ThunkResult<any> {
  return (dispatch, getState) => {
    const url = API.superDiagnostic.canStudentTakeSuperDiagnostic
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url
    };
    return Axios(axiosOptions)
    .then(resp => {
      const data = resp.data
      dispatch({ type: types.LOAD_CAN_STUDENT_TAKE_SUPER_DIAGNOSTIC_SUCCESS, data })
      return data
    })
    .catch(error => {
      dispatch({ type: types.LOAD_CAN_STUDENT_TAKE_SUPER_DIAGNOSTIC_FAILED, error, })
      console.error('Error checking take super diagnostic', error)
      constants.handleError(error, 'unable to check if student can take super diagnostic')
    })
  }
}

export function getStudentSuperDiagnosticResult(): ThunkResult<any> {
  return (dispatch, getState) => {
    const url = API.superDiagnostic.getStudentSuperDiagnosticResult
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url
    }
    return Axios(axiosOptions)
    .then(resp => {
      const data = resp.data
      dispatch({ type: types.GET_STUDENT_SUPER_DIAGNOSTIC_RESULT_SUCCESS, data })
      return data
    })
    .catch(error => {
      dispatch({ type: types.GET_STUDENT_SUPER_DIAGNOSTIC_RESULT_FAILED, error })
      console.error('Error loading student diagnostic quiz result', error)
      constants.handleError(error, 'load student diagnostic quiz result')
      throw error
    })
  }
}

export function getSuperDiagnosticReportClass(id: string): ThunkResult<any> {
  return (dispatch, getState) => {
    const url = `${API.superDiagnostic.superDiagnosticReportClass}?classid=${id}`
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url
    }
    return Axios(axiosOptions)
    .then(resp => {
      const data = resp.data
      dispatch({ type: types.GET_SUPER_DIAGNOSTIC_REPORT_CLASS_SUCCESS, data })
      return data
    })
    .catch(error => {
      dispatch({ type: types.GET_SUPER_DIAGNOSTIC_REPORT_CLASS_FAILED })
      console.error('Error loading diagnostic report class', error)
      constants.handleError(error, 'load diagnostic report class')
      throw error
    })
  }
}

export function getSuperDiagnosticReportStudent(id: string): ThunkResult<any> {
  return (dispatch, getState) => {
    const url = `${API.superDiagnostic.superDiagnosticReportStudent}?studentID=${id}`
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url
    }
    return Axios(axiosOptions)
    .then(resp => {
      const data = resp.data
      dispatch({ type: types.GET_SUPER_DIAGNOSTIC_REPORT_STUDENT_SUCCESS, data })
      return data
    })
    .catch(error => {
      dispatch({ type: types.GET_SUPER_DIAGNOSTIC_REPORT_STUDENT_FAILED })
      console.error('Error loading diagnostic report student', error)
      constants.handleError(error, 'load diagnostic report student')
      throw error
    })
  }
}


export function getSuperDiagnosticReportTopic(classID: string, topicID: string): ThunkResult<any> {
  return (dispatch, getState) => {
    let url = `${API.superDiagnostic.superDiagnosticReportTopic}?classid=${classID}`;
    if (topicID){
      url = `${url}&topicid=${topicID}`
    }
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url
    }
    return Axios(axiosOptions)
    .then(resp => {
      const data = resp.data
      dispatch({ type: types.GET_SUPER_DIAGNOSTIC_REPORT_TOPIC_SUCCESS, data })
      return data
    })
    .catch(error => {
      dispatch({ type: types.GET_SUPER_DIAGNOSTIC_REPORT_TOPIC_FAILED })
      console.error('Error loading diagnostic report topic', error)
      constants.handleError(error, 'load diagnostic report topic')
      throw error
    })
  }
}


export function getSuperDiagnosticStudentResult(studentID: string, topicID: string): ThunkResult<any> {
  return (dispatch, getState) => {
    let url = `${API.superDiagnostic.superDiagnosticReportStudentAnswers}?studentID=${studentID}&topicID=${topicID}`;
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url
    }
    return Axios(axiosOptions)
    .then(resp => {
      dispatch({ type: types.GET_SUPER_DIAGNOSTIC_STUDENT_RESULTS_SUCCESS, data: resp.data })
      dispatch({ type: types.MODAL_SHOW_QUIZ_ANSWERS });
    })
    .catch(error => {
      dispatch({ type: types.GET_SUPER_DIAGNOSTIC_STUDENT_RESULTS_FAILED })
      console.error('Error loading diagnostic report topic', error)
      constants.handleError(error, 'load diagnostic report topic')
      throw error
    })
  }
}