/* eslint-disable no-extra-boolean-cast */
import * as React from "react"; // tslint:disable-line
import {
  Navbar,
  Nav,
  NavItem,
  Button,
  Image
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router";
import constants from "../../constants";
import moment from "moment";
import { GFUser } from "../../models/models";

const FontAwesome = require("react-fontawesome");

const logo = require("../../images/gflogo.png");
const graham = require("../../images/registerGraham.png");
const kahoot = require("../../images/Kahoot-Grey.png");
const waving = require("../../images/graham-waving.gif");

type Iprops = {
  user: GFUser;
  loading: boolean;
  logout: () => void;
  isTeacher: boolean;
  isStudent: boolean;
  superDiagnosticEnabled: boolean;
}

const Header = ({ user, loading, logout, isTeacher, isStudent, superDiagnosticEnabled }: Iprops) => {
  let loaderCss = "cssload-loader transition";
  if (loading) {
    loaderCss = "cssload-loader transition visible";
  }

  let headerMenu = "usermenu";
  let headerMenuNoUser = "usermenu";
  if (!!user.first) {
    headerMenu = "usermenu visible";
    headerMenuNoUser = "usermenu";
  } else {
    headerMenuNoUser = "usermenu visible";
    headerMenu = "usermenu";
  }

  function expirationDate() {
    const expirationD: any = user.expirationDate;
    // console.log(user.expirationDate, 'expiration');
    const month: any = expirationD.split("-")[1];
    const day: any = expirationD.split("-")[2];
    const year: any = expirationD.split("-")[0];
    // const parsedDay: any = day.split('T')[0];

    const todayD: any = moment().format("L");
    // console.log(todayD, 'todays date');
    const tYear = todayD.split("/")[2];
    const tMonth: any = todayD.split("/")[0];
    const tDay: any = todayD.split("/")[1];
    const expirationDateM = moment.utc([year, month - 1, day]);
    const todayDate = moment([tYear, tMonth - 1, tDay]);
    let totalNumDays = expirationDateM.diff(todayDate, "days");
    if (totalNumDays < 0) {
      totalNumDays = 0;
    }
    // console.log(totalNumDays);
    return totalNumDays;
  }

  function openKahootLesson(kahootPath: string) {
    window.open(kahootPath);
  }
  return (
    // fixedTop
    <Navbar className="header">
      <Navbar.Header>
        <Link to="/">
          {/* Any user logo displayed */}
          <Navbar.Brand className="gf-logo">
            <img alt="logo" src={logo} />
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle />
      </Navbar.Header>
      <div id="pageLoader" className={loaderCss}>
        <div className="cssload-inner cssload-one" />
        <div className="cssload-inner cssload-two" />
        <div className="cssload-inner cssload-three" />
      </div>
      <Navbar.Collapse>
        <Nav pullRight={true} className={headerMenuNoUser}>
          <NavItem href={constants.links.features}>
            <h1 className="headerText">Features</h1>
          </NavItem>
          <NavItem href={constants.links.pricing}>
            <h1 className="headerText">Pricing</h1>
          </NavItem>
          <NavItem href={constants.links.about}>
            <h1 className="headerText">About</h1>
          </NavItem>
          <NavItem href={constants.links.support}>
            <h1 className="headerText">Support</h1>
          </NavItem>
        </Nav>
        {isStudent && (
          <Nav className={headerMenu}>
            <LinkContainer to="/courses">
              <NavItem className="nav-button-student">
                <h1 className="headerText dashboard">Dashboard</h1>
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/progress">
              <NavItem className="nav-button-student">
                <h1 className="headerText">Student Progress Report</h1>
              </NavItem>
            </LinkContainer>
            {superDiagnosticEnabled ? 
            <LinkContainer to="/super-diagnostic">
              <NavItem className="nav-button-student">
                <h1 className="headerText">Start Diagnostic</h1>
                <Image src={waving} style={{width: '50px', marginBottom: '4px'}}/>
              </NavItem>
            </LinkContainer>
            :
            null
            }
          </Nav>
        )}
        {isTeacher &&
          user.paymentPlan === "trial" && (
            <Nav>
              <NavItem className="timer-text">
                <p>
                  {expirationDate()} days left in your trial.
                  <LinkContainer to="/payment">
                    <Button className="upgrade-payment">Upgrade</Button>
                  </LinkContainer>
                </p>
              </NavItem>
            </Nav>
          )}
        <Nav pullRight={true} className={headerMenu}>
          {/* any user */}
          {isStudent && (
            <NavItem disabled={true}>
              <img
                alt="graham"
                src={graham}
                style={{
                  height: 64,
                  position: "absolute",
                  bottom: "-53px",
                  marginLeft: "-50px"
                }}
              />
            </NavItem>
          )}
          <NavItem disabled={true} className="first-name">
            <h1 className="headerText">{user.first}</h1>
          </NavItem>
          {isStudent && (
            <NavItem
              onClick={() => {
                openKahootLesson("https://kahoot.it/");
              }}
              title="Kahoot!"
            >

                <img alt="kahoot" src={kahoot} className="kahoot-path-header" />
            </NavItem>
          )}
          <NavItem
            eventKey={1}
            target="_blank"
            href="http://grammarflip.freshdesk.com/"
            className="nav-button"
            title="Help"
          >
            <FontAwesome name="life-ring" />
          </NavItem>
          {isTeacher && (
            <LinkContainer to="/payment">
              <NavItem eventKey={1} href="#" className="nav-button" title="Payment">
                  <FontAwesome name="usd" />
              </NavItem>
            </LinkContainer>
          )}
          <LinkContainer to="/profile">
            <NavItem eventKey={2} href="#" className="nav-button" title="Profile">
                <FontAwesome name="user-circle-o" />
            </NavItem>
          </LinkContainer>
          <NavItem
            eventKey={3}
            href="#"
            className="nav-button"
            onClick={logout}
            title="sign-out"
          >
              <FontAwesome name="sign-out" />
          </NavItem>
          {/* end of navbar for any user */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
