import * as React from 'react';
import { FormGroup, Row, Col, ControlLabel } from 'react-bootstrap';
import Select from 'react-select';
import { Option } from '../../models/models';

interface Iprops {
  classOptions: Option[];
  selectedClass: Option;
  setSelectedClassID: (value: any) => void;
}

export const CustomLessonClassSelect = ({
  classOptions,
  selectedClass,
  setSelectedClassID
}: Iprops) => {
  // add the All Lessons option
  const defaultOption = { value: 0, label: 'Display All Lessons' };
  const cOptions = [defaultOption, ...classOptions];

  return (
    <FormGroup bsSize="lg" className="class-lesson-selects">
      <Row>
        <Col xs={12}>
          <h4>
            Select a class section to view the lessons enabled for that specific
            class.{' '}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          <ControlLabel>Select Class</ControlLabel>
        </Col>
        <Col xs={4} className="class-row">
          <Select
            className="react-select-container"
            options={cOptions}
            onChange={setSelectedClassID}
            value={selectedClass}
            placeholder="select a class"
            classNamePrefix="react-select"
            isSearchable={false}
          />
        </Col>
      </Row>
    </FormGroup>
  );
};
