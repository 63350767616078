import * as React from "react";

import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  HelpBlock,
} from "react-bootstrap";
import { GFInitialState, GFUser } from "../../models/models";
import { browserHistory } from "react-router";
import {
  manualAjaxEnd,
  manualAjaxStart,
} from "../../actions/ajaxStatusActions";
import { selectIsAdmin, selectIsSuperTeacher, selectIsTeacher } from "../../reducers/userReducer";
import {
  setSocialObj,
  startResetPassword,
  userLogin,
  userLoginSocial,
  userLoginSocialWithCode,
  userUpdatePassword,
  verifyResetPasswordCode,
} from "../../actions/userActions";
import { validateField, validateForm } from "../common/UtilValidation";

import ForgotPassword from "./ForgotPassword";
import GoogleLogin from "react-google-login";
import { LinkContainer } from "react-router-bootstrap";
import ResetPassword from "./resetPassword";
import { connect } from "react-redux";
import constants from "../../constants";
import { toastr } from "react-redux-toastr";

const FontAwesome = require("react-fontawesome");

interface Props extends React.Props<LoginForm> {
  user: GFUser;
  userLogin: typeof userLogin;
  userLoginSocial: typeof userLoginSocial;
  userUpdatePassword: (password: string) => Promise<void>;
  setSocialObj: typeof setSocialObj;
  loading: boolean;
  manualAjaxEnd: any;
  manualAjaxStart: any;
  location: any;
  startResetPassword: (email: string) => Promise<void>;
  verifyResetPasswordCode: (token: string, email: string) => Promise<GFUser>;
  isSuperTeacher: boolean;
  isTeacher: boolean;
  isAdmin: boolean;
  userLoginSocialWithCode: typeof userLoginSocialWithCode;
}

interface State {
  email: string;
  password: string;
  showForgotPW: boolean;
  showResetPW: boolean;
  token: string;
  openToken: boolean;
  newPassword: string;
  newPassword2: string;
  formValidations: any;
  formValid: boolean;
  return_to: string;
}

class LoginForm extends React.Component<Props, State> {
  emailInput: any;
  passwordInput: any;

  newRefPassword: any;
  newRefPassword2: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showForgotPW: false,
      showResetPW: false,
      token: "",
      openToken: false,
      newPassword: "",
      newPassword2: "",
      return_to: "",
      formValidations: {
        email: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [
            { required: true, message: "email or username is required" },
          ],
        },
        password: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [
            { required: true, message: "password is required" },
            {
              pattern: "^.{3,}",
              message: "Password must be at least 3 characters",
            },
          ],
        },
      },
      formValid: false,
    };
  }
  componentWillMount() {
    // if we have a logged in user, redirect them to their appropriate home page
    if (!!this.props.user.apiKey.length) {
      this.handleAuthorizedUser();
    }
  }
  componentDidMount() {
    // validate all the form fields but do not show errors
    this.validateAllFields(false);
    if (this.props.location.query.return_to) {
      this.setState({ return_to: this.props.location.query.return_to });
    }
    if (this.props.location.query.code) {
      this.props.userLoginSocialWithCode(this.props.location.query.code);
    }
  }
  componentDidUpdate() {
    if (this.props.user.apiKey.length) {
      setTimeout(() => {
        // give time for the API key to populate
        this.handleAuthorizedUser();
      }, 200);
    }
  }

  handleAuthorizedUser = () => {
    const return_to_url = sessionStorage.getItem("return_to");
    sessionStorage.removeItem("return_to");

    if (return_to_url) {
      return browserHistory.push(return_to_url);
    }

    if (this.state.return_to) {
      browserHistory.push(this.state.return_to);
    } else {
      if (this.props.isSuperTeacher) {
        browserHistory.push("/teachers");
      } else 
      if (this.props.isTeacher || this.props.isAdmin) {
        browserHistory.push("/classes");
      } else {
        browserHistory.push("/courses");
      }
    }
  };

  handleChange = (e: any) => {
    const name = e.currentTarget.name || "";
    const value = e.currentTarget.value || "";
    const fv = validateField(name, value, true, this.state.formValidations);
    const vf = validateForm(this.state.formValidations);

    this.setState({
      [name]: value,
      formValidations: fv,
      formValid: vf,
    } as unknown as State);
  };

  // validate all fields and conditionally show errors
  validateAllFields = (showErrors: boolean) => {
    validateField(
      "email",
      this.state.email,
      showErrors,
      this.state.formValidations
    );
    validateField(
      "password",
      this.state.password,
      showErrors,
      this.state.formValidations
    );
  };

  /* End of form validation */

  /*
   * Submit Form
   */
  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // check if the form is valid
    if (!this.state.formValid) {
      toastr.error(
        "Error",
        `Please check your form entries and try again.`,
        constants.toastrError
      );
      // validate and show errors
      this.validateAllFields(true);
      this.forceUpdate(); // needed because if the user clicks submit whithout touching some fields, then the inlide validation does not show as expected

      return;
    }

    this.props.userLogin(this.state.email, this.state.password);
  };

  openForgotPW = () => {
    this.setState({
      showForgotPW: true,
    });
  };

  closeForgotPW = () => {
    this.setState({
      showForgotPW: false,
      openToken: false,
    });
  };

  openResetPW = () => {
    this.setState({
      showResetPW: true,
    });
  };

  closeResetPW = () => {
    this.setState({ showResetPW: false, newPassword: "", newPassword2: "" });
  };

  submitForgotPW = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props
      .startResetPassword(this.state.email)
      .then(() => {
        this.setState({ openToken: true });
      })
      .catch((error) => {
        //error handled in action
        console.error("error requesting password reset", error);
      });
  };

  submitToken = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props
      .verifyResetPasswordCode(this.state.token, this.state.email)
      .then((user) => {
        this.setState({ showForgotPW: false, token: "", showResetPW: true });
      });
  };

  changePW = (password: string) => {
    this.props
      .userUpdatePassword(password)
      .then((resp) => {
        this.closeResetPW();
        toastr.success(
          "Success",
          `Your password has been updated!`,
          constants.toastrSuccess
        );
      })
      .catch((error) => {
        console.error("Error updating user password", error);
        const message = "update password";
        constants.handleError(error, message);
      });
  };

  openTokenLink = () => {
    this.setState({ openToken: true });
  };

  reqGoogleLogin = () => {
    this.props.manualAjaxStart();
  };

  responseGoogle = (resp: any) => {
    this.props.manualAjaxEnd();
    if (!!resp.error) {
      console.error("Error from Google Auth", resp);
      let message =
        "Unable to log in with Google.  Please try again or contact support.";
      if (resp.details) {
        message = resp.details;
      }
      if (resp.error !== "idpiframe_initialization_failed") {
        toastr.error("Error", message, constants.toastrError);
      }
    } else {
      // send api request with resp.tokenId
      this.props.userLoginSocial(resp.tokenId, "google");
    }
  };

  render() {
    return (
      <Grid className="content">
        <div className="row loginPage">
          <div className="col-md-offset-3 col-md-6 col-lg-6">
            <form id="loginForm" onSubmit={this.handleSubmit}>
              <h2>Hi, there. Would you like to log in?</h2>
              <FormGroup
                controlId="userEmail"
                bsSize="lg"
                validationState={
                  this.state.formValidations.email.validationState
                }
              >
                <FormControl
                  type="text"
                  name="email"
                  placeholder="Email or Username"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                <FormControl.Feedback />
                <HelpBlock>
                  {this.state.formValidations.email.validationMessage}
                </HelpBlock>
              </FormGroup>

              <FormGroup
                controlId="userPassword"
                bsSize="lg"
                validationState={
                  this.state.formValidations.password.validationState
                }
              >
                <FormControl
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
                <FormControl.Feedback />
                <HelpBlock>
                  {this.state.formValidations.password.validationMessage}
                </HelpBlock>
              </FormGroup>
              <div className="row rowPadding">
                <div className="col-md-12">
                  <Button
                    bsStyle="link"
                    className="small"
                    onClick={this.openForgotPW}
                    disabled={this.props.loading}
                  >
                    Forgot Password?
                  </Button>
                  <div className="buttons">
                    <Button
                      disabled={this.props.loading}
                      id="btnLogin"
                      className="pull-right"
                      bsStyle="primary"
                      type="submit"
                    >
                      Log Me in!
                    </Button>
                    <LinkContainer to="/register">
                      <Button
                        id="btnRegister"
                        bsStyle="primary"
                        type="button"
                        disabled={this.props.loading}
                      >
                        I Need to Create an Account!
                      </Button>
                    </LinkContainer>
                  </div>
                  {/* <GoogleLogin
                    clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                    buttonText="Login With Google"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    onRequest={this.reqGoogleLogin}
                    className="google-login"
                    // icon={false}
                  /> */}

                  <Button
                    bsStyle="default"
                    type="button"
                    href={constants.buildGoogleLoginLink('', this.state.return_to)}
                    className="google-login"
                    disabled={this.props.loading}
                  >
                    <FontAwesome name="google" /> &nbsp; Log in with Google
                  </Button>
                </div>
              </div>
            </form>
          </div>
          <ForgotPassword
            loading={this.props.loading}
            container={this}
            show={this.state.showForgotPW}
            close={this.closeForgotPW}
            email={this.state.email}
            change={this.handleChange}
            submit={this.submitForgotPW}
            openToken={this.state.openToken}
            token={this.state.token}
            submitToken={this.submitToken}
          />
          <ResetPassword
            loading={this.props.loading}
            container={this}
            show={this.state.showResetPW}
            close={this.closeResetPW}
            changePW={this.changePW}
            title="Step 3 of 3: Create a new password."
          />
        </div>
        <div className="version-section">
          <small>
            {" "}
            {process.env.NODE_ENV} {process.env.REACT_APP_VERSION}{" "}
          </small>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  return {
    user: state.user,
    loading: state.ajaxCallsInProgress > 0,
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state),
    isAdmin: selectIsAdmin(state),
  };
};

export default connect(mapStateToProps, {
  userLogin,
  userLoginSocial,
  userUpdatePassword,
  setSocialObj,
  manualAjaxEnd,
  manualAjaxStart,
  startResetPassword,
  verifyResetPasswordCode,
  userLoginSocialWithCode,
})(LoginForm);
