import * as React from "react";
import constants from "../../constants";
import MainMenu from "../menu/MainMenu";
import { connect } from "react-redux";
import { GFUser, GFInitialState } from "../../models/models";
import {
  userUpdateProfile,
  userUpdatePassword,
  resendVerification
} from "../../actions/userActions";
import {
  addStudentClass,
  removeStudentClass
} from "../../actions/studentActions";
import VerificationAlert from "../common/VerificationAlert";
import ChangePW from "../common/ChangePW";
import { checkSession } from "../../actions/userActions";
import { userLogout } from "../../actions/userActions";

import { forEach, find } from "lodash";

import { browserHistory } from "react-router";

import {
  Button,
  Grid,
  Row,
  Col,
  Modal,
  FormGroup,
  FormControl,
  ControlLabel,
  InputGroup,
  ButtonToolbar,
  ListGroup,
  ListGroupItem,
  HelpBlock
} from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { selectIsTeacher, selectIsSuperTeacher, selectHasGoogleClasses } from "../../reducers/userReducer";
const FontAwesome = require("react-fontawesome");

const graham = require("../../images/graham.gif");

interface Iprops extends React.Props<Profile> {

}
interface IdispatchProps {
  hasGoogleClasses: boolean;
  isTeacher: boolean;
  isSuperTeacher: boolean;
  user: GFUser;
  loading: boolean;
  userUpdateProfile: typeof userUpdateProfile;
  userUpdatePassword: (password: string) => Promise<void>;
  addStudentClass: (studentID: string,
    classID: string) => Promise<GFUser>;
  removeStudentClass: any;
  checkSession: any;
  userLogout: any;
  location: any;
  resendVerification: typeof resendVerification;
  version: string;
}

interface State {
  showChangePW: boolean;
  gender: string;
  first: string;
  last: string;
  username: string;
  code: string;
  showConfirmationModal: boolean;
  formValidations: any;
  formValid: boolean;
}

class Profile extends React.Component<Iprops & IdispatchProps, State> {
  refgender: any;
  reffirst: any;
  reflast: any;
  refusername?: any;
  refrole: any;

  refcode: any;

  constructor(props: Iprops & IdispatchProps) {
    super(props);
    this.state = {
      showChangePW: false,
      gender: "",
      first: "",
      last: "",
      username: "",
      code: "",
      showConfirmationModal: false,
      formValidations: this.buildFormValidation(),
      formValid: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showChangePW = this.showChangePW.bind(this);
    this.hideChangePW = this.hideChangePW.bind(this);
    this.addClass = this.addClass.bind(this);
    this.changePW = this.changePW.bind(this);
    this.goToDashboard = this.goToDashboard.bind(this);
    this.removeClass = this.removeClass.bind(this);
    this.confirmationWindow = this.confirmationWindow.bind(this);
    this.showConfirmationModal = this.showConfirmationModal.bind(this);
    this.hideConfirmationModal = this.hideConfirmationModal.bind(this);
  }

  componentDidMount() {
    let code = "";
    if (
      this.props.location &&
      this.props.location.query &&
      this.props.location.query.classcode &&
      this.props.location.query.classcode.length
    ) {
      code = this.props.location.query.classcode;
    }
    this.setState(
      {
        gender: this.props.user.gender,
        first: this.props.user.first,
        last: this.props.user.last || '',
        username: this.props.user.username,
        code
      },
      () => {
        // validate all the form fields but do not show errors
        this.validateAllFields(false);
        if (!!code.length) {
          if (this.props.isTeacher) {
            toastr.warning(
              "Warning",
              "Only students can add a class.",
              constants.toastrWarning
            );
          } else {
            this.addClass();
          }
        }
      }
    );
  }

  buildFormValidation = () => {
    const commonValidations = {
      first: {
        validationState: null,
        validationMessage: "",
        valid: true,
        validators: [{ required: true, message: "First Name is required" }]
      },
      last: {
        validationState: null,
        validationMessage: "",
        valid: true,
        validators: [{ required: true, message: "Last Name is required" }]
      }
      // code: {validationState: null, validationMessage: '', valid: true, validators: [
      //   {pattern: '^[A-Za-z0-9-]{8}$', message: 'class code must be 8 characters, letters and numbers only'}]}
    };

    if (this.props.isTeacher) {
      const username = {
        validationState: null,
        validationMessage: "",
        valid: true,
        validators: [
          { required: true, message: "Email is required" },
          {
            pattern: /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i,
            message: "Must be valid email address"
          }
        ]
      };
      return { ...commonValidations, username };
    } else {
      const username = {
        validationState: null,
        validationMessage: "",
        valid: true,
        validators: [
          { required: true, message: "Email or Username is required" }
        ]
      };
      return { ...commonValidations, username };
    }
  };

  /*
  * FORM VALIDATION
  */

  validateField(name: string, value: string, showErrors: boolean) {
    // loop over each field we need to validate
    forEach(this.state.formValidations, (field, key) => {
      if (key === name) {
        const newFormValidations = { ...this.state.formValidations };
        let errorMessage = "";

        // loop over each validation for this field, and set error to the error message if something is invalid
        field.validators.forEach((validator: any) => {
          if (!this.validate(value, validator)) {
            errorMessage = validator.message;
          }
        });
        // after we have checked all the validators, is there an error message for this field?
        if (!errorMessage) {
          // no error message
          newFormValidations[name].validationState = "success";
          newFormValidations[name].validationMessage = "";
          newFormValidations[name].valid = true;
          if (!showErrors) {
            newFormValidations[name].validationState = null;
            newFormValidations[name].validationMessage = "";
          }
        } else {
          // error
          // only show the errors if showErrors
          if (showErrors) {
            newFormValidations[name].validationState = "error";
            newFormValidations[name].validationMessage = errorMessage;
          }

          newFormValidations[name].valid = false;
        }
        this.setState(
          { formValidations: newFormValidations },
          this.validateForm
        );
      }
    });
  }

  validate(value: string, validator: any) {
    let valid: boolean = true;
    if (validator.pattern && value.length > 0) {
      const newValue = value.match(validator.pattern);
      valid = newValue ? true : false;
    }
    if (validator.required) {
      valid = !!value.length || false;
    }
    if (validator.mustMatch) {
      valid =
        this.state[validator.mustMatch as keyof State] === value ? true : false;
    }
    return valid;
  }

  validateForm() {
    const formValid = !find(this.state.formValidations, ["valid", false]);
    this.setState({ formValid });
  }
  // validate all fields and conditionally show errors
  validateAllFields(showErrors: boolean) {
    this.validateField("username", this.state.username, showErrors);
    this.validateField("first", this.state.first, showErrors);
    this.validateField("last", this.state.last, showErrors);
    // this.validateField('code', this.state.code, showErrors);
  }

  /* End of form validation */

  showChangePW() {
    this.setState({ showChangePW: true });
  }

  hideChangePW() {
    this.setState({ showChangePW: false });
  }

  changePW(password: string) {
    this.hideChangePW();

    this.props
      .userUpdatePassword(password)
      .then(resp => {
        toastr.success(
          "Success",
          "Your password was updated!",
          constants.toastrSuccess
        );
      })
      .catch(error => {
        console.error("Error updating password", error);
        const message = "update password";
        constants.handleError(error, message);
      });
  }

  handleChange(e: any) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value } as unknown as State, () =>
      this.validateField(name, value, true)
    );
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    // check if the form is valid
    if (!this.state.formValid) {
      toastr.error(
        `Form Error`,
        `Please check your form entries and try again.`,
        constants.toastrError
      );
      // validate and show errors
      this.validateAllFields(true);
      return;
    }

    const { gender, first, last, username } = this.state;
    this.props.userUpdateProfile({
      gender,
      first,
      last,
      username
    });
  }

  logout() {
    this.props.userLogout().then(() => {
      browserHistory.push("/");
    });
  }
  /*
  * Student adding a class
  * the API returns and updated Student user (isActive) as well as the class that was just added
  */
  addClass() {
    this.props
      .addStudentClass(this.props.user.id, this.state.code)
      .then((user: any) => {
        return this.props.checkSession(this.props.user).then((resp: any) => {
          toastr.success(
            "Success",
            "Successfully added class.",
            constants.toastrSuccess
          );
          // console.log("got updated user from checkSession", resp);
        });
      })
      .catch((error: any) => {
        if (this.handleAddClassError(error) === false){
          constants.handleError(error, 'add class')
        }
      });
  }

  handleAddClassError = (error: any): boolean => {
    let message = '';
    console.error("Error adding a class", error);
    if (error.response && error.response.status === 409) {
      message =
        "Sorry! We can't find a class with that code. Please try again.";
    } else if (error.response && error.response.status === 411) {
      // no more slots available
      message =
        "Sorry! We cannot add you to this class because your teacher needs to upgrade their account.";
    } else if (error.response && error.response.status === 410) {
      // bad classcode
      message = `Sorry! We could not find a class with the code: ${
        this.state.code
      }.`;
    }
    if (message){
      toastr.error(
        `Unable to Add Class`,
        `${message}`,
        constants.toastrError
        );
        return true;
      } else {
        return false;
      }
  }

  /*
  * Student removing a class
  * The API returns an empty student user object with "isVerified" indicating if the student should
  * be active or not.  Not active means they are not part of a class that is active/paid for.
  */
  removeClass(selectedClass: string) {
    // console.log('remove me', selectedClass);
    this.setState({ showConfirmationModal: false });

    this.props
      .removeStudentClass(this.props.user.id, selectedClass, this.props.user)
  }

  goToDashboard() {
    browserHistory.push("/courses");
  }

  hideConfirmationModal() {
    this.setState({ showConfirmationModal: false });
  }

  showConfirmationModal() {
    this.setState({ showConfirmationModal: true });
  }

  confirmationWindow(studentID: string) {
    return (
      <Modal
        show={this.state.showConfirmationModal}
        onHide={this.hideConfirmationModal}
        className="static-modal-confirmation-window"
      >
        <Modal.Header>
          <Modal.Title>
            <h1 className="body-text">Warning!</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Warning: You are about to remove yourself from this class section. By
          removing yourself from this class, you will lose all access to the
          lessons and only be able to view your progress report. You will need
          to contact your current teacher for a valid class code in order to
          re-access the lessons.
        </Modal.Body>
        <Modal.Body>
          Are you sure you want to remove yourself from this class?
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel"
            onClick={() => {
              this.hideConfirmationModal();
            }}
          >
            No, I want to stay.
          </Button>
          <Button
            bsStyle="danger"
            className="save"
            onClick={() => {
              this.removeClass(studentID);
            }}
          >
            Yes, remove me.
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  printClasses() {
    if (
      !this.props.isTeacher &&
      this.props.user.classes &&
      this.props.user.classes.length > 0
    ) {
      return (
        <div>
          <label> Current Class </label>
          <ListGroup>
            {this.props.user.classes.length &&
              this.props.user.classes.map(studentClass => (
                <ListGroupItem
                  className="list-item classes"
                  key={studentClass.id}
                >
                  <Row>
                    {this.confirmationWindow(studentClass.id)}
                    <Col md={10}>
                      {studentClass.name
                        ? `${studentClass.name} (${studentClass.code})`
                        : `${studentClass.code}`}
                    </Col>
                    <Col md={2}>
         
                        <Button
                          disabled={this.props.loading}
                          bsStyle="link"
                          className="pull-right"
                          onClick={() => this.showConfirmationModal()}
                          title="Remove Class"
                        >
                          <FontAwesome name="times" />
                        </Button>
                    </Col>
                  </Row>
                </ListGroupItem>
              ))}
          </ListGroup>
        </div>
      );
    } else {
      return "";
    }
  }

  render() {
    const {isTeacher, isSuperTeacher} = this.props;
    const contentClasses = isTeacher
      ? "main-content content-with-sidebar profile"
      : "main-content content-without-sidebar profile";
    const usernameLabel = isTeacher ? "Email" : "Email or Username";
    const addClassButtonText =
      this.props.user.classes && this.props.user.classes.length
        ? "Replace Class"
        : "Add Class";

    return (
      <Grid className="content modal-container">
        {isTeacher && (
          <div className="sidemenu">
            <MainMenu isTeacher={isTeacher} isSuperTeacher={isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
            <div className="logo text-center">
              <img alt="graham" src={graham} />
            </div>
          </div>
        )}
        <div className={contentClasses}>
          <VerificationAlert user={this.props.user} isTeacher={this.props.isTeacher} />
          <Row className="sub-header">
            <Col md={10} xs={12}>
              <h1 className="text-center">Manage Profile</h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <form id="registerForm" onSubmit={this.handleSubmit}>
                <FormGroup
                  bsSize="lg"
                  validationState={
                    this.state.formValidations.first.validationState
                  }
                >
                  <ControlLabel>First Name</ControlLabel>
                  <FormControl
                    id="first"
                    type="text"
                    name="first"
                    value={this.state.first}
                    onChange={this.handleChange}
                  />
                  <FormControl.Feedback />
                  <HelpBlock>
                    {this.state.formValidations.first.validationMessage}
                  </HelpBlock>
                </FormGroup>
                <FormGroup
                  bsSize="lg"
                  validationState={
                    this.state.formValidations.last.validationState
                  }
                >
                  <ControlLabel>Last Name</ControlLabel>
                  <FormControl
                    type="text"
                    id="last"
                    name="last"
                    value={this.state.last}
                    onChange={this.handleChange}
                  />
                  <FormControl.Feedback />
                  <HelpBlock>
                    {this.state.formValidations.last.validationMessage}
                  </HelpBlock>
                </FormGroup>
                <FormGroup
                  bsSize="lg"
                  validationState={
                    this.state.formValidations.username.validationState
                  }
                >
                  <ControlLabel>{usernameLabel}</ControlLabel>
                  <FormControl
                    type="text"
                    id="username"
                    name="username"
                    value={this.state.username}
                    onChange={this.handleChange}
                    disabled={!!this.props.user.socialType}
                  />
                  <FormControl.Feedback />
                  <HelpBlock>
                    {this.state.formValidations.username.validationMessage}
                  </HelpBlock>
                </FormGroup>
                {this.printClasses()}
                {!isTeacher && (
                  <FormGroup controlId="classCode" bsSize="lg">
                    <ControlLabel>Class Code</ControlLabel>
                    <InputGroup>
                      <FormControl
                        type="text"
                        name="code"
                        value={this.state.code}
                        onChange={this.handleChange}
                      />
                      <InputGroup.Button>
                        <Button
                          disabled={this.props.loading}
                          onClick={this.addClass}
                          bsStyle="primary"
                        >
                          {addClassButtonText}
                        </Button>
                      </InputGroup.Button>
                    </InputGroup>
                    <FormControl.Feedback />
                  </FormGroup>
                )}
                <div className="row rowPadding">
                  <div className="col-md-12">
                    <ButtonToolbar>
                      {isTeacher &&
                        !this.props.user.isVerified && (
                          <Button
                            disabled={this.props.loading}
                            bsStyle="default"
                            type="button"
                            onClick={this.props.resendVerification}
                          >
                            Resend Verification
                          </Button>
                        )}
                      <Button
                        disabled={this.props.loading}
                        bsStyle="primary"
                        type="submit"
                      >
                        Update Profile
                      </Button>
                      <Button
                        bsStyle="link"
                        type="button"
                        onClick={this.showChangePW}
                        style={{
                          display: this.props.user.socialType ? "none" : "block"
                        }}
                      >
                        Need to update your password?
                      </Button>
                    </ButtonToolbar>
                    <small
                      style={{
                        display: this.props.user.socialType ? "block" : "none",
                        marginTop: "10px"
                      }}
                    >
                      This account is using a Google Log In. You will need to
                      access your Google account to change your password.
                    </small>
                  </div>
                </div>
              </form>
            </Col>
          </Row>
          <Modal
            show={this.state.showChangePW}
            onHide={this.hideChangePW}
            container={this}
          >
            <Modal.Header closeButton={true}>
              <Modal.Title>Update Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ChangePW
                changePW={this.changePW}
                loading={this.props.loading}
                isSocialType={!!this.props.user.socialType}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.hideChangePW} bsStyle="default">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="version-section">
          <small>
            {" "}
            {process.env.NODE_ENV} {process.env.REACT_APP_VERSION}{" "}
          </small>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: Iprops) => {
  return {
    user: state.user,
    loading: state.ajaxCallsInProgress > 0,
    hasGoogleClasses: selectHasGoogleClasses(state),
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state),
    version: state.appSettings.version,
  };
};

export default connect(
  mapStateToProps,
  {
    userUpdateProfile,
    userUpdatePassword,
    addStudentClass,
    removeStudentClass,
    checkSession,
    userLogout,
    resendVerification,
  }
)(Profile);
