import * as React from "react";

import {
  Button,
  Col,
  FormControl,
  FormGroup,
  Grid,
  Row,
  Well
} from "react-bootstrap";
import {
  GFInitialState,
  GFLesson,
  GFWritingAssignment,
  GFWritingAssignmentResult
} from "../../models/models";
import {
  getAllWAByLessonID,
  getWAResultsForClass
} from "../../actions/waActions";
import { isEmpty, map, values } from "lodash";

import { saveWAUpdate } from "../../actions/waActions";
import MainMenu from "../menu/MainMenu";
import { connect } from "react-redux";
import constants from "../../constants";
import { getAllLessons } from "../../actions/lessonActions";
import { loadClasses } from "../../actions/classActions";
import { toastr } from "react-redux-toastr";
import { selectIsTeacher, selectIsSuperTeacher, selectHasGoogleClasses } from "../../reducers/userReducer";

const checkMarkFontAwesome = (
  <i className="fa fa-check fa-2x" aria-hidden="true" />
);
const timesMarkFontAwesome = (
  <i className="fa fa-minus fa-2x" aria-hidden="true" />
);

const mixpanel = require("mixpanel-browser");

const graham = require("../../images/graham.gif");

interface Props extends React.Props<GradingWA> {
  user: any;
  classes: any[];
  lessons: { [key: string]: GFLesson };
  getAllLessons: typeof getAllLessons;
  loadClasses: any;
  getAllWAByLessonID: typeof getAllWAByLessonID;
  writingAssignments: { [key: string]: GFWritingAssignment };
  loading: boolean;
  params: any;
  getWAResultsForClass: any;
  waResultsByID: { [key: string]: GFWritingAssignmentResult };
  hasGoogleClasses: boolean;
  isTeacher: boolean;
  isSuperTeacher: boolean;
}

interface State {
  lessonID: string;
  lesson: any;
  courseID: string;
  selectedClassID: string;
  selectedWA: string;
  waResultsEdited: GFWritingAssignmentResult[];
}

class GradingWA extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      lessonID: "",
      courseID: "",
      selectedClassID: "",
      selectedWA: "",
      lesson: [],
      waResultsEdited: []
    };

    this.printClasses = this.printClasses.bind(this);
    this.changeClassID = this.changeClassID.bind(this);
    this.updateWA = this.updateWA.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.teachersGrade = this.teachersGrade.bind(this);
    this.teacherCommentSend = this.teacherCommentSend.bind(this);
    this.teacherCommentChange = this.teacherCommentChange.bind(this);
  }

  componentDidMount() {
    // clear out any exisitng writing assignments that might have been loaded while viewing a lesson
    this.props.getAllWAByLessonID("");
    if (this.props.classes.length === 0) {
      // no classes so let's retrieve them
      this.props.loadClasses(this.props.user).then((classes: any) => {
        // check for classID param and autoselect correct class!
        if (!!this.props.params.classID) {
          this.changeClassID(this.props.params.classID);
        }
      });
    } else {
      // check for classID param and autoselect correct class!
      if (!!this.props.params.classID) {
        this.changeClassID(this.props.params.classID);
      }
    }
    // if we don't already have lessons, get them all
    if (isEmpty(this.props.lessons)) {
      this.props.getAllLessons();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(prevProps.waResultsByID) !==
      JSON.stringify(this.props.waResultsByID)
    ) {
      this.setState({ waResultsEdited: values(this.props.waResultsByID) });
    }
    if (
      JSON.stringify(prevProps.writingAssignments) !==
      JSON.stringify(this.props.writingAssignments)
    ) {
      // this.props.getwritingAssignmentsForClass(this.state.selectedClassID, this.state.selectedWA.value);
      if (!isEmpty(this.props.writingAssignments)) {
        this.setState(
          {
            selectedWA: this.props.writingAssignments[
              Object.keys(this.props.writingAssignments)[0]
            ].id
          },
          () => {
            this.props.getWAResultsForClass(
              this.state.selectedClassID,
              this.state.selectedWA
            );
          }
        );
      } else {
        this.props.getWAResultsForClass("", "");
      }
    }
  }

  changeClassID(classID: string) {
    this.setState({ selectedClassID: classID }, () => {
      this.props.getWAResultsForClass(
        this.state.selectedClassID,
        this.state.selectedWA
      );
    });
  }

  handleWASelectChange = (
    e: React.FormEvent<FormControl & HTMLInputElement>
  ) => {
    this.setState({ selectedWA: e.currentTarget.value }, () => {
      this.props.getWAResultsForClass(
        this.state.selectedClassID,
        this.state.selectedWA
      );
    });
  };

  printClasses() {
    return (
      <FormGroup controlId="formControlsSelect">
        <FormControl
          componentClass="select"
          placeholder="select"
          value={this.state.selectedClassID}
          onChange={(e: any) => {
            this.changeClassID(e.target.value);
          }}
          disabled={this.props.loading}
        >
          <option value="">Filter by class...</option>
          {this.props.classes.map(gfClass => (
            <option key={gfClass.id} value={gfClass.id}>
              {gfClass.name}
            </option>
          ))}
        </FormControl>
      </FormGroup>
    );
  }

  printLessons() {
    return (
      <FormGroup controlId="formControlsSelect">
        <FormControl
          componentClass="select"
          placeholder="select"
          onChange={(e: any) => {
            this.props.getAllWAByLessonID(e.target.value);
            this.setState({ selectedWA: "" });
          }}
          disabled={this.props.loading}
        >
          <option value="">Select a Lesson</option>
          {map(this.props.lessons, gfLesson => (
            <option key={gfLesson.id} value={gfLesson.id}>
              {gfLesson.name}
            </option>
          ))}
        </FormControl>
      </FormGroup>
    );
  }

  /*
* Writing assignment select
*/
  printWritingAssignments() {
    return (
      <FormGroup controlId="formControlsSelect">
        <FormControl
          componentClass="select"
          placeholder="Filter by Writing Portal"
          onChange={this.handleWASelectChange}
          disabled={this.props.loading}
          value={this.state.selectedWA}
        >
          <option value="">Filter by Writing Portal</option>
          {map(this.props.writingAssignments, gfWA => (
            <option key={gfWA.id} value={gfWA.id}>
              {gfWA.name}
            </option>
          ))}
        </FormControl>
      </FormGroup>
    );
  }

  printWAResults() {
    // Coulse I compare when the create date was made?
    if (
      this.state.waResultsEdited.length === 0 &&
      this.state.selectedWA !== ""
    ) {
      return <div className="empty-assignments">No activities written.</div>;
    } else if (this.state.waResultsEdited.length !== 0) {
      return (
        <Row className="the-assignments-container">
          {this.state.waResultsEdited.map(waResult => {
            let textStyle = "graded-text";
            if (
              waResult.isCorrect === null ||
              waResult.isCorrect === undefined
            ) {
              textStyle = "not-graded-text";
            }
            return (
              <Col md={4} key={waResult.id} className="grading-assignments">
                <Row className="student">
                  <h2 className="name">
                    {waResult.student.first} {waResult.student.last || ''}
                  </h2>
                </Row>
                <Row>
                  <Col md={12}>
                    <div
                      className={textStyle}
                      dangerouslySetInnerHTML={{
                        __html: waResult.studentText
                      }}
                    />
                  </Col>
                </Row>
                <Row className="teachers">
                  <Col className="input" xs={12} sm={12} md={12}>
                    <FormGroup controlId="formControlsTextarea">
                      <FormControl
                        value={waResult.teacherText || ""}
                        componentClass="textarea"
                        placeholder="Use the quick buttons to respond to your students, and/or enter comments here to send to them."
                        onChange={(e: any) => {
                          this.teacherCommentChange(waResult, e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="grade">
                    <Col md={12}>
                      <Button
                        bsStyle={
                          waResult.isCorrect === null ? "primary" : "default"
                        }
                        className="teacher-send-comments"
                        onClick={() => {
                          this.teacherCommentSend(waResult);
                        }}
                      >
                        Send Comments
                      </Button>
                        <Button
                          className="teacher-button-1"
                          bsStyle={
                            waResult.isCorrect === true ||
                            waResult.isCorrect === null
                              ? "success"
                              : "default"
                          }
                          onClick={() => {
                            this.teachersGrade(waResult, true);
                          }}
                          title="Looking good!"
                        >
                          {checkMarkFontAwesome}
                        </Button>
                        <Button
                          className="teacher-button-2"
                          bsStyle={waResult.isCorrect ? "default" : "warning"}
                          onClick={() => {
                            this.teachersGrade(waResult, false);
                          }}
                          title="Check some of your work."
                        >
                          {timesMarkFontAwesome}
                        </Button>
                    </Col>
                  </div>
                </Row>
              </Col>
            );
          })}
        </Row>
      );
    } else {
      return null;
    }
  }

  teachersGrade(waResult: GFWritingAssignmentResult, isCorrect: any) {
    const teacherInput: any = {};
    teacherInput.isCorrect = isCorrect;
    const message = isCorrect ? "passed" : "failed";
    mixpanel.track("Teacher graded writing activitity", { grade: message });
    const war: GFWritingAssignmentResult = Object.assign(
      {},
      waResult,
      teacherInput
    );
    this.updateWA(war);
  }

  teacherCommentChange(waResult: GFWritingAssignmentResult, teacherText?: any) {
    const teacherInput: any = {};
    teacherInput.teacherText = teacherText;
    const war: GFWritingAssignmentResult = Object.assign(
      {},
      waResult,
      teacherInput
    );
    this.updateWA(war, false);
  }

  teacherCommentSend(waResult: any) {
    if (waResult.teacherText !== "" && waResult.teacherText !== null) {
      this.updateWA(waResult, true);
    } else {
      toastr.error(
        `Please add comments before sending.`,
        `No comment included.`,
        constants.toastrError
      );
    }
  }
  /*
  * update the comment for the Writing Assignment
  */
  updateWA(war: GFWritingAssignmentResult, sendComment?: boolean) {
    const index = this.state.waResultsEdited.findIndex(
      item => item.id === war.id
    );
    const waResultsEdited = [
      ...this.state.waResultsEdited.slice(0, index),
      war,
      ...this.state.waResultsEdited.slice(index + 1)
    ];

    // checking to see if what is being sent is a comment or a grade
    if (sendComment === true) {
      saveWAUpdate(war)
        .then(res => {
          toastr.success(
            `Comments sent to student successfully!`,
            `Comments Sent!`,
            constants.toastrSuccess
          );
        })
        .catch(error => {
          const message = "sending comments to student";
          constants.handleError(error, message);
        });
    } else if (sendComment === undefined) {
      saveWAUpdate(war)
        .then(res => {
          toastr.success(
            `Grade Sent!`,
            `Grade sent to student successfully!`,
            constants.toastrSuccess
          );
        })
        .catch(error => {
          const message = "sending grade to student";
          constants.handleError(error, message);
        });
    }
    this.setState({ waResultsEdited });
  }

  refreshPage() {
    this.props
      .getWAResultsForClass(this.state.selectedClassID, this.state.selectedWA)
      .then((waResults: any) => {
        // this.setState({ waResults })
      })
      .catch((error: any) => {
        console.error("Error with loading Writing Activities", error);
        const message = "load Writing Activities";
        constants.handleError(error, message);
      });
  }

  render() {
    let instructions = "";
    let writingPrompt = "";
    const selectedAssignment = this.props.writingAssignments[
      this.state.selectedWA
    ];

    if (this.state.waResultsEdited.length > 0) {
      instructions =
        "Below are the students' answers to the writing activity. Notes to the student are saved when you click the green or yellow buttons.";
    } else if (!selectedAssignment) {
      instructions =
        "No writing activities for current selection.  Please select a lesson";
    }
    if (selectedAssignment) {
      writingPrompt = selectedAssignment.description;
    }
    return (
      <Grid className="content modal-container">
        <div className="sidemenu">
          <MainMenu isTeacher={this.props.isTeacher} isSuperTeacher={this.props.isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
          <div className="logo text-center">
            <img alt="graham" src={graham} />
          </div>
        </div>
        <div className="students main-content content-with-sidebar">
          <div>
            <Row>
              <Col md={12} xs={12} className="writing-assignment-banner">
                <h1>Writing Activities</h1>
                <div>
                  <p>
                    Select the class section, lesson, and writing portal to view
                    your students' writing.
                  </p>
                </div>
              </Col>
            </Row>
            <div className="filters">
              <Row>
                <Col xs={12} md={6}>
                  {this.printClasses()}
                </Col>
                <Col xs={12} md={6}>
                  {this.printLessons()}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  {this.printWritingAssignments()}
                </Col>
                <Col xs={12} md={6}>
                  <Button
                    bsStyle="primary"
                    bsSize="sm"
                    className="refresh-button"
                    onClick={() => {
                      this.refreshPage();
                    }}
                  >
                    Refresh
                  </Button>
                </Col>
              </Row>
              {writingPrompt.length !== 0 && (
                <Row className="writing-prompt">
                  <Col xs={12}>
                    <Well dangerouslySetInnerHTML={{ __html: writingPrompt }} />
                  </Col>
                </Row>
              )}
              <Row className="instructions text-left">
                <Col xs={12}>{instructions}</Col>
              </Row>
            </div>
            {this.printWAResults()}
          </div>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  return {
    user: state.user,
    classes: state.classes,
    lessons: state.lessons,
    waResultsByID: state.waResultsByID,
    writingAssignments: state.writingAssignments,
    loading: state.ajaxCallsInProgress > 0,
    hasGoogleClasses: selectHasGoogleClasses(state),
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state),
  };
};

export default connect(
  mapStateToProps,
  {
    loadClasses,
    getAllWAByLessonID,
    getAllLessons,
    getWAResultsForClass
  }
)(GradingWA);
