import * as React from "react";

import {
  Button,
  Col,
  FormControl,
  FormGroup,
  Grid,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import {
  GFClass,
  GFInitialState,
  GFUser,
} from "../../models/models";
import { find, forEach, sortBy, uniqBy } from "lodash";
import {
  updateTeacherPW,
  searchTeachers,
} from "../../actions/teacherActions";
import {
  removeTeacher,
  saveTeacher,
} from "../../actions/userActions";
import { LinkContainer } from 'react-router-bootstrap';
import MainMenu from "../menu/MainMenu";
import AddTeacherModal from "./AddTeacherModal";
import EditTeacherModal from "./EditTeacherModal";
import VerificationAlert from "../common/VerificationAlert";
import { connect } from "react-redux";
import constants from "../../constants";
import { initialUser } from "../../reducers/initialState";
import { selectIsTeacher, selectIsSuperTeacher, selectHasGoogleClasses } from "../../reducers/userReducer";
import { toastr } from "react-redux-toastr";
import { GFSuperTeacherPlan, GFTeacherUser } from './../../models/models';
import TeachersList from "./TeachersList";

const FontAwesome = require("react-fontawesome");

const graham = require("../../images/graham.gif");

interface Props extends React.Props<Teachers> {
  user: GFUser;
  classes: GFClass[];
  loadClasses: () => Promise<void>;
  searchTeachers: (query: string) => GFTeacherUser[];
  updateTeacherPW: typeof updateTeacherPW;
  loading: boolean;
  params: any;
  hasGoogleClasses: boolean;
  isTeacher: boolean;
  isSuperTeacher: boolean;
  saveTeacher: (
    teacher: GFTeacherUser,
  ) => Promise<any>;
  removeTeacher: (
    teacher: GFTeacherUser,
  ) => Promise<any>;
}

interface State {
  filteredTeachers: GFTeacherUser[];
  showAddTeacher: boolean;
  showEditTeacher: boolean;
  showDeleteTeacher: boolean;
  showDeleteTeacherModal: boolean;
  teacher: GFTeacherUser;
  class: GFClass;
  searchInput: string;
  formValidations: any;
  formValid: boolean;
  teacherToAdd: GFTeacherUser;
  teacherToEdit: GFTeacherUser|null;
  teacherToDelete: GFTeacherUser|null;
}

class Teachers extends React.Component<Props, State> {
  teacherToEdit: any;
  teacherToDelete: any;

  /**
   * The constructor
   * @param props 
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      filteredTeachers: [],
      showAddTeacher: false,
      showEditTeacher: false,
      showDeleteTeacher: false,
      searchInput: "",
      teacher: initialUser,
      class: {
        id: "",
        name: "",
        code: "",
        classSize: 0,
        createDate: "",
      },
      showDeleteTeacherModal: false,
      formValidations: {
        first: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [{ required: true, message: "First Name is required" }],
        },
        last: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [{ required: true, message: "Last Name is required" }],
        },
        username: {
          validationState: null,
          validationMessage: "",
          valid: true,
          validators: [
            { required: true, message: "Email or Username is required" },
          ],
        },
      },
      formValid: true,
      teacherToAdd: initialUser,
      teacherToEdit: null,
      teacherToDelete: null
    };
    this.handleChange = this.handleChange.bind(this);
    // this.printClasses = this.printClasses.bind(this);
    // this.changeClassFilter = this.changeClassFilter.bind(this);
    this.searchTeachers = this.searchTeachers.bind(this);
    this.showAddTeacher = this.showAddTeacher.bind(this);
    this.hideAddTeacher = this.hideAddTeacher.bind(this);
    this.showEditTeacher = this.showEditTeacher.bind(this);
    this.hideEditTeacher = this.hideEditTeacher.bind(this);
    this.saveTeacher = this.saveTeacher.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.showDeleteTeacher = this.showDeleteTeacher.bind(this);
    this.hideDeleteTeacher = this.hideDeleteTeacher.bind(this);
    this.deleteTeacher = this.deleteTeacher.bind(this);
    this.changeTeacherPW = this.changeTeacherPW.bind(this);
  }

  /**
   * Called when component mounts
   */
  componentDidMount() {
    const { user } = this.props;
    if (user.hasOwnProperty('subTeachers') && user.subTeachers.length !== 0) {
      this.searchTeachers();
    }
  }

  /**
   * Called when component updates
   * @param prevProps 
   */
  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(prevProps.user.subTeachers) !== JSON.stringify(this.props.user.subTeachers)
    ) {
      this.searchTeachers();
    }
  }

  /**
   * Validates Form fields
   * @param name 
   * @param value 
   * @param showErrors 
   */
  validateField(name: string, value: string, showErrors: boolean) {
    // loop over each field we need to validate
    forEach(this.state.formValidations, (field, key) => {
      if (key === name) {
        const newFormValidations = Object.assign(
          {},
          this.state.formValidations
        );
        let errorMessage = "";

        // loop over each validation for this field, and set error to the error message if something is invalid
        field.validators.forEach((validator: any) => {
          if (!this.validate(value, validator)) {
            errorMessage = validator.message;
          }
        });
        // after we have checked all the validators, is there an error message for this field?
        if (!errorMessage) {
          // no error message
          newFormValidations[name].validationState = "success";
          newFormValidations[name].validationMessage = "";
          newFormValidations[name].valid = true;
          if (!showErrors) {
            newFormValidations[name].validationState = null;
            newFormValidations[name].validationMessage = "";
          }
        } else {
          // error
          // only show the errors if showErrors
          if (showErrors) {
            newFormValidations[name].validationState = "error";
            newFormValidations[name].validationMessage = errorMessage;
          }

          newFormValidations[name].valid = false;
        }
        this.setState(
          { formValidations: newFormValidations },
          this.validateForm
        );
      }
    });
  }

  /**
   * Validates with pattern
   * @param value 
   * @param validator 
   * @returns 
   */
  validate(value: string, validator: any) {
    let valid = true;
    if (validator.pattern && value.length > 0) {
      const newValue = value.match(validator.pattern);
      valid = newValue ? true : false;
    }
    if (validator.required) {
      valid = !!value.length || false;
    }
    if (validator.mustMatch) {
      valid =
        this.state[validator.mustMatch as keyof State] === value ? true : false;
    }
    return valid;
  }

  /**
   * Validate a Form
   */
  validateForm() {
    const formValid = !find(this.state.formValidations, ["valid", false]);
    this.setState({ formValid });
  }

  /**
   * Validate all fields and conditionally show errors
   * @param showErrors 
   */
  validateAllFields(showErrors: boolean) {
    this.validateField(
      "username",
      this.state.teacher.username,
      showErrors
    );
    this.validateField("first", this.state.teacher.first, showErrors);
    this.validateField("last", this.state.teacher.last, showErrors);
  }

  /**
   * Update Teacher state when Form input changes
   * @param e 
   */
  handleChange(e: any) {
    const value = e.target.value;
    const name = e.target.name;
    const oldTeacher = this.state.teacher;
    const newTeacher = Object.assign({}, oldTeacher, {
      [e.target.name]: e.target.value,
    });
    const newerTeacher = Object.assign({}, this.state.teacher, {
      teacher: newTeacher,
    });
    this.setState({ teacher: newerTeacher }, () =>
      this.validateField(name, value, true)
    );
  }

  /**
   * Update state when Form input changes
   * @param e 
   */
  handleInputChange(e: any) {
    const state = {
      [e.target.name]: e.target.value
    };
    this.setState(state as unknown as State, () => {
      this.searchTeachers();
    });
  }

  /**
   * Updates filteredTeachers state
   */
  searchTeachers() {
    const { user } = this.props;
    const teachers = user.subTeachers;
    const val = this.state.searchInput.toLowerCase();
    let teach = Object.values(teachers).filter((teacher) => {
      return (
        teacher.first.toLowerCase().indexOf(val) !== -1 ||
        teacher.last.toLowerCase().indexOf(val) !== -1
      );
    });
    // dedupe
    const uniqTeachers = uniqBy(teach, "id");
    this.setState({
      filteredTeachers: sortBy(uniqTeachers, teacher => teacher.last.toLocaleLowerCase()),
    });
  }

  /**
   * Show the Edit Teacher modal
   * @param teacher 
   */
  showEditTeacher(teacher: GFTeacherUser) {
    const tu = Object.assign({}, teacher, {
      password: "",
      password2: "",
    });
    const te = Object.assign({}, teacher, { teacher: tu });

    this.setState(
      {
        showEditTeacher: true,
        teacherToEdit: teacher,
        teacher: te,
      },
      () => {
        // validate all the form fields but do not show errors
        // this.validateAllFields(false);
      }
    );
  }

  /**
   * Hid the Edit Teacher modal
   */
  hideEditTeacher() {
    // const fv = Object.assign({}, this.state.formValidations, { validationState: null, validationMessage: '' });
    const fv = {
      first: {
        validationState: null,
        validationMessage: "",
        valid: true,
        validators: [{ required: true, message: "First Name is required" }],
      },
      last: {
        validationState: null,
        validationMessage: "",
        valid: true,
        validators: [{ required: true, message: "Last Name is required" }],
      },
      username: {
        validationState: null,
        validationMessage: "",
        valid: true,
        validators: [
          { required: true, message: "Email or Username is required" },
        ],
      },
    };

    this.setState({ showEditTeacher: false, formValidations: fv });
  }

  /**
   * Show the Add Teacher modal
   */
  showAddTeacher() {
    this.setState({ showAddTeacher: true });
  }

  /**
   * Hide the Add Teacher modal
   */
  hideAddTeacher() {
    this.setState({ showAddTeacher: false });
  }

  /**
   * Show the Delete Teacher modal
   */
  showDeleteTeacher(teacher: GFTeacherUser) {
    this.setState({ showDeleteTeacher: true, teacherToDelete: teacher });
  }

  /**
   * Hide the Delete Teacher modal
   */
  hideDeleteTeacher() {
    this.setState({ showDeleteTeacher: false });
  }

  /**
   * Delete the Teacher from Super Teacher plan
   */
  deleteTeacher() {
    const teacher = this.state.teacherToDelete;
    if(teacher){
      this.props
        .removeTeacher(teacher)
        .then((deleted: any) => {
          if (deleted) {
            toastr.success(
              "Success",
              "Teacher removed.",
              constants.toastrSuccess
            );
            this.searchTeachers();
          }
          this.hideDeleteTeacher();
        })
        .catch((error: any) => {
          this.hideDeleteTeacher();
          console.error("Error with deleting teacher", error);
          const message = "delete teacher";
          constants.handleError(error, message);
        });
    }
  }

  /**
   * Save the Teacher on the API
   * @param e 
   * @returns 
   */
  saveTeacher(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    // check if the form is valid
    if (!this.state.formValid) {
      toastr.error(
        `Form Error`,
        `Please check your form entries and try again.`,
        constants.toastrError
      );
      // validate and show errors
      this.validateAllFields(true);
      return;
    }

    this.hideEditTeacher();
    this.props.saveTeacher(this.state.teacher)
  }

  /**
   * Update a Teacher password
   * 
   * A Super Teacher can update their Teachers' passwords, but
   * cannot change a password for a Teacher who is also a GoogleUser
   * 
   * @param password 
   */
  changeTeacherPW(password: string) {
    this.hideEditTeacher();
    this.props
      .updateTeacherPW(this.state.teacherToEdit, password)
  }

  /**
   * How many available teacher openings we have according to our
   * Super Teacher Plans.
   * 
   * @param user GFUser
   * @returns number
   */
  calculateAvailableTeacherOpenings(user: GFUser) {
    return user.calculatedSuperTeacherPlans.reduce((total: number, plan: GFSuperTeacherPlan) => {
      return total + plan.quantity;
    }, 0);
  }
    
  /**
   * Set the teacher to add
   * @param teacher GFTeacherUser
   */
  setTeacherToAdd = (teacher: GFTeacherUser) => {
    this.setState({
      teacherToAdd: teacher
    });
  }

  /**
   * Set the teacher to add
   * @param teacher GFTeacherUser
   */
  setTeacherToEdit = (teacher: GFTeacherUser) => {
    this.setState({
      teacherToEdit: teacher
    });
  }

  /**
   * Render the component
   * @returns Grid
   */
  render() {
    const { loading, user, searchTeachers } = this.props;
    const { filteredTeachers, showEditTeacher, teacherToAdd, teacherToEdit } = this.state;
    const openings = this.calculateAvailableTeacherOpenings(user);
    return (
      <Grid className="content modal-container">
        <div>
          {this.state.showAddTeacher && <AddTeacherModal
            user={user}
            showAddTeacher={this.state.showAddTeacher}
            hideAddTeacher={this.hideAddTeacher}
            loading={this.props.loading}
            addTeacher={this.props.saveTeacher}
            setTeacherToAdd={this.setTeacherToAdd}
            teacher={teacherToAdd}
            plans={user.calculatedSuperTeacherPlans}
            searchTeachers={searchTeachers}
            refreshTeacherList={this.searchTeachers}
          />}
        </div>
        <div>
          {this.state.showEditTeacher && <EditTeacherModal
            showEditTeacher={showEditTeacher}
            hideEditTeacher={this.hideEditTeacher}
            setTeacherToEdit={this.setTeacherToEdit}
            loading={loading}
            teacher={teacherToEdit}
            saveTeacher={this.props.saveTeacher}
            changeTeacherPW={this.changeTeacherPW}
          />}
        </div>
        <div className="sidemenu">
          <MainMenu isTeacher={this.props.isTeacher} isSuperTeacher={this.props.isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
          <div className="logo text-center">
            <img alt="graham" src={graham} />
          </div>
        </div>
        <div className="teachers content-with-sidebar main-content">
          <VerificationAlert
            user={this.props.user}
            isTeacher={this.props.isTeacher}
          />
          <Row className="sub-header">
            <Col md={12} xs={12}>
              <h1 className="pull-left">Manage Teachers</h1>
              <Button disabled={loading} className="pull-right btn-sm" onClick={this.showAddTeacher} style={{ marginTop: 8 }}>
                <FontAwesome name="plus" />
                Add Teacher ({openings})
              </Button>
              <LinkContainer to="/teacherprogress">
                <Button disabled={loading} className="pull-right btn-sm" onClick={() => {}} style={{ marginTop: 8 }}>
                  <FontAwesome name="eye" />
                  View Teacher Progress Report
                </Button>
              </LinkContainer>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={6}></Col>
            <Col xs={6} md={6}>
              <form>
                <FormGroup bsSize="large">
                  <InputGroup>
                    <InputGroup.Addon>
                      <FontAwesome name="search" />
                    </InputGroup.Addon>
                    <FormControl
                      onChange={this.handleInputChange}
                      placeholder="Filter by name"
                      name="searchInput"
                      type="text"
                      value={this.state.searchInput}
                    />
                  </InputGroup>
                </FormGroup>
              </form>
            </Col>
          </Row>
          <TeachersList
            loading={loading}
            openSeats={openings}
            filteredTeachers={filteredTeachers}
            showAddTeacher={this.showAddTeacher}
            showEditTeacher={this.showEditTeacher}
            showDeleteTeacher={this.showDeleteTeacher}
          />
        </div>
        <Modal
          show={this.state.showDeleteTeacher}
          onHide={this.hideDeleteTeacher}
          container={this}
        >
          <Modal.Header closeButton={true}>
            <Modal.Title>Are You Sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to remove this teacher?
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideDeleteTeacher} bsStyle="default">
              Cancel
            </Button>
            <Button
              disabled={this.props.loading}
              bsStyle="primary"
              onClick={this.deleteTeacher}
            >
              Remove Teacher
            </Button>
          </Modal.Footer>
        </Modal>
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  return {
    user: state.user,
    classes: state.classes,
    loading: state.ajaxCallsInProgress > 0,
    hasGoogleClasses: selectHasGoogleClasses(state),
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state),
  };
};

export default connect(mapStateToProps, {
  saveTeacher,
  searchTeachers,
  updateTeacherPW,
  removeTeacher,
})(Teachers);
