/*
  * updating stipe credit card form section
  */

// hoho resend send you to profile
// hoho on the payment page
// 412 for need to verify your email
// double check wiping local storage when logging out

import * as React from 'react';
import { CardElement, CardNumberElement } from '@stripe/react-stripe-js';
import { CardSection } from './CardSection';
import { GFUser } from '../../models/models';
import { Button, Modal } from 'react-bootstrap';
import constants from '../../constants';
import { toastr } from 'react-redux-toastr';
import { Stripe, StripeElements } from '@stripe/stripe-js';

interface Props extends React.Props<UpdateCardSection> {
  show: boolean;
  hide: () => void;
  user: GFUser;
  loading: boolean;
  manualAjaxEnd: any;
  manualAjaxStart: any;
  updateCard: (paymentToken: string) => Promise<void>;
  stripe: Stripe;
   elements: StripeElements;
}
class UpdateCardSection extends React.Component<
  Props,
  {}
> {
  handleUpdateCard = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!this.props.stripe && this.props.elements) {
      console.error("missing stripe", this.props.stripe);
      return;
    }
    const cardElement = this.props.elements.getElement(CardNumberElement);
    if (!cardElement){
      console.error('missing cardElement');
      return;
    }
    this.props.manualAjaxStart();
    this.props.stripe
      .createToken(cardElement)
      .then(({ token }) => {
        this.props.manualAjaxEnd();
        if (token === undefined) {
          throw {
            error: '',
            message:
              'Invalid credit card info.  Please double check and try again.'
          };
        }
        return this.props.updateCard(token.id).then(user => {
          this.props.hide();
        });
      })
      .catch(error => {
        // catch the error here because we are catching the stripe createToken error as well

        this.props.manualAjaxEnd();
        let message = ''
        // errors from strip have a json body, errors from the GF API do not, client side throws do not
        const contentType =
          error && error.headers ? error.headers.get('content-type') : '';
        if (contentType && contentType.indexOf('application/json') !== -1) {
          error
            .json()
            .then((stripeError: any) => {
              // this is an error from Stripe
              console.error('Error from Stripe', stripeError);
              message = stripeError.message;
              toastr.error('Error', message, constants.toastrError);
            })
            .catch((resp: any) => {
              // this is an error from the GF API or from the app throwing an error
              if (error && error.response && error.response.status === 412) {
                message = constants.friendlyErrors.verifyRequired;
              }
              if (error && error.response && error.response.status === 433) {
                // this is an error we thew in the client app
                message = error.message;
              }
              console.error('Error parsing json', resp);
              console.error('Error signing up', error);
              if (message.length){
                toastr.error('Error', message, constants.toastrError);

              } else {
                constants.handleError(error, 'update credit card')
              }
            });
        } else {
          constants.handleError(error, 'update credit card');
        }
      });
  };
  render() {
    return (
      <div>
        <form onSubmit={this.handleUpdateCard}>
          <Modal.Body>
            <CardSection />
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" onClick={this.props.hide}>
              Close
            </Button>
            <Button
              bsStyle="primary"
              type="submit"
              disabled={this.props.loading}
            >
              Update Credit Card
            </Button>
          </Modal.Footer>
        </form>
      </div>
    );
  }
}

export default UpdateCardSection;