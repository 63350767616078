import * as React from 'react'; // tslint:disable-line
import { Route, IndexRoute } from 'react-router';
import App from './App';
import LoginForm from './components/auth/LoginForm';
import RegisterForm from './components/auth/Register';
import Verification from './components/auth/Verification';
import Classes from './components/class/Classes';
import DirectPayment from './components/direct-payment/DirectPayment';
import Students from './components/student/Students';
import Teachers from './components/teacher/Teachers';
// import StudentProgress from './components/progress/StudentProgress';
import Profile from './components/profile/Profile';
import Payment from './components/payment/Payment';
import Courses from './components/course/Courses';
import Lesson from './components/lesson/Lesson';
import Quiz from './components/quiz/Quiz';
import gradingWA from './components/writingassignment/GradingWA';
import WritingAssignment from './components/writingassignment/WritingAssignment';

import { store } from './index';
import { browserHistory } from 'react-router';
import StudentReport from './components/progressReports/StudentReport';
import TeacherStudentReport from './components/progressReports/TeacherStudentReport';
import SuperTeacherSubTeacherReport from './components/progressReports/SuperTeacherSubTeacherReport';
import ClassProgressReport from './components/progressReports/ClassProgressReport';
import customLessons from './components/customLessons/customLessons';
import CourseLessons from './components/courseLessons/CourseLessons';

import BuildSuperDiagnostic from "./components/superDiagnostic/BuildSuperDiagnostic";
import SuperDiagnosticQuiz from './components/superDiagnostic/TakeSuperDiagnostic';
import { selectIsTeacher, selectIsSuperTeacher, selectHasGoogleClasses } from './reducers/userReducer';
import { QuizCompleteContainer } from './components/quiz/QuizCompleteContainer';
import { QuizAnswersContainer } from './components/quiz/QuizAnswersContainer';
import { SuperDiagnosticComplete } from './components/superDiagnostic/SuperDiagnosticComplete';
import SuperDiagnosticClassReportLayout from './components/progressReports/SuperDiagnosticClassReportLayout';
import SuperDiagnosticStudentReportLayout from './components/progressReports/SuperDiagnosticStudentReportLayout';

function requireTeacher(nextState: any, replace: any) {
  const {user} = store.getState();
  const isTeacher = selectIsTeacher(store.getState());
  if (user && user.apiKey && user.apiKey.length && isTeacher) {
    return true;
  } else if (
    user &&
    user.apiKey &&
    user.apiKey.length &&
    !isTeacher
  ) {
    return browserHistory.push('/courses');
  } else {
    replace({
      pathname: '/',
      query: { return_to: nextState.location.pathname }
    });
  }
}

function requireSuperTeacher(nextState: any, replace: any) {
  const {user} = store.getState();
  const isSuperTeacher = selectIsSuperTeacher(store.getState());
  if (user && user.apiKey && user.apiKey.length && isSuperTeacher) {
    return true;
  } else if (
    user &&
    user.apiKey &&
    user.apiKey.length &&
    !isSuperTeacher
  ) {
    return browserHistory.push('/courses');
  } else {
    replace({
      pathname: '/',
      query: { return_to: nextState.location.pathname }
    });
  }
}

function requireUser(nextState: any, replace: any) {
  const user = store.getState().user;
  const { location } = nextState;
  if (user && user.apiKey && user.apiKey.length) {
    return true;
  } else {
    replace({
      pathname: '/',
      query: { return_to: location.pathname + location.search }
    });
    return false;
  }
}

function requireUserCanManageStudents(nextState: any, replace: any) {
  const user = store.getState().user;
  const hasGoogleClasses = selectHasGoogleClasses(store.getState());
  const { location } = nextState;
  if (user && user.apiKey && user.apiKey.length && !hasGoogleClasses) {
    return true;
  } else {
    replace({
      pathname: '/',
      query: { return_to: location.pathname + location.search }
    });
    return false;
  }
}

export default (
  <Route path='/' component={App}>
    <IndexRoute component={LoginForm} />
    <Route path='register(/:socialService)' component={RegisterForm} />
    <Route path='verify' component={Verification} />
    <Route path='verifyerror' component={Verification} />
    <Route path='direct-payment' component={DirectPayment} />
    <Route path='classes' component={Classes} onEnter={requireTeacher} />
    <Route
      path='students(/:classID)'
      component={Students}
      onEnter={requireUserCanManageStudents}
    />
    <Route path='teachers' component={Teachers} onEnter={requireSuperTeacher} />
    <Route path='profile' component={Profile} onEnter={requireUser} />
    <Route path='payment' component={Payment} onEnter={requireTeacher} />
    <Route path='courses(?:signup)' component={Courses} onEnter={requireUser} />
    <Route
      path='courseLessons(/:courseID)'
      component={CourseLessons}
      onEnter={requireUser}
    />
    <Route
      path='lesson(/:courseID)(/:lessonID)'
      component={Lesson}
      onEnter={requireUser}
    />
    <Route
      path='quiz(/:courseID)(/:lessonID)(/:quizID)'
      component={Quiz}
      onEnter={requireUser}
    />
    <Route
      path='quizComplete(/:courseID)(/:lessonID)(/:quizID)'
      component={QuizCompleteContainer}
      onEnter={requireUser}
    />
    <Route
      path='quizAnswers(/:courseID)(/:lessonID)(/:quizID)'
      component={QuizAnswersContainer}
      onEnter={requireTeacher}
    />
    <Route
      path='classprogress(/:classID)(/:lessonID)'
      component={ClassProgressReport}
      onEnter={requireTeacher}
    />
    <Route
      path='studentprogress(/:classID)(/:studentID)'
      component={TeacherStudentReport}
      onEnter={requireTeacher}
    />
    <Route
      path='teacherprogress(/:teacherID)'
      component={SuperTeacherSubTeacherReport}
      onEnter={requireSuperTeacher}
    />
    <Route
      path='customLessons(/:classID)(/:courseID)'
      component={customLessons}
      onEnter={requireTeacher}
    />
    <Route path='progress' component={StudentReport} onEnter={requireUser} />
    <Route
      path='writingassignment(/:courseID)(/:lessonID)(/:waID)'
      component={WritingAssignment}
      onEnter={requireUser}
    />
    <Route
      path='gradingwritingassignment(/:classID)'
      component={gradingWA}
      onEnter={requireTeacher}
    />
    <Route
      path='buildSuperDiagnostic(/:classID)'
      component={BuildSuperDiagnostic}
      onEnter={requireTeacher}
    />
    <Route
      path='super-diagnostic(/:classID)'
      component={SuperDiagnosticQuiz}
      onEnter={requireUser}
    />
    <Route
      path='superDiagnosticComplete'
      component={SuperDiagnosticComplete}
      onEnter={requireUser}
    />
    <Route
      path='super-diagnostic-report-class(/:classID)(/:topicID)'
      component={SuperDiagnosticClassReportLayout}
      onEnter={requireTeacher}
    />
    <Route
      path='super-diagnostic-report-student(/:classID)(/:studentID)'
      component={SuperDiagnosticStudentReportLayout}
      onEnter={requireTeacher}
    />
    <Route path='*' component={LoginForm} />
  </Route>
);
