/*
* Contains all the components for displaying and changing payment plans
List of possible payment plans are in the FAQ.md
*/

import * as React from "react";
import constants from "../../constants";
import MainMenu from "../menu/MainMenu";
import { GFUser, GFPlan, GFInitialState } from "../../models/models";
import { connect } from "react-redux";
import {
  manualAjaxEnd,
  manualAjaxStart,
} from "../../actions/ajaxStatusActions";
import VerificationAlert from "../common/VerificationAlert";
import * as moment from "moment";

import {
  cancelSubscription,
  couponCheck,
  updateCard,
} from "../../actions/paymentActions";
import { toastr } from "react-redux-toastr";
// import { LinkContainer } from "react-router-bootstrap";
import {
  Grid,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import PaymentButton from "./PaymentButton";
import UpdateCardSection from "./UpdateCardSection";
import { ManageAccountButtons } from "./ManageAccountButtons";
import { selectIsTeacher, selectIsSuperTeacher, hasSubscription, selectHasGoogleClasses } from "../../reducers/userReducer";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import CheckoutModal from "./CheckoutModal";
import { find } from "lodash";

const graham = require("../../images/graham.gif");
const masterGraham = require("../../images/diploma.png");
interface Props extends React.Props<Checkout> {
  user: GFUser;
  loading: boolean;
  cancelSubscription: () => Promise<void>;
  updateCard: (paymentToken: string) => Promise<void>;
  manualAjaxEnd: any;
  manualAjaxStart: any;
  hasGoogleClasses: boolean;
  isTeacher: boolean;
  isSuperTeacher: boolean;
  stripe: Stripe;
  elements: StripeElements;
}

interface State {
  planRecurringType: string;
  StripeKey: string;
  newPaymentPlan: string;
  paymentPlanStudents: string;
  showPaymentModal: boolean;
  masterButton: string;
  showCreditCardModal: boolean;
  selectedPlan: GFPlan;
}

class Checkout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      planRecurringType: "Annual",
      StripeKey: `${process.env.REACT_APP_STRIPE}`,
      newPaymentPlan: "",
      paymentPlanStudents: "50", // 50, 100, ''
      showPaymentModal: false,
      masterButton: "",
      showCreditCardModal: false,
      selectedPlan: constants.paymentPlanObjectsByID.masterAnnual50,
    };
  }
  componentDidMount(){
    if(this.props.user.paymentPlan){
      this.updateSelectedPaymentPlan(this.props.user.paymentPlan)
    }
  }

  cancelSubscription = () => {
    this.props
      .cancelSubscription()
      .then((user) => {
        toastr.success(
          "Success",
          "You have successfully cancelled your subscription.",
          constants.toastrSuccess
        );
      })
      .catch((error) => {
        console.error("Unable to delete subscription", error);
        const message = "cancel subscription";
        constants.handleError(error, message);
      });
  };

  showConfirmationModal = () => {
    const toastrConfirmOptions = {
      onOk: () => {
        this.cancelSubscription();
      },
      onCancel: () => console.log("CANCEL: clicked"),
      okText: "Yes, remove me.",
      cancelText: "No, I want to stay.",
    };
    toastr.confirm(
      "Are you sure you want to cancel your subscription?",
      toastrConfirmOptions
    );
  };

  openUpdateCreditCard = () => {
    this.setState({ showCreditCardModal: true });
  };

  closeUpdateCreditCard = () => {
    this.setState({ showCreditCardModal: false });
  };

  closePaymentModal = () => {
    this.setState({ showPaymentModal: false });
  };

  openPaymentModal = () => {
      this.setState({ showPaymentModal: true });
  };
  handleChange = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    const targetName = e.currentTarget.name;
    this.setState({ [targetName]: e.currentTarget.value } as unknown as State, () => {
      this.updateSelectedPaymentPlan();
    });
  };
  
  togglePlanRecurringType = () => {
    this.setState(
      { planRecurringType: this.state.planRecurringType === "Annual" ? "Monthly" : "Annual" },
      () => {
        this.updateSelectedPaymentPlan();
      }
    );
  };

  /*
   * Update Credit Card Modal
   */
  updateCreditCardModal = () => {
    return (
      <Modal
        show={this.state.showCreditCardModal}
        onHide={this.closeUpdateCreditCard}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Update Credit Card</Modal.Title>
        </Modal.Header>
        <UpdateCardSection
          user={this.props.user}
          loading={this.props.loading}
          show={this.state.showCreditCardModal}
          hide={this.closeUpdateCreditCard}
          updateCard={this.props.updateCard}
          manualAjaxStart={this.props.manualAjaxStart}
          manualAjaxEnd={this.props.manualAjaxEnd}
          stripe={this.props.stripe}
          elements={this.props.elements}
        />
      </Modal>
    );
  };

  displayExpirationDate = () => {
    const { user } = this.props;
    const showDate = moment
      .utc(user.expirationDate)
      .local(true)
      .format("MMMM Do, YYYY");
    let message;

    if (user.isActive) {
      if (user.isCancelling) {
        message = "Cancellation pending.  Account Expires on";
      } else if (user.paymentPlan === "trial") {
        message = "Trial Account Expires on";
      } else if (user.isPurchaseOrder === true) {
        message = "Account expires on"; // purchase orders do not automatically renew
      } else {
        message = "Account renews on";
      }
      if (user.isPurchaseOrder) {
        message = `This is a purchase order subscription with ${user.maxStudents} student seats.  ${message}`;
      }
    } else {
      message = "Account Expired on";
    }

    return (
      <div>
        {message} {showDate}
      </div>
    );
  };

  /*
   * updateSelectedPaymentPlan
   * figure out the selectedPayment plan and set it to state
   */
  updateSelectedPaymentPlan = (stripePaymentPlanString?: string) => {
    const masterPlanPriceTitle = `master${this.state.planRecurringType}${this.state.paymentPlanStudents}_2`;
    let selectedPlan = constants.paymentPlanObjectsByID[masterPlanPriceTitle] || constants.paymentPlanObjectsByID.masterMonthly50;

    if (stripePaymentPlanString && stripePaymentPlanString !== 'trial' && stripePaymentPlanString !== 'expired' && stripePaymentPlanString !== 'cancelled'){
      const paymentPlan = find(constants.paymentPlanObjectsByID, {id: stripePaymentPlanString});
      if (paymentPlan){
        selectedPlan = paymentPlan;
      }
    }
    // console.log(
    //   "updating selected payment plan",
    //   selectedPlan,
    //   masterPlanPriceTitle,
    //   stripePaymentPlanString
    // );
    this.setState({ selectedPlan, planRecurringType: selectedPlan.time, paymentPlanStudents: `${selectedPlan.size}` });
  };

  isHomeschoolMonthly = () => {
    return (
      this.state.paymentPlanStudents === "5" &&
      this.state.planRecurringType === "Monthly"
    );
  };

  render() {
    const { isTeacher, isSuperTeacher } = this.props;
    let amountOfStudents = this.state.selectedPlan.size;
    if (this.state.paymentPlanStudents === "") {
      amountOfStudents = 150;
    }
    const subscription: boolean = hasSubscription(this.props.user);

    const masterPlanPrice = this.state.selectedPlan.price / 100;
    const masterPlanPriceString = masterPlanPrice.toFixed(2);

    // TODO can we simply change the paymentPlans time attribute to "Annually"

    return (
      <div>
        <Grid className="content modal-container">
          <div className="sidemenu">
            <MainMenu isTeacher={isTeacher} isSuperTeacher={isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
            <div className="logo text-center">
              <img alt="graham" src={graham} />
            </div>
          </div>
          <div className="main-content content-with-sidebar payment">
            <VerificationAlert user={this.props.user} isTeacher={isTeacher} />
            <Row>
              <Col md={12} xs={12}>
                <div className="payment-header">
                  <h1>Payment and Plans</h1>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={12}>
                <div className="link-to-other-class-numbers text-center">
                  <h3>{this.displayExpirationDate()}</h3>
                </div>
              </Col>
            </Row>
            <Row className="display-payment-options">
              {/*
               * Display Payment Modal
               */}
              <CheckoutModal
                show={this.state.showPaymentModal}
                stripe={this.props.stripe}
                selectedPlan={this.state.selectedPlan}
                hide={this.closePaymentModal}
                elements={this.props.elements}
                togglePlanRecurringType={this.togglePlanRecurringType}
              />
              {/*
               * Display Update Credit Card Modal
               */
              this.updateCreditCardModal()}

              <Col md={5} xs={5} style={{ padding: 0 }}>
                <div className="payment-col-1">
                  <div className="top-box">
                    <form>
                      <Row className="plan-select-input">
                        <Col md={11}>
                          <FormGroup controlId="planStudents">
                            <FormControl
                              componentClass="select"
                              placeholder="select number of students"
                              value={this.state.paymentPlanStudents}
                              name="paymentPlanStudents"
                              disabled={this.props.loading}
                              onChange={this.handleChange}
                            >
                              <option value="5">
                                5 Student Package (Homeschool)
                              </option>
                              <option value="25">25 Student Package</option>
                              <option value="50">50 Student Package</option>
                              <option value="75">75 Student Package</option>
                              <option value="100">100 Student Package</option>
                              <option value="125">125 Student Package</option>
                              <option value="150">150 Student Package</option>
                            </FormControl>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="plan-select-input">
                        <Col md={11}>
                          <FormGroup controlId="planRecurringType">
                            <FormControl
                              componentClass="select"
                              placeholder="select recurring type"
                              value={this.state.planRecurringType}
                              name="planRecurringType"
                              disabled={this.props.loading}
                              onChange={this.handleChange}
                            >
                              <option value="Annual">Annual</option>
                              <option value="Monthly">Monthly</option>
                            </FormControl>
                          </FormGroup>
                        </Col>
                      </Row>
                      <ManageAccountButtons
                        hasSubscription={subscription}
                        openUpdateCreditCard={this.openUpdateCreditCard}
                        showConfirmationModal={this.showConfirmationModal}
                        openPaymentModal={this.openPaymentModal}
                        isPurchaseOrder={this.props.user.isPurchaseOrder}
                      />
                    </form>
                  </div>
                  {this.isHomeschoolMonthly() === false && (
                    <ListGroup>
                      {/* Build the header row */}
                      <ListGroupItem className="list-item col-1">
                        <p>Over 85 Instructional Units</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Diagnostic Assessments</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Video and Slideshow Lessons</p>
                      </ListGroupItem>
                      {/* <ListGroupItem className="list-item col-1">
                      <p>Integrated Kahoot Review Games</p>
                    </ListGroupItem> */}
                      <ListGroupItem className="list-item col-1">
                        <p>Practice Exercises</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Writing Application Activities</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Teacher Writing Portals</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Post-Evaluations</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Virtual Badges</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Student Progress Reporting</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Integrated Kahoot Review Games</p>
                      </ListGroupItem>
                    </ListGroup>
                  )}
                </div>
              </Col>
              <Col md={7} xs={7} style={{ padding: 0 }}>
                {this.isHomeschoolMonthly() && (
                  <h3 style={{ padding: "20px" }}>
                    Homeschool plans are annual only.  Please select annual.
                  </h3>
                )}
                {this.isHomeschoolMonthly() === false && (
                  <div className="payment-col-2">
                    <div className="top-box">
                      <Row>
                        <img alt="master graham" src={masterGraham} />
                      </Row>
                      <Row>
                        <h1 className="col-2">GrammarFlip Subscription</h1>
                        <h5 className="display-amount-of-students">
                          {amountOfStudents} Students
                        </h5>
                        <h3 style={{ fontWeight: 600, marginTop: "10px" }}>
                          ${masterPlanPriceString} /
                          {this.state.planRecurringType}
                        </h3>
                      </Row>
                      <Row className="payment-button">
                        <PaymentButton
                          selectedPlan={this.state.selectedPlan}
                          handleClick={this.openPaymentModal}
                          paymentPlan={this.props.user.paymentPlan}
                          loading={this.props.loading}
                          hasStripeSubscription={
                            hasSubscription(this.props.user) &&
                            this.props.user.isPurchaseOrder === false
                          }
                          paymentPlanStudents={this.state.paymentPlanStudents}
                        />
                      </Row>
                    </div>
                    <ListGroup>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      {/* <ListGroupItem className="list-item col-2">
                      <div className="fa fa-check green" />
                    </ListGroupItem> */}
                    </ListGroup>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  return {
    user: state.user,
    loading: state.ajaxCallsInProgress > 0,
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state),
    hasGoogleClasses: selectHasGoogleClasses(state),
  };
};

const myCheckoutForm = connect(mapStateToProps, {
  cancelSubscription,
  couponCheck,
  updateCard,
  manualAjaxEnd,
  manualAjaxStart,
})(Checkout);
// export default injectStripe(myCheckoutForm);
export default myCheckoutForm;
