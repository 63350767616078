import * as React from "react";

import {
  Button,
  Col,
  ControlLabel,
  FormGroup,
  Grid,
  Row,
  Image,
} from "react-bootstrap";
import { printElement } from "./StudentReportUtil";
import {
  GFInitialState,
  GFStudent,
  GFSuperDiagnosticReportReducer,
  GFUser,
  Option,
  GFSuperDiagnosticTopic,
} from "../../models/models";
import { RouteComponentProps, browserHistory, Link } from "react-router";
import { find, isEmpty, orderBy } from "lodash";

import {
  getSuperDiagnosticReportClass,
  getSuperDiagnosticReportTopic,
  getTopics,
} from "../../actions/superDiagnosticActions";

import MainMenu from "../menu/MainMenu";
import Select from "react-select";
import { connect } from "react-redux";
import { loadClasses } from "../../actions/classActions";
import { loadStudents } from "../../actions/studentActions";
import { selectIsTeacher, selectIsSuperTeacher, selectIsTrial, selectHasGoogleClasses } from "../../reducers/userReducer";
import SuperDiagnosticReportClass from "./SuperDiagnosticReportClass";
import SuperDiagnosticReportTopic from "./SuperDiagnosticReportTopic";
import { selectClassOptions } from "../../reducers/classReducer";

const mixpanel = require("mixpanel-browser");
const graham = require("../../images/graham.gif");
const diagnosticDemo = require("../../images/Diagnostic_Results.gif")

interface MatchParams {
  classID: string;
  topicID: string;
}

interface Props extends RouteComponentProps<MatchParams, {}> {
  user: GFUser;
  data: GFSuperDiagnosticReportReducer;
  students: GFStudent[];
  classOptions: Option[];
  loading: boolean;
  loadStudents: () => Promise<any>;
  loadClasses: () => Promise<any>;
  getSuperDiagnosticReportClass: typeof getSuperDiagnosticReportClass;
  getStudentQuizResult: any;
  hasGoogleClasses: boolean;
  isTeacher: boolean;
  isSuperTeacher: boolean;
  getSuperDiagnosticReportTopic: typeof getSuperDiagnosticReportTopic;
  selectedTopic: GFSuperDiagnosticTopic;
  getTopics: () => Promise<void>;
  superDiagnosticTopics: { [key: string]: GFSuperDiagnosticTopic };
  isTrial: boolean;
}

interface State {
  selectedClass: Option | null;
}

class SuperDiagnosticClassReportLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedClass: null,
    };
  }

  componentDidMount() {
    mixpanel.track("Viewing Class Diagnostic Report");
    this.checkDependencies();
  }

  /*
   * check for dependencies. once you get one, try again by recursively calling self
   */
  checkDependencies = () => {
    let dependencyPromises: Array<Promise<any>> = [];

    if (this.props.students.length === 0) {
      dependencyPromises = [...dependencyPromises, this.props.loadStudents()];
    }
    if (this.props.classOptions.length === 0) {
      dependencyPromises = [...dependencyPromises, this.props.loadClasses()];
    }
    if (isEmpty(this.props.superDiagnosticTopics)) {
      dependencyPromises = [...dependencyPromises, this.props.getTopics()];
    }
    if (dependencyPromises.length) {
      Promise.all(dependencyPromises)
        .then(() => {
          this.updateSelects();
        })
        .catch((error) => {
          console.error("failed to get dependencies", error);
        });
    } else {
      this.updateSelects();
    }
  };
  updateSelects = () => {
    const { classID } = this.props.params;
    if (this.props.classOptions && this.props.classOptions.length) {
      const selectedClass =
        find(this.props.classOptions, (obj: any) => {
          return obj.value === classID;
        }) || this.props.classOptions[0];
      this.setState({ selectedClass }, () => {
        this.updateReports();
      });
    } else {
      console.log("skipping update selects");
    }
  };

  /*
   * updateReports
   * update the topic report and class report
   */
  updateReports = () => {
    // replace current path so we know which course is selected
    const { selectedClass } = this.state;
    if (selectedClass) {
      const path = `/super-diagnostic-report-class/${selectedClass.value}/${
        this.props.params.topicID || ""
      }`;
      browserHistory.replace(path);
      this.props.getSuperDiagnosticReportClass(selectedClass.value);
      this.props.getSuperDiagnosticReportTopic(
        selectedClass.value,
        this.props.params.topicID
      );
    }
  };

  // need to adjust Option type so changing to any for now
  handleClassChange = (selectedClass: any) => {
    this.setState({ selectedClass }, () => {
      this.updateReports();
    });
  };

  doPrint = () => {
    const classForPrint =
      this.state.selectedClass === null ? "" : this.state.selectedClass.label;
    mixpanel.track("Printing Diagnostic Results", {
      className: classForPrint,
    });
    printElement(document.getElementsByClassName("students")[0], false, null);
    window.print();
  };

  render() {
    if (this.props.user.isActive === false) {
      return (
        <Grid className="content modal-container">
          <div className="sidemenu">
            <MainMenu isTeacher={this.props.isTeacher} isSuperTeacher={this.props.isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
            <div className="logo text-center">
              <img alt="graham" src={graham} />
            </div>
          </div>
          <div className="students main-content content-with-sidebar">
            <Row className="students-header" style={{marginBottom: '20px'}}>
              <Col md={10} xs={10} className="text-xl-left">
              <h1 className="pageDescription">Diagnostic Results</h1>
              <p>Diagnostic results not available. <Link to="/payment">Upgrade to a subscription plan to access your students' results!</Link></p>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Image src={diagnosticDemo} responsive />
              </Col>
            </Row>
          </div>
        </Grid>
      );
    }
    const classForPrint =
      this.state.selectedClass === null ? "" : this.state.selectedClass.label;
    return (
      <Grid className="content modal-container">
        <div className="sidemenu">
          <MainMenu isTeacher={this.props.isTeacher} isSuperTeacher={this.props.isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
          <div className="logo text-center">
            <img alt="graham" src={graham} />
          </div>
        </div>
        <div className="students main-content content-with-sidebar">
          <Row className="students-header">
            <Col md={10} xs={10} className="text-xl-left">
              <h1 className="pageDescription">
                Diagnostic Results
                <Button
                  onClick={this.doPrint}
                  bsStyle="default"
                  type="button"
                  bsSize="sm"
                >
                  Print
                </Button>
              </h1>
            </Col>
          </Row>
          <Row className="class-form">
            <Col xs={8}>
              <FormGroup className="class-lesson-selects">
                <Row>
                  <Col xs={4} lg={3} style={{ marginLeft: "-8px" }}>
                    <ControlLabel>Class: </ControlLabel>
                  </Col>
                  <Col
                    xs={8}
                    lg={9}
                    className="class-row"
                    style={{ marginLeft: "-70px" }}
                  >
                    <Select
                      className="react-select-container"
                      options={this.props.classOptions}
                      onChange={this.handleClassChange}
                      value={this.state.selectedClass}
                      placeholder="select a class"
                      classNamePrefix="react-select"
                      isSearchable={false}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <Row
                className="class-lesson-selects"
                style={{ marginBottom: "25px" }}
              >
                <Col xs={7} lg={5}>
                  <ControlLabel>Students Completed:</ControlLabel>
                </Col>
                <Col xs={3} lg={5} style={{ marginLeft: "-100px" }}>
                  {this.props.data.classStats.studentsTakenQuiz} of{" "}
                  {this.props.data.classStats.totalStudents}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="for-print">
            <Col xs={12}>Class: {classForPrint}</Col>
          </Row>
          <Row className="class-table">
            <Col lg={6} xs={12} style={{ marginBottom: "20px" }}>
              <SuperDiagnosticReportTopic
                selectedTopic={this.props.selectedTopic}
                params={this.props.params}
              />
            </Col>
            <Col lg={6} xs={12}>
              <SuperDiagnosticReportClass params={this.props.params} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} style={{ marginTop: "20px" }}>
              <small>* suggested diagnostic topic</small>
            </Col>
          </Row>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  const classOptions = selectClassOptions(state);
  const isTrial = selectIsTrial(state);
  return {
    data: state.superDiagnosticReport,
    user: state.user,
    students: state.students,
    classOptions,
    loading: state.ajaxCallsInProgress > 0,
    hasGoogleClasses: selectHasGoogleClasses(state),
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state),
    superDiagnosticTopics: state.superDiagnosticTopics,
    selectedTopic: state.superDiagnosticTopics[ownProps.params.topicID],
    isTrial,
  };
};

export default connect(mapStateToProps, {
  getSuperDiagnosticReportClass,
  getSuperDiagnosticReportTopic,
  loadStudents,
  loadClasses,
  getTopics,
})(SuperDiagnosticClassReportLayout);
