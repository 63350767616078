import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import { GFInitialState } from "../models/models";

export const selectedClassIDReducer = (
  state: string = initialState.selectedClassID,
  action: any
): string => {
  switch (action.type) {
    case types.SET_SELECTED_CLASS_ID:
      return action.classID;
    case types.CLEAR_SELECTED_CLASS_ID:
      return initialState.selectedClassID;
    case types.USER_LOGOUT_SUCCESS:
      return initialState.selectedClassID;
    default:
      return state;
  }
};

export function createSelectedIDWithName(name: string) {
  return (state: string = "", action: any): string => {
    switch (action.type) {
      case `SET_SELECTED_${name}`:
        return action.id;
      case `CLEAR_SELECTED_${name}`:
        return "";
      case types.USER_LOGOUT_SUCCESS:
        return "";
      default:
        return state;
    }
  };
}

export function appSettingsReducer(
  state: GFInitialState["appSettings"] = initialState.appSettings,
  action: any
): GFInitialState["appSettings"] {
  switch (action.type) {
    case types.TEACHER_REGISTER_SUCCESS:
      return { version: `${process.env.REACT_APP_VERSION}` };
    case types.USER_LOGIN_SUCCESS:
      return { version: `${process.env.REACT_APP_VERSION}` };
    case types.STUDENT_REGISTER_SUCCESS:
      return { version: `${process.env.REACT_APP_VERSION}` };
    case types.UPDATE_APP_SETTINGS:{
      return action.payload;
    }
    default:
      return state;
  }
}

export function createShowModalWithNamedType(modalName = "") {
  return function modalToggle(state = false, action: any): boolean {
    switch (action.type) {
      case `TOGGLE_MODAL_${modalName}`:
        return !state;
      case `SHOW_MODAL_${modalName}`:
        return true;
      case `HIDE_MODAL_${modalName}`:
        return false;
      default:
        return state;
    }
  };
}
