import React from 'react';
import { connect } from "react-redux";
import { GFInitialState, GFUser } from "../../models/models";
import Joyride from 'react-joyride';
import { browserHistory } from "react-router";
import { Image } from "react-bootstrap";

const splash = require("../../images/GrammerFlip_TutorialSplash.png");

const joyrideSteps: Array<any> = [
  {
    target: 'body',
    title: 'Want to take a tour?',
    isFixed: true,
    disableBeacon: true,
    content: <Image src={splash} responsive style={{maxHeight: '299px', margin: '0 auto'}} />,
    placement: 'center',
    styles: { options: { zIndex: 10000, width: '600px' } },
  },
  {
    target: 'body',
    title: 'Step 1: Create a Class Section & Invite Students',
    isFixed: true,
    disableBeacon: true,
    content: <iframe
      className='joyride-iframe'
      src={'https://fast.wistia.net/embed/iframe/od146ls9lw'}
      frameBorder="0"
      allowFullScreen={true}
      title="Video"
    />,
    placement: 'center',
    styles: { options: { zIndex: 10000, width: '600px' } },
  },
  {
    target: 'body',
    title: 'Step 2: Build a Diagnostic Assessment',
    isFixed: true,
    content: <iframe
      className='joyride-iframe'
      src={'https://fast.wistia.net/embed/iframe/plcphaas0c'}
      frameBorder="0"
      allowFullScreen={true}
      title="Video"
    />,
    placement: 'center',
    styles: { options: { zIndex: 10000, width: '600px' } },
  },
  {
    target: 'body',
    title: 'Step 3: Customize Your Lessons',
    isFixed: true,
    content: <iframe
      className='joyride-iframe'
      src={'https://fast.wistia.net/embed/iframe/z65ktte4i9'}
      frameBorder="0"
      allowFullScreen={true}
      title="Video"
    />,
    placement: 'center',
    styles: { options: { zIndex: 10000, width: '600px' } },
  }
];

interface Props {
  isTeacher: boolean;
  user: GFUser;
  userUpdateShowTutorial: (user: GFUser) => void;
}

interface State {
  showJoyride: boolean;
}

class HelpWidget extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      showJoyride: false
    }
  }

  resetJoyride = (restart?: any) => { }

  startJoyride = () => {
    this.setState({
      showJoyride: true
    });
  }

  autostartJoyride = (location: any) => {
    const { user } = this.props;
    if (location.pathname === '/classes' && user.showTutorial) {
      this.resetJoyride(true);
      this.setState({
        showJoyride: true
      });
      this.props.userUpdateShowTutorial({...user, showTutorial: false});
    }
  }

  componentDidMount() {
    const location = browserHistory.getCurrentLocation();
    this.autostartJoyride(location);
    browserHistory.listenBefore(nextLocation => {
      this.autostartJoyride(nextLocation);
    });
  }

  render() {
    const { showJoyride } = this.state;
    if(!this.props.isTeacher){
      return null;
    }
    return (
      <>
        <div
          className="help-widget-trigger"
          onClick={() => {
            this.resetJoyride(true);
            this.setState({
              showJoyride: true
            });
          }}
        />
        <Joyride
          run={showJoyride}
          steps={joyrideSteps}
          continuous
          showProgress
          styles={{
            options: {
              primaryColor: '#1C4D9F'
            }
          }}
          getHelpers={({ reset }) => {
            this.resetJoyride = reset;
          }}
          callback={({ action }) => {
            if (action === "close") {
              this.setState({
                showJoyride: false
              });
            }
          }}
        />
      </>
    )
  }

}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  return {};
};

export default connect(mapStateToProps, {})(HelpWidget);
