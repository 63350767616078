import * as React from 'react';
import { ThunkAction } from 'redux-thunk';
import {
  GFInitialState,
  GFLesson,
  Option,
} from "../../models/models";
import { Button, ListGroupItem, Media } from 'react-bootstrap';
import GoogleShareToClassRoomButton, { GoogleShareablesTypeMap } from "../common/GoogleShareToClassRoomButton";
import { quizTypeEnum } from "../../enums";

const FontAwesome = require('react-fontawesome');


export const QuizWAListItem = ({
  isTeacher,
  item,
  itemUrl,
  enabled,
  onClick,
  onPrintView,
  isGoogleUser,
  lesson,
  postQuizToGoogleClassroom,
  postWAToGoogleClassroom,
  classOptions,
  type,
  isTrial,
}: {
  isTeacher: boolean;
  item: { imagePath: string; name: string; isComplete: boolean, type?: number };
  itemUrl?: string;
  enabled: boolean;
  onClick: (item: any) => void;
  onPrintView: (item: any) => void;
  isGoogleUser?: boolean;
  lessonID?: string;
  lesson: GFLesson;
  postQuizToGoogleClassroom:  (parentID: string, link: string, classID: string) => ThunkAction<any, GFInitialState, undefined, any>;
  postWAToGoogleClassroom: (parentID: string, link: string, classID: string) => ThunkAction<any, GFInitialState, undefined, any>;
  classOptions?: Option[];
  type: keyof GoogleShareablesTypeMap;
  isTrial: boolean;
}) => {
  const canPrintView = item.type && item.type < 4 && isTeacher;
  let showGoogleClassroom = isTeacher && isGoogleUser && itemUrl;
  let gfImage = item.imagePath;
  let className = '';
  let isDisabled = false;
  if (item.imagePath === '') {
    gfImage = require('../../images/graham-thumb.jpg');
  }
  if (!enabled) {
    className = 'pe-gray-out';
  }
  if(item.type === quizTypeEnum.postEval && isTrial){
    isDisabled = true;
  }
  return (
    <ListGroupItem
      className={className}
    >
      <Media>
        <Media.Left className={'pointer'} onClick={() => {
          onClick(item);
        }}>
          <img width={64} height={64} src={gfImage} alt="gfImage" />
        </Media.Left>
        <Media.Body>
          <Media.Heading className={isDisabled ? 'gray-text pointer' : 'pointer'} onClick={() => {
            onClick(item);
          }}>{item.name}</Media.Heading>
        </Media.Body>
        <Media.Right>
          <div className='list-group-item-media-right'>

            {(canPrintView && !isDisabled) && <Button bsSize="small" onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onPrintView(item);
            }}>Print/View Answers</Button>}

            {(showGoogleClassroom && item && itemUrl && classOptions) && (
              <GoogleShareToClassRoomButton
                type={type}
                item={item}
                link={itemUrl}
                shareToClassRoom={type === 'quiz' ? postQuizToGoogleClassroom : postWAToGoogleClassroom}
                lesson={lesson}
                subtitle={item.name}
                classOptions={classOptions}
                hide={isDisabled}
              />
            )}

            {item.isComplete === true && (
              <div style={{ paddingLeft: 10 }}><FontAwesome name="circle 2x blue" /></div>
            )}
          </div>

        </Media.Right>

      </Media>
    </ListGroupItem>
  );
};
