/*
* Student Count Banner shows teachers how many student slots they have available
*/

import * as React from 'react';
import { Row, Col, Alert, Button } from 'react-bootstrap';
import { GFClass, GFUser } from '../../models/models';

interface Iprops {
  user: GFUser;
  classes: GFClass[];
  onReset: () => void;
}

export const StudentCountBanner = (props: Iprops) => {
  const { user, classes, onReset } = props;
  const purchasedSeats = user.maxStudents;
  let filledSeats = 0;
  classes.forEach(gfClass => {
    filledSeats += gfClass.classSize;
  });
  const availableSeats = purchasedSeats - filledSeats;
  const numberStyle: React.CSSProperties = { fontSize: `1.5em` };
  return (
    <Row className="verification-header" style={{ paddingTop: 10 }}>
      {/* <Col xs={12} xsOffset={0} lg={5} lgOffset={7}></Col> */}
      <Col xs={12}>
        <Alert bsStyle="info" className='flex-banner-alert'>
          <div>
            <span style={{ paddingLeft: '20px' }}>Filled Student Seats: </span>
            <span style={numberStyle}>{filledSeats}</span>
            <span style={{ paddingLeft: '20px' }}>Available Student Seats: </span>
            <span style={numberStyle}>{availableSeats}</span>
          </div>
          {classes.length > 0 && (
          <div>
            {/* <Button className={'btn-sm'} bsStyle='primary' onClick={onReset}>
              <i className="fa fa-undo" aria-hidden="true" style={{marginRight:10}} />
              Reset All
            </Button> */}
          </div>
          )}
        </Alert>
        {/* <Alert bsStyle="warning" >
            <h3 style={{marginTop: '0'}}>Student Seats</h3>
          <span>Purchased: </span>
          <span style={numberStyle}>
          {purchasedSeats}

          </span>
          <span style={{paddingLeft: '20px'}}>Filled: </span>
          <span style={numberStyle}>

          {filledSeats}
          </span>
          <span style={{paddingLeft: '20px'}}>Available: </span>
          <span style={numberStyle}>

          {availableSeats}
          </span>
        </Alert> */}
      </Col>
    </Row>
  );
};
