import moment from 'moment';
import { GFInitialState } from '../models/models';

// export const initialQuizQuestion = {
//   id: "",
//   text: "",
//   type: "",
//   options: [],
//   correctAnswer: "",
//   correctText: "",
//   wrongText: "",
//   order: 0
// }

export const initialCourse = {
  id: '',
  name: '',
  description: ''
};
export const initialQuiz = {
  id: '',
  name: '',
  imagePath: '',
  isComplete: false,
  videoPath: '',
  instructions: '',
  lessonID: '',
  questions: [],
  type: 2
};
export const initialQuizResult = {
  id: '',
  studentID: '',
  quizID: '',
  lessonID: '',
  quiz: initialQuiz,
  answers: [],
  score: 0,
  createDate: '',
  className: '',
  scoreString: '',
  isComplete: false,
  isLocked: false
};

export const initialCustomLesson = {
  id: '',
  lessonID: '',
  classID: '',
  lessonEnabled: true,
  diagnosticEnabled: true,
  postEvalEnabled: false,
  postEvalAnswersEnabled: false
};

export const initialLesson = {
  id: '',
  name: '',
  description: '',
  courseID: '',
  imagePath: '',
  order: 0,
  primaryVideoPath: '',
  secondaryVideoPath: '',
  slideshowPath: '',
  courseLessons: [],
  kahootPath: '',
  commonCoreNumber: ''
};

export const initialClass = {
  id: '',
  name: '',
  code: '',
  classSize: 0,
  createDate: '',
  superDiagnosticEnabled: false
};
export const initialStudent = {
  id: '',
  userID: '',
  classID: '',
  student: {
    id: '',
    gender: '',
    first: '',
    last: '',
    username: '',
    password: '',
    password2: '',
    roleID: '',
    paymentPlan: '',
    classes: []
  },
  class: initialClass
};

/*
* closest previous Aug 1st of the current date? So...
1) If it's July 31, 2018, the default selected start date would be Aug 1, 2017
2) If it's Sept 10, 2018, the default selected start date would be Aug 1, 2018
3) If it's Feb 2, 2019, the default selected start date would be Aug 1, 2018
*/
const calculateStartDate = (): string => {
  // do we need to subtract a year?
  // yes if this is before the 1st of the August
  const startDate = moment();
  if (startDate.month() < 7) {
    return startDate
      .subtract(1, 'years')
      .month(7)
      .date(1).toISOString();
  } else {
    return startDate.month(7).date(1).toISOString();
  }
};
export const initialDateRange = {
  startDate: calculateStartDate(),
  endDate: new Date().toISOString()
};

export const initialOption = {
  value: '',
  label: ''
};
export const intialQuizAnswer = {
  questionID: '',
  answer: '',
  isCorrect: false
};
export const initialQuizResultReducer = {
  show: false,
  quizResult: initialQuizResult
};

export const initialWritingAssignment = {
  id: '',
  lessonID: '',
  name: '',
  description: '',
  imagePath: '',
  isComplete: false,
  createDate: ''
};
export const initialUser = {
  apiKey: '',
  id: '',
  gender: '',
  first: '',
  last: '',
  email: '',
  password: '',
  password2: '',
  role: '',
  roleID: '',
  howDidYouHearAboutUs: '',
  hearOther: '',
  maxStudents: 0,
  username: '',
  classCode: '',
  under13: '',
  isVerified: false,
  classes: [],
  isActive: false,
  paymentPlan: '',
  lastUpdated: '',
  isPurchaseOrder: false,
  isCancelling: false,
  expirationDate: '',
  stripeSubscriptionID: '',
  showTutorial: true,
  UTMSource : '',
  UTMMedium : '',
  UTMCampaign : '',
  UTMContent : '',
  UTMTerm : '',
  GCLID : '',
  mustUpdatePassword: false,
  // Super Teacher
  isSuperTeacher: false,
  superTeacherExpiration: '',
  superTeacherPlans: [],
  calculatedSuperTeacherPlans: [],
  subTeachers: [],
};

const initialState: GFInitialState = {
  toastr: { toastrs: [] },
  user: initialUser,
  selectedClassID: '',
  socialObj: {},
  googleClasses: [],
  classes: [],
  courses: {},
  lessons: {},
  quizzes: {},
  quizView: {
    quizAnswersSubmitted: false,
    inProgressQuizID: '',
    quizAnswers: [],
  },
  superDiagnosticView:{
    quizAnswersSubmitted: 0,
    inProgressQuizID: '',
    quizAnswers: [],
    canTakeSuperDiagnostic: false
  },
  quizResult: initialQuizResultReducer,
  students: [],
  writingAssignments: {},
  waResultsByID: {},
  badges: [],
  ajaxCallsInProgress: 0,
  classProgressRaw: {},
  myProgressRaw: [],
  customDiagnosticsByID: {},
  superDiagnosticTopics: {},
  progressFilters: {
    lessonFilter: 'progress',
    lessonIDs: []
  },

  classProgress: {
    data: [],
    dateRange: initialDateRange
  },
  studentProgress: {
    data: [],
    dateRange: initialDateRange
  },
  teacherProgress: {
    data: [],
    dateRange: initialDateRange
  },
  myProgress: {
    data: [],
    dateRange: initialDateRange
  },
  superDiagnosticReport: {
    classResults: [],
    classTopicResults: [],
    classStudentResults: [],
    classStats: {
      studentsTakenQuiz: 0,
      totalStudents: 0
    }
  },
  customLessons: {},
  visibleLessonsWithCustomLesson: [],
  appSettings: { version: '' },
  showConfirmDisableModal: false,
  superDiagnosticQuiz: initialQuiz,
  selectedSuperDiagnosticTopic: ''
};

export default initialState;
