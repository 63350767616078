import { combineReducers } from "redux";
import * as types from '../actions/actionTypes';

import { GFSuperDiagnosticReportReducer, GFSuperDiagnosticTopicResult, GFSuperDiagnosticStudentResult, GFSuperDiagnosticClassStats, GFSuperDiagnosticClassResult, GFSuperDiagnosticStudentAnswers } from "../models/models";
import initialState from "./initialState";

/*
* classResultsReducer
*/

const classResultsReducer = (
  state: GFSuperDiagnosticClassResult[] = initialState.superDiagnosticReport.classResults,
  action: any
): GFSuperDiagnosticClassResult[] => {
  switch (action.type) {
    
    case types.GET_SUPER_DIAGNOSTIC_REPORT_CLASS_SUCCESS:
      return action.data.Topics;

    default:
      return state;
  }
}

/*
* classTopicResultsReducer
rows = students
columns = results on a topic
*/

const classTopicResultsReducer = (state: GFSuperDiagnosticTopicResult[] = initialState.superDiagnosticReport.classTopicResults, action: any): GFSuperDiagnosticTopicResult[] =>{
  switch (action.type) {
    case types.GET_SUPER_DIAGNOSTIC_REPORT_TOPIC_SUCCESS:
      return action.data.Students;
    default:
      return state;
  }
}

/*
* studentProgressReducer
*/

const superDiagnosticStudentResultsReducer = (
  state: GFSuperDiagnosticStudentResult[] = initialState.superDiagnosticReport.classStudentResults,
  action: any
): GFSuperDiagnosticStudentResult[] => {
  switch (action.type) {
    
    case types.GET_SUPER_DIAGNOSTIC_REPORT_STUDENT_SUCCESS:
      if (action.data && action.data.Topics){
        return action.data.Topics;
      } else {
        return initialState.superDiagnosticReport.classStudentResults;
      }

    default:
      return state;
  }
}

/*
* classStatsReducer
* StudentsTakenQuiz
* TotalStudents
*/

const superDiagnosticClassStatsReducer = (
  state: GFSuperDiagnosticClassStats = initialState.superDiagnosticReport.classStats,
  action: any
): GFSuperDiagnosticClassStats => {
  switch (action.type) {
    
    case types.GET_SUPER_DIAGNOSTIC_REPORT_CLASS_SUCCESS:
      const newClassStats = {
        studentsTakenQuiz: action.data.StudentsTakenQuiz,
        totalStudents: action.data.TotalStudents
      }
      return newClassStats;

    default:
      return state;
  }
}

/*
* Select Super Diagnostic Topic Reducer 
*/

export const selectSuperDiagnosticTopicReducer = (
  state: string = initialState.selectedSuperDiagnosticTopic,
  action: any
): string => {
  switch (action.type) {
    
    case types.GET_SUPER_DIAGNOSTIC_TOPIC:
      return action.topic
    
    default:
      return state
  }
}

// export const superDiagnosticStudentAnswersReducer = (
//   state: GFSuperDiagnosticStudentAnswers[] = initialState.superDiagnosticStudentAnswers,
//   action: any
// ): GFSuperDiagnosticStudentAnswers[] => {
//   switch (action.type) {
    
//     case types.GET_SUPER_DIAGNOSTIC_STUDENT_RESULTS_SUCCESS:
//       return action.data.answers

//     default:
//       return state
//   }
// }



export const superDiagnosticReportReducer = combineReducers<GFSuperDiagnosticReportReducer>({
  classResults: classResultsReducer,
  classTopicResults: classTopicResultsReducer,
  classStudentResults: superDiagnosticStudentResultsReducer,
  classStats: superDiagnosticClassStatsReducer
})