import * as types from '../actions/actionTypes';
import initialState, { initialStudent } from './initialState';
import { GFStudent } from '../models/models';
import { pickBy } from 'lodash';

export default function studentReducer(
  state: GFStudent[] = initialState.students,
  action: any
) {
  switch (action.type) {
    case types.LOAD_STUDENTS_SUCCESS:
      if (action.students){
        return action.students.map(cleanStudent);
      } else{
        return state;
      }
    case types.UPDATE_STUDENT_SUCCESS:{

      const oldStudent = state.find(
        (gfStudent: GFStudent) => gfStudent.student.id === action.payload.id
        );
        const newStudent = Object.assign({}, oldStudent, {
          student: action.payload
        });
        return [
          ...state.filter(
            (gfStudent: GFStudent) => gfStudent.student.id !== action.payload.id
            ),
            Object.assign({}, cleanStudent(newStudent))
          ];
        }
    case types.DELETE_STUDENT_CLASS_SUCCESS:
      return state.filter(
        (gfStudent: GFStudent) => gfStudent.student.id !== action.studentID
      );

    case types.USER_LOGOUT_SUCCESS:
      return initialState.students;

    default:
      return state;
  }
}

const cleanStudent = (student: any): GFStudent => {
  const cleanedUser: GFStudent = {
    ...initialStudent,
    ...pickBy(student, (property) => property !== null),
    student: {
      ...initialStudent.student,
      ...pickBy(student.student, (property) => property !== null),
    }
  };
  return cleanedUser;
};
