import * as React from "react";
import * as viewerMode from "../common/viewerMode";
import { find } from "lodash";
import { Col, Grid, Row } from "react-bootstrap";
import {
  GFInitialState,
  GFLesson,
  GFQuizAnswer,
  GFQuizItem,
  GFUser,
} from "../../models/models";
import { RouteComponentProps, browserHistory } from "react-router";
import {
  addAnswer,
  getQuizzesByLessonID,
  resetAnswers,
  saveQuizResult,
  saveQuizResultTeacher,
  setInProgressQuizID,
  startQuiz,
} from "../../actions/quizActions";
import {
  initialLesson,
  initialQuiz,
  intialQuizAnswer,
  initialCustomLesson
} from "../../reducers/initialState";

import Loading from "../common/Loading";
import Question from "../common/Question";
import { QuizButton } from "./QuizButton";
import VerificationAlert from "../common/VerificationAlert";
import { connect } from "react-redux";
import constants from "../../constants";
import { getLessonsByCourseID } from "../../actions/lessonActions";
import { toastr } from "react-redux-toastr";
import { selectIsStudent, selectIsTeacher } from "../../reducers/userReducer";
import { QuizBreadcrumbContainer } from "./QuizBreadcrumbContainer";
import { quizTypeEnum } from "../../enums";

const txtBubble = require("../../images/owl-bubble.svg");

interface RouterParams {
  courseID: string;
  lessonID: string;
  quizID: string;
}

interface IdispatchProps {
  // Add your dispatcher properties here
  user: GFUser;
  quiz: GFQuizItem;
  lesson: GFLesson;
  quizzes: { [key: string]: GFQuizItem };
  getLessonsByCourseID: typeof getLessonsByCourseID;
  getQuizzesByLessonID: typeof getQuizzesByLessonID;
  saveQuizResult: (quizID: string, quizName: string, quizType: number) => any;
  loading: boolean;
  addAnswer: typeof addAnswer;
  resetAnswers: typeof resetAnswers;
  quizAnswers: GFQuizAnswer[];
  quizAnswersSubmitted: boolean;
  saveQuizResultTeacher: typeof saveQuizResultTeacher;
  inProgressQuizID: string;
  setInProgressQuizID: typeof setInProgressQuizID;
  startQuiz: (quizID: string)=> Promise<void>;
  isStudent: boolean;
  isTeacher: boolean;
  postEvalEnabled: boolean;
}

interface Iprops extends RouteComponentProps<RouterParams, {}> {
  // Add your regular properties here
}

interface State {
  questionIndex: number;
  selectedAnswer: GFQuizAnswer;
  showCorrectAnswer: boolean;
  fullScreenLoading: boolean;
}

class Quiz extends React.Component<Iprops & IdispatchProps, State> {
  quizLoading: boolean;
  quizLoadingTimeout: any;

  constructor(props: Iprops & IdispatchProps) {
    super(props);
    this.state = {
      questionIndex: 0,
      selectedAnswer: intialQuizAnswer,
      showCorrectAnswer: false,
      fullScreenLoading: false,
    };

    this.quizLoading = false;
  }

  /*
   * When the component mounts we want to check for a quizID, if there is not one then we can not load a quiz,
   * so send them back to the courses page
   * we need the lesson in order to show the breadcrumbs
   * then get the quiz
   */
  componentDidMount() {
    if (!this.props.params.quizID || !this.props.params.lessonID) {
      browserHistory.replace(`/courses`);
      return;
    }
    this.checkLesson();
    this.checkQuiz();
    this.initQuiz();

    window.scrollTo(0, 0);
  }
  componentDidUpdate(prevProps: IdispatchProps) {
    if (this.props.quizAnswers !== prevProps.quizAnswers) {
      this.handleNewAnswer();
    }
  }

  componentWillUnmount() {
    // this.props.resetAnswers(); // if the user intentionally navigates away, we reset the answers
    clearTimeout(this.quizLoadingTimeout);
  }

  initQuiz = () => {
    // if we have answers and it is the same quizID, then reload
    // otherwise set the new quizID
    if (
      this.props.quizAnswers.length &&
      this.props.inProgressQuizID === this.props.params.quizID &&
      this.props.quizAnswersSubmitted === false
    ) {
      this.reloadExistingQuizAnswers();
    } else {
      this.props.resetAnswers();
      this.props.setInProgressQuizID(this.props.params.quizID);
    }

    // check if this is a post-eval or Diagnostic and we should check access
    if (
      (this.props.quiz.type === quizTypeEnum.postEval ||
        this.props.quiz.type === quizTypeEnum.diagnostic)
    ) {
      this.checkCanStartQuiz();
    }
  };

  /*
   * reloadExistingQuizAnswers
   */
  reloadExistingQuizAnswers = () => {
    const howManyAnswered = this.props.quizAnswers.length;
    if (howManyAnswered >= this.props.quiz.questions.length) {
      const lastAnswer = this.props.quizAnswers[howManyAnswered - 1];
      this.setState({
        questionIndex: howManyAnswered - 1,
        selectedAnswer: lastAnswer,
        showCorrectAnswer: true,
      });
    } else {
      this.setState({
        questionIndex: howManyAnswered,
      });
    }
  };

  checkLesson = () => {
    if (!this.props.lesson.id.length) {
      console.log("did not find lesson in Redux, loading lessons from API");
      this.props.getLessonsByCourseID(this.props.params.courseID);
    }
  };
    /* 
    if the quiz with questions has not been loaded, then retrieve it. 
    (this will happen when handling a direct link)
    We do not have an endpoint to get the quiz by ID, so in order to get the quiz by ID 
    we have to use the endpoint that gets all the quizzes for the lesson.
    */
   checkQuiz = () => {
    if (!this.props.quiz.questions.length) {
      this.props.getQuizzesByLessonID(this.props.params.lessonID);
    }
  };
  /*
   * checkCanStartQuiz
  * if the quiz is complete, they can re-start as many times as they want
   */
  checkCanStartQuiz = () => {
    if (this.getIsQuizComplete()){
      return;
    }
    const { quiz } = this.props;
    const isPostEval = quiz.type === quizTypeEnum.postEval;
    this.setState({ fullScreenLoading: true });
    if (isPostEval && !this.props.postEvalEnabled) {
      setTimeout(() => {
        toastr.warning(
          "",
          "Your teacher must enable the Post-Evaluation before you can proceed.",
          constants.toastrSuccess
        );
        browserHistory.push(
          `/lesson/${this.props.params.courseID}/${this.props.params.lessonID}`
        );
      }, 1);      
    } else if (isPostEval && this.props.user.paymentPlan === "trial") {
      // Using setTimeout because toastr is not immediately available,
      // when navigating to quiz by direct link.
      setTimeout(() => {
        toastr.warning("", "Post-Evaluations are not enabled on trial accounts.");
        browserHistory.push(
          `/lesson/${this.props.params.courseID}/${this.props.params.lessonID}`
        );
      }, 1);      
    } else if (
      isPostEval ||
      quiz.type === quizTypeEnum.diagnostic
    ) {
      let confirmMessage =
        "This assessment will help your teacher understand what you know regarding this topic.  Don't worry if you don't know some of the answers.";
      if (isPostEval) {
        confirmMessage =
          "Do your best!  You may only take this assessment once.";
      }
      const toastrConfirmOptions = {
        onOk: () => {
          this.startQuiz();
        },
        onCancel: () => {
          console.log("CANCEL: clicked");
          browserHistory.push(
            `/lesson/${this.props.params.courseID}/${this.props.params.lessonID}`
          );
        },
        okText: "Start",
        cancelText: "cancel"
      };
      // Using setTimeout because toastr is not immediately available,
      // when navigating to quiz by direct link.
      setTimeout(() => {
        toastr.confirm(confirmMessage, toastrConfirmOptions);
      }, 1);
      
    } else {
      this.startQuiz();
    }
  };

  startQuiz = () => {
    this.setState({ fullScreenLoading: true });
    this.props
      .startQuiz(this.props.quiz.id)
      .then(() => {
        this.setState({ fullScreenLoading: false });
      })
      .catch((error: any) => {
        this.setState({ fullScreenLoading: false });
        browserHistory.push(
          `/lesson/${this.props.params.courseID}/${this.props.params.lessonID}`
        );
        if (error && error.response && error.response.status === 405) {
          const message =
            error.response && error.response.data
              ? error.response.data
              : 'failed to start';
          toastr.warning('Warning', message, constants.toastrWarning);
          throw error;
        } else {
          console.error('Error starting timed quiz', error);
          constants.handleError(error, 'start quiz');
          throw error;
        }
      });
  };

  shouldShowCorrectAnswers = () => {
    if (
      this.props.quiz.type === quizTypeEnum.postEval ||
      this.props.quiz.type === quizTypeEnum.diagnostic
    ) {
      return false;
    } else {
      return true;
    }
  };

  getIsQuizComplete = (): boolean => {
    return this.props.quiz.questions.length === this.props.quizAnswers.length && this.props.quizAnswers.length > 0
  }

  /*
  * User answered a question.  if show correct answers is Not enabled, then go to the next question
  if last question, then finish it
  */
  handleNewAnswer = () => {
    if (!this.shouldShowCorrectAnswers()) {
      if (this.getIsQuizComplete()) {
        this.finishQuiz();
      } else {
        this.nextQuestion();
      }
    }
  };

  handleChange = (selectedAnswer: GFQuizAnswer) => {
    this.setState({ selectedAnswer });
  };

  nextQuestion() {
    // prevent double tap
    if (this.isQuizLoading()) {
      return;
    }
    const newIndex = this.props.quizAnswers.length;
    this.setState({
      questionIndex: newIndex,
      selectedAnswer: intialQuizAnswer,
      showCorrectAnswer: false,
    });
  }

  isQuizLoading = () => {
    if (this.quizLoading) {
      return true;
    }
    this.quizLoading = true;
    this.quizLoadingTimeout = setTimeout(() => {
      this.quizLoading = false;
    }, 200);
    return false;
  };

  /*
   * The button on the question is a form submit in order to capture keyboard return key on the fill in the blank type questions
   * prevent double click
   * if we are taking a quiz that has showing correct answers enabled, then show the correct anser after each question
   * otherwise show the next question.
   */
  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.currentTarget.reset();

    if (this.shouldShowCorrectAnswers()) {
      if (this.state.showCorrectAnswer) {
        if (
          this.props.quiz.questions.length === this.props.quizAnswers.length
        ) {
          this.finishQuiz();
        } else {
          this.nextQuestion();
        }
      } else {
        this.saveQuizAnswer();
        this.showCorrectAnswer();
      }
    } else {
      if (this.props.quiz.questions.length === this.props.quizAnswers.length) {
        // this happens if the previous finishQuiz failed for some reason
        this.finishQuiz();
      } else {
        this.saveQuizAnswer();
      }
    }
  };

  showCorrectAnswer = () => {
    if (this.isQuizLoading()) {
      return;
    }
    this.setState({ showCorrectAnswer: true });
  };

  /*
  * saveQuizAnswer
  * add the questionID and save the answer to redux
  */
  saveQuizAnswer = () => {
    const answer: GFQuizAnswer = {
      questionID: this.props.quiz.questions[this.state.questionIndex].id,
      answer: this.state.selectedAnswer.answer,
      isCorrect: this.state.selectedAnswer.isCorrect,
    };
    this.props.addAnswer(answer);
  };

  /*
  * finishQuiz
  * for students, save the quiz answers to the API
  * for teachers, show them the completed page but do NOT save to the API
  */
  finishQuiz = () => {
    // make sure we prevent double submission
    if (this.isQuizLoading()) {
      return;
    }

    if (this.props.isStudent) {
      this.props
        .saveQuizResult(
          this.props.quiz.id,
          this.props.quiz.name,
          this.props.quiz.type
        )
        .then((res: any) => {
          browserHistory.push(
            `/quizComplete/${this.props.params.courseID}/${this.props.params.lessonID}/${this.props.params.quizID}`
          );
        }).catch((error: any)=> {
          console.error(
            'Error with saving Practice Exercise results',
            error,
            this.props.quiz.name
          );
          const message = 'saving practice exercise';
          constants.handleError(error, message);
        });
    } else {
      // for teachers, pretend we saved the quiz
      this.props.saveQuizResultTeacher();
      this.setState(
        {
          selectedAnswer: intialQuizAnswer,
          showCorrectAnswer: false,
          questionIndex: 0,
        },
        () => {
          // this is a teacher finishing the quiz, so show a toast letting them know it is not actually saved
          toastr.warning(
            `Thank you!`,
            `Only students can save score results.`,
            constants.toastrSuccess
          );
          browserHistory.push(
            `/quizComplete/${this.props.params.courseID}/${this.props.params.lessonID}/${this.props.params.quizID}`
          );
        }
      );
    }
  };

  render() {
    const { questionIndex } = this.state;
    let questions;
    let totQ;
    let curQ: any;
    let courseName;
    if (this.props.quiz && this.props.quiz.id) {
      questions = this.props.quiz.questions;
      totQ = this.props.quiz.questions.length;
      curQ = questions[questionIndex];
    }
    const bubble = {
      backgroundImage: `url(${txtBubble})`,
    };
    if (this.props.lesson && this.props.lesson.id) {
      courseName = this.props.lesson.courseLessons[0].course.name;
    }

    return (
      <div>
        <Loading show={this.state.fullScreenLoading} message={"Loading..."} />

        <Grid className="content modal-container">
          <div className="main-content content-without-sidebar quiz animated fadeIn">
            <VerificationAlert
              user={this.props.user}
              isTeacher={this.props.isTeacher}
            />
            <Row className="sub-header">
              <QuizBreadcrumbContainer params={this.props.params} />
            </Row>

            {/*
             * Quiz Question and buttons
             */}
            {typeof curQ !== "undefined" && (
              <div className="sub-header">
                <form id="quizForm" onSubmit={this.handleSubmit}>
                  <Row className="question">
                    <Col md={12} xs={12} className="quiz-text-container">
                      <div className="text-instructions">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.props.quiz.instructions.replace(
                              "**blank**",
                              "__________"
                            ),
                          }}
                        />
                      </div>
                    </Col>
                    <Question
                      curQ={curQ}
                      showCorrectAnswer={this.state.showCorrectAnswer}
                      selectedAnswer={this.state.selectedAnswer}
                      handleChange={this.handleChange}
                      viewerMode={viewerMode.TEACHER_OR_STUDENT_TAKING_QUIZ}
                    />
                  </Row>
                  <Row className="button-row">
                    <Col md={5} sm={5} className="quiz-buttons">
                      <QuizButton
                        showCorrectAnswer={this.state.showCorrectAnswer}
                        isLastQuestion={this.state.questionIndex + 1 === this.props.quiz.questions.length}
                        answer={this.state.selectedAnswer.answer}
                        loading={this.props.loading}
                        showCorrectAnswersEnabled={this.shouldShowCorrectAnswers()}
                      />
                    </Col>
                    <Col md={7} sm={7}>
                      <div className="pull-right page-number">
                        {questionIndex + 1} of {totQ}
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            )}
          </div>
        </Grid>

        {/*
         * Display right or wrong
         */}
        {this.state.showCorrectAnswer && typeof curQ !== 'undefined' && (
          <div className="animated slideInUp owl-image" style={bubble}>
            {this.state.selectedAnswer.isCorrect && (
              <p className="right bubble-text">
                {curQ.correctText || "Correct"}
              </p>
            )}
            {!this.state.selectedAnswer.isCorrect && (
              <p className="wrong bubble-text">{curQ.wrongText || "Wrong"}</p>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: Iprops) => {
  const { user, selectedClassID } = state;
  const quiz = state.quizzes[ownProps.params.quizID] || initialQuiz;
  const lesson = state.lessons[ownProps.params.lessonID] || initialLesson;
  const isStudent = selectIsStudent(state);
  const classID =
    isStudent && user.classes.length
      ? user.classes[0].id
      : selectedClassID;
    // find the customLesson for this lesson and class then check if the post-eval is enabled
    const customLesson =
    find(
      state.customLessons,
      customL =>
        customL.lessonID === ownProps.params.lessonID &&
        customL.classID === classID
    ) || initialCustomLesson;

  // post-evals are disabled for students unless a teacher has enabled them for the class
  // post-evals are enabled for Teachers who select "view all lessons" or if they have enabled them for the class they have selected
  let postEvalEnabled = false;
  if (selectedClassID.length === 0 && isStudent === false) {
    postEvalEnabled = true;
  } else {
    postEvalEnabled =
      customLesson && !customLesson.postEvalEnabled ? false : true;
  }
  return {
    user: state.user,
    quiz,
    lesson,
    quizzes: state.quizzes,
    quizAnswers: state.quizView.quizAnswers,
    loading: state.ajaxCallsInProgress > 0,
    quizAnswersSubmitted: state.quizView.quizAnswersSubmitted,
    inProgressQuizID: state.quizView.inProgressQuizID,
    isStudent: selectIsStudent(state),
    isTeacher: selectIsTeacher(state),
    postEvalEnabled,
  };
};

export default connect(mapStateToProps, {
  getLessonsByCourseID,
  getQuizzesByLessonID,
  saveQuizResult,
  addAnswer,
  saveQuizResultTeacher,
  resetAnswers,
  setInProgressQuizID,
  startQuiz,
})(Quiz);
