import React, { Component } from "react";
import * as viewerMode from "../common/viewerMode";

import Question from "../common/Question";
import {
  getSuperDiagnosticQuiz,
  addSuperDiagnosticQuizAnswer,
  saveSuperDiagnostic,
  saveSuperDiagnosticTeacher,
  resetSuperDiagnosticQuizAnswers,
  getStudentSuperDiagnosticResult,
  getTopics,
} from "../../actions/superDiagnosticActions";
import {
  GFInitialState,
  GFUser,
  GFQuizItem,
  GFQuizAnswer,
  GFSuperDiagnosticTopicsById,
} from "../../models/models";
import { RouteComponentProps, browserHistory } from "react-router";
import { connect } from "react-redux";
import { intialQuizAnswer } from "../../reducers/initialState";
import { QuizButton } from "../quiz/QuizButton";
import { Col, Grid, Row } from "react-bootstrap";
import { selectIsStudent } from "../../reducers/userReducer";
import { toastr, ConfirmToastrCustomOptions } from "react-redux-toastr";
import constants from "../../constants";
import { isEmpty } from "lodash";

interface State {
  showCorrectAnswer: boolean;
  selectedAnswer: GFQuizAnswer;
  questionIndex: number;
}

interface RouterParams {
  classID: string;
}

interface IProps extends RouteComponentProps<RouterParams, {}> {
  user: GFUser;
  superDiagnosticQuiz: GFQuizItem;
  loading: boolean;
}

interface IDispatchProps {
  getSuperDiagnosticQuiz: (classID: string) => Promise<any>;
  getStudentSuperDiagnosticResult: () => Promise<any>;
  addSuperDiagnosticQuizAnswer: typeof addSuperDiagnosticQuizAnswer;
  quizAnswers: GFQuizAnswer[];
  isStudent: boolean;
  saveSuperDiagnostic: (
    quizID: string,
    quizName: string,
    questionIndex: number
  ) => Promise<any>;
  saveSuperDiagnosticTeacher: typeof saveSuperDiagnosticTeacher;
  quizAnswersSubmitted: number;
  resetSuperDiagnosticQuizAnswers: typeof resetSuperDiagnosticQuizAnswers;
  superDiagnosticTopics: GFSuperDiagnosticTopicsById;
  getTopics: typeof getTopics;
}

class SuperDiagnosticQuiz extends Component<IProps & IDispatchProps, State> {
  state = {
    showCorrectAnswer: false,
    selectedAnswer: intialQuizAnswer,
    questionIndex: 0,
  };

  componentDidMount() {
    if (this.props.isStudent) {
      this.props.getStudentSuperDiagnosticResult().then(() => {
        this.props
          .getSuperDiagnosticQuiz(this.props.user.classes[0].id)
          .then(() => {
            this.initQuiz();
          });
      });
    } else {
      this.props.getSuperDiagnosticQuiz(this.props.params.classID).then(() => {
        this.initQuiz();
      });
    }
    if (isEmpty(this.props.superDiagnosticTopics)) {
      this.props.getTopics();
    }
    this.showStartInstructions();
  }

  componentDidUpdate(prevProps: IProps & IDispatchProps) {
    // check if the number of answers increased by 1 in order to avoid saving after reloading the answers
    if (this.props.quizAnswers.length - prevProps.quizAnswers.length === 1) {
      this.handleNewAnswer();
    }
  }

  /*
   * if no answers, show the start instructions
   */
  showStartInstructions = () => {
    if (this.props.quizAnswers.length === 0) {
      toastr.confirm("Welcome to your GrammarFlip Diagnostic!", {
        okText: "Let's do this!",
        disableCancel: true,
        component: () => (
          <div
            style={{ padding: "0 20px 10px 20px", fontSize: "1.0em" }}
            className="sd-start-confirm-body"
          >
            <p>
              The following assessment will provide your teacher with
              information regarding your understanding of several topics related
              to grammar.
              <b>This is not a graded assignment. </b>
              You are not expected to know all the answers to these questions,
              but try to answer each question to the best of your ability. It's
              okay if you don't know any of the answers.
            </p>
          </div>
        ),
      });
    }
  };

  /*
   * initQuiz
   * check if we have answers and the quiz has not been submitted then load the answers from redux
   */
  initQuiz = () => {
    if (
      this.props.quizAnswers.length &&
      this.props.quizAnswersSubmitted !==
        this.props.superDiagnosticQuiz.questions.length
    ) {
      this.reloadExistingQuizAnswers();
    } else {
      this.props.resetSuperDiagnosticQuizAnswers();
    }
  };
  /*
   * reloadExistingQuizAnswers
   */
  reloadExistingQuizAnswers = () => {
    const howManyAnswered = this.props.quizAnswers.length;
    if (howManyAnswered >= this.props.superDiagnosticQuiz.questions.length) {
      const lastAnswer = this.props.quizAnswers[howManyAnswered - 1];
      this.setState({
        questionIndex: howManyAnswered - 1,
        selectedAnswer: lastAnswer,
        showCorrectAnswer: true,
      });
    } else {
      this.setState({
        questionIndex: howManyAnswered,
      });
    }
  };
  /*
   * handleNewAnswer
   * User answered a question.
   */
  handleNewAnswer = () => {
    // auto save logic to save after every 5 questions, but not on the last question, and not if questions answered is 0
    if (
      this.props.quizAnswers.length % 5 === 0 &&
      this.props.quizAnswers.length !==
        this.props.superDiagnosticQuiz.questions.length &&
      this.props.quizAnswers.length !== 0
    ) {
      if (this.props.isStudent) {
        this.props.saveSuperDiagnostic(
          this.props.superDiagnosticQuiz.id,
          this.props.superDiagnosticQuiz.name,
          this.state.questionIndex
        );
      }
    }
    if (
      this.props.superDiagnosticQuiz.questions.length ===
        this.props.quizAnswers.length &&
      this.props.quizAnswers.length > 0
    ) {
      this.finishQuiz();
    } else {
      this.nextQuestion();
    }
  };

  nextQuestion() {
    const newIndex = this.props.quizAnswers.length;
    this.setState(
      {
        questionIndex: newIndex,
        selectedAnswer: intialQuizAnswer,
      },
      () => {
        this.handleNewTopic();
      }
    );
  }

  /*
   * handleNewTopic
   * if the topic changes notify the user
   */
  handleNewTopic = () => {
    if (
      !this.props.superDiagnosticQuiz ||
      !this.props.superDiagnosticQuiz.questions ||
      (this.props.superDiagnosticQuiz.questions &&
        this.props.superDiagnosticQuiz.questions.length === 0)
    ) {
      return;
    }
    if (this.state.questionIndex > 0) {
      const previousQuestion = this.props.superDiagnosticQuiz.questions[
        this.state.questionIndex - 1
      ];
      const currentQuestion = this.props.superDiagnosticQuiz.questions[
        this.state.questionIndex
      ];
      if (previousQuestion.topicID !== currentQuestion.topicID) {
        const newTopic = this.props.superDiagnosticTopics[
          currentQuestion.topicID
        ];

        if (!newTopic) {
          toastr.error(
            "error",
            "Missing topic.  Please try again or contact support.",
            constants.toastrError
          );
          console.error(
            "missing topic while trying to confirm new topic",
            this.props.superDiagnosticTopics,
            currentQuestion
          );
          return;
        }
        const topicNameWithoutAstrisk = newTopic.name.replace("*", "");
        toastr.confirm("", {
          okText: "Got it!",
          disableCancel: true,
          component: () => (
            <div
              style={{ padding: "20px", fontSize: "1.2em" }}
              className="topic-confirm-body"
            >
              <p>You will now be answering questions regarding...</p>
              <h1 style={{ marginBottom: "40px", marginTop: "40px" }}>
                {topicNameWithoutAstrisk}
              </h1>
              <p>
                <i>Please read the directions carefully.</i>
              </p>
            </div>
          ),
        });
      }
    }
  };
  /*
   * finishQuiz
   * for students, save the quiz answers to the API
   * for teachers, show them the completed page but do NOT save to the API
   */
  finishQuiz = () => {
    if (this.props.isStudent) {
      this.props
        .saveSuperDiagnostic(
          this.props.superDiagnosticQuiz.id,
          this.props.superDiagnosticQuiz.name,
          this.state.questionIndex
        )
        .then((res: any) => {
          browserHistory.push(`/superDiagnosticComplete`);
        });
    } else {
      // for teachers, pretend we saved the quiz
      this.props.saveSuperDiagnosticTeacher(this.state.questionIndex);
      this.setState(
        {
          selectedAnswer: intialQuizAnswer,
          showCorrectAnswer: false,
          questionIndex: 0,
        },
        () => {
          // this is a teacher finishing the quiz, so show a toast letting them know it is not actually saved
          toastr.warning(
            `Thank you`,
            `Only students can save Diagnostic results!`,
            constants.toastrSuccess
          );
          browserHistory.goBack();
        }
      );
    }
  };

  handleChange = (selectedAnswer: GFQuizAnswer) => {
    this.setState({ selectedAnswer });
  };

  saveSuperDiagnosticQuizAnswer = () => {
    const answer: GFQuizAnswer = {
      questionID: this.props.superDiagnosticQuiz.questions[
        this.state.questionIndex
      ].id,
      answer: this.state.selectedAnswer.answer,
      isCorrect: false,
    };
    this.props.addSuperDiagnosticQuizAnswer(answer);
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.currentTarget.reset();
    if (
      this.props.superDiagnosticQuiz.questions.length ===
        this.props.quizAnswers.length &&
      this.props.quizAnswers.length > 0
    ) {
      this.finishQuiz();
    } else {
      this.saveSuperDiagnosticQuizAnswer();
    }
  };

  render() {
    const isLastQuestion =
      this.state.questionIndex + 1 ===
      this.props.superDiagnosticQuiz.questions.length;
    const curQ = this.props.superDiagnosticQuiz.questions[
      this.state.questionIndex
    ];
    let totQ = this.props.superDiagnosticQuiz.questions.length;
    if (typeof curQ === "undefined") {
      return null;
    }

    return (
      <Grid className="content modal-container">
        <div className="main-content content-without-sidebar quiz animated fadeIn">
          <form id="quizForm" onSubmit={this.handleSubmit}>
            <Row className="question">
              <Col md={12} xs={12} className="quiz-text-container">
                <Question
                  curQ={curQ}
                  showCorrectAnswer={false}
                  selectedAnswer={this.state.selectedAnswer}
                  handleChange={this.handleChange}
                  viewerMode={viewerMode.TEACHER_OR_STUDENT_TAKING_QUIZ}
                />
              </Col>
            </Row>
            <Row className="button-row">
              <Col
                md={5}
                sm={5}
                className="quiz-buttons"
                style={{ marginLeft: "15px" }}
              >
                <QuizButton
                  showCorrectAnswer={false}
                  isLastQuestion={isLastQuestion}
                  answer={this.state.selectedAnswer.answer}
                  loading={this.props.loading}
                  showCorrectAnswersEnabled={false}
                />
              </Col>
              <Col md={7} sm={7}>
                <div className="pull-right page-number">
                  {this.state.questionIndex + 1} of {totQ}
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: IProps) => {
  return {
    user: state.user,
    superDiagnosticQuiz: state.superDiagnosticQuiz,
    loading: state.ajaxCallsInProgress > 0,
    quizAnswers: state.superDiagnosticView.quizAnswers,
    isStudent: selectIsStudent(state),
    quizAnswersSubmitted: state.superDiagnosticView.quizAnswersSubmitted,
    superDiagnosticTopics: state.superDiagnosticTopics,
  };
};

export default connect(mapStateToProps, {
  getSuperDiagnosticQuiz,
  addSuperDiagnosticQuizAnswer,
  saveSuperDiagnostic,
  saveSuperDiagnosticTeacher,
  resetSuperDiagnosticQuizAnswers,
  getStudentSuperDiagnosticResult,
  getTopics,
})(SuperDiagnosticQuiz);
