import * as React from 'react';
import { connect } from 'react-redux';
import {
  GFLesson,
  GFInitialState,
  GFCustomLesson,
  GFLessonWithCustomLesson,
  Option,
  GFUser
} from '../../models/models';
import { find, isEmpty, orderBy } from 'lodash';

import { RouteComponentProps, browserHistory } from 'react-router';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  DropdownButton,
  MenuItem,
  Radio
} from 'react-bootstrap';
import MainMenu from '../menu/MainMenu';
import Select from 'react-select';
import { convertToOptions } from '../progressReports/StudentReportUtil';
import { loadClasses } from '../../actions/classActions';
import ReactTable, { RowInfo, Column } from 'react-table';
import {
  toggleCustomLesson,
  filterVisibleLessonsWithCustomLesson,
  toggleAllCustomLessons
} from '../../actions/customLessonActions';
import Toggle from 'react-toggle';
import { toastr } from 'react-redux-toastr';
import constants from '../../constants';
import { selectClassOptions } from '../../reducers/classReducer';
import { selectHasGoogleClasses, selectIsSuperTeacher } from '../../reducers/userReducer';

const graham = require('../../images/graham.gif');

const ToggleButton = (props: {
  value: boolean;
  onChange: React.ChangeEventHandler;
}) => {
  return (
    <Toggle
      icons={false}
      checked={props.value}
      onChange={props.onChange}
      className="gf-toggle"
    />
  );
};

interface RowInfoLesson extends RowInfo {
  original: GFLessonWithCustomLesson;
}

interface RouterParams {
  courseID: string;
  classID: string;
}

interface Props extends RouteComponentProps<RouterParams, {}> {
  classOptions: Option[];
  courseOptions: Option[];
  filteredLessons: GFLesson[];
  loadClasses: typeof loadClasses;
  toggleCustomLesson: typeof toggleCustomLesson;
  loading: boolean;
  customLessons: { [key: string]: GFCustomLesson };
  lessons: { [key: string]: GFLesson };
  visibleLessonsWithCustomLesson: GFLessonWithCustomLesson[];
  filterVisibleLessonsWithCustomLesson: typeof filterVisibleLessonsWithCustomLesson;
  toggleAllCustomLessons: typeof toggleAllCustomLessons;
  user: GFUser;
  hasGoogleClasses: boolean;
  isSuperTeacher: boolean;
}

interface State {
  selectedCourse: string;
  selectedClass: Option;
}

class CustomLessons extends React.Component<Props, State> {
  public columns: any[];
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedCourse: '',
      selectedClass: { label: '', value: '' }
    };
    this.columns = this.buildColumns();
  }

  componentWillMount() {
    this.props.loadClasses(); // this also runs getCustomLessons for each class
    // this.props.loadCourses(); // TODO
    this.updateSelects();
    if (!isEmpty(this.props.customLessons) && !isEmpty(this.props.lessons)) {
      this.props.filterVisibleLessonsWithCustomLesson(
        this.props.params.classID,
        this.props.params.courseID
      );
    }
  }
  componentDidUpdate(prevProps: Props) {
    if (
      JSON.stringify(this.props.lessons) !==
        JSON.stringify(prevProps.lessons) ||
      JSON.stringify(this.props.customLessons) !==
        JSON.stringify(prevProps.customLessons)
    ) {
      this.props.filterVisibleLessonsWithCustomLesson(
        this.props.params.classID,
        this.props.params.courseID
      );
    }
    if (
      JSON.stringify(this.props.classOptions) !==
      JSON.stringify(prevProps.classOptions)
    ) {
      this.updateSelects();
    }
    if (
      JSON.stringify(this.props.courseOptions) !==
      JSON.stringify(prevProps.courseOptions)
    ) {
      this.updateSelects();
    }
  }

  selectActivateLesson = (eventKey: any) => {
    if (eventKey === '1') {
      this.props.toggleAllCustomLessons(
        this.props.params.classID,
        this.props.params.courseID,
        true
      );
    } else if (eventKey === '2') {
      this.props.toggleAllCustomLessons(
        this.props.params.classID,
        this.props.params.courseID,
        false
      );
    }
  };
  selectEnablePostEval = (eventKey: any) => {
    if (this.shouldPreventPostEvalToggleOnTrialAccount()) {
      return;
    }
    if (eventKey === '1') {
      this.props.toggleAllCustomLessons(
        this.props.params.classID,
        this.props.params.courseID,
        undefined,
        true
      );
    } else if (eventKey === '2') {
      this.props.toggleAllCustomLessons(
        this.props.params.classID,
        this.props.params.courseID,
        undefined,
        false
      );
    }
  };
  selectEnablePostEvalAnswers = (eventKey: any) => {
    if (eventKey === '1') {
      this.props.toggleAllCustomLessons(
        this.props.params.classID,
        this.props.params.courseID,
        undefined,
        undefined,
        true
      );
    } else if (eventKey === '2') {
      this.props.toggleAllCustomLessons(
        this.props.params.classID,
        this.props.params.courseID,
        undefined,
        undefined,
        false
      );
    }
  };
  buildColumns = (): Column[] => {
    return [
      {
        Header: (data, column) => {
          return (
            <DropdownButton
              title="Activate Lesson"
              id="activate-lesson"
              onSelect={this.selectActivateLesson}
              bsSize="small"
              bsStyle="default"
              className="table-header-button"
            >
              <MenuItem eventKey="1">Activate All</MenuItem>
              <MenuItem eventKey="2">Deactivate All</MenuItem>
            </DropdownButton>
          );
        },
        id: 'lessonEnabled',
        width: 150,
        Cell: (row: RowInfoLesson) => {
          return (
            <ToggleButton
              value={row.original.customLesson.lessonEnabled}
              onChange={() =>
                this.props.toggleCustomLesson(
                  row.original.id,
                  this.props.params.classID,
                  row.original.customLesson.id,
                  row.original.customLesson.lessonEnabled,
                  'lessonEnabled'
                )
              }
            />
          );
        }
      },
      {
        Header: () => <span style={{ lineHeight: 2.1 }}>Lesson Name</span>,
        id: 'name',
        accessor: 'name',
        minWidth: 200
      },
      {
        Header: (data, column) => {
          return (
            <DropdownButton
              title="Enable Post-Eval"
              id="activate-lesson"
              onSelect={this.selectEnablePostEval}
              bsSize="small"
              bsStyle="default"
              className="table-header-button"
            >
              <MenuItem eventKey="1">Enable All</MenuItem>
              <MenuItem eventKey="2">Disable All</MenuItem>
            </DropdownButton>
          );
        },
        id: 'postEvalEnabled',
        width: 180,
        Cell: (row: RowInfoLesson) => {
          return (
            <ToggleButton
              value={row.original.customLesson.postEvalEnabled}
              onChange={() => {
                if (this.shouldPreventPostEvalToggleOnTrialAccount()) {
                  return;
                }
                this.props.toggleCustomLesson(
                  row.original.id,
                  this.props.params.classID,
                  row.original.customLesson.id,
                  row.original.customLesson.postEvalEnabled,
                  'postEvalEnabled'
                );
              }}
            />
          );
        }
      },
      {
        Header: (data, column) => {
          return (
            <DropdownButton
              title="Display Post-Eval Answers"
              id="activate-lesson"
              onSelect={this.selectEnablePostEvalAnswers}
              bsSize="small"
              bsStyle="default"
              className="table-header-button"
            >
              <MenuItem eventKey="1">Display All</MenuItem>
              <MenuItem eventKey="2">Hide All</MenuItem>
            </DropdownButton>
          );
        },
        id: 'postEvalAnswersEnabled',
        width: 220,
        Cell: (row: RowInfoLesson) => {
          return (
            <ToggleButton
              value={row.original.customLesson.postEvalAnswersEnabled}
              onChange={() =>
                this.props.toggleCustomLesson(
                  row.original.id,
                  this.props.params.classID,
                  row.original.customLesson.id,
                  row.original.customLesson.postEvalAnswersEnabled,
                  'postEvalAnswersEnabled'
                )
              }
            />
          );
        }
      }
      // {
      //   Header: "Diagnostic",
      //   id: "quiz01",
      //   accessor: lesson => lesson.quiz01Results,
      //   Cell: scoreCell,
      //   sortMethod: sortQuizzes,
      //   minWidth: 100,
      //   Footer: FooterCell
      // },
    ];
  };

  /*
* first try to use the selections in state, then what is the route params, then fall back to the 1st option in the array.
*/
  updateRouteParams = () => {
    const { selectedClass, selectedCourse } = this.state;
    const { courseOptions, classOptions, params } = this.props;

    const courseID = selectedCourse.length
      ? selectedCourse
      : params.courseID || courseOptions[0].value;
    const classID = selectedClass
      ? selectedClass.value
      : params.classID || classOptions[0].value;
    this.props.filterVisibleLessonsWithCustomLesson(classID, courseID);

    browserHistory.replace(`/customLessons/${classID}/${courseID}`);
  };

  updateSelects = () => {
    const { classID, courseID } = this.props.params;
    if (this.props.classOptions.length && this.props.courseOptions.length) {
      const selectedClass =
        find(this.props.classOptions, (obj) => {
          return obj.value === classID;
        }) || this.props.classOptions[0];
      const foundCourse = find(this.props.courseOptions, (obj) => {
        return obj.value === courseID;
      });
      this.setState(
        {
          selectedClass,
          selectedCourse: foundCourse
            ? foundCourse.value
            : this.props.courseOptions[0].value
        },
        () => {
          this.updateRouteParams();
        }
      );
    } else {
      console.log('skipping update selects');
    }
  };

  // add better type later for selectedClass
  handleClassChange = (selectedClass: any) => {
    this.setState({ selectedClass }, () => {
      this.updateRouteParams();
    });
  };

  handleCourseChange = (event: any) => {
    if (this.verifyHasClass() === false) return;

    this.setState({ selectedCourse: event.currentTarget.value }, () => {
      this.updateRouteParams();
    });
  };

  verifyHasClass = (): boolean => {
    if (this.props.classOptions.length){
      return true;
    } else {
      toastr.warning('Warning', 'Please add a class first.', constants.toastrError)
      return false;
    }
  }

  handleTdProps = (
    state: any,
    rowInfo: RowInfoLesson | undefined,
    column: any,
    instance: any
  ) => {
    let opacity = 1;
    if (rowInfo === undefined) {
      return {};
    }
    if (rowInfo.original.customLesson.lessonEnabled === false) {
      opacity = 0.3;
    }
    if (
      column.id &&
      (column.id === 'postEvalEnabled' ||
        column.id === 'lessonEnabled' ||
        column.id === 'postEvalAnswersEnabled')
    ) {
      return {
        style: { textAlign: 'center', opacity }
      };
    } else {
      return { style: { opacity } };
    }
  };

  shouldPreventPostEvalToggleOnTrialAccount = () => {
    if (this.props.user.paymentPlan === 'trial') {
      toastr.warning(
        'Warning',
        'Post-Evaluations are not enabled on trial accounts.',
        constants.toastrWarning
      );
      return true;
    } else {
      return false;
    }
  };

  render() {
    if (!this.props.visibleLessonsWithCustomLesson) {
      return <div>loading...</div>;
    }
    return (
      <Grid className="content modal-container">
        <div className="sidemenu">
          <MainMenu isTeacher={true} isSuperTeacher={this.props.isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
          <div className="logo text-center">
            <img alt="graham" src={graham} />
          </div>
        </div>
        <div className="custom-lessons main-content content-with-sidebar">
          <Row className="students-header">
            <Col xs={12} className="text-xl-left">
              <h1 className="pageDescription">Customize Lessons</h1>
              <p style={{ marginBottom: 0 }}>
                Default settings grant students access to ALL lessons. To
                restrict access to specific lessons, you may do so under the
                “Activate Lesson” column.
              </p>
            </Col>
          </Row>
          <Row className="class-form">
            <Col xs={12} lg={10}>
              <FormGroup bsSize="lg" className="class-lesson-selects">
                <Row>
                  <Col xs={3}>
                    <ControlLabel>Select Class</ControlLabel>
                  </Col>
                  <Col xs={8} className="class-row">
                    <Select
                      className="react-select-container"
                      options={this.props.classOptions}
                      onChange={this.handleClassChange}
                      value={this.state.selectedClass}
                      placeholder="select a class"
                      classNamePrefix="react-select"
                      isSearchable={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <ControlLabel>Select Level</ControlLabel>
                  </Col>
                  <Col xs={9}>
                    <FormGroup>
                      {this.props.courseOptions.map(course => (
                        <Radio
                          name={course.label}
                          inline
                          value={course.value}
                          checked={this.state.selectedCourse === course.value}
                          onChange={this.handleCourseChange}
                        >
                          {course.label}{' '}
                        </Radio>
                      ))}
                    </FormGroup>
                    {/* <Select
                      className="react-select-container"
                      options={this.props.courseOptions}
                      onChange={this.handleCourseChange}
                      value={this.state.selectedCourse}
                      placeholder="search..."
                      classNamePrefix="react-select"
                    /> */}
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row className="class-table">
            <Col xs={12}>
              <ReactTable
                className="teacher-class-report -highlight"
                data={this.props.visibleLessonsWithCustomLesson}
                columns={this.columns}
                showPagination={false}
                showPageSizeOptions={false}
                pageSize={this.props.visibleLessonsWithCustomLesson.length}
                loading={this.props.loading}
                getTdProps={this.handleTdProps}
                // getTrProps={this.getTrProps}
                noDataText="No lessons found."
                resizable={false}
                sortable={false}
              />
            </Col>
          </Row>
        </div>
      </Grid>
    );
  }
}
const mapStateToProps = (state: GFInitialState, ownProps: Props) => {
  const classOptions = selectClassOptions(state);

  return {
    classOptions,
    courseOptions: convertToOptions(Object.values(state.courses)),
    visibleLessonsWithCustomLesson: state.visibleLessonsWithCustomLesson,
    customLessons: state.customLessons,
    lessons: state.lessons,
    loading: state.ajaxCallsInProgress > 0,
    user: state.user,
    hasGoogleClasses: selectHasGoogleClasses(state),
    isSuperTeacher: selectIsSuperTeacher(state),
  };
};

export default connect(
  mapStateToProps,
  {
    loadClasses,
    toggleCustomLesson,
    filterVisibleLessonsWithCustomLesson,
    toggleAllCustomLessons
  }
)(CustomLessons);
