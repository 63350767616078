import * as types from '../actions/actionTypes';
import initialState, { initialUser } from './initialState';
import moment from 'moment';
import constants from '../constants';
import { GFUser, GFTeacherUser, GFInitialState } from '../models/models';
import { isNull, pickBy } from 'lodash';
import { cloneDeep } from 'lodash';

export const selectIsTeacher = (state: GFInitialState) => {
  return state.user.roleID === constants.UserRoleIDs.Teacher;
};

export const selectIsSuperTeacher = (state: GFInitialState) => {
  return state.user.roleID === constants.UserRoleIDs.Teacher && state.user.isSuperTeacher === true;
};

export const selectHasGoogleClasses = (state: GFInitialState) => {
  return state.classes.reduce((previousValue: number, currentValue) => {
    return currentValue.hasOwnProperty("googleCourseID") && !isNull(currentValue.googleCourseID) ? previousValue + 1 : previousValue;
  }, 0) > 0;
};

export const selectIsAdmin = (state: GFInitialState) => {
  return state.user.roleID === constants.UserRoleIDs.SuperAdmin;
};

export const selectIsStudent = (state: GFInitialState) => {
  return state.user.roleID === constants.UserRoleIDs.Student;
};

export const selectIsTrial = (state: GFInitialState) => {
  return state.user.paymentPlan === 'trial';
} 

export const hasSubscription = (user: GFUser) => {
  if (
    user.paymentPlan === 'trial' ||
    user.paymentPlan === 'cancelled' ||
    user.paymentPlan === 'expired' ||
    user.paymentPlan === null ||
    user.isCancelling
  ) {
    return false;
  } else {
    return true;
  }
};

export function userReducer(
  state: GFUser = initialState.user,
  action: any
): GFUser {
  switch (action.type) {
    case types.USER_CHECK_SESSION_SUCCESS:
      return { ...state, ...cleanUser(action.user) };
    case types.USER_LOGIN_SUCCESS:
      return { ...cleanUser(action.user) };
    case types.USER_UPDATE_SUCCESS:
      return { ...state, ...cleanUser(action.user) };
    case types.TEACHER_REGISTER_SUCCESS:
      return { ...cleanUser(action.user) };
    case types.STUDENT_REGISTER_SUCCESS:
      return { ...cleanUser(action.user) };
    case types.REMOVE_STUDENT_CLASS_SUCCESS:
      if (!state.classes || state.classes.length === 0) {
        return Object.assign({}, state, { isActive: action.data.isActive });
      } else {
        const classesFiltered = state.classes.filter(
          gfClass => gfClass.id !== action.classID
        );
        return Object.assign({}, state, {
          classes: classesFiltered,
          isActive: action.data.isActive
        });
      }

    case types.ADD_STUDENT_CLASS_SUCCESS:
      const classesC = state.classes.concat([action.data.class]);
      return Object.assign({}, state, {
        classes: classesC,
        isActive: action.data.student.isActive
      });
    /**
     * Super Teacher reducers
     */
    case types.ADD_TEACHER_SUCCESS:
      return { ...state, ...cleanUser(action.user) };
    case types.UPDATE_TEACHER_SUCCESS:
      return { ...state, ...cleanUser(action.user) };
    case types.REMOVE_TEACHER_SUCCESS:
      return { ...state, ...cleanUser(action.user) };

    case types.USER_LOGOUT_SUCCESS:
      return initialState.user;
    case types.PAY_SUCCESS:
      const {
        expirationDate,
        paymentPlan,
        isCancelling,
        isPurchaseOrder,
        maxStudents,
        stripeSubscriptionID
      } = action.user;
      return {
        ...state,
        expirationDate,
        paymentPlan,
        isCancelling,
        isPurchaseOrder,
        maxStudents,
        stripeSubscriptionID
      };
    case types.LOAD_QUIZZES_SUCCESS:
      return { ...state, lastUpdated: moment().toISOString() };
    default:
      return state;
  }
}

export function socialReducer(
  state: any = initialState.socialObj,
  action: any
) {
  switch (action.type) {
    case types.SET_SOCIAL:
      return Object.assign({}, action.socialObj);

    case types.USER_LOGOUT_SUCCESS:
      return initialState.user;

    default:
      return state;
  }
}

/**
 * Will calculate the quantity per superTeacherPlan, which is the original
 * quantity minus the total number of our subTeachers with each plan.
 * 
 * @param user 
 * @returns 
 */
const calculateSuperTeacherPlans = (user: GFUser) => {
  const { subTeachers, superTeacherPlans } = user;
  const spentPlans = subTeachers.reduce((collector: any, teacher: GFTeacherUser) => {
    if(collector.hasOwnProperty(teacher.paymentPlan)){
      collector[teacher.paymentPlan]++
    } else {
      collector[teacher.paymentPlan] = 1
    }
    return collector;
  }, {});
  const clonedPlans = cloneDeep(superTeacherPlans);
  
  return clonedPlans.map(plan => {
    plan.quantity = spentPlans.hasOwnProperty(plan.plan) ? plan.quantity - spentPlans[plan.plan] : plan.quantity;
    return plan;
  });
}

const cleanUser = (user: any) => {
  const cleanedUser: GFUser = {
    ...initialUser,
    ...pickBy(user, (property, key) => property !== null)
  };
  // We must calculate the quantity remaining in our super teacher plans
  if(cleanedUser.isSuperTeacher && cleanedUser.hasOwnProperty('superTeacherPlans') && cleanedUser.hasOwnProperty('subTeachers')){
    cleanedUser.calculatedSuperTeacherPlans = calculateSuperTeacherPlans(cleanedUser);
  }
  return cleanedUser;
};
