/*
* A little util from https://github.com/ReactTraining/react-router/issues/1100
*/
import { browserHistory } from 'react-router';
/**
 * @param {Object} query
 */
export const addQuery = (query: {[key:string]: string}) => {
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    Object.assign(location.query, query);
    browserHistory.push(location);
};

/**
 * @param {...String} queryNames
 */
export const removeQuery = (...queryNames: string[]) => {
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    queryNames.forEach(q => delete location.query[q]);
    browserHistory.push(location);
};
