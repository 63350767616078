import * as types from '../actions/actionTypes';
import initialState, { initialWritingAssignment } from './initialState';
import {
  GFWritingAssignment,
  GFWritingAssignmentResult
} from '../models/models';
import { pickBy, keyBy } from 'lodash';

export function waArrayReducer(
  state: {
    [key: string]: GFWritingAssignment;
  } = initialState.writingAssignments,
  action: any
): { [key: string]: GFWritingAssignment } {
  switch (action.type) {
    case types.LOAD_WA_SUCCESS:
      return keyBy(cleanWritingAssignments(action.writingAssignments), 'id');

    default:
      return state;
  }
}

export function waResultsReducer(
  state: {
    [key: string]: GFWritingAssignmentResult;
  } = initialState.waResultsByID,
  action: any
): { [key: string]: GFWritingAssignmentResult } {
  switch (action.type) {
    case types.LOAD_WAR_SUCCESS:
      return keyBy(action.waResults, 'id');
    case types.GET_SINGLE_WRITING_ASSIGNMENT_RESULT_SUCCESS:
      return { ...state, [action.result.id]: action.result };

    default:
      return state;
  }
}

const cleanWritingAssignments = (writingAssignments: GFWritingAssignment[]) => {
  return writingAssignments.map(wa => {
    return {
      ...initialWritingAssignment,
      ...pickBy(wa, (property, key) => property !== null)
    } as GFWritingAssignment;
  });
};
