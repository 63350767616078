import * as React from 'react'; // tslint:disable-line
import { Thumbnail } from 'react-bootstrap';
import { GFBadge } from '../../models/models';

export const StudentBadge = ({badge, className = ''}: {badge: GFBadge, className : string}) => {
  let typicalBadge;
  let signUpBadge;
  if (badge.count < 0) {
    signUpBadge = <p className="sign-up-badge-text">{badge.description}</p>;
  } else {
    typicalBadge = (
      <p className="badge-description-text">{badge.description}</p>
    );
  }
  return (
    <Thumbnail src={badge.imagePath} alt="" className={className}>
      {typicalBadge}
      <h3 className="badge-name">
        Congrats! You just earned the{' '}
        <span style={{ color: '#6DB2F9' }}>{badge.name}</span>
      </h3>
      {signUpBadge}
    </Thumbnail>
  );
};
