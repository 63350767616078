/*
* PaymentButton figures out which payment button to display and returns it.
* if they do not have a subscription then show "sign me up" on the 2 buttons
* If they have a subscription, figure out when to show "Downgrade" or "Current Plan" or "Upgrade"
* Do this by passing in what is currently selected for recurring and number of students.
* Build the paymentPlan string for the two buttons based on what is selected, then compare that too the user's selected plan
*
*/

import * as React from 'react';
import { Button } from 'react-bootstrap';
import constants from '../../constants';
import { find } from 'lodash';
import { GFPlan } from '../../models/models';

interface Props extends React.Props<PaymentButton> {
  selectedPlan: GFPlan;
  paymentPlan: string; // full string of plan
  handleClick: any;
  loading: boolean;
  hasStripeSubscription: boolean;
  paymentPlanStudents: string;
}

class PaymentButton extends React.Component<Props, {}> {
  /*
  *  split the string we receive from Stripe that represents the plan and figure out which plan object it is
  */
  getUserPlan() {
    const paymentPlan = this.props.paymentPlan; // the user's current plan
    const userPlanObject = find(constants.paymentPlanObjectsByID, plan => {
      return plan.id === paymentPlan;
    });
    if (!userPlanObject) {
      console.error(
        'could not find matching payment plan, assuming they are cancelled.'
      );
      return constants.paymentPlanObjectsByID.cancelled;
    } else {
      return userPlanObject;
    }
  }
  render() {

    const userPlanObj = this.getUserPlan();

    /*
    * compare the plan object to the user's current plan
    * current plan = same rank, upgrade is lower, downgrade is higher
    */
    const rank = this.props.selectedPlan.rank - userPlanObj.rank;
    let buttonLabel = '';

    if (!this.props.hasStripeSubscription) {
      buttonLabel = 'Sign Me Up!';
    } else {
      if (rank < 0) {
        buttonLabel = 'Downgrade';
      } else if (rank > 0) {
        buttonLabel = 'Upgrade';
      } else if (rank === 0) {
        buttonLabel = 'Current Plan';
      }
    }

    // If selection is current plan, show as text. Button is causing grief.
    if (buttonLabel === 'Current Plan') {
      return (
        <div>
          <h4><strong>{buttonLabel}</strong></h4>
        </div>
      );
    }

    return (
      <div>
        <Button
          className={buttonLabel}
          onClick={() => this.props.handleClick()}
          disabled={this.props.loading}
        >
          {buttonLabel}
        </Button>
      </div>
    );
  }
}

export default PaymentButton;
