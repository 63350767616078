/*
* stateless component to help select the visible lessons a a teacher student report
*/
import * as React from 'react';
import { connect } from 'react-redux';
import { ToggleButtonGroup, ToggleButton, Button } from 'react-bootstrap';
import Select from 'react-select';
import { convertToOptions } from './StudentReportUtil';
import { Option, GFLesson, GFInitialState } from '../../models/models';
import { setLessonFilter } from '../../actions/progressActions';
import { map, filter, indexOf } from 'lodash';

interface Props {
  doStuff: boolean;
}

interface DispatchProps {
  lessonOptions: Option[];
  loading: boolean;
  lessons: { [key: string]: GFLesson };
  lessonIDs: string[];
  setLessonFilter: (filt: string, lessonIDs?: string[]) => any;
}

interface State {
  selectedLesson: Option;
  selectedFilter: string;
}

const defaultSelected: Option = { label: 'Add Lesson...', value: '' };

class LessonToggleButtons extends React.Component<
  Props & DispatchProps,
  State
> {
  constructor(props: Props & DispatchProps) {
    super(props);
    this.state = {
      selectedLesson: defaultSelected,
      selectedFilter: 'progress'
    };
  }
  handleFilterChange = (selectedFilter: string) => {
    if (selectedFilter === 'hideAll') {
      selectedFilter = 'custom';
      this.props.setLessonFilter('custom', []);
    } else if (selectedFilter === 'showAll') {
      selectedFilter = 'custom';
      const lessonIDs = map(this.props.lessons, (v: any, key: string) => key);
      this.props.setLessonFilter('custom', lessonIDs);
    } else if (
      selectedFilter === 'custom' &&
      this.state.selectedFilter !== 'custom'
    ) {
      this.props.setLessonFilter('custom', this.props.lessonIDs);
    } else {
      this.props.setLessonFilter('progress', []);
    }
    this.setState({ selectedFilter });
  };
  // need to adjust Option type so changing to any for now
  handleLessonChange = (selectedLesson: any) => {
    const lessonIDs = [...this.props.lessonIDs, selectedLesson.value];
    this.props.setLessonFilter('custom', lessonIDs);
    this.setState({ selectedLesson: defaultSelected });
  };
  render() {
    const isDisabled = this.state.selectedFilter !== 'custom';
    return (
      <div className="lesson-toggle-buttons pull-right">
        <ToggleButtonGroup
          type="radio"
          name="lesson-toggle"
          defaultValue={[this.state.selectedFilter]}
          onChange={this.handleFilterChange}
        >
          <ToggleButton bsStyle="default" bsSize="sm" value={'progress'}>
            Show Default Lessons
          </ToggleButton>
          <ToggleButton bsStyle="default" bsSize="sm" value={'custom'}>
            Customize
          </ToggleButton>
        </ToggleButtonGroup>
        <br />
        <div className="lesson-buttons">
          <Button
            bsStyle="default"
            bsSize="sm"
            disabled={isDisabled}
            onClick={() => {
              this.handleFilterChange('showAll');
            }}
          >
            Show All
          </Button>
          <Button
            bsStyle="default"
            bsSize="sm"
            disabled={isDisabled}
            onClick={() => {
              this.handleFilterChange('hideAll');
            }}
          >
            Hide All
          </Button>
          <Select
            className="react-select-container pull-right"
            options={this.props.lessonOptions}
            onChange={this.handleLessonChange}
            value={this.state.selectedLesson}
            isDisabled={isDisabled}
            placeholder="Add Lesson..."
            classNamePrefix="react-select"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: Props) => {
  const lessonIDs = state.studentProgress.data.map(o => o.id);
  const lessonOptions = filter(state.lessons, l => {
    return indexOf(lessonIDs, l.id) === -1;
  });
  return {
    lessonOptions: convertToOptions(lessonOptions),
    loading: state.ajaxCallsInProgress > 0,
    lessons: state.lessons,
    lessonIDs
  };
};

export default connect(
  mapStateToProps,
  { setLessonFilter }
)(LessonToggleButtons);
