import * as React from 'react'; // tslint:disable-line
import { Modal, Button } from 'react-bootstrap';
import ChangePW from '../common/ChangePW';

interface ResetPasswordParameters {
  loading: boolean;
  show: boolean;
  close: () => void;
  changePW: (password: string) => void;
  container: any;
  title: string;
}

const ResetPassword = ({
  loading,
  show,
  close,
  changePW,
  container,
  title = 'Update Password'
}: ResetPasswordParameters) => {
  return (
    <Modal show={show} onHide={close} container={container}>
      <Modal.Header closeButton={true}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ChangePW changePW={changePW} loading={loading} isSocialType={false} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close} bsStyle="default" type="button">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResetPassword;
