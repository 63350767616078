import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { keyBy } from 'lodash';
import { GFLessons, GFLesson } from '../models/models';

export default function lessonsReducer(
  state: GFLessons = initialState.lessons,
  action: any
) {
  switch (action.type) {
    case types.LOAD_LESSONS_SUCCESS:
      return Object.assign(
        {},
        state,
        keyBy(action.lessons, (lesson: GFLesson) => lesson.id)
      );
    case types.USER_LOGOUT_SUCCESS:
      return initialState.lessons;

    default:
      return state;
  }
}
