import * as types from '../actions/actionTypes';
import initialState, {
  initialDateRange,
  initialQuizResultReducer,
  initialQuizResult,
  initialQuiz
} from './initialState';
import {
  GFLessonsWithProgress,
  GFClassProgressReducer,
  GFDateRange,
  GFStudentProgressReducer,
  GFStudentWithProgress,
  GFStudentQuizResult,
  GFTeacherProgressReducer,
  GFTeacherWithProgress,
  GFProgressFilters,
  GFClassProgressRaw,
  GFQuizResultReducer,
  GFMyProgressReducer,
  GFInitialState
} from '../models/models';
import { filter, find } from 'lodash';
import { quizTypeEnum } from '../enums';
import { cleanQuizObject } from './quizzesReducer';

const cleanQuizResult = (quizResult: any): GFStudentQuizResult => {
  const quiz = quizResult.quiz ? cleanQuizObject(quizResult.quiz) : initialQuiz;
  return {...initialQuizResult, ...quizResult, quiz}
}

/*
* SELECTORS
*/

const selectAllQuizzes = (state: GFInitialState) => state.quizzes;

const selectQuizzesByLessonID = (state: GFInitialState, {lessonID}: {lessonID: string}) => {
  const quizzes = selectAllQuizzes(state);
  return filter(quizzes, {lessonID})
}

export const prepareQuizzesForProgressTable = (state: GFInitialState, {lessonID}: {lessonID: string}) => {
  const filteredQuizzes = selectQuizzesByLessonID(state, {lessonID});
  const diagnostic = find(filteredQuizzes, {type: quizTypeEnum.diagnostic});
  const postEval = find(filteredQuizzes, {type: quizTypeEnum.postEval});
  const practice = filter(filteredQuizzes, {type: quizTypeEnum.practice});
  return {diagnostic, postEval, practice}
}

/*
* REDUCERS
*/

/*
* RAW quiz results (progress) for a specific class
*/
export function classProgressRaw(
  state: GFClassProgressRaw = {},
  action: any
): GFClassProgressRaw {
  switch (action.type) {
    case types.GET_PROGRESS_CLASS_SUCCESS:
      return { ...state, [action.classID]: action.progress };
    case types.USER_LOGOUT_SUCCESS:
      return {};
    default:
      return state;
  }
}

/*
* RAW quiz results (progress) for the current user
*/
export function myProgressRaw(state: GFStudentQuizResult[] = [], action: any): GFStudentQuizResult[] {
  switch (action.type) {
    case types.GET_PROGRESS_USER_SUCCESS:
      return action.progress;
    case types.USER_LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

/*
* studentProgress is a reducer for the visible student progress report.  It is a collection of lessons with progress
*/
export function studentProgress(
  state: GFStudentProgressReducer = initialState.studentProgress,
  action: any
): GFStudentProgressReducer {
  return {
    data: lessonsWithProgress(state.data, action, 'CLASS_STUDENT'),
    dateRange: dateRange(state.dateRange, action, 'CLASS_STUDENT')
  };
}

/*
* teacherProgress is a reducer for the visible teacher progress report.  It is a collection of teachers with progress
*/
export function teacherProgress(
  state: GFTeacherProgressReducer = initialState.teacherProgress,
  action: any
): GFTeacherProgressReducer {
  return {
    data: teachersWithProgress(state.data, action),
    dateRange: dateRange(state.dateRange, action, 'SUBTEACHER')
  };
}

/*
* classProgress is a reducer for the visible class progress.  this is a collection of students with progress on a single lesson
*/

export function classProgress(
  state: GFClassProgressReducer = initialState.classProgress,
  action: any
): GFClassProgressReducer {
  return {
    data: studentsWithProgress(state.data, action),
    dateRange: dateRange(state.dateRange, action, 'CLASS_LESSON')
  };
}

/*
* myProgress is for the student to login and view their own progress report.  It is a collection of lessons with progress
* Notice: as of 8/30/2018 we are not using dateRange or dynamic filters when viewing your own report.  But we are ready to add support.
*  the next step is to add a getMyVisibleProgress action in progressActions.ts
*/
export function myProgress(
  state: GFMyProgressReducer = initialState.myProgress,
  action: any
): GFMyProgressReducer {
  return {
    data: lessonsWithProgress(state.data, action, 'USER'),
    dateRange: dateRange(state.dateRange, action, 'USER')
  };
}

/*
* **************************   SUPPORTING REDUCERS *************************
*/
function dateRange(
  state: GFDateRange = initialDateRange,
  action: any,
  type: string
): GFDateRange {
  switch (action.type) {
    case `SET_START_DATE_${type}`:
      return { ...state, startDate: action.date };
    case `SET_END_DATE_${type}`:
      return { ...state, endDate: action.date };
    case types.USER_LOGOUT_SUCCESS:
      return initialDateRange;
    default:
      return state;
  }
}
/*
* reduce visible lessons with progress
*/
function lessonsWithProgress(
  state: GFLessonsWithProgress[] = [],
  action: any,
  type: string
): GFLessonsWithProgress[]  {
  switch (action.type) {
    case `GET_VISIBLE_PROGRESS_${type}`:
      return action.lessonsWithProgress;
    case types.CLEAR_VISIBLE_PROGRESS:
      return [];
    case types.USER_LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

// reducer to reuse to help manage class progress for the class or the teacher viewing a student
function studentsWithProgress(
  state: GFStudentWithProgress[] = [],
  action: any
): GFStudentWithProgress[] {
  switch (action.type) {
    case types.GET_VISIBLE_PROGRESS_CLASS_LESSON:
      return action.studentsWithProgress;
    case types.CLEAR_VISIBLE_PROGRESS:
      return [];
    case types.USER_LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

// reducer to reuse to help manage class progress for the class or the teacher viewing a student
function teachersWithProgress(
  state: GFTeacherWithProgress[] = [],
  action: any
): GFTeacherWithProgress[] {
  switch (action.type) {
    case types.GET_VISIBLE_PROGRESS_SUBTEACHER:
      return action.teachersWithProgress.data;
    case types.CLEAR_VISIBLE_PROGRESS:
      return [];
    case types.USER_LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

/*
* filters for the teacher viewing a student and a student viewing themselves
*/
export function progressFilters(
  state: GFProgressFilters = initialState.progressFilters,
  action: any
): GFProgressFilters {
  switch (action.type) {
    case types.SET_LESSON_FILTER:
      return {
        ...state,
        lessonFilter: action.lessonFilter,
        lessonIDs: action.lessonIDs
      };

    default:
      return state;
  }
}

/*
* QuizResultReducer contains the visible quiz answers as well as if we show the modal or not
*/

export function quizResultReducer(
  state: GFQuizResultReducer = initialQuizResultReducer,
  action: any
): GFQuizResultReducer {
  switch (action.type) {
    case types.MODAL_SHOW_QUIZ_ANSWERS:
      return { ...state, show: true };
    case types.MODAL_HIDE_QUIZ_ANSWERS:
      return { ...state, show: false };
    case types.LOAD_STUDENT_QUIZ_ANSWERS_SUCCESS:
      return { ...state, quizResult: cleanQuizResult(action.quizResult) };
    case types.GET_SUPER_DIAGNOSTIC_STUDENT_RESULTS_SUCCESS:
      return {...state, quizResult: cleanQuizResult(action.data)}
    default:
      return state;
  }
}
