import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { GFBadge } from '../models/models';

export default function badgesReducer(
  state: GFBadge[] = initialState.badges,
  action: any
): GFBadge[] {
  switch (action.type) {
    case types.LOAD_BADGES_SUCCESS:
      return action.badges;

    default:
      return state;
  }
}
