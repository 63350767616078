import * as React from 'react'; // tslint:disable-line
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { GFBadge } from '../../models/models';
const getTooltip = (title: string) => {
  return <Tooltip id={title}>{title}</Tooltip>;
};

const Badges = ({badges}:{badges: GFBadge[]}) => {
  let i;
  let j;
  const chunk = 8;
  const groups: Array<GFBadge[]> = [];
  for (i = 0, j = badges.length; i < j; i += chunk) {
    groups.push(badges.slice(i, i + chunk));
  }
  const colSize = 12 / chunk;
  return (
    <div>
      {groups.map((group: GFBadge[], gindex: number) => {
        return (
          <Row key={gindex} style={{ marginTop: 10 }}>
            {group.map((badge: GFBadge) => {
              return (
                <Col
                  xs={colSize}
                  key={badge.id}
                  className="students-badge animated fadeInUp pull-left"
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={getTooltip(badge.description)}
                  >
                    <img alt="badge" src={badge.imagePath} />
                  </OverlayTrigger>
                </Col>
              );
            })}
          </Row>
        );
      })}
    </div>
  );
};

export default Badges;
