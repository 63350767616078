import * as React from 'react';
import * as types from './actionTypes';

import Axios, { AxiosRequestConfig } from 'axios';
import {
  GFBadge,
  GFInitialState,
  GFStudentCreateFormValues,
  GFUpdateProfileFormValues,
  GFUserSaveFormValues,
  ThunkResult,
  GFUser,
  GFTeacherUser
} from '../models/models';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { selectIsAdmin, selectIsStudent, selectIsTeacher } from '../reducers/userReducer';

import { API } from '../apiEndpoints';
import { Dispatch } from 'redux';
import { isArray, isEmpty, uniqBy, filter, cloneDeep, find } from 'lodash';
import MixPanelUtil from '../api/mixpanel';
import { StudentBadge } from '../components/course/Badge';
import { TrackJS } from 'trackjs';
import constants from '../constants';
import { toastr } from 'react-redux-toastr';
import { socialServiceEnum } from '../enums';
import { registerTypeEnum } from "../components/auth/Register";
const mixpanel = require('mixpanel-browser');


export function userLogoutSuccess() {
  return { type: types.USER_LOGOUT_SUCCESS, user: {} };
}

/*
 * Teacher Register
 */
export function userSave({
  id,
  gender,
  first,
  last,
  email,
  roleID,
  password,
  role,
  social,
  newGoogle,
  howDidYouHearAboutUs,
  hearOther,
  utm_source,
  utm_medium,
  utm_campaign,
  utm_content,
  utm_term,
  gclid,
}: GFUserSaveFormValues): ThunkResult<any> {
  return (dispatch, getState) => {
    const isTeacher = selectIsTeacher(getState());
    dispatch(beginAjaxCall());
    const url = API.POST.user.save;

    const data = {
      id: !isEmpty(id) ? id : undefined,
      email: email.trim(),
      first,
      last,
      gender,
      password: password.trim(),
      roleID,
      howDidYouHearAboutUs,
      hearOther,
      username: email.trim(),
      teacherType: role,
      socialType: social || null,
      newGoogle,
      UTMSource: utm_source,
      UTMMedium: utm_medium,
      UTMCampaign: utm_campaign,
      UTMContent: utm_content,
      UTMTerm: utm_term,
      GCLID: gclid,
    };

    if(!data.id){
      delete data.id;
    }

    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      data,
      url
    };
    return Axios(axiosOptions)
      .then(resp => {
        const user = resp.data;
        dispatch({ type: types.TEACHER_REGISTER_SUCCESS, user });
        document.dispatchEvent(new CustomEvent('registerSucccess'));
        MixPanelUtil.identify(user, isTeacher);
        TrackJS.configure({
          userId: user.email,
          version: `${process.env.REACT_APP_VERSION}`
        });
        Axios.defaults.headers.common['apikey'] = user.apiKey;
        mixpanel.track('Teacher Signed Up', { TeacherRole: role });
      })
      .catch(error => {
        dispatch({ type: types.TEACHER_REGISTER_FAILED });
        console.error('Error registering teacher', error);
        constants.handleError(error, 'register teacher');
      });
  };
}

/*
 * Student Register
 */
export function studentCreate({
  gender,
  first,
  last,
  email,
  password,
  classCode,
  username,
  social
}: GFStudentCreateFormValues): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const isTeacher = selectIsTeacher(getState());
    const url = API.student.create;
    // copy the email to the username
    if (username.length > 0) {
      email = username.trim();
    } else {
      username = email;
    }

    const data = {
      User: {
        email,
        first,
        last,
        gender,
        password: password.trim(),
        roleID: constants.UserRoleIDs.Student,
        username,
        socialType: social || null
      },
      Class: {
        code: classCode
      }
    };

    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      data,
      url
    };
    return Axios(axiosOptions)
      .then(resp => {
        const user = { ...resp.data.user, classes: [resp.data.class] };
        dispatch({ type: types.STUDENT_REGISTER_SUCCESS, user });
        document.dispatchEvent(new CustomEvent('registerSucccess'));
        MixPanelUtil.identify(user, isTeacher);
        TrackJS.configure({
          userId: user.email,
          version: `${process.env.REACT_APP_VERSION}`
        });
        Axios.defaults.headers.common['apikey'] = user.apiKey;
        mixpanel.track('Student Signed Up Successfully');
        if (resp.data.signupBadge) {
          const component = (
            <StudentBadge
              badge={resp.data.signupBadge as GFBadge}
              className='toast-badge'
            />
          );
          toastr.message('', '', { component });
        }
      })
      .catch(error => {
        dispatch({ type: types.STUDENT_REGISTER_FAILED, error, axiosOptions });
        console.error('Error registering student', error);
        if (!error.response) {
          constants.handleError(error, 'register student');
        } else {
          // custom error handling for this endpoint
          let message = '';
          console.error('Error registering student', error);
          if (error.response.status === 409) {
            // email already exists
            message = `You already have an account under this email or username!
            Do not create another account; simply log into your existing account using this email or username and your password.`;
            if(error.response.config.url === API.student.create){
              message = `You already have an account under this email address!  Do not create another account.  Ask your teacher to add you to the classroom using the “Move/Add Existing Student” button from the Manage Students tab.`
            }
          }
          if (error.response.status === 411) {
            // no more slots available
            message =
              'Sorry! We cannot add you to this class because your teacher needs to upgrade their account.';
          }
          if (error.response.status === 410) {
            // no more slots available
            message = 'Invalid Class Code';
          }
          if (message.length){

            toastr.error('Error', message, {
              ...constants.toastrError,
              timeOut: 8000
            });
          } else {
            constants.handleError(error, 'register student')
          }
        }
      });
  };
}

/*
 * Update User Profile
 */
export function userUpdateProfile({
  gender,
  first,
  last,
  username
}: GFUpdateProfileFormValues): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const isTeacher = selectIsTeacher(getState());
    const { user } = getState();
    const url = API.POST.user.save;

    const data = {
      email: username.trim(),
      first,
      last,
      gender,
      username: username.trim(),
      id: user.id
    };

    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      data,
      url
    };
    return Axios(axiosOptions)
      .then(resp => {
        dispatch({ type: types.USER_UPDATE_SUCCESS, user: resp.data });
        document.dispatchEvent(new CustomEvent('registerSucccess'));
        MixPanelUtil.identify(resp.data, isTeacher);
        TrackJS.configure({
          userId: user.email,
          version: `${process.env.REACT_APP_VERSION}`
        });
        toastr.success(
          'Success',
          'Your profile was updated!',
          constants.toastrSuccess
        );
      })
      .catch(error => {
        dispatch({ type: types.USER_UPDATE_FAILED });
        console.error('Error updating user', error);
        constants.handleError(error, 'update user');
      });
  };
}

/*
 * Check the user's session and then update the user object with the user from the API
 */
export function checkSession(): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.POST.user.checkSession;
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url
    };
    return Axios(axiosOptions)
      .then(resp => {
        dispatch({ type: types.USER_CHECK_SESSION_SUCCESS, user: resp.data });
      })
      .catch(error => {
        dispatch({ type: types.USER_CHECK_SESSION_FAILED });
        console.error('Error checking session', error);
        constants.handleError(error, 'check session');
        throw error;
      });
  };
}

export function userUpdatePassword(password: string): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.POST.user.updateUserPassword;
    const data = { password: password.trim() };
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url,
      data
    };
    return Axios(axiosOptions)
      .then(resp => {
        dispatch({ type: types.USER_UPDATEPW_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: types.USER_UPDATEPW_FAILED });
        console.error('Error updating password', error);
        constants.handleError(error, 'update password');
        throw error;
      });
  };
}

export function userLogin(
  username: string,
  password: string
): ThunkResult<any> {
  return (dispatch, getState) => {
    if (!navigator.onLine) {
      return Promise.reject({ statusText: 'Offline' });
    }
    dispatch(beginAjaxCall());
    const url = API.POST.user.login;
    const data = { username: username.trim(), password: password.trim() };
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url,
      data
    };
    return Axios(axiosOptions)
      .then(resp => {
        handleLoginSuccess(dispatch, resp.data, getState);
      })
      .catch(error => {
        console.error('Error logging in', error);
        handleLoginError(dispatch, error);
        throw error;
      });
  };
}

export function userLogout() : ThunkResult<any> {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    return new Promise((resolve, reject) => {
      Axios.defaults.headers.common['apikey'] = '';
      dispatch(userLogoutSuccess());
      resolve(true);
    });
  };
}

export function userLoginSocial(
  socialToken: string,
  socialType: string
): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.POST.user.loginsocial;
    const data = { socialToken, socialType };
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url,
      data
    };
    return Axios(axiosOptions)
      .then(resp => {
        handleLoginSuccess(dispatch, resp.data, getState);
      })
      .catch(error => {
        console.error('Error logging in with Google', error);
        handleLoginError(dispatch, error);
        throw error;
      });
  };
}

export function userLoginSocialWithCode(
  code: string,
): ThunkResult<any> {
  return (dispatch, getState) => {
    if (code === 'access_denied'){
      toastr.error('Error', 'Google access denied', constants.toastrError)
    }
    dispatch(beginAjaxCall());
    const url = API.POST.user.loginSocialWithCode;
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url,
      params: {code: code.trim(), redirect: `${process.env.REACT_APP_HOST}`}
    };
    return Axios(axiosOptions)
      .then(resp => {
        handleLoginSuccess(dispatch, resp.data, getState);
      })
      .catch(error => {
        console.error('Error logging in with Google code', error);
        handleLoginError(dispatch, error);
      });
  };
}
export function userDecodeSocialCode(
  code: string,
  redirect: string,
  registrationType: registerTypeEnum
): ThunkResult<any> {
  return (dispatch, getState) => {
    if (code === 'access_denied'){
      toastr.error('Error', 'Google access denied', constants.toastrError)
    }
    dispatch(beginAjaxCall());
    const url = API.POST.user.decodeSocialCode;
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url,
      params: {code, redirect, registrationType, socialType: socialServiceEnum.google}
    };
    return Axios(axiosOptions)
      .then(resp => {
        dispatch(endAjaxCall());
        Axios.defaults.headers.common['apikey'] = resp.data.apiKey;
        dispatch({ type: types.SET_SOCIAL, socialObj: resp.data });
        // If user is student, treat register with google same as login with google
        if(registrationType === registerTypeEnum.student){
          handleLoginSuccess(dispatch, resp.data, getState);
        }
      })
      .catch(error => {
        dispatch(endAjaxCall());
        const message = 'authenticate with google'
        console.error(message, error);
        constants.handleError(error, message);
      });
  };
}

export function setSocialObj(socialObj: any): ThunkResult<any> {
  return function(dispatch, getState) {
    dispatch({ type: types.SET_SOCIAL, socialObj });
  };
}

export function startResetPassword(email: string): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.POST.user.startResetPassword;

    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      data: { email: email.trim() },
      url
    };
    return Axios(axiosOptions)
      .then(data => {
        dispatch(endAjaxCall());
        toastr.success(
          'Success',
          'Token has been sent to your inbox.',
          constants.toastrSuccess
        );
      })
      .catch(error => {
        dispatch(endAjaxCall());
        if (error && error.response && error.response.status === 404) {
          toastr.error(
            'Error',
            'No account found with this email address.',
            constants.toastrError
          );
          throw error;
        } else {
          constants.handleError(error, 'reset password');
        }
        throw error;
      });
  };
}

export function resendVerification(): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const { user } = getState();
    const { id, username } = user;
    const url = API.POST.user.resendVerification;
    const data = { id, email: username };
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url,
      data
    };
    return Axios(axiosOptions)
      .then(resp => {
        dispatch(endAjaxCall());
        toastr.success(
          'Success',
          `Successfully requested resend!`,
          constants.toastrSuccess
        );
      })
      .catch(error => {
        dispatch(endAjaxCall());
        console.error('Error resending verification email', error);
        constants.handleError(error, 'resend verification email');
        throw error;
      });
  };
}

export function verifyResetPasswordCode(
  verificationToken: string,
  email: string
): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.POST.user.verifyResetPasswordCode;
    const data = { verificationToken: verificationToken.trim(), email: email.trim() };
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url,
      data
    };
    return Axios(axiosOptions)
      .then(resp => {
        dispatch(endAjaxCall());
        Axios.defaults.headers.common['apikey'] = resp.data.apiKey;
        toastr.success('Success', 'Access granted.', constants.toastrSuccess);
        return resp.data;
      })
      .catch(error => {
        dispatch(endAjaxCall());
        console.error('Error verifying reset password code', error);
        if (!error.response) {
          constants.handleError(error, 'verify reset password code');
        } else {
          // custom error handling for this endpoint
          let message = '';
          if (error.response.status === 401) {
            message =
              'This email address/username and password do not match.  If you have registered your account via Google, please click the "Log in with Google" button';
          }
          if (error.response.status === 404) {
            message =
              'Sorry, that is a invalid code. Please request a new token.';
          }
          if (message.length){
            toastr.error('Error', message, constants.toastrError);
          } else {
            console.error('error sending password reset token', error);
          }
        }
        throw error;
      });
  };
}

export function userUpdateShowTutorial(user: GFUser): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.POST.user.updateShowTutorial;
    const data = user;
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url,
      data
    };
    return Axios(axiosOptions)
      .then(resp => {
        dispatch({ type: types.USER_UPDATESHWTUTORIAL_SUCCESS });
      })
      .catch(error => {
        dispatch({ type: types.USER_UPDATESHWTUTORIAL_FAILED });
        console.error('Error updating show tutorial', error);
        constants.handleError(error, 'update show tutorial');
        throw error;
      });
  };
}

/**
 * SuperTeacher Actions
 */

/**
 * The types for the AddTeacher api call.
 * ID must be optional so we can create a user
 */
interface AddTeacherBody {
  PaymentPlan: string;
  Email: string|undefined;
  First: string;
  Last: string;
  ID?: string;
}

/**
 * 
 * Super Teacher add Teacher
 * 
 * @param teacher 
 * @returns ThunkResult<any>
 */
export function saveTeacher(
  teacher: GFTeacherUser
): ThunkResult<any> {
  const body: AddTeacherBody = {
    PaymentPlan: teacher.paymentPlan,
    Email: teacher.email,
    First: teacher.first,
    Last: teacher.last,
    ID: teacher.id
  }
  // If this teacher user account does not exist, it must be created.
  // The API expects no ID key to create the new user, so we delete it.
  if(!teacher.id){
    delete body.ID;
  }
  const axiosOptions: AxiosRequestConfig = {
    method: 'post',
    data: body,
    url: API.superTeacher.addSubTeacher
  }
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    return Axios(axiosOptions)
      .then((resp) => {
        if (!resp) {
          throw resp;
        } else {
          // We have the teacher, we must push into our user.subTeachers and filter to uniques
          const user = cloneDeep(getState().user);
          const subTeacher = resp.data;
          if(user.hasOwnProperty('subTeachers') && isArray(user.subTeachers)){
            if(find(user.subTeachers, {id: subTeacher.id})){
              // UPDATE
              // If we already have the teacher, this means we updated them.
              user.subTeachers = user.subTeachers.map(teacher => {
                if (teacher.id === subTeacher.id) {
                  return subTeacher;
                } else {
                  return teacher;
                }
              });
              dispatch({ type: types.UPDATE_TEACHER_SUCCESS, user: user });
            } else {
              // ADD
              // We don't already have them, this was an "Add"
              user.subTeachers.push(subTeacher)
              user.subTeachers = uniqBy(user.subTeachers, "id");
              dispatch({ type: types.ADD_TEACHER_SUCCESS, user: user });
            }
            
          }
          return subTeacher;
        }
      })
      .catch(error => {
        // error handled in component
        dispatch({ type: types.ADD_TEACHER_FAILED, error, axiosOptions });
        throw error;
      });
  };
}

/**
 * 
 * Super Teacher removing a teacher from their subTeachers
 * 
 * @param teacher 
 * @returns ThunkResult<any>
 */
export function removeTeacher(
  teacher: GFTeacherUser,
): ThunkResult<any>  {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
      method: 'put',
      url: API.superTeacher.removeSubTeacher,
      data: { ID: teacher.id }
    }
    return Axios(axiosOptions)
      .then((resp) => {
        if (!resp) {
          throw resp;
        } else {
          const user = cloneDeep(getState().user);
          if(user.hasOwnProperty('subTeachers') && isArray(user.subTeachers)){
            user.subTeachers = filter(user.subTeachers, (teacherIteration) => {
              return teacher.id !== teacherIteration.id;
            });
          }
          dispatch({ type: types.REMOVE_TEACHER_SUCCESS, user: user });
          return true;
        }
      })
      .catch(error => {
        // error handled in component
        dispatch({ type: types.REMOVE_TEACHER_FAILED, error });
        throw error;
      });
  };
}

/*
 * Private helper fuctions
 */

const trackMixpanel = (getState: () => GFInitialState) => {
  const {user} = getState();
  let userRole;
  const type = user.socialType ? 'Google' : '';
  const email = user.email;
  if (selectIsTeacher(getState())) {
    userRole = `${type} Teacher Signed In`;
  } else if (selectIsAdmin(getState())) {
    userRole = `${type} Admin Signed In`;
  } else if (selectIsStudent(getState())) {
    userRole = `${type} Student Signed In`;
  }
  mixpanel.track('Logged In', {
    Email: email,
    'Type of User': userRole
  });
};

const handleLoginSuccess = (dispatch: Dispatch, user: any, getState: ()=> GFInitialState) => {
  const isTeacher = selectIsTeacher(getState());
  dispatch({ type: types.USER_LOGIN_SUCCESS, user });
  Axios.defaults.headers.common['apikey'] = user.apiKey;
  MixPanelUtil.identify(user, isTeacher);
  document.dispatchEvent(new Event('loggedin'));

  TrackJS.configure({
    userId: user.email,
    version: `${process.env.REACT_APP_VERSION}`
  });
  trackMixpanel(getState);
};

const handleLoginError = (dispatch: Dispatch, error: any) => {
  dispatch({ type: types.USER_LOGIN_FAILED });
  if (!error.response) {
    constants.handleError(error, 'login');
  } else {
    // custom error handling for this endpoint
    if (error.response.status === 401) {
      let message =
        'This email address/username and password do not match.  If you have registered your account via Google, please click the "Log in with Google" button';
      if (error.response.config.url === API.POST.user.loginSocialWithCode) {
        message = `${message} Only use this button if you created your GrammarFlip account using the “Register with Google” button.`;
      }
        toastr.error('Error', message, constants.toastrError);
    } else {
      constants.handleError(error, 'login')
    }
  }
};
