import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { GFCourse } from '../models/models';
import { keyBy } from 'lodash';

export default function courseReducer(
  state: { [key: string]: GFCourse } = initialState.courses,
  action: any
): { [key: string]: GFCourse } {
  switch (action.type) {
    case types.LOAD_COURSES_SUCCESS:
      const courses = keyBy(action.courses, 'id');
      return { ...state, ...courses };
    case types.USER_LOGOUT_SUCCESS:
      return initialState.courses;
    default:
      return state;
  }
}
