import * as types from './actionTypes';

import Axios, { AxiosRequestConfig } from 'axios';
import {
  GFDateRange,
  GFInitialState,
  GFLesson,
  GFQuizItem,
  GFStudent,
  GFStudentQuizResult,
  GFTeacherQuizResult,
  GFUser,
  ThunkResult,
  GFTeacherUser
} from '../models/models';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import { filter, indexOf, map, orderBy, sortBy } from 'lodash';
import { initialDateRange, initialQuiz } from '../reducers/initialState';

import { API } from '../apiEndpoints';
import { Dispatch } from 'redux';
import { Moment } from 'moment';
// import { toastr } from "react-redux-toastr";
import constants from '../constants';
import moment from 'moment';
import { prepareQuizzesForProgressTable } from '../reducers/progressReducer';
import { quizTypeEnum } from '../enums';
import { toastr } from 'react-redux-toastr';

/*
* get my user's progress
*/
export function getMyProgress(): ThunkResult<Promise<void>> {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    const { user } = getState();
    const url = API.GET.progress.getStudentReport + user.id 
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url: url
    }
    return Axios(axiosOptions)
      .then((resp) => {
        const data = resp.data
        const newProgress = data as GFStudentQuizResult[];
        const processedLessons = processQuizResultsForStudent(
          getState().lessons,
          newProgress,
          getState().quizzes,
          false,
          { startDate: moment('1999-01-01', 'YYYY-MM-DD').toISOString(), endDate: moment().toISOString() }, // effectivly get all progress
          false,
          getState()
        );
        const lessonsWithProgress = filterLessons(
          processedLessons,
          getState().progressFilters.lessonFilter,
          getState().progressFilters.lessonIDs
        );
        dispatch({ type: types.GET_PROGRESS_USER_SUCCESS, progress: data });
        dispatch({
          type: types.GET_VISIBLE_PROGRESS_USER,
          lessonsWithProgress
        });
      })
      .catch(error => {
        dispatch({ type: types.LOAD_PROGRESS_FAILED, error });
        console.error('Error getting quiz results for student', error);
        constants.handleError(error, 'get quiz results');
        throw error;
      });
  };
}

/*
*
* Teacher getting an array of students with results for the selected lesson and class
* if in redux and the date params have not changed (will be indicated by the forceRefresh), return it, otherwise show loading and get it from the API.
* We do not store this reducer in local storage.  So it refreshes the progress every time the user refreshes the app.
*/
export function getVisibleProgressClassLesson(
  classID: string,
  lessonID: string,
  forceRefresh: boolean
): ThunkResult<any> {
  return (dispatch: Dispatch, getState: () => GFInitialState) => {
    const progressForClass = getState().classProgressRaw[classID];
    const { dateRange } = getState().classProgress;
    if (progressForClass && !forceRefresh) {
      getVisibleProgressClassLessonHelper(
        progressForClass,
        dispatch,
        getState,
        classID,
        lessonID,
        dateRange
      );
      return Promise.resolve(true);
    } else {
      dispatch(beginAjaxCall());
      return getClassProgressFromServer(classID, dateRange)
        .then(data => {
          getVisibleProgressClassLessonHelper(
            data.data,
            dispatch,
            getState,
            classID,
            lessonID,
            dateRange
          );
          dispatch({
            type: types.GET_PROGRESS_CLASS_SUCCESS,
            progress: data.data,
            classID
          });
        })
        .catch(error => {
          dispatch({ type: types.GET_PROGRESS_CLASS_FAILED, error });
          console.error('Error getting quiz results for class', error);
          constants.handleError(error, 'get quiz results');
          throw error;
        });
    }
  };
}
function getVisibleProgressClassLessonHelper(
  progressForClass: any,
  dispatch: Dispatch,
  getState: () => GFInitialState,
  classID: string,
  lessonID: string,
  dateRange: GFDateRange
) {
  const studentsInClass = filter(getState().students, (student: GFStudent) => {
    return student.class.id === classID;
  });
  const studentsWithProgress = processQuizResultsForClass(
    studentsInClass,
    getState().lessons[lessonID],
    progressForClass,
    getState().quizzes,
    dateRange,
    getState()
  );
  dispatch({
    type: types.GET_VISIBLE_PROGRESS_CLASS_LESSON,
    studentsWithProgress
  }); // this has been filtered for a lesson and ready to be piped to a react table
  return studentsWithProgress;
}

/*
* Teacher getting a student's progress report
* if in redux, return it, otherwise get it from the API.
* We do not store this reducer in local storage.  So it refreshes the progress every time the user refreshes the app.
*/
export function getVisibleProgressClassStudent(
  classID: string,
  studentID: string,
  forceRefresh: boolean
): ThunkResult<any> {
  return (dispatch: Dispatch, getState: () => GFInitialState) => {
    const progressForClass = getState().classProgressRaw[classID];
    const { dateRange } = getState().studentProgress;
    if (progressForClass && !forceRefresh) {
      getVisibleProgressClassStudentHelper(
        progressForClass,
        dispatch,
        getState,
        classID,
        studentID
      );
      return Promise.resolve(true);
    } else {
      return getClassProgressFromServer(classID, dateRange)
        .then(data => {
          getVisibleProgressClassStudentHelper(
            data.data,
            dispatch,
            getState,
            classID,
            studentID
          );
          dispatch({
            type: types.GET_PROGRESS_CLASS_SUCCESS,
            progress: data.data,
            classID
          });
        })
        .catch(error => {
          dispatch({ type: types.GET_PROGRESS_CLASS_FAILED, error });
          console.error(
            'Error getting quiz results for class while viewing student report',
            error
          );
          constants.handleError(
            error,
            'get quiz results for class while viewing student report'
          );
          throw error;
        });
    }
  };
}

/*
* get the class progress
* if there is a student ID then filter for the student after receiving
*/
export const getClassProgressFromServer = (
  classID: string,
  dateRange: GFDateRange
) => {
  const url = `${API.GET.progress.getClassReport}`;
  const start = dateRange.startDate;
  const end = moment(dateRange.endDate).add('day', 1).toISOString(); // add a day so that the filter includes results from today
  const axiosOptions: AxiosRequestConfig = {
    method: 'get',
    params: { classID, start, end },
    url,
    timeout: 60000
  };
  return Axios(axiosOptions);
};

/**
 * Super Teacher getting subteachers' progress report from the API.
 * We do not store this reducer in local storage,
 * so it refreshes the progress every time the user refreshes the app.
 * @param lesson 
 * @returns ThunkResult<any>
 */
export function getVisibleProgressSubTeacher(
  lesson: GFLesson
): ThunkResult<any> {
  return (dispatch: Dispatch, getState: () => GFInitialState) => {
    const { dateRange } = getState().teacherProgress;
    dispatch(beginAjaxCall());
    return getTeacherProgressFromServer(dateRange, lesson.id)
        .then(data => {
          getVisibleProgressSubTeacherHelper(
            data,
            dispatch,
            getState,
            lesson
          );
          dispatch({
            type: types.GET_PROGRESS_SUBTEACHER_SUCCESS,
            teachersWithProgress: data.data,
            lessonID: lesson.id
          });
        })
        .catch(error => {
          dispatch({ type: types.GET_PROGRESS_SUBTEACHER_FAILED, error });
          console.error(
            'Error getting progress for teachers while viewing super teacher report',
            error
          );
          constants.handleError(
            error,
            'get progress for lesson while viewing super teacher report'
          );
          throw error;
        });
  };
}

/**
 * 
 * Get the teacher progress for super teacher
 * 
 * @param dateRange 
 * @param lessonID 
 * @returns Promise
 */
export const getTeacherProgressFromServer = (
  dateRange: GFDateRange,
  lessonID: string
) => {
  const url = `${API.GET.progress.getSuperTeacherReport}`;
  const start = moment(dateRange.startDate).format('MM/DD/YYYY');
  const end = moment(dateRange.endDate).add('day', 1).format('MM/DD/YYYY');
  const axiosOptions: AxiosRequestConfig = {
    method: 'get',
    params: { lessonID, start, end },
    url,
    timeout: 60000
  };
  return Axios(axiosOptions);
};

export const showAnswersModal = () => ({
  type: types.MODAL_SHOW_QUIZ_ANSWERS
});
export const hideAnswersModal = () => ({
  type: types.MODAL_HIDE_QUIZ_ANSWERS
});

export function getStudentQuizResult(quizResultID: string): ThunkResult<any> {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    const url = API.GET.progress.getStudentQuizResult + quizResultID
    
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url: url
    }

    return Axios(axiosOptions)
      .then((resp) => {
        dispatch({
          type: types.LOAD_STUDENT_QUIZ_ANSWERS_SUCCESS,
          quizResult: resp.data
        });
        dispatch({
          type: types.MODAL_SHOW_QUIZ_ANSWERS
        });
      })
      .catch(error => {
        console.error(
          'Error when trying to display a students quiz result',
          error
        );
        dispatch({ type: types.LOAD_STUDENTS_QUIZ_RESULTS_FAILED, error });
        constants.handleError(error, 'load quiz result');
        throw error;
      });
  };
}

function getVisibleProgressClassStudentHelper(
  progressForClass: any,
  dispatch: Dispatch,
  getState: ()=>GFInitialState,
  classID: string,
  studentID: string
) {
  const studentProgress = filter(progressForClass, {
    studentID
  }) as GFStudentQuizResult[];
  const processedLessons = processQuizResultsForStudent(
    getState().lessons,
    studentProgress,
    getState().quizzes,
    true,
    getState().studentProgress.dateRange,
    true,
    getState()
  );
  const lessonsWithProgress = filterLessons(
    processedLessons,
    getState().progressFilters.lessonFilter,
    getState().progressFilters.lessonIDs
  );
  dispatch({
    type: types.GET_VISIBLE_PROGRESS_CLASS_STUDENT,
    lessonsWithProgress
  });
  return lessonsWithProgress;
}

/**
 * 
 * A helper function for preparing Super Teacher progress report screen.
 * 
 * @param progressForClass 
 * @param dispatch 
 * @param getState 
 * @param lesson 
 * @returns []
 */
function getVisibleProgressSubTeacherHelper(
  progressForClass: any,
  dispatch: Dispatch,
  getState: ()=>GFInitialState,
  lesson: GFLesson
) {
  const teacherProgress = filter(progressForClass.data, (d) => {
    return d.quiz.lessonID === lesson.id;
  }) as GFTeacherQuizResult[];
  const processedLessons = processQuizResultsForTeachers(
    getState().user.subTeachers,
    lesson,
    teacherProgress,
    getState().teacherProgress.dateRange,
    getState()
  );
  dispatch({
    type: types.GET_VISIBLE_PROGRESS_SUBTEACHER,
    teachersWithProgress: {data: processedLessons, dateRange: getState().teacherProgress.dateRange}
  });
  return processedLessons;
}

/*
* get the progress data ready for the table view.
* Loop over the lessons and check to see if the student has a score for any of the PEs for that lesson.
* If no scores, then add a className of "hidden" to the lesson.
* If yes, then process the score and add a className of "progress".
*/
function processQuizResultsForStudent(
  lessons: { [key: string]: GFLesson },
  progress: GFStudentQuizResult[],
  quizzes: { [key: string]: GFQuizItem },
  isTeacher: boolean,
  dateRange: GFDateRange = initialDateRange,
  shouldFilterByDateRange: boolean = true,
  state: GFInitialState
) {
  return map(lessons, lesson => {

    const {diagnostic, postEval, practice} = prepareQuizzesForProgressTable(state, {lessonID: lesson.id});
    const lessonProgress = getVisibleProgressForStudent(
      progress,
      dateRange,
      lesson.id,
      shouldFilterByDateRange
    );
    let lessonHasProgress = false;
    const quiz01Results = processQuizScore(diagnostic, lessonProgress);
    const quiz02Results = processQuizScore(practice[0], lessonProgress);
    const quiz03Results = processQuizScore(practice[1], lessonProgress);
    const quiz04Results = processQuizScore(practice[2], lessonProgress);
    const quiz05Results = processQuizScore(postEval, lessonProgress);

    if (lessonProgress.length) {
      lessonHasProgress = true;
    } else {
      lessonHasProgress = false;
    }

    return {
      ...lesson,
      lessonHasProgress,
      quiz01Results,
      quiz02Results,
      quiz03Results,
      quiz04Results,
      quiz05Results,
      isTeacher
    };
  });
}

/*
* get the progress data ready for the table view.
* Loop over the lessons and check to see if the student has a score for any of the PEs for that lesson.
* If no scores, then add a className of "hidden" to the lesson.
* If yes, then process the score and add a className of "progress".
*/
function processQuizResultsForClass(
  students: GFStudent[],
  lesson: GFLesson,
  progress: GFStudentQuizResult[],
  quizzes: { [key: string]: GFQuizItem },
  dateRange: GFDateRange = initialDateRange,
  state: GFInitialState
) {
  const studentsWithProgress = map(students, student => {
    const {diagnostic, postEval, practice} = prepareQuizzesForProgressTable(state, {lessonID: lesson.id});

    const lessonProgress = getVisibleProgressForClass(
      progress,
      dateRange,
      student.userID
    );
    let lessonHasProgress = false;
    const quiz01Results = processQuizScore(diagnostic, lessonProgress);
    const quiz02Results = processQuizScore(practice[0], lessonProgress);
    const quiz03Results = processQuizScore(practice[1], lessonProgress);
    const quiz04Results = processQuizScore(practice[2], lessonProgress);
    const quiz05Results = processQuizScore(postEval, lessonProgress);

    if (lessonProgress.length) {
      lessonHasProgress = true;
    } else {
      lessonHasProgress = false;
    }

    return {
      ...student,
      lessonHasProgress,
      quiz01Results,
      quiz02Results,
      quiz03Results,
      quiz04Results,
      quiz05Results
    };
  });

  return sortBy(
    studentsWithProgress,
    [
      function(o: any) {
        return o.student.last ? o.student.last.toLowerCase() : '';
      }
    ],
    [
      function(o: any) {
        return o.student.first.toLowerCase();
      }
    ]
  );
}

/*
* normalize the scores and apply appropriate color classNames as well as sort them by most recent
*/
function processQuizScore(
  quiz: GFQuizItem = initialQuiz,
  progress: GFStudentQuizResult[]
) {
  const quizResults = filter(progress, qResult => {
    if (qResult.quizID === quiz.id) {
      // no need to show incomplete quizzes that are not locked
      if (qResult.isComplete === false && qResult.isLocked === false) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  });
  if (quizResults.length) {
    // process the scores
    return orderBy(
      map(quizResults, result => {
        let className = '';
        let scoreString = `${result.score}%`;
        if (result.overrideScore) {
          className = '';
          scoreString = '--';
        } else if (result.score >= 80 && result.score <= 100) {
          className = 'green';
        } else if (result.score >= 51 && result.score <= 79) {
          className = 'yellow';
        } else if (result.score >= 0 && result.score <= 50) {
          className = 'red';
        }

        return { ...result, className, scoreString }; // an array of all the results for this quiz
      }),
      ['createDate'],
      ['desc']
    );
  } else {
    return [
      {
        score: 0,
        quizID: quiz.id,
        scoreString: '--',
        className: '',
        isComplete: true,
        isLocked: false
      }
    ]; // am array with a single blank result
  }
}

/**
 * 
 * To process our sub teachers average quiz scores for display in react-table.
 * 
 * @param teachers 
 * @param lesson 
 * @param progress
 * @param dateRange 
 * @param state 
 * @returns []
 */
function processQuizResultsForTeachers(
  teachers: GFTeacherUser[],
  lesson: GFLesson,
  progress: GFTeacherQuizResult[],
  dateRange: GFDateRange = initialDateRange,
  state: GFInitialState
){
  // Loop through progress array and add to teacher.progress where subTeacherID === teacher.id
  const teachersWithProgress = map(teachers, teacher => {
    const {diagnostic, postEval, practice} = prepareQuizzesForProgressTable(state, {lessonID: lesson.id});
    const lessonProgress = getVisibleProgressForTeacher(
      progress,
      dateRange,
      lesson.id,
      false // api does not give us a createDate
    );
    let lessonHasProgress = false;
    const quiz01Results = processSubTeacherScore(diagnostic, lessonProgress, teacher.id);
    const quiz02Results = processSubTeacherScore(practice[0], lessonProgress, teacher.id);
    const quiz03Results = processSubTeacherScore(practice[1], lessonProgress, teacher.id);
    const quiz04Results = processSubTeacherScore(practice[2], lessonProgress, teacher.id);
    const quiz05Results = processSubTeacherScore(postEval, lessonProgress, teacher.id);

    if (lessonProgress.length) {
      lessonHasProgress = true;
    } else {
      lessonHasProgress = false;
    }

    return {
      ...teacher,
      lessonHasProgress,
      quiz01Results,
      quiz02Results,
      quiz03Results,
      quiz04Results,
      quiz05Results
    };
  });
  return sortBy(
    teachersWithProgress,
    [
      function(o: any) {
        return o.last ? o.last.toLowerCase() : '';
      }
    ],
    [
      function(o: any) {
        return o.first.toLowerCase();
      }
    ]
  );
}

/**
 * 
 * Normalize the scores and apply appropriate color classNames,
 * as well as sort them by most recent
 * 
 * @param quiz 
 * @param progress 
 * @param teacherID 
 * @returns []
 */
function processSubTeacherScore(
  quiz: GFQuizItem = initialQuiz,
  progress: GFTeacherQuizResult[],
  teacherID: string
) {
  const quizResults = filter(progress, qResult => {
    if (qResult.quizID === quiz.id && qResult.subTeacherID === teacherID) {
      // no need to show incomplete quizzes that are not locked
      if (qResult.isComplete === false && qResult.isLocked === false) {
        return false;
      }
      // no need to return 0 score, this would indicate 0 attempts so do not include in average
      if (qResult.score === 0) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  });
  if (quizResults.length) {
    // process the scores
    return orderBy(
      map(quizResults, result => {
        let className = '';
        let scoreString = `${result.score}%`;
        if (result.score >= 80 && result.score <= 100) {
          className = 'green';
        } else if (result.score >= 51 && result.score <= 79) {
          className = 'yellow';
        } else if (result.score >= 0 && result.score <= 50) {
          className = 'red';
        }

        return { ...result, className, scoreString }; // an array of all the results for this quiz
      }),
      ['createDate'],
      ['desc']
    );
  } else {
    return [
      {
        score: 0,
        quizID: quiz.id,
        scoreString: '--',
        className: '',
        isComplete: true,
        isLocked: false
      }
    ]; // am array with a single blank result
  }
}

function filterLessons(
  lessons: GFLesson[],
  lessonFilter: string,
  lessonIDs?: string[]
) {
  if (lessonFilter === 'progress') {
    return filter(lessons, { lessonHasProgress: true });
  } else if (lessonFilter === 'custom') {
    return filter(lessons, l => {
      return indexOf(lessonIDs, l.id) !== -1;
    });
  } else {
    // do custom filtering here
    return lessons;
  }
}

export const setLessonFilter = (filt: string, lessonIDs?: string[]): ThunkResult<void> => {
  return (dispatch, getState) => {
    // const visibleLessons = getState().studentProgress.data;
    dispatch({
      type: types.SET_LESSON_FILTER,
      lessonFilter: filt,
      lessonIDs
    });
  };
};

export function confirmResetClassStarts(classID: string, type: number): ThunkResult<any>{
  return (dispatch, getState) => {
    const okText = type === quizTypeEnum.diagnostic ? 'Reset Pre-Tests' : 'Reset Post-Evals';
    const confirmMessage = type === quizTypeEnum.diagnostic ? 'Are you sure you want to reset all Pre-Tests of all students in this class section?' : 'Are you sure you want to reset all Post-Evals of all students in this class section?';

    const toastrConfirmOptions = {
      onOk: () => {
        dispatch(resetClassStarts(classID, type));
      },
      onCancel: () => console.log('CANCEL: clicked'),
      okText,
      cancelText: 'cancel'
    };
    toastr.confirm(confirmMessage, toastrConfirmOptions)
  }
}

export function resetClassStarts(
  classID: string,
  type: number
): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.progress.resetClassProgress;

    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      data: { classID, type },
      url
    };
    return Axios(axiosOptions)
      .then(data => {

        dispatch(endAjaxCall());

        let successMessage = 'Pre-Tests successfully reset.';
        if (type === quizTypeEnum.postEval) {
          successMessage = 'Post-Evaluations successfuly reset.';
        }
        toastr.success('Success', successMessage, constants.toastrSuccess);
      })
      .catch((error: any) => {
        console.error('Error resetting class starts', error);
        dispatch(endAjaxCall());
        constants.handleError(error, 'reset class');
        throw error;
      });
  };
}

export function confirmResetStudentStarts(studentID: string, type: number): ThunkResult<any>{
  return (dispatch, getState) => {
    const okText = type === quizTypeEnum.diagnostic ? 'Reset Pre-Tests' : 'Reset Post-Evals';
    const confirmMessage = type === quizTypeEnum.diagnostic ? 'Are you sure you want to reset all Pre-Tests for this student?' : 'Are you sure you want to reset all Post-Evals for this student?';

    
    const toastrConfirmOptions = {
      onOk: () => {
        dispatch(resetStudentStarts(studentID, type));
      },
      onCancel: () => console.log('CANCEL: clicked'),
      okText,
      cancelText: 'cancel'
    };
    toastr.confirm(confirmMessage, toastrConfirmOptions)
  }
}

export function resetStudentStarts(
  studentID: string,
  type: number
): ThunkResult<any> {
  return (dispatch, getState) => {

    dispatch(beginAjaxCall());
    const url = API.progress.resetStudentProgress;

    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      data: { studentID, type },
      url
    };
    return Axios(axiosOptions)
      .then(data => {
        dispatch(endAjaxCall());

        let successMessage = 'Pre-Tests successfully reset.';
        if (type === quizTypeEnum.postEval) {
          successMessage = 'Post-Evaluations successfuly reset.';
        }
        toastr.success('Success', successMessage, constants.toastrSuccess);
      })
      .catch((error: any) => {
        console.error('Error resetting student starts', error);
        dispatch(endAjaxCall());
        constants.handleError(error, 'reset student');
        throw error;
      });
  };
}

export const resetClassLessonProgress = () => ({
  type: types.CLEAR_VISIBLE_PROGRESS
});

export const setStartDateClassLesson = (date: Date) => ({
  type: types.SET_START_DATE_CLASS_LESSON,
  date
});
export const setEndDateClassLesson = (date: Date) => ({
  type: types.SET_END_DATE_CLASS_LESSON,
  date
});
export const setStartDateClassStudent = (date: Moment) => ({
  type: types.SET_START_DATE_CLASS_STUDENT,
  date
});
export const setEndDateClassStudent = (date: Moment) => ({
  type: types.SET_END_DATE_CLASS_STUDENT,
  date
});
export const setStartDateUser = (date: Moment) => ({
  type: types.SET_START_DATE_USER,
  date
});
export const setEndDate = (date: Moment) => ({
  type: types.SET_END_DATE_USER,
  date
});
export const setStartDateSubTeacher = (date: Moment) => ({
  type: types.SET_START_DATE_SUBTEACHER,
  date
});
export const setEndDateSubTeacher = (date: Moment) => ({
  type: types.SET_END_DATE_SUBTEACHER,
  date
});

function getVisibleProgressForStudent(
  progress: GFStudentQuizResult[],
  dateRange: GFDateRange,
  lessonID: string,
  shouldFilterByDateRange: boolean
) {
  if (shouldFilterByDateRange) {
    const filteredByDate = filter(progress, result => {
      return (
        result.quiz.lessonID === lessonID &&
        (moment
          .utc(result.createDate)
          .isBetween(dateRange.startDate, dateRange.endDate, 'day', '[]') ||
          result.isLocked === true)
      );
    });
    return filteredByDate.map(result => {
      if (
        moment
          .utc(result.createDate)
          .isBetween(dateRange.startDate, dateRange.endDate, 'day', '[]') ===
          false &&
        result.isLocked
      ) {
        return { ...result, overrideScore: true };
      } else {
        return result;
      }
    });
  } else {
    return filter(progress, result => {
      return result.quiz.lessonID === lessonID;
    });
  }
}

/**
 * 
 * To filter the sub teacher quiz score averages
 * 
 * @param progress 
 * @param dateRange 
 * @param lessonID 
 * @param shouldFilterByDateRange 
 * @returns []
 */
function getVisibleProgressForTeacher(
  progress: GFTeacherQuizResult[],
  dateRange: GFDateRange,
  lessonID: string,
  shouldFilterByDateRange: boolean
) {
  if (shouldFilterByDateRange) {
    const filteredByDate = filter(progress, result => {
      return (
        result.quiz.lessonID === lessonID &&
        (moment
          .utc(result.createDate)
          .isBetween(dateRange.startDate, dateRange.endDate, 'day', '[]') ||
          result.isLocked === true)
      );
    });
    return filteredByDate.map(result => {
      if (
        moment
          .utc(result.createDate)
          .isBetween(dateRange.startDate, dateRange.endDate, 'day', '[]') ===
          false &&
        result.isLocked
      ) {
        return { ...result, overrideScore: true };
      } else {
        return result;
      }
    });
  } else {
    return filter(progress, result => {
      return result.quiz.lessonID === lessonID;
    });
  }
}

function getVisibleProgressForClass(
  progress: GFStudentQuizResult[],
  dateRange: GFDateRange,
  studentID: string
) {
  const filteredByDate = filter(progress, result => {
    return (
      result.studentID === studentID &&
      (moment
        .utc(result.createDate)
        .isBetween(dateRange.startDate, dateRange.endDate, 'day', '[]') ||
        result.isLocked === true)
    );
  });
  return filteredByDate.map(result => {
    if (
      moment
        .utc(result.createDate)
        .isBetween(dateRange.startDate, dateRange.endDate, 'day', '[]') ===
        false &&
      result.isLocked
    ) {
      return { ...result, overrideScore: true };
    } else {
      return result;
    }
  });
}

export function getResultsCSVForStudent(studentID: string, user: GFUser) {
  const url = API.GET.progress.getResultsCSVForStudent + studentID;
  const axiosOptions: AxiosRequestConfig = {
    method: 'get',
    url: url
  }

  return Axios(axiosOptions)
    .then(resp => {
      return resp.data;
    })
    .catch(error => {
      throw error
    })
}