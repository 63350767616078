import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { GFClass, GFInitialState, Option } from '../models/models';
import { orderBy, map } from 'lodash';
import moment from 'moment';
import { convertToGoogleClassOptions, convertToOptions } from '../components/progressReports/StudentReportUtil';

export const selectClasses = (state: GFInitialState) => {
  return orderBy(
    state.classes,
    res => moment.utc(res.createDate).unix(),
    'asc'
  );
};

export const selectGoogleClassOptions = (state: GFInitialState) => {
  return convertToGoogleClassOptions(orderBy(
    state.googleClasses,
    (res: any) => moment.utc(res.createDate).unix(),
    'asc'
  ));
};

export const selectClassOptions = (state: GFInitialState) => {
  return convertToOptions(selectClasses(state));
};

export default function classReducer(
  state: GFClass[] = initialState.classes,
  action: any
): GFClass[] {
  switch (action.type) {
    case types.LOAD_CLASSES_SUCCESS:
      return action.classes || [];

    case types.CREATE_CLASS_SUCCESS:
      return [...state, Object.assign({}, action.gfClass)];

    case types.UPDATE_CLASS_SUCCESS:
      return [
        ...state.filter((gfClass: GFClass) => gfClass.id !== action.gfClass.id),
        Object.assign({}, action.gfClass)
      ];

      case types.TOGGLE_SUPER_DIAGNOSTIC_SUCCESS:
        const originalClass = state.find(gfClass => gfClass.id === action.payload.id);
        return [
          ...state.filter((gfClass) => gfClass.id !== action.payload.id),
          {...originalClass, ...action.payload}
        ];


    case types.DELETE_CLASS_SUCCESS:
      return state.filter((gfClass: GFClass) => gfClass.id !== action.classID);

    case types.USER_LOGOUT_SUCCESS:
      return initialState.classes;

    case types.IMPORT_STUDENTS_SUCCESS:
      return state;

    case types.IMPORT_STUDENTS_FAILED:
      return state;

    default:
      return state;
  }
}
