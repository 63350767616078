/*
* QuizBreadcrumbContainer
*/

import { connect } from 'react-redux';
import { GFInitialState } from '../../models/models';
import { resetAnswers } from '../../actions/quizActions';
import { QuizBreadcrumb } from './QuizBreadcrumb';

interface RouterParams {
    courseID: string;
    lessonID: string;
    quizID: string;
  }
interface Iprops {
    params: RouterParams;
}
const mapStateToProps = (state: GFInitialState, ownProps: Iprops) => {
    const courseName = state.courses[ownProps.params.courseID] ? state.courses[ownProps.params.courseID].name : '';
    const lessonName = state.lessons[ownProps.params.lessonID] ? state.lessons[ownProps.params.lessonID].name : '';
    const quizName = state.quizzes[ownProps.params.quizID] ? state.quizzes[ownProps.params.quizID].name : '';
  return {
    courseName,
    lessonName,
    quizName
  };
};

export const QuizBreadcrumbContainer = connect(
  mapStateToProps,
  {
    resetAnswers
  }
)(QuizBreadcrumb);
