// import 'core-js' // TODO figure out what polyfills we need exactly for IE 11 and import only those.
// https://reactjs.org/docs/javascript-environment-requirements.html
// https://stackoverflow.com/questions/43756211/best-way-to-polyfill-es6-features-in-react-app-that-uses-create-react-app
// import 'core-js/es6/map';
// import 'core-js/es6/set';
// import 'core-js/es6/set';
import 'custom-event-polyfill';
// import 'raf/polyfill';
import * as React from 'react'; // tslint:disable-line
import * as ReactDOM from 'react-dom';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import routes from './routes';
import initialState from './reducers/initialState';
import { loadState, saveState } from './store/localStorage';
import { throttle } from 'lodash';
import MixPanelUtil from './api/mixpanel';
import { TrackJS } from 'trackjs';
import ReduxToastr from 'react-redux-toastr';
import * as WebFont from 'webfontloader';

// import 'whatwg-fetch'; // fetch polyfill for unsupported browsers
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-table/react-table.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-toggle/style.css';
import './index.css'; // main CSS file should be last
import constants from './constants';
import Axios from 'axios';
import { GFInitialState } from './models/models';

const persistedState = loadState('state-gf') as GFInitialState;
export const store = configureStore(persistedState || initialState);

// set Axios default header for accepting JSON
Axios.defaults.headers.common['Accept'] = 'application/json';
Axios.defaults.headers.common['Content-Type'] = 'application/json';
Axios.defaults.timeout = constants.httpTimeout;

// Trackjs
TrackJS.install({
  token: '7acefdd92da44ad595db60cb7c09af8c',
  application: 'grammarflip',
  enabled: !(window.location.host.indexOf('localhost') >= 0)
  // for more configuration options, see https://docs.trackjs.com
});

MixPanelUtil.mixpanelInit(`${process.env.REACT_APP_MIXPANEL}`);

WebFont.load({
  typekit: {
    id: 'jsb3cnr'
  }
});

// throttle ensures that we never write to
// localstorage more than once per second
store.subscribe(
  throttle(() => {
    const {
      user,
      lessons,
      courses,
      quizzes,
      classes,
      googleClasses,
      quizAnswers,
      quizView,
      customLessons,
      selectedClassID,
      myProgress,
      myProgressRaw,
      appSettings,
      superDiagnosticQuiz,
      superDiagnosticView,
      superDiagnosticTopics,
      students
    } = store.getState() as any;
    saveState(
      {
        user,
        lessons,
        courses,
        quizzes,
        classes,
        googleClasses,
        quizAnswers,
        quizView,
        customLessons,
        selectedClassID,
        myProgress,
        myProgressRaw,
        appSettings,
        superDiagnosticQuiz,
        superDiagnosticView,
        superDiagnosticTopics,
        students
      },
      'state-gf'
    );
  }, 1000)
);

ReactDOM.render(
  <Provider store={store}>
    <div>
      <Router history={browserHistory} routes={routes} />
      <ReduxToastr preventDuplicates={process.env.NODE_ENV === 'production'} />
    </div>
  </Provider>,
  document.getElementById('root') as HTMLElement
);
