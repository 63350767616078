import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';
import TrackJSLogger from './TrackJSLogger';
import { GFInitialState } from '../models/models';

export default function configureStore(intialState: GFInitialState) {
  // return createStore(rootReducer, intialState, applyMiddleware(thunk));
  console.log(`app launch.  ENV: ${process.env.NODE_ENV}, Version: ${process.env.REACT_APP_VERSION}`)
  if (process.env.NODE_ENV !== 'production') {
    return createStore(
      rootReducer,
      intialState,
      require('redux-devtools-extension').composeWithDevTools(
        applyMiddleware(
          thunk,
          require('redux-immutable-state-invariant').default()
        )
      )
    );
  } else {
    return createStore(
      rootReducer,
      intialState,
      applyMiddleware(thunk, TrackJSLogger)
    );
  }
}

/*
TODO update types with initialstate
require('redux-devtools-extension').composeWithDevTools(
        applyMiddleware(
          thunk as ThunkMiddleware<IinitialState, any>,
          require('redux-immutable-state-invariant').default()
        )
      )
      */
