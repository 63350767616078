import * as React from 'react';
import * as types from './actionTypes';

import Axios, { AxiosRequestConfig } from 'axios';
import { GFQuizAnswer, ThunkResult } from '../models/models';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';

import { API } from '../apiEndpoints';
import { StudentBadge } from '../components/course/Badge';
import constants from '../constants';
import { forEach } from 'lodash';
import { quizTypeEnum } from '../enums';
import { toastr } from 'react-redux-toastr';

const mixpanel = require('mixpanel-browser');

/*
* Get Complete list of quizzes without the questions
*/
export function getAllQuizzes(): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.quiz.getAll;

    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url
    };
    return Axios(axiosOptions)
      .then(response => {
        dispatch({ type: types.LOAD_QUIZZES_SUCCESS, quizzes: response.data });
      })
      .catch((error: any) => {
        dispatch({ type: types.LOAD_QUIZZES_FAILED, error });
        console.error('Error loading all quizzes', error);
        constants.handleError(error, 'load all quizzes');
        throw error; // handle error here and throw because we are using this in a promise array
      });
  };
}

/*
* Load quizes by lesson ID in order to get the questions
*/
export function getQuizzesByLessonID(lessonID: string): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.quiz.getByLesson;

    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      params: { lessonID },
      url
    };
    return Axios(axiosOptions)
      .then(response => {
        dispatch({
          type: types.LOAD_QUIZZES_BY_LESSON_SUCCESS,
          quizzes: response.data
        });
      })
      .catch((error: any) => {
        dispatch({ type: types.LOAD_QUIZZES_BY_LESSON_FAILED, error });
        console.error('Error loading lesson quizzes', error);
        constants.handleError(error, 'load lesson quizzes');
      });
  };
}

/*
* submit a quiz result
*/
export function saveQuizResult(
  quizID: string,
  quizName: string,
  quizType: number
): ThunkResult<Promise<void>> {
  return function(dispatch, getState) {
    dispatch(beginAjaxCall());
    const { quizAnswers } = getState().quizView;
    const url = API.student.saveQuiz;
    // grind quiz results into the sausage that api is expecting
    const { score } = calculateScore(quizAnswers);
    // const isLocked = quizType === quizTypeEnum.postEval || quizType === quizTypeEnum.diagnostic
    const axiosConfig: AxiosRequestConfig = {
      method: 'post',
      url,
      data: {
        Answers: quizAnswers,
        quizID,
        Score: score
        // isLocked
      }
    };
    return Axios(axiosConfig)
      .then(response => {
        if (response.data.earnedBadge) {
          const component = (
            <StudentBadge
              badge={response.data.earnedBadge}
              className="toast-badge"
            />
          );
          toastr.message('', '', { component });
        }
        dispatch({ type: types.SAVE_QUIZ_ANSWERS_SUCCESS });
        mixpanel.track('Finished Practice Exercise', {
          quizID,
          quizName,
          score
        });
      })
      .catch(error => {
        // error handled in component
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

// Save quiz start
export function startQuiz(quizID: string): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.student.startQuiz;

    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      data: { quizID },
      url
    };
    return Axios(axiosOptions)
      .then(data => {
        dispatch({
          type: types.START_QUIZ_SUCCESS,
          startTime: data.data.startTime
        });
      })
      .catch((error: any) => {
        // error handled in component
        dispatch({ type: types.START_QUIZ_FAILED });
        throw error;
      });
  };
}

export function resetQuizStarts(
  quizID: string,
  studentID: string,
  quizType: quizTypeEnum
): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());
    const url = API.student.resetQuizStarts;

    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      data: { quizID, studentID },
      url
    };
    return Axios(axiosOptions)
      .then(data => {
        document.dispatchEvent(new CustomEvent('quizStartsReset'));
        dispatch(endAjaxCall());

        let successMessage = 'Pre-Test successfully reset.';
        if (quizType === quizTypeEnum.postEval) {
          successMessage = 'Post-Evaluation successfuly reset.';
        }
        toastr.success('Success', successMessage, constants.toastrSuccess);
      })
      .catch((error: any) => {
        console.error('Error resetting quiz', error);
        dispatch(endAjaxCall());
        constants.handleError(error, 'reset quiz');
      });
  };
}

export const addAnswer = (answer: GFQuizAnswer) => ({
  type: types.ADD_ANSWER,
  answer
});

export const resetAnswers = () => ({
  type: types.RESET_ANSWERS
});

export const setInProgressQuizID = (quizID: string) => ({
  type: types.SET_SELECTED_IN_PROGRESS_QUIZ,
  id: quizID
});

export const saveQuizResultTeacher = () => ({
  type: types.SAVE_QUIZ_TEACHER
});

export const calculateScore = (quizAnswers: GFQuizAnswer[]) => {
  let numCorrect = 0;
  const tot = quizAnswers.length;
  forEach(quizAnswers, answer => {
    if (answer.isCorrect) {
      numCorrect++;
    }
  });
  return { score: Math.round((numCorrect / tot) * 100), numCorrect, tot };
};
