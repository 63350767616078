import * as types from './actionTypes';

import Axios, { AxiosRequestConfig } from 'axios';

import { API } from '../apiEndpoints';
import { ThunkResult } from '../models/models';
import { beginAjaxCall } from './ajaxStatusActions';
import constants from '../constants';

/*
* Load Courses
*/
export function getAllCourses(): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());

    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url: API.course.getAll
    };
    return Axios(axiosOptions)
      .then(resp => {
        dispatch({ type: types.LOAD_COURSES_SUCCESS, courses: resp.data });
      })
      .catch((error: any) => {
        dispatch({ type: types.LOAD_COURSES_FAILED, error });
        console.error('Error loading courses', error);
        constants.handleError(error, 'load courses');
        throw error;
      });
  };
}
