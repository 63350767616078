import * as types from '../actions/actionTypes';

import initialState, { initialQuiz } from './initialState';
import { map, pickBy } from 'lodash';

import { GFQuizItem, GFQuizQuestion } from '../models/models';
import { keyBy } from 'lodash';

export default function quizzesReducer(
  state: { [key: string]: GFQuizItem } = initialState.quizzes,
  action: any
): { [key: string]: GFQuizItem } {
  switch (action.type) {
    /*
    * When loading all quizzes, the objects do not have instructions or questions.  If we already have them, do not erase them.
    */
    case types.LOAD_QUIZZES_SUCCESS:
      return keyBy(
        map(action.quizzes, quiz => {
          const existingQuiz = state[quiz.id];
          if (existingQuiz) {
            return {
              ...cleanQuizObject(quiz),
              questions: existingQuiz.questions,
              instructions: existingQuiz.instructions,
              imagePath: existingQuiz.imagePath
            };
          } else {
            return cleanQuizObject(quiz);
          }
        }),
        'id'
      );

    case types.LOAD_QUIZZES_BY_LESSON_SUCCESS:
      const quizzesWithParsedOptions = map(action.quizzes, quiz => {
        return cleanQuizObject(quiz)
      });
      return Object.assign({}, state, keyBy(quizzesWithParsedOptions, 'id'));
    case types.USER_LOGOUT_SUCCESS:
      return initialState.quizzes;
    default:
      return state;
  }
}

export const cleanQuizObject = (quiz: any): GFQuizItem => {
  let parsedQuestions: GFQuizQuestion[] = [];
  if (quiz && quiz.questions && quiz.questions.length) {
    parsedQuestions = map(quiz.questions, question => {
      if (question.options && typeof question.options === 'string') {
        return {
          ...question,
          options: question.options.split('*||*').map((option: any) => {
            return JSON.parse(option);
          })
        };
      } else {
        return question;
      }
    });
  }

  return {
    ...initialQuiz,
    ...pickBy(quiz, (property, key) => property !== null),
    questions: parsedQuestions
  };
};
