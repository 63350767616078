import * as React from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router';
import { Grid, Row, Col } from 'react-bootstrap';
import { GFInitialState } from '../../models/models';

const graham = require('../../images/graham-success.png');

interface Props extends React.Props<Verification> {
  user: any;
  location: any;
}

interface State {
  verifyMessage: string;
}

class Verification extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      verifyMessage: ''
    };
    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);
  }

  showSuccess() {
    return (
      <div>
        <h1>
          Your email has been verified. You can close this tab, or go to your{' '}
          <Link to="/classes">dashboard.</Link>
        </h1>
        <img
          alt="graham"
          src={graham}
          style={{ width: 500, position: 'fixed', bottom: 0 }}
          className="animated slideInUp"
        />
      </div>
    );
  }
  showError() {
    return (
      <div>
        <h1>
          Your email has already been verified. Click here to go to your
          <Link to="/profile"> profile page</Link>
        </h1>
      </div>
    );
  }

  render() {
    return (
      <Grid className="content">
        <Row>
          <Col xs={12}>
            {this.props.location.pathname === '/verifyerror'
              ? this.showError()
              : this.showSuccess()}
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(Verification);
