/*
* Teacher viewing the class progress
*/

import * as React from "react";

import AttemptsExpander, { expanderToggle } from "./AttemptsExpander";
import {
  Button,
  Col,
  ControlLabel,
  FormGroup,
  Grid,
  Row
} from "react-bootstrap";
import {
  FooterCell,
  convertToOptions,
  printElement,
  scoreCell
} from "./StudentReportUtil";
import {
  GFDateRange,
  GFInitialState,
  GFQuizItem,
  GFStudent,
  GFStudentWithProgress,
  GFUser,
  Option
} from "../../models/models";
import ReactTable, { RowInfo } from "react-table";
import { RouteComponentProps, browserHistory } from "react-router";
import { find, isEmpty, orderBy } from "lodash";
import { getAllQuizzes, resetQuizStarts } from "../../actions/quizActions";
import {
  getStudentQuizResult,
  getVisibleProgressClassLesson,
  resetClassLessonProgress,
  setEndDateClassLesson,
  setStartDateClassLesson
} from "../../actions/progressActions";

import DateRangePicker from "../common/DateRangePicker";
import MainMenu from "../menu/MainMenu";
import { QuizAnswersModalContainer } from "./QuizAnswersModalContainer";
import Select from "react-select";
import { connect } from "react-redux";
import { loadClasses } from "../../actions/classActions";
import { loadStudents } from "../../actions/studentActions";
import { selectIsTeacher, selectIsSuperTeacher, selectHasGoogleClasses } from "../../reducers/userReducer";
import { selectClassOptions } from "../../reducers/classReducer";

// const fileDownload = require("react-file-download");
const mixpanel = require("mixpanel-browser");
const graham = require("../../images/graham.gif");

/*
* assign the correct color to the table cell
*/
const handleTdProps = (
  state: any,
  rowInfo: any,
  column: any,
  instance: any
) => {
  const quizResult = rowInfo.row[column.id];
  if (
    quizResult &&
    quizResult.length &&
    !state.expanded[rowInfo.row._index] &&
    quizResult[0].className
  ) {
    return {
      className: `${quizResult[0].className}`
    };
  } else {
    return {
      className: "no-score"
    };
  }
};
/*
* assign the correct color to the footer cell
* TODO this is not providing the rowInfor or column so it is impossible to assign the correct color
* opened an issue:  https://github.com/react-tools/react-table/issues/1120
*  getTfootTdProps={handleTfootProps}
*/
// const handleTfootProps = (state, rowInfo, column, instance) => {
//   // console.log(state, rowInfo, column, instance)
//   return {
//     className: 'red'
//   }
//   // const quizResult = rowInfo.row[column.id]
//   // if (
//   //   quizResult &&
//   //   quizResult.length &&
//   //   !state.expanded[rowInfo.row._index] &&
//   //   quizResult[0].className
//   // ) {
//   //   return {
//   //     className: `${quizResult[0].className}`
//   //   }
//   // } else {
//   //   return {
//   //     className: 'no-score'
//   //   }
//   // }
// }

const sortQuizzes = (a: any[], b: any[], desc: boolean) => {
  a = a[0].score;
  b = b[0].score;

  // Return either 1 or -1 to indicate a sort priority
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }
  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0;
};

// TODO sort by last name then first name. Currently this sort is implimented in progressActions processQuizResultsForClass().
// but we will need to impliment it here if we ever want the teacher to be able to change the sorting.
/*defaultSorted={[{id: 'name', desc: true}]}*/

// const sortStudents = (a, b, desc) => {
//   console.log(a.split(" "), b.split(" "))
//   // a = a[0].score;
//   // b = b[0].score;

//   // // Return either 1 or -1 to indicate a sort priority
//   if (a < b) {
//     return 1;
//   }
//   // if (a > b) {
//   //   return -1;
//   // }
//   // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
//   return 0;
// };
const columns = [
  {
    Header: "Student Name",
    id: "name",
    accessor: (lesson: any) => `${lesson.student.first} ${lesson.student.last}`,
    minWidth: 200,
    // sortMethod: sortStudents,
    Footer: <strong>Averages</strong>
  },
  {
    Header: "Pre-Test",
    id: "quiz01",
    accessor: (lesson: any) => lesson.quiz01Results,
    Cell: scoreCell,
    sortMethod: sortQuizzes,
    minWidth: 100,
    Footer: FooterCell
  },
  {
    Header: "PE 1",
    id: "quiz02",
    accessor: (lesson: any) => lesson.quiz02Results,
    Cell: scoreCell,
    sortMethod: sortQuizzes,
    minWidth: 100,
    Footer: FooterCell
  },
  {
    Header: "PE 2",
    id: "quiz03",
    accessor: (lesson: any) => lesson.quiz03Results,
    Cell: scoreCell,
    sortMethod: sortQuizzes,
    minWidth: 100,
    Footer: FooterCell
  },
  {
    Header: "PE 3",
    id: "quiz04",
    accessor: (lesson: any) => lesson.quiz04Results,
    Cell: scoreCell,
    sortMethod: sortQuizzes,
    minWidth: 100,
    Footer: FooterCell
  },
  {
    Header: "Post-Evaluation",
    id: "quiz05",
    accessor: (lesson: any) => lesson.quiz05Results,
    Cell: scoreCell,
    sortMethod: sortQuizzes,
    minWidth: 140,
    Footer: FooterCell
  },
  {
    expander: true,
    Expander: expanderToggle,
    style: {
      cursor: "pointer",
      textAlign: "center",
      userSelect: "none"
    }
  }
];

interface MatchParams {
  classID: string;
  lessonID: string;
}
interface Props extends RouteComponentProps<MatchParams, {}> {
  user: GFUser;
  data: GFStudentWithProgress[];
  students: GFStudent[];
  classOptions: Option[];
  lessonOptions: Option[];
  loading: boolean;
  loadStudents: () => Promise<any>;
  loadClasses: () => Promise<any>;
  getVisibleProgressClassLesson: typeof getVisibleProgressClassLesson;
  resetClassLessonProgress: typeof resetClassLessonProgress;
  startDate: Date;
  endDate: Date;
  setStartDateClassLesson: typeof setStartDateClassLesson;
  setEndDateClassLesson: typeof setEndDateClassLesson;
  getStudentQuizResult: any;
  getAllQuizzes: () => Promise<void>;
  quizzes: { [key: string]: GFQuizItem };
  resetQuizStarts: typeof resetQuizStarts;
  hasGoogleClasses: boolean;
  isTeacher: boolean;
  isSuperTeacher: boolean;
}

interface State {
  selectedRow: any;
  selectedClass: Option | null;
  selectedLesson: Option | null;
}

class ClassProgressReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedClass: null,
      selectedLesson: null,
      selectedRow: {}
    };
  }
  componentDidMount() {
    mixpanel.track("Viewing Class Progress Report");
    this.checkDependencies();
    // when a quiz is reset, refresh the report
    document.addEventListener("quizStartsReset", this.handleQuizStartReset);
  }
  componentDidUpdate(prevProps: Props) {

    if (prevProps.startDate.getTime() != this.props.startDate.getTime() || prevProps.endDate.getTime() != this.props.endDate.getTime()) {
      this.updateReport(true);
    }
  }
  componentWillUnmount() {
    document.removeEventListener("quizStartsReset", this.handleQuizStartReset);
  }
  handleQuizStartReset = () => {
    this.updateReport(true);
  };

  /*
  * check for dependencies. once you get one, try again by recursively calling self
  */
  checkDependencies = () => {
    let dependencyPromises: Array<Promise<any>> = [];

    if (isEmpty(this.props.quizzes)) {
      dependencyPromises = [...dependencyPromises, this.props.getAllQuizzes()];
    }
    if (this.props.students.length === 0) {
      dependencyPromises = [
        ...dependencyPromises,
        this.props.loadStudents()
      ];
    }
    if (this.props.classOptions.length === 0) {
      dependencyPromises = [
        ...dependencyPromises,
        this.props.loadClasses()
      ];
    }
    if (dependencyPromises.length) {
      Promise.all(dependencyPromises).then(() => {
        this.updateSelects();
      }).catch(error => {
        console.error('failed to get dependencies', error)
      });
    } else {
      this.updateSelects();
    }
  };
  updateSelects = () => {
    const { classID, lessonID } = this.props.params;
    if (
      this.props.classOptions &&
      this.props.classOptions.length &&
      this.props.lessonOptions &&
      this.props.lessonOptions.length
    ) {
      const selectedClass =
        find(this.props.classOptions, (obj: any) => {
          return obj.value === classID;
        }) || this.props.classOptions[0];
      const selectedLesson =
        find(this.props.lessonOptions, (obj: any) => {
          return obj.value === lessonID;
        }) || this.props.lessonOptions[0];
      this.setState({ selectedClass, selectedLesson }, () => {
        this.updateReport(true);
      });
    } else {
      console.log("skipping update selects");
    }
  };
  updateReport = (forceRefresh: boolean = false) => {
    this.setState({ selectedRow: {} });
    // replace current path so we know which course is selected
    const { selectedClass, selectedLesson } = this.state;
    if (selectedClass && selectedLesson) {
      const path = `/classprogress/${selectedClass.value}/${
        selectedLesson.value
      }`;
      browserHistory.replace(path);
      this.props.getVisibleProgressClassLesson(
        selectedClass.value,
        selectedLesson.value,
        forceRefresh
      );
    }
  };
  getTrProps = (state: any, rowInfo: any) => {
    if (rowInfo) {
      return {
        onClick: (e: React.MouseEvent<HTMLFormElement>) => {
          this.setState({
            selectedRow: {
              [rowInfo.viewIndex]: !this.state.selectedRow[rowInfo.viewIndex]
            }
          });
        },
        style: {
          background: this.state.selectedRow[rowInfo.viewIndex] ? `#fdf5c4` : ""
        },
        title: "display attempts"
      };
    } else {
      return {};
    }
  };
  // need to adjust Option type so changing to any for now
  handleClassChange = (selectedClass: any) => {
    this.setState({ selectedClass }, () => {
      this.updateReport(true);
    });
  };
  // need to adjust Option type so changing to any for now
  handleLessonChange = (selectedLesson: any) => {
    this.setState({ selectedLesson }, () => {
      this.updateReport();
    });
  };

  getCSV = () => {
    // const firstName = this.state.student.student.first;
    // const lastName = this.state.student.student.last;
    // const fullName = `${firstName} ${lastName}`;
    // mixpanel.track("Getting Students CSV", { studentName: fullName });
    // // this.props.manualAjaxStart();
    // ClassAPI.getResultsCSVForStudent(
    //   this.state.student.student.id || "",
    //   this.props.user
    // ).then(csvData => {
    //   // this.props.manualAjaxEnd();
    //   fileDownload(
    //     csvData,
    //     `${this.state.student.student.first}_${
    //       this.state.student.student.last
    //     }.csv`
    //   );
    // });
  };

  doPrint = () => {
    const classForPrint =
      this.state.selectedClass === null ? "" : this.state.selectedClass.label;
    const lessonForPrint =
      this.state.selectedLesson === null ? "" : this.state.selectedLesson.label;
    mixpanel.track("Printing Class Progress Report", {
      className: classForPrint,
      lessonName: lessonForPrint
    });
    this.setState({ selectedRow: {} }, () => {
      printElement(document.getElementsByClassName("students")[0], false, null);
      window.print();
    });
  };

  render() {
    const classForPrint =
      this.state.selectedClass === null ? "" : this.state.selectedClass.label;
    const lessonForPrint =
      this.state.selectedLesson === null ? "" : this.state.selectedLesson.label;
    return (
      <Grid className="content modal-container">
        <div className="sidemenu">
          <MainMenu isTeacher={this.props.isTeacher} isSuperTeacher={this.props.isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
          <div className="logo text-center">
            <img alt="graham" src={graham} />
          </div>
        </div>
        <div className="students main-content content-with-sidebar">
          <Row className="students-header">
            <Col md={10} xs={10} className="text-xl-left">
              <h1 className="pageDescription">
                Class Progress Report
                <Button
                  onClick={this.doPrint}
                  bsStyle="default"
                  type="button"
                  bsSize="sm"
                >
                  Print
                </Button>
                {/*                <Button bsStyle="default" type="button" bsSize="sm">
                  CSV
                </Button>*/}
                <Button
                  onClick={() => this.updateReport(true)}
                  bsStyle="default"
                  type="button"
                  bsSize="sm"
                >
                  Refresh
                </Button>
              </h1>
            </Col>
          </Row>
          <Row className="class-form">
            <Col xs={4} style={{ paddingTop: "15px" }}>
              <DateRangePicker
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                handleChangeStart={this.props.setStartDateClassLesson}
                handleChangeEnd={this.props.setEndDateClassLesson}
              />
            </Col>

            <Col xs={8}>
              <FormGroup bsSize="lg" className="class-lesson-selects">
                <Row>
                  <Col xs={2}>
                    <ControlLabel>Class</ControlLabel>
                  </Col>
                  <Col xs={10} className="class-row">
                    <Select
                      className="react-select-container"
                      options={this.props.classOptions}
                      onChange={this.handleClassChange}
                      value={this.state.selectedClass}
                      placeholder="select a class"
                      classNamePrefix="react-select"
                      isSearchable={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <ControlLabel>Lesson</ControlLabel>
                  </Col>
                  <Col xs={10}>
                    <Select
                      className="react-select-container"
                      options={this.props.lessonOptions}
                      onChange={this.handleLessonChange}
                      value={this.state.selectedLesson}
                      placeholder="search..."
                      classNamePrefix="react-select"
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>
          <Row className="for-print">
            <Col xs={12}>Class: {classForPrint}</Col>
          </Row>
          <Row className="for-print">
            <Col xs={12}>Lesson: {lessonForPrint}</Col>
          </Row>
          <Row className="class-table">
            <Col xs={12}>
              <ReactTable
                className="teacher-class-report -highlight"
                data={this.props.data}
                columns={columns}
                showPagination={false}
                showPageSizeOptions={false}
                pageSize={this.props.data.length}
                key={this.props.data.length}
                loading={this.props.loading}
                SubComponent={(rowInfo: RowInfo) => (
                  <AttemptsExpander
                    {...rowInfo}
                    getAndShowStudentAnswers={this.props.getStudentQuizResult}
                    resetQuizStarts={this.props.resetQuizStarts}
                    studentID={rowInfo.original.userID}
                  />
                )}
                getTdProps={handleTdProps}
                getTrProps={this.getTrProps}
                expanded={this.state.selectedRow}
                noDataText="No progress found."
                resizable={false}
                sortable={false}
              />
            </Col>
          </Row>
        </div>
        <QuizAnswersModalContainer
          className="progress-reports-modal"
          bsSize="large"
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  const startDate = new Date(state.classProgress.dateRange.startDate);
  const endDate = new Date(state.classProgress.dateRange.endDate);
  const classOptions = selectClassOptions(state);

  return {
    user: state.user,
    data: state.classProgress.data,
    students: state.students,
    classOptions,
    lessonOptions: convertToOptions(Object.values(state.lessons)),
    loading: state.ajaxCallsInProgress > 0,
    startDate,
    endDate,
    quizzes: state.quizzes,
    hasGoogleClasses: selectHasGoogleClasses(state),
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state)
  };
};

export default connect(
  mapStateToProps,
  {
    getVisibleProgressClassLesson,
    loadStudents,
    loadClasses,
    resetClassLessonProgress,
    setStartDateClassLesson,
    setEndDateClassLesson,
    getStudentQuizResult,
    getAllQuizzes,
    resetQuizStarts
  }
)(ClassProgressReport);
