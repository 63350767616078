import * as React from "react";

import {
  GFInitialState,
  GFSuperDiagnosticStudentResult,
  GFSuperDiagnosticTopicsById
} from "../../models/models";
import ReactTable, { RowInfo } from "react-table";

import { connect } from "react-redux";
import { ProgressBar } from "react-bootstrap";
import { getSuperDiagnosticStudentResult } from "../../actions/superDiagnosticActions";
import { RouteComponentProps } from "react-router";
import { QuizAnswersModalContainer } from "./QuizAnswersModalContainer";

interface rowInfoEdited extends RowInfo{
  original: GFSuperDiagnosticStudentResult;
}
/*
* handleTdProps
*/
const handleTdProps = (
  state: any,
  rowInfo: rowInfoEdited | undefined,
  column: any,
  instance: any
) => {
  return {};
};

/*
* sortMethod
* TODO figure out how to sort these
*/
const sortMethod = (a: any[], b: any[], desc: boolean) => {
  a = a[0].score;
  b = b[0].score;

  // Return either 1 or -1 to indicate a sort priority
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }
  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0;
};

const columns = [
  {
    Header: "Topic",
    id: "topic",
    accessor: 'topicName',
    minWidth: 100,

  },
  {
    Header: "Performance",
    id: "performance",
    accessor: ({score}: GFSuperDiagnosticStudentResult)=> { 
      if (score !== undefined) {
        return (
          <ProgressBar>
            <ProgressBar bsStyle="success" now={score} label={`${score}%`} />
            <ProgressBar bsStyle="danger" now={100 - score} />
          </ProgressBar>
        )
      } else {
        return (
          'N/A'
        )
      }
  },
    // Cell: percentBarCell
    sortMethod: sortMethod,
    minWidth: 200,
  }
];
interface MatchParams {
  studentID: string;
}

interface Props extends RouteComponentProps<MatchParams, {}> {
  data: GFSuperDiagnosticStudentResult[];
  loading: boolean;
  superDiagnosticTopics: GFSuperDiagnosticTopicsById;
}

interface State {
  selectedRow: any;
  topicID: string;
}

interface IDispatchProps {
    getSuperDiagnosticStudentResult: typeof getSuperDiagnosticStudentResult
}

class SuperDiagnosticReportStudent extends React.Component<Props & IDispatchProps, State> {
  constructor(props: Props & IDispatchProps) {
    super(props);
    this.state = {
      selectedRow: {},
      topicID: ''
    };
  }

  getTrProps = (state: any, rowInfo: rowInfoEdited | undefined) => {
    if (rowInfo) {
      return {
        onClick: (e: React.MouseEvent<HTMLFormElement>) => {
          this.setState({
            selectedRow: {
              [rowInfo.viewIndex]: !this.state.selectedRow[rowInfo.viewIndex],
            }
          });
          this.setState({ topicID: rowInfo.original.topicID }, ()=>{
            this.props.getSuperDiagnosticStudentResult(this.props.params.studentID, rowInfo.original.topicID)
          })
        },
        title: "view student answers"
      };
    } else {
      return {};
    }
  };

  render() {
    return (
    <>
      <ReactTable
        className="teacher-class-report -highlight"
        data={this.props.data}
        columns={columns}
        showPagination={false}
        showPageSizeOptions={false}
        pageSize={this.props.data.length}
        key={this.props.data.length}  
        loading={this.props.loading}
        getTdProps={handleTdProps}
        getTrProps={this.getTrProps}
        noDataText="No progress found."
        resizable={false}
        sortable={false}
      />
      <QuizAnswersModalContainer
        className="progress-reports-modal"
        bsSize="large"
        title={this.props.superDiagnosticTopics[this.state.topicID] ? this.props.superDiagnosticTopics[this.state.topicID].name : ''}
      />
    </>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  return {
    data: state.superDiagnosticReport.classStudentResults,
    loading: state.ajaxCallsInProgress > 0,
    superDiagnosticTopics: state.superDiagnosticTopics
  };
};

export default connect(
  mapStateToProps, {
    getSuperDiagnosticStudentResult
  }
)(SuperDiagnosticReportStudent);
