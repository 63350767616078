import * as React from 'react';
import ReactTable, {
  RowInfo,
  RowRenderProps,
  ColumnRenderProps,
  FinalState
} from 'react-table';
import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { GFStudentQuizResult } from '../../models/models';
import { expanderScoreCell } from './StudentReportUtil';
import { resetQuizStarts } from '../../actions/quizActions';
import { quizTypeEnum } from '../../enums';

const FontAwesome = require('react-fontawesome');

interface RowInfoQuizResult extends RowInfo {
  original: GFStudentQuizResult;
}

export const expanderToggle = (props: RowRenderProps) => {
  return (
    <div>
      {props.isExpanded ? (
        <span>
          <FontAwesome name="chevron-down" />
        </span>
      ) : (
        <span>
          <FontAwesome name="chevron-right" />
        </span>
      )}
    </div>
  );
};

/*
* figure out how many rows we need in the expanded section of previous attempts
*/
const calculateExpanderRows = (row: any) => {
  let numberOfRows = row.quiz01.length;
  if (row.quiz02.length > numberOfRows) {
    numberOfRows = row.quiz02.length;
  }
  if (row.quiz03.length > numberOfRows) {
    numberOfRows = row.quiz03.length;
  }
  if (row.quiz04.length > numberOfRows) {
    numberOfRows = row.quiz04.length;
  }
  if (row.quiz05.length > numberOfRows) {
    numberOfRows = row.quiz05.length;
  }
  return numberOfRows;
};

interface RowInfoEdited extends RowInfo {
  getAndShowStudentAnswers: (
    id: string,
    columnID?: string,
    lessonID?: string
  ) => void;
  resetQuizStarts?: typeof resetQuizStarts;
  studentID: string;
}

const expander = (props: RowInfoEdited) => {
  // skip the first result for each quiz
  // which quiz has the highest number of attempts, set the number of loops to that
  // loop over and create a row for each attempt
  // a for loop that loops as long as there is a quiz with attempts, it builds rows of attempts.
  const studentReportLink =
    props.original.class && props.studentID.length
      ? `${props.original.class.id}/${props.studentID}`
      : '';

  const ExpanderButtonBar = (eProps: ColumnRenderProps) => {
    const buttonBarStyles: React.CSSProperties = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      paddingRight: '20px'
    };
    const diagnosticResult = props.original['quiz01Results'][0];
    const postEvalResult = props.original['quiz05Results'][0];

    if (studentReportLink.length) {
      // teacher viewing the class report

      return (
        <span className=" text-right" style={buttonBarStyles}>
          <span className="expander-button-bar">
            <Button
              bsStyle="primary"
              bsSize="sm"
              onClick={() => {
                if (!!props.resetQuizStarts) {
                  if (diagnosticResult.isLocked === false){
                    console.error('teacher is reseting quiz starts on a diagnostic that is Not locked.', diagnosticResult)
                  }
                  props.resetQuizStarts(
                    diagnosticResult.quizID,
                    props.studentID,
                    quizTypeEnum.diagnostic
                  );
                }
              }}
            >
              reset pre-test
            </Button>
            <Button
              bsStyle="primary"
              bsSize="sm"
              onClick={() => {
                if (!!props.resetQuizStarts) {
                  if (postEvalResult.isLocked === false){
                    console.error('teacher is reseting quiz starts on a postEval that is Not locked.', postEvalResult)
                  }
                  props.resetQuizStarts(postEvalResult.quizID, props.studentID, quizTypeEnum.postEval);
                }
              }}
            >
              reset post-evaluation
            </Button>
            <LinkContainer to={`/studentprogress/${studentReportLink}`}>
              <Button
                bsStyle="primary"
                bsSize="sm"
                className={'pull-left'}
                style={{ marginLeft: '15px' }}
              >
                view student report
              </Button>
            </LinkContainer>
          </span>
          <span className="pull-right recent-row">Most recent: </span>
        </span>
      );
    } else if (props.original.isTeacher && studentReportLink.length === 0) {
      // teacher viewing a student's report
      return (
        <span className="text-right" style={buttonBarStyles}>
          <span className="expander-button-bar">
            <Button
              bsStyle="primary"
              bsSize="sm"
              onClick={() => {
                if (props.resetQuizStarts) {
                  if (diagnosticResult.isLocked === false){
                    console.error('teacher is reseting quiz starts on a diagnostic that is Not locked.', diagnosticResult)
                  }
                  const quizID = props.original['quiz01Results'][0].quizID;
                  props.resetQuizStarts(quizID, props.studentID, quizTypeEnum.diagnostic);
                }
              }}
            >
              reset pre-test
            </Button>
            <Button
              bsStyle="primary"
              bsSize="sm"
              onClick={() => {
                if (props.resetQuizStarts) {
                  if (postEvalResult.isLocked === false){
                    console.error('teacher is reseting quiz starts on a postEval that is Not locked.', postEvalResult)
                  }
                  const quizID = props.original['quiz05Results'][0].quizID;
                  props.resetQuizStarts(quizID, props.studentID, quizTypeEnum.postEval);
                }
              }}
            >
              reset post-evaluation
            </Button>
          </span>
          <span className="pull-right recent-row">Most recent: </span>
          {/* <span className="answer-tip-row"><small>Tip: Click a score to view answers.</small></span> */}
        </span>
      );
    } else {
      // student viewing their own report
      return (
        <span className="text-right">
          <span className="pull-right recent-row">Most recent: </span>
          {/* <span className="answer-tip-row">
            <small>
              Tip: When enabled, you can click a score and view your graded
              post-eval.
            </small>
          </span> */}
        </span>
      );
    }
  };

  const expanderColumns = [
    { Header: '', minWidth: 200, id: 'name-column', Footer: ExpanderButtonBar },
    {
      Header: 'Pre-Test',
      id: 'quiz01',
      accessor: (lesson: any) => lesson.quiz01,
      Cell: expanderScoreCell,
      minWidth: 100
    },
    {
      Header: 'PE 1',
      id: 'quiz02',
      accessor: (lesson: any) => lesson.quiz02,
      Cell: expanderScoreCell,
      minWidth: 100
    },
    {
      Header: 'PE 2',
      id: 'quiz03',
      accessor: (lesson: any) => lesson.quiz03,
      Cell: expanderScoreCell,
      minWidth: 100
    },
    {
      Header: 'PE 3',
      id: 'quiz04',
      accessor: (lesson: any) => lesson.quiz04,
      Cell: expanderScoreCell,
      minWidth: 100
    },
    {
      Header: 'Post-Evaluation',
      id: 'quiz05',
      accessor: (lesson: any) => lesson.quiz05,
      Cell: expanderScoreCell,
      minWidth: 140
    },
    { Header: '', minWidth: 25 }
  ];

  // how many rows / what is the highest number of attempts?
  const numberOfRows = calculateExpanderRows(props.row);
  let lessonQuizzesWithAttempts: any[] = [];

  // build an array of quiz attempts
  for (let i = 0; i < numberOfRows; ++i) {
    lessonQuizzesWithAttempts = [
      ...lessonQuizzesWithAttempts,
      {
        quiz01: props.row.quiz01[i] || { score: '--', isComplete: true },
        quiz02: props.row.quiz02[i] || { score: '--', isComplete: true },
        quiz03: props.row.quiz03[i] || { score: '--', isComplete: true },
        quiz04: props.row.quiz04[i] || { score: '--', isComplete: true },
        quiz05: props.row.quiz05[i] || { score: '--', isComplete: true }
      }
    ];
  }
  /*
* assign the correct color to the table cell for teachers and students
* assign the function to open the ModalQuizResultPreview for teachers only
*/
  const expanderHandleTdProps = (
    state: FinalState,
    rowInfo: RowInfoQuizResult | undefined,
    column: any,
    instance: any
  ) => {
    if (rowInfo === undefined) {
      return {};
    }
    if (column && rowInfo.row[column.id] && rowInfo.row[column.id].className) {
      return {
        className: `${rowInfo.row[column.id].className}`,
        onClick: (
          e: React.MouseEvent<HTMLFormElement>,
          handleOriginal: () => void
        ) => {
          props.getAndShowStudentAnswers(
            rowInfo.row[column.id].id,
            column.id,
            rowInfo.row[column.id].quiz.lessonID
          );
          if (handleOriginal) {
            handleOriginal();
          }
        }
      };
    } else {
      return {
        className: 'no-score'
      };
    }
  };
  const className =
    props.original.isTeacher || studentReportLink.length
      ? 'attempts-expander teacher'
      : 'attempts-expander';

  return (
    <div>
      <ReactTable
        className={className}
        data={lessonQuizzesWithAttempts}
        columns={expanderColumns}
        pageSize={numberOfRows}
        key={numberOfRows}
        showPagination={false}
        showPageSizeOptions={false}
        getTdProps={expanderHandleTdProps}
        noDataText="No prior attempts found."
      />
    </div>
  );
};

export default expander;
