import * as React from "react";

import {
  GFInitialState,
  GFStudent,
  Option,
  GFSuperDiagnosticClassResult
} from "../../models/models";
import ReactTable, { RowInfo } from "react-table";

import { connect } from "react-redux";
import { selectIsTeacher } from "../../reducers/userReducer";
import { ProgressBar } from "react-bootstrap";
import { getSuperDiagnosticReportTopic } from "../../actions/superDiagnosticActions";
import { RouteComponentProps, browserHistory } from "react-router";
import { orderBy } from "lodash";
import { selectClassOptions } from "../../reducers/classReducer";

interface rowInfoEdited extends RowInfo{
  original: GFSuperDiagnosticClassResult;
}

/*
* assign the correct color to the table cell
*/
const handleTdProps = (
  state: any,
  rowInfo: rowInfoEdited | undefined,
  column: any,
  instance: any
) => {
return {};
};

/*
* sortMethod
* TODO figure out how to sort these
*/
const sortMethod = (a: any[], b: any[], desc: boolean) => {
  a = a[0].score;
  b = b[0].score;

  // Return either 1 or -1 to indicate a sort priority
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }
  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0;
};

const columns = [
  {
    Header: "Topic",
    id: "topic",
    accessor: 'topicName',
    minWidth: 100,

  },
  {
    Header: "Class Performance",
    id: "passed",
    accessor: ({score, hasTaken}: GFSuperDiagnosticClassResult)=> { 
      if (hasTaken) {
        return (
          <ProgressBar>
            <ProgressBar bsStyle="success" now={score} label={`${score}%`} />
            <ProgressBar bsStyle="danger" now={100 - score}  />
          </ProgressBar>
        )
      } else {
        return (
          'N/A'
        )
      }
  },
    // Cell: percentBarCell
    sortMethod: sortMethod,
    minWidth: 200,
  }
];
interface MatchParams {
  classID: string;
  topicID: string;
}

interface Props extends RouteComponentProps<MatchParams, {}> {
  data: GFSuperDiagnosticClassResult[];
  students: GFStudent[];
  classOptions: Option[];
  loading: boolean;
  isTeacher: boolean;

}

interface State {
  selectedRow: any;
}

interface IDispatchProps {
  getSuperDiagnosticReportTopic: typeof getSuperDiagnosticReportTopic
}

class SuperDiagnosticReportTopic extends React.Component<Props & IDispatchProps, State> {
  constructor(props: Props & IDispatchProps) {
    super(props);
    this.state = {
      selectedRow: {0: true}
    };
  }

  /*
  * getSelectedRow
  */
  getSelectedRow = (topicID: string) => {
    if (!this.props.params.topicID && topicID === '00000000-0000-0000-0000-000000000000'){
      return true; // all topics
    } else if (this.props.params.topicID && this.props.params.topicID === topicID){
      return true;
    } else {
      return false;
    }

  }

  getTrProps = (state: any, rowInfo: rowInfoEdited | undefined) => {
    if (rowInfo) {
      return {
        onClick: (e: React.MouseEvent<HTMLFormElement>) => {
          if (rowInfo.original.topicID) {
            const path = `/super-diagnostic-report-class/${this.props.params.classID}/${rowInfo.original.topicID}`
            this.props.getSuperDiagnosticReportTopic(this.props.params.classID, rowInfo.original.topicID);
            browserHistory.replace(path)
          }
        },
        style: {
          background: this.getSelectedRow(rowInfo.original.topicID) ? `#fdf5c4` : ""
        },
        title: "select topic"
      };
    } else {
      return {};
    }
  };
  

  render() {
    return (
      <ReactTable
        className="teacher-class-report -highlight"
        data={this.props.data}
        columns={columns}
        showPagination={false}
        showPageSizeOptions={false}
        pageSize={this.props.data.length}
        key={this.props.data.length}  
        loading={this.props.loading}
        getTdProps={handleTdProps}
        getTrProps={this.getTrProps}
        noDataText="No progress found."
        resizable={false}
        sortable={false}
      />
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  const classOptions = selectClassOptions(state);

  return {
    data: state.superDiagnosticReport.classResults,
    students: state.students,
    classOptions,
    loading: state.ajaxCallsInProgress > 0,
    isTeacher: selectIsTeacher(state)
  };
};

export default connect(
  mapStateToProps, {
    getSuperDiagnosticReportTopic
  }
)(SuperDiagnosticReportTopic);
