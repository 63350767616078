import * as React from "react"; // tslint:disable-line
import { Row, Col, Alert } from "react-bootstrap";
import { Link } from "react-router";
import { GFUser } from "../../models/models";
import { socialServiceEnum } from "../../enums";

const graham = require("../../images/graham.gif");

// show the alert to teachers who have not verified their email
const VerificationAlert = ({ user, isTeacher }: { user: GFUser, isTeacher: boolean }) => {
  const isGoogleUser = user.hasOwnProperty('socialType') &&
      user.socialType === socialServiceEnum.google;
  if (user.isVerified || !isTeacher || isGoogleUser) {
    return <span />;
  } else {
    return (
      <Row className="verification-header" style={{ paddingTop: 10 }}>
        <Col xs={12}>
          <Alert bsStyle="warning" style={{ marginBottom: 0 }}>
            <img alt="graham" src={graham} style={{ height: 40, paddingRight: 30 }} />{" "}
            <strong>HOO! HOO!</strong> Please check your email to verify your
            account.{" "}
            <Link to="/profile">Click here to resend verification email.</Link>
          </Alert>
        </Col>
      </Row>
    );
  }
};

export default VerificationAlert;
