/*
*  Teacher Register Form
*/
import * as React from 'react';

import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Row
} from 'react-bootstrap';
import { validateField, validateForm } from '../common/UtilValidation';

import { GFUserSaveFormValues } from '../../models/models';
import constants from '../../constants';
import { toastr } from 'react-redux-toastr';
import { registerTypeEnum } from './Register';
import { userDecodeSocialCode } from '../../actions/userActions';
import { isEmpty } from 'lodash';
import { socialServiceEnum } from '../../enums';
import { browserHistory } from 'react-router';
const FontAwesome = require("react-fontawesome");

interface Props extends React.Props<TeacherRegisterForm> {
  handleSubmit: (formValues: GFUserSaveFormValues) => void;
  manualAjaxEnd: any;
  manualAjaxStart: any;
  setSocialObj: any;
  goBack: any;
  loading: boolean;
  socialObj: any;
  googleCode?: string;
  userDecodeSocialCode: typeof userDecodeSocialCode;
  isDirectCheckout?: boolean;
}

interface State {
  gender: string;
  first: string;
  last: string;
  email: string;
  id: string;
  username: string;
  classCode: string;
  password: string;
  password2: string;
  role: string;
  roleID: string;
  howDidYouHearAboutUs: string;
  hearOther: string;
  under13: any;
  formValidations: any;
  formValid: boolean;
  social: string;
  newGoogle: boolean;
  utm_source : string;
  utm_medium : string;
  utm_campaign : string;
  utm_content : string;
  utm_term : string;
  gclid : string;
  isDirectCheckout?: boolean;
}

class TeacherRegisterForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      gender: 'O',
      first: '',
      last: '',
      email: '',
      id: '',
      username: '',
      classCode: '',
      password: '',
      password2: '',
      role: '',
      roleID: constants.UserRoleIDs.Teacher,
      under13: false,
      howDidYouHearAboutUs: '',
      hearOther: '',
      social: '',
      newGoogle: false,
      utm_source : '',
      utm_medium : '',
      utm_campaign : '',
      utm_content : '',
      utm_term : '',
      gclid : '',
      // TODO validate email for teacher only:
      formValidations: {
        first: {
          validationState: null,
          validationMessage: '',
          valid: true,
          validators: [{ required: true, message: 'First Name is required' }]
        },
        last: {
          validationState: null,
          validationMessage: '',
          valid: true,
          validators: [{ required: true, message: 'Last Name is required' }]
        },
        email: {
          validationState: null,
          validationMessage: '',
          valid: true,
          validators: [
            { required: true, message: 'Email or Username is required' },
            {
              pattern: /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i,
              message: 'Must be valid email address'
            }
          ]
        },
        password: {
          validationState: null,
          validationMessage: '',
          valid: true,
          validators: [
            { required: true, message: 'Password is required' },
            {
              pattern: constants.passwordRegex,
              message: constants.passwordValidationMessage
            }
          ]
        },
        password2: {
          validationState: null,
          validationMessage: '',
          valid: true,
          validators: [
            { required: true, message: 'Confirm Password is required' },
            {
              pattern: constants.passwordRegex,
              message: constants.passwordValidationMessage
            },
            { mustMatch: 'password', message: 'passwords have to match' }
          ]
        },
        howDidYouHearAboutUs: {
          validationState: null,
          validationMessage: '',
          valid: true,
          validators: [{ required: true, message: 'How did you hear about us is required' }]
        },
        // hearOther will be required if howDidYouHearAboutUs is 'Other'
        hearOther: {
          validationState: null,
          validationMessage: '',
          valid: true,
          validators: [{ required: false, message: 'How did you hear about us is required' }]
        },
      },
      formValid: false
    };
  }
  componentDidMount() {
    // validate all the form fields but do not show errors
    this.validateAllFields(false);
    if (!!this.props.googleCode) {
      this.props.userDecodeSocialCode(
        this.props.googleCode,
        constants.buildGoogleLoginLink(`/register?type=${registerTypeEnum.teacher}`),
        registerTypeEnum.teacher
      );
    }
  }
  componentDidUpdate(prevProps: Props){
    if (prevProps.socialObj !== this.props.socialObj){
      this.setParams('google')
    }
  }

  // validate all fields and conditionally show errors
  validateAllFields = (showErrors: boolean) => {
    validateField(
      'email',
      this.state.email,
      showErrors,
      this.state.formValidations
    );
    validateField(
      'password',
      this.state.password,
      showErrors,
      this.state.formValidations
    );
    validateField(
      'password2',
      this.state.password2,
      showErrors,
      this.state.formValidations,
      this.state.password
    );
    validateField(
      'first',
      this.state.first,
      showErrors,
      this.state.formValidations
    );
    validateField(
      'last',
      this.state.last,
      showErrors,
      this.state.formValidations
    );
    validateField(
      'howDidYouHearAboutUs',
      this.state.howDidYouHearAboutUs,
      showErrors,
      this.state.formValidations
    );
    validateField(
      'hearOther',
      this.state.hearOther,
      showErrors,
      this.state.formValidations
    );
  }

  handleChange = (e: React.FormEvent<FormControl & HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value =
      e.currentTarget.type === 'checkbox'
        ? e.currentTarget.checked
        : e.currentTarget.value;

    const validations = { ...this.state.formValidations };

    // If howDidYouHearAboutUs is "Other", make hearOther field required.
    if (name === 'howDidYouHearAboutUs' && value === 'Other') {
      validations.hearOther.validators[0].required = true;
      validations.hearOther.valid = false;
      validations.hearOther.validationMessage = 'How did you hear about us is required';
      validations.hearOther.validationState = 'error';
    } else if (name === 'howDidYouHearAboutUs' && value !== 'Other') {
      validations.hearOther.validators[0].required = false;
      validations.hearOther.valid = true;
      validations.hearOther.validationMessage = '';
      validations.hearOther.validationState = 'success';
      this.setState({ hearOther: '' } as State);
    }

    const fv = validateField(
      name,
      value,
      true,
      validations,
      this.state.password
    );
    const vf = validateForm(validations);

    this.setState({
      [name]: value,
      formValidations: fv,
      formValid: vf
    } as State);
  };

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const {isDirectCheckout} = this.props;
    const formData = new FormData(e.currentTarget)
    e.preventDefault();
    // check if registering in with Google
    if (isEmpty(this.props.socialObj) === false) {
      // registering with google
      validateField(
        'first',
        this.state.first,
        true,
        this.state.formValidations
      );
      validateField('last', this.state.last, true, this.state.formValidations);
      this.forceUpdate();
      if (
        this.state.formValidations.first.valid !== true ||
        this.state.formValidations.last.valid !== true
      ) {
        toastr.error(
          'Error',
          `Please check your form entries and try again.`,
          constants.toastrError
        );

        this.forceUpdate(); // needed because if the user clicks submit whithout touching some fields, then the inlide validation does not show as expected

        return;
      }
    } else {
      if (!this.state.formValid) {
        toastr.error(
          'Error',
          `Please check your form entries and try again.`,
          constants.toastrError
        );
        // validate and show errors
        this.validateAllFields(true);
        this.forceUpdate(); // needed because if the user clicks submit whithout touching some fields, then the inlide validation does not show as expected
        return;
      }
    }
    if(isDirectCheckout){
      this.handleDirectCheckout(this.state);
      return;
    }
    this.props.handleSubmit(this.state);
  }

  handleDirectCheckout = (state: State) => {
    // We go to the /direct-payment route with the register form data.
    // The direct-payment route will handle the payment and registration.
    browserHistory.push({
      pathname: '/direct-payment',
      state: state,
    });
  }

  reqGoogleLogin = () => {
    this.props.manualAjaxStart();
    console.log('trying to register with google');
  }

  responseGoogle = (resp: any) => {
    this.props.manualAjaxEnd();
    if (!!resp.error) {
      console.error('Error with Google Authentication', resp);
      let message = 'Unable to register with Google.  Please try again or contact support.';
      if (resp.details){
        message = resp.details;
        
      }
      if (resp.error !== 'idpiframe_initialization_failed'){
        toastr.error('Error', message, constants.toastrError)
      }
    } else {
      console.log('successfully registered with Google');
      // fill in the form info using google profile
      this.props.setSocialObj(resp);
      this.setParams('google');
    }
  }

  setParams = (service: keyof typeof socialServiceEnum = socialServiceEnum.google) => {
    const newParams = {
      first: this.props.socialObj.first,
      last: this.props.socialObj.last,
      email: this.props.socialObj.email,
      id: this.props.socialObj.id,
      social: service,
      newGoogle: true,
    };
    this.setState(newParams);
  }
  render() {
    const submitButtonLabel = this.props.isDirectCheckout ? 'Buy Now!' : 'Start Your Free, 30-Day Trial!';
    return (
      <div className="teacher-register-form">
        <form id="tRegisterForm" onSubmit={this.handleSubmit}>
          {this.state.social === '' && (
            <span>
              {this.props.isDirectCheckout === false && (
                <React.Fragment>
                  <Row>
                    <Col md={12}>
                      <h3 className='text-center'><strong>We support Google Classroom!</strong></h3>
                      <h4 className='text-center'>If you'd like the option to sync your GrammarFlip account with Google Classroom,<br />be sure to use the "Register with Google" button! (<a href="https://edu.google.com/intl/en/products/classroom/" target="_blank" rel="noopener noreferrer">learn more</a>)</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className='social-button-row'>
                      <Button
                        bsStyle="default"
                        type="button"
                        href={constants.buildGoogleLoginLink(`/register?type=${registerTypeEnum.teacher}`)}
                        className="google-login initial-text"
                        disabled={this.props.loading}
                      >
                        <FontAwesome name="google" /> &nbsp; Register with Google
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}><hr className='hr' /></Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <h3 className='text-center m-0'>Or create your GrammarFlip account with an email address and password below!</h3>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
              <Row>
                <Col md={6}>
                  <FormGroup
                    controlId="first"
                    bsSize="lg"
                    validationState={
                      this.state.formValidations.first.validationState
                    }
                  >
                    <ControlLabel>First Name</ControlLabel>
                    <FormControl
                      type="text"
                      id="first"
                      name="first"
                      placeholder="First Name"
                      value={this.state.first}
                      onChange={this.handleChange}
                    />
                    <FormControl.Feedback />
                    <HelpBlock>
                      {this.state.formValidations.first.validationMessage}
                    </HelpBlock>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    controlId="last"
                    bsSize="lg"
                    validationState={
                      this.state.formValidations.last.validationState
                    }
                  >
                    <ControlLabel>Last Name</ControlLabel>
                    <FormControl
                      type="text"
                      id="last"
                      name="last"
                      placeholder="Last Name"
                      value={this.state.last}
                      onChange={this.handleChange}
                    />
                    <FormControl.Feedback />
                    <HelpBlock>
                      {this.state.formValidations.last.validationMessage}
                    </HelpBlock>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup
                    controlId="email"
                    bsSize="lg"
                    validationState={
                      this.state.formValidations.email.validationState
                    }
                  >
                    <ControlLabel>Email</ControlLabel>
                    <FormControl
                      id="email"
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                    <FormControl.Feedback />
                    <HelpBlock>
                      {this.state.formValidations.email.validationMessage}
                    </HelpBlock>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup
                    controlId="password"
                    bsSize="lg"
                    validationState={
                      this.state.formValidations.password.validationState
                    }
                  >
                    <ControlLabel>Password</ControlLabel>
                    <FormControl
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                    <FormControl.Feedback />
                    <HelpBlock>
                      {this.state.formValidations.password.validationMessage}
                    </HelpBlock>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    controlId="confirmPassword"
                    bsSize="lg"
                    validationState={
                      this.state.formValidations.password2.validationState
                    }
                  >
                    <ControlLabel>Confirm Password</ControlLabel>
                    <FormControl
                      type="password"
                      name="password2"
                      placeholder="Confirm Password"
                      value={this.state.password2}
                      onChange={this.handleChange}
                    />
                    <FormControl.Feedback />
                    <HelpBlock>
                      {this.state.formValidations.password2.validationMessage}
                    </HelpBlock>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="heard-about-select">
                  <FormGroup
                      controlId="howDidYouHearAboutUs"
                      bsSize="lg"
                      validationState={
                        this.state.formValidations.howDidYouHearAboutUs.validationState
                      }
                    >
                    <ControlLabel>How did you hear about us?</ControlLabel>
                    <FormControl
                      componentClass="select"
                      name="howDidYouHearAboutUs"
                      value={this.state.howDidYouHearAboutUs}
                      onChange={this.handleChange}
                    >
                      <option value="">--Please Select--</option>
                      <option value="Email">Email</option>
                      <option value="Social media">Social media</option>
                      <option value="Internet search">Internet search</option>
                      <option value="Referral from colleague/friend">Referral from colleague/friend</option>
                      <option value="School purchased">School purchased</option>
                      <option value="Conference">Conference</option>
                      <option value="Online review">Online review</option>
                      <option value="Other">Other (fill in blank)</option>
                    </FormControl>
                    <FormControl.Feedback className="select-feedback" />
                    <HelpBlock>
                      {this.state.formValidations.howDidYouHearAboutUs.validationMessage}
                    </HelpBlock>
                  </FormGroup>                  
                </Col>
              </Row>
              {this.state.howDidYouHearAboutUs === 'Other' && (
                <Row>
                  <Col md={12}>
                    <FormGroup
                      controlId="hearOther"
                      bsSize="lg"
                      validationState={
                        this.state.formValidations.hearOther.validationState
                      }
                    >
                      <FormControl
                        type="text"
                        id="hearOther"
                        name="hearOther"
                        placeholder="How did you hear about us?"
                        value={this.state.hearOther}
                        onChange={this.handleChange}
                      />
                      <FormControl.Feedback />
                      <HelpBlock>
                        {this.state.formValidations.hearOther.validationMessage}
                      </HelpBlock>
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </span>
          )}
          {this.state.social !== '' && (
            <div>
              <Row>
                <Col md={6}>
                  <FormGroup
                    controlId="first"
                    bsSize="lg"
                    validationState={
                      this.state.formValidations.first.validationState
                    }
                  >
                    <ControlLabel>First Name</ControlLabel>
                    <FormControl
                      type="text"
                      id="first"
                      name="first"
                      placeholder="First Name"
                      value={this.state.first}
                      onChange={this.handleChange}
                    />
                    <FormControl.Feedback />
                    <HelpBlock>
                      {this.state.formValidations.first.validationMessage}
                    </HelpBlock>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup
                    controlId="last"
                    bsSize="lg"
                    validationState={
                      this.state.formValidations.last.validationState
                    }
                  >
                    <ControlLabel>Last Name</ControlLabel>
                    <FormControl
                      type="text"
                      id="last"
                      name="last"
                      placeholder="Last Name"
                      value={this.state.last}
                      onChange={this.handleChange}
                    />
                    <FormControl.Feedback />
                    <HelpBlock>
                      {this.state.formValidations.last.validationMessage}
                    </HelpBlock>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="heard-about-select">
                  <FormGroup
                      controlId="howDidYouHearAboutUs"
                      bsSize="lg"
                      validationState={
                        this.state.formValidations.howDidYouHearAboutUs.validationState
                      }>
                    <ControlLabel>How did you hear about us?</ControlLabel>
                    <FormControl
                      componentClass="select"
                      name="howDidYouHearAboutUs"
                      value={this.state.howDidYouHearAboutUs}
                      onChange={this.handleChange}
                    >
                      <option value="">--Please Select--</option>
                      <option value="Email">Email</option>
                      <option value="Social media">Social media</option>
                      <option value="Internet search">Internet search</option>
                      <option value="Referral from colleague/friend">Referral from colleague/friend</option>
                      <option value="School purchased">School purchased</option>
                      <option value="Conference">Conference</option>
                      <option value="Online review">Online review</option>
                      <option value="Other">Other (fill in blank)</option>
                    </FormControl>
                    <FormControl.Feedback className="select-feedback" />
                    <HelpBlock>
                      {this.state.formValidations.howDidYouHearAboutUs.validationMessage}
                    </HelpBlock>
                  </FormGroup>                  
                </Col>
              </Row>
              {this.state.howDidYouHearAboutUs === 'Other' && (
                <Row>
                  <Col md={12}>
                    <FormGroup
                      controlId="hearOther"
                      bsSize="lg"
                      validationState={
                        this.state.formValidations.hearOther.validationState
                      }
                    >
                      <FormControl
                        type="text"
                        id="hearOther"
                        name="hearOther"
                        placeholder="How did you hear about us?"
                        value={this.state.hearOther}
                        onChange={this.handleChange}
                      />
                      <FormControl.Feedback />
                      <HelpBlock>
                        {this.state.formValidations.hearOther.validationMessage}
                      </HelpBlock>
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </div>
          )}
          <Row>
            <Col md={12}>
              <FormGroup>
                <p className="register-terms text-center">
                  <small>
                    By clicking <b>{submitButtonLabel}</b> below you agree to the
                    GrammarFlip{' '}
                    <a
                      href="https://www.grammarflip.com/privacy-policy-terms-use/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy and Terms of Use
                    </a>
                    .
                  </small>
                </p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormGroup>
                <Button bsStyle="default" onClick={this.props.goBack} disabled={this.props.loading}>
                  back to login
                </Button>
                <Button
                  disabled={this.props.loading}
                  id="btnRegister"
                  bsStyle="primary"
                  className="pull-right"
                  type="submit"
                >
                  {submitButtonLabel}
                </Button>
              </FormGroup>
              <br />
              <br />
              <br />
            </Col>
          </Row>
          <span className="hidden-fields-for-saltbox">
          <FormControl type="text" name="utm_source" onChange={this.handleChange} />
<FormControl type="text" name="utm_medium"  onChange={this.handleChange}/>
<FormControl type="text" name="utm_campaign" onChange={this.handleChange} />
<FormControl type="text" name="utm_content"  onChange={this.handleChange}/>
<FormControl type="text" name="utm_term" onChange={this.handleChange} />
<FormControl type="text" name="gclid" onChange={this.handleChange} />
          </span>
        </form>
      </div>
    );
  }
}

export default TeacherRegisterForm;
