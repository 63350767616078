export enum quizTypeEnum {
    diagnostic = 1,
    practice,
    postEval
  }

export enum topicHeaderEnum {
  mechanicsAndUsage = 'Mechanics and Usage',
  partsOfSpeech = 'Parts of Speech',
  partsOfSentence = 'Parts of the Sentence'
}

export enum socialServiceEnum {
  google = 'google'
}