/*
* Contains all the components for displaying and changing payment plans
List of possible payment plans are in the FAQ.md
*/

import * as React from "react";
import constants from "../../constants";
import { GFUser, GFPlan, GFInitialState } from "../../models/models";
import { connect } from "react-redux";
import {
  manualAjaxEnd,
  manualAjaxStart,
} from "../../actions/ajaxStatusActions";
import VerificationAlert from "../common/VerificationAlert";
import { Button } from "react-bootstrap";
import { browserHistory } from "react-router";
import {
  couponCheck,
  updateCard,
} from "../../actions/paymentActions";
import {
  Grid,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import UpdateCardSection from "../payment/UpdateCardSection";
import { ManageAccountButtons } from "../payment/ManageAccountButtons";
import { selectIsTeacher, selectIsSuperTeacher, selectHasGoogleClasses } from "../../reducers/userReducer";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import DirectCheckoutModal from "./DirectCheckoutModal";
import { find } from "lodash";

const graham = require("../../images/graham.gif");
const masterGraham = require("../../images/diploma.png");
interface Props extends React.Props<DirectCheckout> {
  user: GFUser;
  loading: boolean;
  updateCard: (paymentToken: string) => Promise<void>;
  manualAjaxEnd: any;
  manualAjaxStart: any;
  hasGoogleClasses: boolean;
  isTeacher: boolean;
  isSuperTeacher: boolean;
  stripe: Stripe;
  elements: StripeElements;
  location: any;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
  utm_term: string;
  gclid: string;
}

interface State {
  planRecurringType: string;
  StripeKey: string;
  newPaymentPlan: string;
  paymentPlanStudents: string;
  showPaymentModal: boolean;
  masterButton: string;
  showCreditCardModal: boolean;
  selectedPlan: GFPlan;
}

class DirectCheckout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      planRecurringType: "Annual",
      StripeKey: `${process.env.REACT_APP_STRIPE}`,
      newPaymentPlan: "",
      paymentPlanStudents: "50", // 50, 100, ''
      showPaymentModal: false,
      masterButton: "",
      showCreditCardModal: false,
      selectedPlan: constants.paymentPlanObjectsByID.masterAnnual50,
    };
  }
  componentDidMount(){
    if(this.props.user && this.props.user.paymentPlan){
      this.updateSelectedPaymentPlan(this.props.user.paymentPlan)
    }
    // If we don't have a user here, we go back to /register
    if(!this.props.user){
      browserHistory.push("/register")
    }
  }

  openUpdateCreditCard = () => {
    this.setState({ showCreditCardModal: true });
  };

  closeUpdateCreditCard = () => {
    this.setState({ showCreditCardModal: false });
  };

  closePaymentModal = () => {
    this.setState({ showPaymentModal: false });
  };

  openPaymentModal = () => {
      this.setState({ showPaymentModal: true });
  };
  handleChange = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    const targetName = e.currentTarget.name;
    this.setState({ [targetName]: e.currentTarget.value } as unknown as State, () => {
      this.updateSelectedPaymentPlan();
    });
  };

  togglePlanRecurringType = () => {
    this.setState(
      { planRecurringType: this.state.planRecurringType === "Annual" ? "Monthly" : "Annual" },
      () => {
        this.updateSelectedPaymentPlan();
      }
    );
  };

  /*
   * Update Credit Card Modal
   */
  updateCreditCardModal = () => {
    return (
      <Modal
        show={this.state.showCreditCardModal}
        onHide={this.closeUpdateCreditCard}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Update Credit Card</Modal.Title>
        </Modal.Header>
        <UpdateCardSection
          user={this.props.user}
          loading={this.props.loading}
          show={this.state.showCreditCardModal}
          hide={this.closeUpdateCreditCard}
          updateCard={this.props.updateCard}
          manualAjaxStart={this.props.manualAjaxStart}
          manualAjaxEnd={this.props.manualAjaxEnd}
          stripe={this.props.stripe}
          elements={this.props.elements}
        />
      </Modal>
    );
  };

  /*
   * updateSelectedPaymentPlan
   * figure out the selectedPayment plan and set it to state
   */
  updateSelectedPaymentPlan = (stripePaymentPlanString?: string) => {
    const masterPlanPriceTitle = `master${this.state.planRecurringType}${this.state.paymentPlanStudents}_2`;
    let selectedPlan = constants.paymentPlanObjectsByID[masterPlanPriceTitle] || constants.paymentPlanObjectsByID.masterMonthly50;

    if (stripePaymentPlanString && stripePaymentPlanString !== 'trial' && stripePaymentPlanString !== 'expired' && stripePaymentPlanString !== 'cancelled'){
      const paymentPlan = find(constants.paymentPlanObjectsByID, {id: stripePaymentPlanString});
      if (paymentPlan){
        selectedPlan = paymentPlan;
      }
    }
    // console.log(
    //   "updating selected payment plan",
    //   selectedPlan,
    //   masterPlanPriceTitle,
    //   stripePaymentPlanString
    // );
    this.setState({ selectedPlan, planRecurringType: selectedPlan.time, paymentPlanStudents: `${selectedPlan.size}` });
  };

  isHomeschoolMonthly = () => {
    return (
      this.state.paymentPlanStudents === "5" &&
      this.state.planRecurringType === "Monthly"
    );
  };

  render() {
    const { isTeacher, user } = this.props;
    let amountOfStudents = this.state.selectedPlan.size;
    if (this.state.paymentPlanStudents === "") {
      amountOfStudents = 150;
    }

    const masterPlanPrice = this.state.selectedPlan.price / 100;
    const masterPlanPriceString = masterPlanPrice.toFixed(2);

    if(!user){
      return null;
    }

    return (
      <div>
        <Grid className="content modal-container">
          <div className="main-content direct-payment">
            <VerificationAlert user={this.props.user} isTeacher={isTeacher} />
            <Row>
              <Col md={12} xs={12}>
                <div className="payment-header">
                  <h1>Payment and Plans</h1>
                </div>
              </Col>
            </Row>
            <Row className="display-payment-options">
              {/*
               * Display Payment Modal
               */}
              <DirectCheckoutModal
                show={this.state.showPaymentModal}
                stripe={this.props.stripe}
                selectedPlan={this.state.selectedPlan}
                hide={this.closePaymentModal}
                elements={this.props.elements}
                togglePlanRecurringType={this.togglePlanRecurringType}
                user={this.props.user}
                utm_source={this.props.utm_source}
                utm_medium={this.props.utm_medium}
                utm_campaign={this.props.utm_campaign}
                utm_content={this.props.utm_content}
                utm_term={this.props.utm_term}
                gclid={this.props.gclid}
              />
              {/*
               * Display Update Credit Card Modal
               */
              this.updateCreditCardModal()}

              <Col md={5} xs={5} style={{ padding: 0 }}>
                <div className="payment-col-1">
                  <div className="top-box">
                    <form>
                      <Row className="plan-select-input">
                        <Col md={11}>
                          <FormGroup controlId="planStudents">
                            <FormControl
                              componentClass="select"
                              placeholder="select number of students"
                              value={this.state.paymentPlanStudents}
                              name="paymentPlanStudents"
                              disabled={this.props.loading}
                              onChange={this.handleChange}
                            >
                              <option value="5">
                                5 Student Package (Homeschool)
                              </option>
                              <option value="25">25 Student Package</option>
                              <option value="50">50 Student Package</option>
                              <option value="75">75 Student Package</option>
                              <option value="100">100 Student Package</option>
                              <option value="125">125 Student Package</option>
                              <option value="150">150 Student Package</option>
                            </FormControl>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="plan-select-input">
                        <Col md={11}>
                          <FormGroup controlId="planRecurringType">
                            <FormControl
                              componentClass="select"
                              placeholder="select recurring type"
                              value={this.state.planRecurringType}
                              name="planRecurringType"
                              disabled={this.props.loading}
                              onChange={this.handleChange}
                            >
                              <option value="Annual">Annual</option>
                              <option value="Monthly">Monthly</option>
                            </FormControl>
                          </FormGroup>
                        </Col>
                      </Row>
                      <ManageAccountButtons
                        hasSubscription={false}
                        openUpdateCreditCard={this.openUpdateCreditCard}
                        showConfirmationModal={() => {}}
                        openPaymentModal={this.openPaymentModal}
                        isPurchaseOrder={false}
                      />
                    </form>
                  </div>
                  {this.isHomeschoolMonthly() === false && (
                    <ListGroup>
                      {/* Build the header row */}
                      <ListGroupItem className="list-item col-1">
                        <p>Over 85 Instructional Units</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Diagnostic Assessments</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Video and Slideshow Lessons</p>
                      </ListGroupItem>
                      {/* <ListGroupItem className="list-item col-1">
                      <p>Integrated Kahoot Review Games</p>
                    </ListGroupItem> */}
                      <ListGroupItem className="list-item col-1">
                        <p>Practice Exercises</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Writing Application Activities</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Teacher Writing Portals</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Post-Evaluations</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Virtual Badges</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Student Progress Reporting</p>
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-1">
                        <p>Integrated Kahoot Review Games</p>
                      </ListGroupItem>
                    </ListGroup>
                  )}
                </div>
              </Col>
              <Col md={7} xs={7} style={{ padding: 0 }}>
                {this.isHomeschoolMonthly() && (
                  <h3 style={{ padding: "20px" }}>
                    Homeschool plans are annual only.  Please select annual.
                  </h3>
                )}
                {this.isHomeschoolMonthly() === false && (
                  <div className="payment-col-2">
                    <div className="top-box">
                      <Row>
                        <img alt="master graham" src={masterGraham} />
                      </Row>
                      <Row>
                        <h1 className="col-2">GrammarFlip Subscription</h1>
                        <h5 className="display-amount-of-students">
                          {amountOfStudents} Students
                        </h5>
                        <h3 style={{ fontWeight: 600, marginTop: "10px" }}>
                          ${masterPlanPriceString} /
                          {this.state.planRecurringType}
                        </h3>
                      </Row>
                      <Row className="payment-button">
                        <div>
                        <Button
                          onClick={() => this.openPaymentModal()}
                          disabled={this.props.loading}
                        >
                          Sign Me Up!
                        </Button>
                      </div>
                      </Row>
                    </div>
                    <ListGroup>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      <ListGroupItem className="list-item col-2">
                        <div className="fa fa-check green" />
                      </ListGroupItem>
                      {/* <ListGroupItem className="list-item col-2">
                      <div className="fa fa-check green" />
                    </ListGroupItem> */}
                    </ListGroup>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  const location = browserHistory.getCurrentLocation();
  const { utm_source, utm_medium, utm_campaign, utm_content, utm_term, gclid } = location.state;
  return {
    user: location.state,
    utm_source: utm_source,
    utm_medium: utm_medium,
    utm_campaign: utm_campaign,
    utm_content: utm_content,
    utm_term: utm_term,
    gclid: gclid,
    loading: state.ajaxCallsInProgress > 0,
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state),
    hasGoogleClasses: selectHasGoogleClasses(state),
  };
};

const myCheckoutForm = connect(mapStateToProps, {
  couponCheck,
  updateCard,
  manualAjaxEnd,
  manualAjaxStart,
})(DirectCheckout);
// export default injectStripe(myCheckoutForm);
export default myCheckoutForm;
