import * as React from "react";
import { connect } from "react-redux";
import {
  userSave,
  studentCreate,
  setSocialObj,
  userDecodeSocialCode
} from "../../actions/userActions";
import {
  manualAjaxEnd,
  manualAjaxStart
} from "../../actions/ajaxStatusActions";

import { browserHistory } from "react-router";
import { Row, Col, Grid, Button } from "react-bootstrap";
import StudentRegisterForm from "../auth/StudentRegisterForm";
import TeacherRegisterForm from "../auth/TeacherRegisterForm";

import { GFInitialState, GFUser } from "../../models/models";

import { selectIsAdmin, selectIsTeacher } from "../../reducers/userReducer";
import { addQuery } from "../../common/utils-router";

const graham = require("../../images/registerGraham.png");

export enum registerTypeEnum {
  student = 'student',
  teacher = 'teacher'
}

interface Iprops {
  type?: registerTypeEnum;
  startTeacherRegister: () => void;
  startStudentRegister: () => void;
  isDirectCheckout?: boolean;
}

const TeacherOrStudent = (props: Iprops) => {
  if (props.type) {
    return <div />;
  }
  return (
    <Col md={6}>
      <div className="role-buttons">
        <div className="teacher-section">
          <Row className="text-center">
            <Col>
              <h1> Teachers / Parents (click here) </h1>
            </Col>
          </Row>
          <Row className="text-center">
            <Col md={12}>
              <Button
                bsStyle="primary"
                bsSize="large"
                onClick={props.startTeacherRegister}
                type="button"
                role="button"
              >
                Start Your Free, 30-Day Trial!
              </Button>
            </Col>
          </Row>
        </div>
        <hr />
        <div className="student-section">
          <Row className="text-center">
            <Col>
              <h1> Students </h1>
            </Col>
          </Row>
          <Row className="text-center">
            <Col md={12}>
              <Button
                bsStyle="primary"
                bsSize="large"
                onClick={props.startStudentRegister}
                type="button"
                role="button"
              >
                Join your class
              </Button>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>Be sure to have the class code from your teacher handy!</Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

interface Props extends React.Props<Register> {
  user: GFUser;
  userSave: typeof userSave;
  loading: boolean;
  studentCreate: typeof studentCreate;
  params: any;
  socialObj: any;
  manualAjaxEnd: any;
  manualAjaxStart: any;
  setSocialObj: any;
  location: any;
  isTeacher: boolean;
  isAdmin: boolean;
  classcode?: string;
  type?: registerTypeEnum;
  isDirectCheckout?: boolean;
  googleCode?: string;
  userDecodeSocialCode: typeof userDecodeSocialCode;
}

class Register extends React.Component<Props> {

  componentDidMount() {
    if (this.props.classcode
    ) {
      addQuery({type: registerTypeEnum.student})
    }
    this.checkForLoggedInUser();
  }
  componentDidUpdate(prevProps: Props) {
    this.checkForLoggedInUser();
  }

  checkForLoggedInUser = () => {
    const { user, isTeacher, isAdmin } = this.props;
    if (user.id.length) {
      if (isTeacher || isAdmin) {
        // wait for the API key to be set
        setTimeout(() => {
          browserHistory.push("/classes");
        }, 300);
      } else {
        setTimeout(() => {
          browserHistory.push("/courses");
        }, 300);
      }
    }
  };

  goBack() {
    browserHistory.push("/");
  }
  printRegister() {
    if (this.props.type === undefined) {
      return;
    }
    if (this.props.type === registerTypeEnum.student) {
      return (
        <Col md={6} xsOffset={3}>
          <StudentRegisterForm
            handleSubmit={this.props.studentCreate}
            goBack={this.goBack}
            loading={this.props.loading}
            manualAjaxEnd={this.props.manualAjaxEnd}
            manualAjaxStart={this.props.manualAjaxStart}
            setSocialObj={this.props.setSocialObj}
            socialObj={this.props.socialObj}
            classcode={this.props.classcode || ""}
            googleCode={this.props.googleCode}
            userDecodeSocialCode={this.props.userDecodeSocialCode}
          />
        </Col>
      );
    } else {
      /*
      * Teacher Register form
      */
      return (
        <Col md={6}>
          <TeacherRegisterForm
            handleSubmit={this.props.userSave}
            goBack={this.goBack}
            loading={this.props.loading}
            manualAjaxEnd={this.props.manualAjaxEnd}
            manualAjaxStart={this.props.manualAjaxStart}
            setSocialObj={this.props.setSocialObj}
            socialObj={this.props.socialObj}
            googleCode={this.props.googleCode}
            userDecodeSocialCode={this.props.userDecodeSocialCode}
            isDirectCheckout={this.props.isDirectCheckout}
          />
        </Col>
      );
    }
  }
  startStudentRegister() {
    addQuery({type: registerTypeEnum.student})
  }

  startTeacherRegister() {
    addQuery({type: registerTypeEnum.teacher})
  }

  render() {
    return (
      <Grid className="registerPage content">
        <Row>
          <Col md={12}>
            <h1 className="intro-text text-center">
              Create your GrammarFlip Account!
            </h1>
          </Col>
        </Row>

        <Row>
          <TeacherOrStudent
            startTeacherRegister={this.startTeacherRegister}
            startStudentRegister={this.startStudentRegister}
            type={this.props.type}
            isDirectCheckout={this.props.isDirectCheckout}
          />

          {this.printRegister()}
          {this.props.type !== registerTypeEnum.student && (
            <Col md={6}  className="teacher-register-info">
              <Row>
                <Col md={12}>
                  <div className="login-graham-space">
                    <div className="fusion-column-wrapper">
                      {!this.props.isDirectCheckout && (
                        <h2 data-fontsize="28" data-lineheight="41">
                        Start your FREE, 30-day trial today!
                      </h2>
                      )}

                      <h4 className="copy">
                        Experience GrammarFlip's comprehensive grammar and writing platform: Designed by
                        Teachers For Teachers. Whether your classroom is large or small, digital or in-person, we have
                        an option that's just right for your students. Perfect for elementary, middle, and high school 
                        levels.
                      </h4>

                      <ul className="check-list">
                        <li>
                          Video Lessons by Real English Teachers
                        </li>
                        <li>
                          Practice Exercises with Instant Feedback
                        </li>
                        <li>
                          Promotes Student Participation
                        </li>
                        <li>
                          Identify Students Who Need Assistance
                        </li>
                        <li>
                          Virtual Badges for Encouraging Progress
                        </li>
                      </ul>

                      <h4 data-lineheight="38">
                        Used by Thousands of Teachers Around the World! {!this.props.isDirectCheckout && "Get started today, It’s free!"}
                      </h4>
                    </div>
                  </div>
                </Col>
              </Row>
          <div className="login-graham ">
            <img alt="graham" src={graham} />
          </div>
            </Col>
          )}

        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: any) => {
  let classcode = ownProps.location &&
  ownProps.location.query ? ownProps.location.query.classcode : undefined;
  const type = ownProps.location && ownProps.location.query ? ownProps.location.query.type : undefined;
  const isDirectCheckout = ownProps.location && ownProps.location.query ? ownProps.location.query.direct : undefined; 
  const googleCode = ownProps.location && ownProps.location.query ? ownProps.location.query.code : undefined;
  if (!classcode){
    // when registering with google, we store the classcode in the "state" param
    classcode = ownProps.location && ownProps.location.query ? ownProps.location.query.state : undefined;
  }
  return {
    user: state.user,
    socialObj: state.socialObj,
    loading: state.ajaxCallsInProgress > 0,
    isTeacher: selectIsTeacher(state),
    isAdmin: selectIsAdmin(state),
    classcode,
    type,
    googleCode,
    isDirectCheckout: !!isDirectCheckout,
  };
};

export default connect(
  mapStateToProps,
  {
    userSave,
    setSocialObj,
    studentCreate,
    manualAjaxEnd,
    manualAjaxStart,
    userDecodeSocialCode
  }
)(Register);
