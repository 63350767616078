import * as types from './actionTypes';
import { beginAjaxCall } from './ajaxStatusActions';
import { ThunkResult } from '../models/models';
import constants from '../constants';
import { toastr } from 'react-redux-toastr';
import { API } from '../apiEndpoints';
import Axios, { AxiosRequestConfig } from 'axios';

/*
* TEMPLATE AXIOS ACTION API REQUEST

export function templateAction(): ThunkResult<any> {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall());

    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url: API.something
    };
    return Axios(axiosOptions)
      .then(resp => {
        dispatch({ type: types.something_SUCCESS, something: resp.data });
        return something;
      })
      .catch((error: any) => {
        dispatch({ type: types.something_FAILED, error });
        constants.handleError(error, 'what failed');
        console.error('what failed', error)
        throw error;
      });
  };
}

*/

/*
* Add Lesson to Google Classroom.
*/
export function postLessonToGoogleClassroom(
  lessonID: string,
  lessonLink: string,
  classID: string,
): ThunkResult<any> {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url: API.google.createLesson,
      data: {lessonID, lessonLink, classID}
    }
    return Axios(axiosOptions)
      .then((resp) => {
        toastr.success('Success', 'Shared to Google Classroom', constants.toastrSuccess);
        dispatch({ type: types.ADD_LESSON_TO_GOOGLE_SUCCESS });
        return resp.data;
      })
      .catch(error => {
        dispatch({ type: types.ADD_LESSON_TO_GOOGLE_FAILED, error });
        const message = 'share lesson to Google Classroom'
        console.error(message, error);
        constants.handleError(error, message);
        throw error;
      });
  };
}

/*
* Add Quiz to Google Classroom.
*/
export function postQuizToGoogleClassroom(
  quizID: string,
  quizLink: string,
  classID: string,
): ThunkResult<any> {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url: API.google.createQuiz,
      data: {quizID, quizLink, classID}
    }
    return Axios(axiosOptions)
      .then((resp) => {
        toastr.success('Success', 'Shared to Google Classroom', constants.toastrSuccess);
        dispatch({ type: types.ADD_QUIZ_TO_GOOGLE_SUCCESS });
        return resp.data;
      })
      .catch(error => {
        const message = 'post quiz to Google Classroom'
        console.error(message, error);
        constants.handleError(error, message);
        dispatch({ type: types.ADD_QUIZ_TO_GOOGLE_FAILED, error });
        throw error;
      });
  };
}

/*
* Add Writing assignment to Google Classroom.
*/
export function postWAToGoogleClassroom(
  writingAssignmentID: string,
  writingAssignmentLink: string,
  classID: string,
): ThunkResult<any> {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url: API.google.createWritingAssignment,
      data: {writingAssignmentID, writingAssignmentLink, classID}
    }
    return Axios(axiosOptions)
      .then((resp) => {
        toastr.success('Success', 'Shared to Google Classroom', constants.toastrSuccess);
        dispatch({ type: types.ADD_WA_TO_GOOGLE_SUCCESS });
        return resp.data;
      })
      .catch(error => {
        const message = 'post writing assignment to Google Classroom'
        console.error(message, error);
        constants.handleError(error, message);
        dispatch({ type: types.ADD_WA_TO_GOOGLE_FAILED, error });
        throw error;
      });
  };
}

/*
* Teacher getting Classrooms from Google.
*/
export function loadGoogleClasses(callback: () => void): ThunkResult<any> {
  return function(dispatch) {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
      method: 'get',
      url: API.google.getClasses,
    };
    return Axios(axiosOptions)
      .then((resp) => {
        dispatch({ type: types.LOAD_GOOGLE_CLASSES_SUCCESS, googleClasses: resp.data });
        callback();
        return resp.data;
      })
      .catch(error => {
        const message = 'download list of Google Classroom classes'
        console.error(message, error);
        constants.handleError(error, message);
        dispatch({ type: types.LOAD_GOOGLE_CLASSES_FAILED, error });
        callback();
        throw error;
      });
  };
}

/*
* Teacher importing students from Google Classroom.
*/
export function importStudents(classes: any, callback: () => void): ThunkResult<any> {
  const classIDs: any[] = [];
  classes.forEach((item: any) => {
    classIDs.push(item.value);
  });

  return function(dispatch) {
    dispatch(beginAjaxCall());
    const axiosOptions: AxiosRequestConfig = {
      method: 'post',
      url: API.google.importStudents,
      data: classIDs
    };
    return Axios(axiosOptions)
      .then((resp) => {
        dispatch({ type: types.IMPORT_STUDENTS_SUCCESS });
        callback();
        return resp.data;
      })
      .catch(error => {
        const message = 'importing students'
        console.error(message, error);
        constants.handleError(error, message);
        dispatch({ type: types.IMPORT_STUDENTS_FAILED, error });
        callback();
        throw error;
      });
  };
}

// export function confirmImportStudents(callback: () => void): ThunkResult<any>{
//   return (dispatch) => {
//     const okText = 'Sync';
//     const confirmMessage = "Are you sure you want to sync your account with Google Classroom? This will delete your other classes.";

//     const toastrConfirmOptions = {
//       onOk: () => {
//         dispatch(importStudents(callback));
//       },
//       onCancel: () => console.log('CANCEL: clicked'),
//       okText,
//       cancelText: 'cancel'
//     };
//     toastr.confirm(confirmMessage, toastrConfirmOptions)
//   }
// }
