import * as React from "react";

import { Button, Col, Grid, Panel, Row } from "react-bootstrap";
import {
  GFBadge,
  GFCourse,
  GFInitialState,
  GFLesson,
  GFUser
} from "../../models/models";
import { filter, isEmpty, map } from "lodash";
import {
  filterLessonsWithCustomLessonHelper,
  getCustomLessons
} from "../../actions/customLessonActions";

import Badges from "./Badges";
import { CustomLessonClassSelectContainer } from "../common/CustomLessonClassSelectContainer";
import { LinkContainer } from "react-router-bootstrap";
import MainMenu from "../menu/MainMenu";
import { RouteComponentProps } from "react-router";
import { StudentBadge } from "./Badge";
import VerificationAlert from "../common/VerificationAlert";
import { connect } from "react-redux";
import constants from "../../constants";
import { getAllCourses } from "../../actions/courseActions";
import { getAllLessons } from "../../actions/lessonActions";
import { getBadges } from "../../actions/studentActions";
import { toastr } from "react-redux-toastr";
import { selectIsStudent, selectIsTeacher, selectIsSuperTeacher, selectHasGoogleClasses } from "../../reducers/userReducer";

const graham = require("../../images/graham.gif");

const CoursePanel = ({ gfCourse }: { gfCourse: GFCourse }) => {
  const shortenDescription = (text: string) => {
    let ret = text;
    const maxLength = 95;
    if (ret.length > maxLength) {
      ret = ret.substr(0, maxLength - 3) + "...";
    }
    return ret;
  };

  const shortenTitle = (text: string) => {
    let ret = text;
    const maxLength = 55;
    if (ret.length > maxLength) {
      ret = ret.substr(0, maxLength - 3) + "...";
    }
    return ret;
  };

  return (
    <LinkContainer to={`/courseLessons/${gfCourse.id}`}>
      <Col xs={12} sm={6} md={4} className="course animated fadeInUp">
        <Panel className="text-center">
          <h2>{shortenTitle(gfCourse.name)}</h2>
          <h4>{shortenDescription(gfCourse.description)}</h4>
          <div className="course-footer" />
        </Panel>
      </Col>
    </LinkContainer>
  );
};

const StudentCourseMessage = ({
  isActive,
  hasClassCode
}: {
  isActive: boolean;
  hasClassCode: boolean;
}) => {
  if (isActive === true && hasClassCode) {
    return <h1 className="text-center">Dashboard</h1>;
  } else if (isActive === false && hasClassCode === false) {
    return (
      <h1 className="text-center">
        <span>
          Welcome back to your GrammarFlip account! You are not currently
          enrolled in a class, so you will need to ask your teacher for a class
          code. Once you have a class code, you can add yourself to a class by
          <LinkContainer to={`/profile`}>
            <Button bsStyle="link" bsSize="sm" className="update-profile-link">
              clicking here.
            </Button>
          </LinkContainer>
        </span>
      </h1>
    );
  } else {
    // have a classcode but it is inactive
    return (
      <h1 className="text-center">
        <span>
          Welcome back to your GrammarFlip account! You are enrolled in a class,
          but the teacher's account is not active. Please ask your teacher to
          renew their account or ask for a new classcode. You can replace your
          classcode on your profile by
          <LinkContainer to={`/profile`}>
            <Button bsStyle="link" bsSize="sm" className="update-profile-link">
              clicking here.
            </Button>
          </LinkContainer>
        </span>
      </h1>
    );
  }
};

const IsEmptyMessage = ({
  isEmptyLessons,
  isTeacher,
  hasClassCode = false
}: {
  isEmptyLessons: boolean;
  isTeacher: boolean;
  hasClassCode?: boolean;
}) => {

  if (isEmptyLessons) {
    if (isTeacher) {
      return (
        <h4 className={"text-center"}>No lessons enabled for this class.</h4>
      );
    } else if (isTeacher === false && hasClassCode){
      return (
        <h4 className={"text-center"}>
          Hold tight! Your teacher has not enabled any lessons yet.
        </h4>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

interface Props extends RouteComponentProps<{}, {}> {
  user: GFUser;
  filteredCourses: GFCourse[];
  lessons: { [key: string]: GFLesson };
  badges: GFBadge[];
  getAllCourses: typeof getAllCourses;
  getBadges: typeof getBadges;
  getCustomLessons: typeof getCustomLessons;
  loading: boolean;
  getAllLessons: typeof getAllLessons;
  hasGoogleClasses: boolean;
  isTeacher: boolean;
  isSuperTeacher: boolean;
  isStudent: boolean;
}

interface Istate {
  selectedClass: any;
}

class Courses extends React.Component<Props, Istate> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedClass: null
    };
    this.printStudentCourses = this.printStudentCourses.bind(this);
    this.printTeacherCourses = this.printTeacherCourses.bind(this);
    this.showBadge = this.showBadge.bind(this);
    this.openKahootLesson = this.openKahootLesson.bind(this);
  }

  componentDidMount() {
    if (this.props.user.classes.length){

      if (!this.props.filteredCourses.length) {
        this.props.getAllCourses();
      }
      if (isEmpty(this.props.lessons)) {
        this.props.getAllLessons();
      }
      if (this.props.isStudent) {
        this.props.getBadges();
      }
    }

    // no need to get custom lessons here, we already call this for students from App.tsx and Lesson.tsx
    // if (
    //   isStudent(this.props.user.roleID) &&
    //   this.props.user.classes.length
    // ) {
    //   this.props.getCustomLessons(this.props.user.classes[0].id);
    // }
  }

  // TODO refactor this to be a .message like in quizActions or visa versa
  showBadge(badge: GFBadge) {
    const toastrOptions = {
      ...constants.toastrSuccessBadge,
      component: <StudentBadge badge={badge} className="toast-badge" />
    };
    toastr.success("", "", toastrOptions);
  }

  printStudentCourses() {
    return (
      <div className="main-content content-without-sidebar courses animated fadeIn">
        <VerificationAlert user={this.props.user} isTeacher={this.props.isTeacher} />
        <Row className="sub-header">
          <Col md={8} xs={8}>
            <StudentCourseMessage
              isActive={this.props.user.isActive}
              hasClassCode={this.props.user.classes.length > 0}
            />
          </Col>
          <Col md={4} xs={4}>
            <h1 className="text-center">Your Badges</h1>
          </Col>
        </Row>
        <Row className="">
          <Col xs={8} sm={8} md={8}>
            <IsEmptyMessage
              isEmptyLessons={this.props.filteredCourses.length === 0}
              isTeacher={this.props.isTeacher}
              hasClassCode={this.props.user.classes.length > 0}
            />
            <div className="courses-list text-center">
              {this.props.user.isActive && 
                map(this.props.filteredCourses, gfCourse => {
                  return <CoursePanel gfCourse={gfCourse} key={gfCourse.id} />;
                })}
            </div>
          </Col>
          <Col xs={4} sm={4} md={4} className="badges">
            <Badges badges={this.props.badges} />
          </Col>
        </Row>
      </div>
    );
  }

  printTeacherCourses() {
    return (
      <div className="main-content content-with-sidebar courses animated fadeIn">
        <VerificationAlert user={this.props.user} isTeacher={this.props.isTeacher}/>
        <CustomLessonClassSelectContainer />
        <IsEmptyMessage
          isEmptyLessons={this.props.filteredCourses.length === 0}
          isTeacher={this.props.isTeacher}
        />
        <div className="row courses-list text-center">
          {map(this.props.filteredCourses, gfCourse => {
            return <CoursePanel gfCourse={gfCourse} key={gfCourse.id} />;
          })}
        </div>
      </div>
    );
  }

  openKahootLesson(kahootPath: string) {
    window.open(kahootPath);
  }

  /*
  * figure out what to display, courses for students, courses for teachers
  */
  displayCourseHtml() {
    let displayHtml = <div />;
    if (this.props.isStudent) {
      displayHtml = this.printStudentCourses();
    }
    if (this.props.isTeacher) {
      displayHtml = this.printTeacherCourses();
    }
    return displayHtml;
  }

  render() {
    return (
      <Grid className="content modal-container">
        {this.props.isTeacher && (
          <div className="sidemenu">
            <MainMenu isTeacher={this.props.isTeacher} isSuperTeacher={this.props.isSuperTeacher} hideManageStudents={this.props.hasGoogleClasses} />
            <div className="logo text-center">
              <img alt="graham" src={graham} />
            </div>
          </div>
        )}
        {this.displayCourseHtml()}
      </Grid>
    );
  }
}

const mapStateToProps = (state: GFInitialState, ownProps: Props) => {
  // when student, use the student's classID, when teacher use the selected classID
  let selectedClassID = state.selectedClassID;
  const isStudent = selectIsStudent(state);
  if (isStudent && state.user.classes.length) {
      selectedClassID = state.user.classes[0].id;
  }
  const filteredCourses = filter(state.courses, course => {
    const lessonsWithCutomLessons = filterLessonsWithCustomLessonHelper(
      state.lessons,
      state.customLessons,
      selectedClassID,
      course.id
    );
    return !!lessonsWithCutomLessons.length;
  });
  return {
    user: state.user,
    filteredCourses,
    lessons: state.lessons,
    badges: state.badges,
    loading: state.ajaxCallsInProgress > 0,
    hasGoogleClasses: selectHasGoogleClasses(state),
    isTeacher: selectIsTeacher(state),
    isSuperTeacher: selectIsSuperTeacher(state),
    isStudent
  };
};

export default connect(
  mapStateToProps,
  {
    getAllCourses,
    getBadges,
    getCustomLessons,
    getAllLessons
  }
)(Courses);
