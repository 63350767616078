import React from 'react'
import { find, forEach } from "lodash";
import {
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  Modal,
} from "react-bootstrap";
import { debounce } from 'lodash';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { GFUser, GFTeacherUser, GFSuperTeacherPlan } from './../../models/models';
import constants from "../../constants";
import { initialUser } from '../../reducers/initialState';
import { toastr } from 'react-redux-toastr';

const FontAwesome = require("react-fontawesome");

interface Props extends React.Props<AddTeacherModal> {
  showAddTeacher: boolean;
  hideAddTeacher: () => void;
  loading: boolean;
  addTeacher: (
    teacher: GFTeacherUser,
  ) => Promise<any>;
  setTeacherToAdd: (teacher: any) => void;
  teacher: GFTeacherUser;
  plans: GFSuperTeacherPlan[];
  searchTeachers: (query: string) => GFTeacherUser[],
  refreshTeacherList: () => void,
  user: GFUser,
}

interface State {
  id: string;
  email: string;
  first: string;
  last: string;
  plan: string;
  formValidations: any;
  formValid: boolean;
  isSearching: boolean;
  debouncedSearch: any;
  selectedPlan: any;
  searchResults: GFTeacherUser[];
}

class AddTeacherModal extends React.Component<Props, State> {

  /**
   * The constructor
   * @param props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      id: '',
      email: '',
      first: '',
      last: '',
      plan: '',
      formValidations: {
        email: {
          validationState: null,
          validationMessage: "",
          valid: false,
          validators: [
            { required: true, message: "Email is required" },
            {
              pattern: constants.emailRegex,
              message: constants.emailValidationMessage,
            },
          ],
        },
        first: {
          validationState: null,
          validationMessage: "",
          valid: false,
          validators: [{ required: true, message: "First Name is required" }],
        },
        last: {
          validationState: null,
          validationMessage: "",
          valid: false,
          validators: [{ required: true, message: "Last Name is required" }],
        },
        plan: {
          validationState: null,
          validationMessage: "",
          valid: false,
          validators: [{ required: true, message: "Plan is required" }],
        },
      },
      formValid: false,
      isSearching: false,
      debouncedSearch: null,
      selectedPlan: null,
      searchResults: [],
    }
  }

  /**
   * Get called when component will unmount
   */
  componentWillUnmount() {
    const { setTeacherToAdd } = this.props;
    setTeacherToAdd({});
    this.setState({
      id: '',
      email: '',
      formValid: false,
      first: '',
      last: '',
      selectedPlan: '',
      plan: ''
    });
  }

  /**
   * Validate a Form
   */
  validateForm() {
    const formValid = !find(this.state.formValidations, ["valid", false]);
    this.setState({ formValid });
  }

  /**
   * Validate a Form input
   * @param value 
   * @param validator 
   * @returns boolean
   */
  validate(value: string, validator: any) {
    let valid = true;
    if (validator.pattern && value.length > 0) {
      const newValue = value.match(validator.pattern);
      valid = newValue ? true : false;
    }
    if (validator.required) {
      valid = !!value.length || false;
    }
    if (validator.mustMatch) {
      valid =
        this.state[validator.mustMatch as keyof State] === value ? true : false;
    }
    return valid;
  }

  /**
   * Perform Form validations
   * @param name 
   * @param value 
   * @param showErrors 
   */
  validateField(name: string, value: string, showErrors: boolean) {
    // loop over each field we need to validate
    forEach(this.state.formValidations, (field, key) => {
      if (key === name) {
        const newFormValidations = Object.assign(
          {},
          this.state.formValidations
        );
        let errorMessage = "";

        // loop over each validation for this field, and set error to the error message if something is invalid
        field.validators.forEach((validator: any) => {
          if (!this.validate(value, validator)) {
            errorMessage = validator.message;
          }
        });
        // after we have checked all the validators, is there an error message for this field?
        if (!errorMessage) {
          // no error message
          newFormValidations[name].validationState = "success";
          newFormValidations[name].validationMessage = "";
          newFormValidations[name].valid = true;
          if (!showErrors) {
            newFormValidations[name].validationState = null;
            newFormValidations[name].validationMessage = "";
          }
        } else {
          // error
          // only show the errors if showErrors
          if (showErrors) {
            newFormValidations[name].validationState = "error";
            newFormValidations[name].validationMessage = errorMessage;
          }

          newFormValidations[name].valid = false;
        }
        this.setState(
          { formValidations: newFormValidations },
          this.validateForm
        );
      }
    });
  }
  
  /**
   * Gets called when a Form input changes
   * @param e 
   */
  handleChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [name]: value } as unknown as State, () => {
      this.validateField(name, value, true)
    });
  }

  /**
   * Gets called when Teacher Plan is selected
   * @param e 
   */
  handlePlanChange = (plan: any) => {
    this.setState({
      selectedPlan: plan,
      plan: plan.id
    }, () => {
      this.validateField('plan', plan.id, true)
    });
  }

  /**
   * Gets called when teacher search result is selected
   * @param selectedTeacher 
   */
  handleAsyncSelectChange = (selectedTeacher: any, searchResults: any) => {
    const tempTeacher = searchResults.reduce((teacher: any, currentIteration: GFTeacherUser) => {
      return currentIteration.id === selectedTeacher.id ? currentIteration : teacher;
    }, null);
    if(tempTeacher){
      this.setState({
        id: tempTeacher.id,
        first: tempTeacher.first,
        last: tempTeacher.last,
        email: tempTeacher.email
      }, () => {
        this.validateField('email', selectedTeacher.label, true);
        this.validateField('first', tempTeacher.first, true);
        this.validateField('last', tempTeacher.last, true);
      });                    
    } else {
      // What happened, this should not be possible. We selected from our same list.
    }
    this.validateForm()
  }

  /**
   * Search the api for teachers
   * @param query string
   * @param callback function
   * @param setIsSearching function
   */
  teacherSearch = async (query: string, callback: (results: Array<{id: string, label: string}>) => void, setIsSearching: (isSearching: boolean) => void) => {
    const { searchTeachers, user } = this.props;
    // Only do the search if email is a valid regex pattern
    const emailValid = query.match(constants.emailRegex) ? true : false;
    if (emailValid) {
      setIsSearching(true);
      const searchResults = await searchTeachers(query)
      const teacherOptions: any = [];
      let tempUser: any = {};
      if(searchResults.length === 0){
        // Only if email does not exist in our subTeachers
        const existingSubTeacher = find(user.subTeachers, {email: query});
        if(!existingSubTeacher){
          // Insert one with no ID that they can create from.
          tempUser = {
            ...initialUser,
            email: query
          };
          this.props.setTeacherToAdd(tempUser);
          teacherOptions.push({
            id: tempUser.id,
            label: tempUser.email
          });
        }
        if(tempUser){
          this.setState({
            searchResults: [tempUser]
          });
        }
      } else {
        const currentSubTeacherIds = user.subTeachers.map(subTeacher => {
          return subTeacher.id;
        });
        searchResults.forEach(result => {
          // Only return teachers we don't already have.
          if(currentSubTeacherIds.indexOf(result.id) < 0){
            teacherOptions.push({
              id: result.id,
              label: result.email
            });
          }
        });
        this.setState({
          searchResults: searchResults
        });
      }
      callback(teacherOptions);
      setIsSearching(false);
    } else {
      callback([]);
      this.setState({
        searchResults: []
      });
    }
  }

  /**
   * Send selected teacher to API
   */
  addTeacher = () => {
    const { hideAddTeacher, setTeacherToAdd, teacher } = this.props;
    // Send teacher and plan to API
    const teacherPacket = {
      ...teacher,
      id: this.state.id,
      paymentPlan: this.state.selectedPlan.rawLabel,
      first: this.state.first,
      email: this.state.email,
      last: this.state.last,
    };
    this.props.addTeacher(teacherPacket)
    .then((resp:any) => {
      // Close modal.
      this.props.refreshTeacherList();
      toastr.success(
        "Success",
        !!teacherPacket.id ? "Teacher added." : "Your new teacher has been created. We have emailed them their temporary password.",
        constants.toastrSuccess
      );
      this.setState({email: '', first: '', last: '', selectedPlan: '', plan: '', formValid: false});
      setTeacherToAdd({});
      this.validateForm();
      hideAddTeacher();
    })
    .catch(e => {
      // Close modal.
      toastr.error(
        "Error",
        "Unable to add teacher. Please check the form and try again.",
        constants.toastrError
      );
      this.setState({email: '', first: '', last: '', selectedPlan: '', plan: '', formValid: false});
      setTeacherToAdd({});
      this.validateForm();
      hideAddTeacher();
    });                
  }

  /**
   * The AsyncSelect options loader
   * @param inputValue 
   * @param callback 
   */
  loadOptions = (inputValue: string, callback: (requestResults: Array<{}>) => void) => {
    const { isSearching, debouncedSearch } = this.state;
    if(!isSearching){
      if(debouncedSearch){
        debouncedSearch.cancel();
        this.setState({
          debouncedSearch: null
        });
      }
      const newDebouncedSearch = debounce(this.teacherSearch, 1500);
      if(!inputValue){
        debouncedSearch.cancel();
        callback([]);
        this.setState({
          isSearching: false,
          debouncedSearch: null
        });
      } else {
        this.setState({
          debouncedSearch: newDebouncedSearch
        });
        return newDebouncedSearch(inputValue, callback, (isSearching) => {
          this.setState({
            isSearching: isSearching
          })
        })
      }
    }
  }

  /**
   * Parse available plans to Select options
   * @param plans 
   * @returns []
   */
  loadPlanOptions = (plans: GFSuperTeacherPlan[]) => {
    return plans.map((plan: GFSuperTeacherPlan) => {
      const planWithName = find(constants.paymentPlanObjectsByID, currentPlan => {
        return currentPlan.id === plan.plan;
      });
      let label = planWithName ? planWithName.name : plan.plan;
      if(plan.quantity === 1){
        label = `${label} (${plan.quantity} plan remaining)`;
      } else {
        label = `${label} (${plan.quantity} plans remaining)`;
      }
      return {
        id: plan.id,
        label: label,
        isDisabled: plan.quantity === 0,
        rawLabel: plan.plan
      }
    });
  }

  /**
   * Renders the component
   * 
   * @returns Modal
   */
  render () {
    const { showAddTeacher, hideAddTeacher, loading, plans } = this.props;
    const { first, last, formValid, isSearching, searchResults, email, id } = this.state;
    const planOptions: any = this.loadPlanOptions(plans);
    const isExistingTeacher = !!id;
    return (
      <Modal
        show={showAddTeacher}
        onHide={hideAddTeacher}
        container={this}
      >
        <Modal.Header>
          <Modal.Title>
            <span className="body-text">{id ? `Add Teacher` : `Add New Teacher`}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="teacherForm" onSubmit={() => {}}>
            <FormGroup
              controlId="teacherSearch"
              bsSize="lg"
            >
              <ControlLabel>Email</ControlLabel>
              <AsyncSelect
                loadOptions={this.loadOptions}
                className="react-select-container"
                onChange={(selectedTeacher) => {
                  this.handleAsyncSelectChange(selectedTeacher, searchResults);
                  this.validateForm();
                }}
                isSearchable={true}
                isDisabled={isSearching}
                value={{
                  value: id,
                  label: (isExistingTeacher && email || !email) ? email : `Add New Teacher (${email})`,
                }}
                onBlur={() => {
                  this.validateForm();
                }}
                placeholder="Select a Teacher"
                classNamePrefix="react-select"
              />
            </FormGroup>
            <FormGroup
              controlId="teacherFirstName"
              bsSize="lg"
              validationState={
                this.state.formValidations.first.validationState
              }
            >
              <ControlLabel>First Name</ControlLabel>
              <FormControl
                type="text"
                name="first"
                value={first}
                readOnly={isExistingTeacher && !!first}
                placeholder="First name"
                onChange={this.handleChange}
              />
              <FormControl.Feedback />
            </FormGroup>
            <FormGroup
              controlId="teacherLastName"
              bsSize="lg"
              validationState={
                this.state.formValidations.last.validationState
              }
            >
              <ControlLabel>Last Name</ControlLabel>
              <FormControl
                type="text"
                name="last"
                value={last}
                readOnly={isExistingTeacher && !!last}
                placeholder="Last name"
                onChange={this.handleChange}
              />
              <FormControl.Feedback />
            </FormGroup>
            <FormGroup
              controlId="teacherPlan"
              bsSize="lg"
              validationState={
                this.state.formValidations.plan.validationState
              }
            >
              <ControlLabel>Plan</ControlLabel>
              <Select
                className="react-select-container"
                options={planOptions}
                onChange={this.handlePlanChange}
                value={this.state.selectedPlan}
                placeholder="Select a plan"
                classNamePrefix="react-select"
                isSearchable={false}
              />
              <FormControl.Feedback />
            </FormGroup>
            <Button
              disabled={loading || !formValid}
              bsStyle="primary"
              type="button"
              block
              onClick={this.addTeacher}
            >
              <FontAwesome name="plus" />
              <span className="backLink">{!id ? 'Create and Add Teacher' : 'Add Existing Teacher'}</span>
            </Button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={hideAddTeacher}
            bsStyle="default"
            type="button"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddTeacherModal;
